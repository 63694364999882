import React from 'react';
import { BiConsumer } from '../../../types'
import { PersonEmail } from '../../admin-types';
import SingleCheckBox from '../../../Form/Components/CheckBox';

type DisplayPersonProps = PersonEmail & {
    togglePerson: BiConsumer<string, boolean>,
    checked: boolean,
}
export default function DisplayPerson({ personid, checked, firstName, lastName, email, status, togglePerson }: DisplayPersonProps) {
    return (
        <SingleCheckBox field={personid} value={checked} setValue={togglePerson} label={`${firstName} ${lastName} - ${email}`} disabled={status !== 'OK'}/>
    )
}