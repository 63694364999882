import React from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';

import { useAuth } from '../Contexts/AuthContext';
import { useRouter, routes } from '../Contexts/RouterContext';

export default function ProtectedRoute(props: RouteProps) {
    const { isAuth, registrationStep } = useAuth()
    const { pathname } = useRouter()
    if (isAuth) {
        if (registrationStep === 'DONE' || registrationStep === 'APPROVED') {
            return <Route {...props} />
        } else {
            return <Redirect to={{
                pathname: routes.registerUser,
                state: { redirected: true }
            }} />
        }
    } else {
        return <Redirect to={{
            pathname: routes.login,
            state: { next: pathname, redirected: true }
        }} />
    }
}