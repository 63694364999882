import React, { useState, useCallback, useEffect } from 'react'

import { useTextLang } from '../Contexts/LangContext';
import FormContainer from './FormContainer';
import { Grid, Typography, Divider } from '@material-ui/core';
import { AddBox, Check } from '@material-ui/icons';
import { useAuth } from '../Contexts/AuthContext';
import { useRouter, routes } from '../Contexts/RouterContext';
import { Callback, Consumer, PersonInfoType } from '../types';
import { useBasicTexts } from '../Global/GlobalItems';
import useForm, { FormatForm } from '../Form/FormUtils';
import PersonFields, { fullPersonRules, personDefaultValues } from '../Form/PersonFields';
import FormNavigation from '../Form/FormNavigation';
import { FamilyPerson } from '../server-types';


const childrenInfoTexts = {
    title: {
        en: 'Family Members'
    },
    child: {
        en: 'Child'
    },
    addChild: {
        en: 'Add child'
    },
}

const ChildrenInfo = () => {
    const [editing, setEditing] = useState(false)
    const [loading, setLoading] = useState(false)
    const [parents, setParents] = useState<FamilyPerson[]>([])
    const [children, setChildren] = useState<FamilyPerson[]>([])

    const texts = useTextLang(childrenInfoTexts)
    const basicTexts = useBasicTexts()
    const { authMutation, authQuery } = useAuth()

    const updateFamilyInfo = useCallback(() => {
        const processInfo = (info: { persons: FamilyPerson[] }) => {
            if (info) {
                const { persons } = info
                setParents(persons.filter(({ role }) => role !== 'child'))
                setChildren(persons.filter(({ role }) => role === 'child'))
                setLoading(false)
            }
        }
        authQuery('getFamilyInfo', processInfo, undefined, 'persons{personid,name,role}')
    }, [authQuery])

    useEffect(() => {
        updateFamilyInfo()
    }, [updateFamilyInfo])

    const finishChild = useCallback((name: string) => {
        updateFamilyInfo()
        setEditing(false)
    }, [updateFamilyInfo])

    const addChild = useCallback(() => {
        setEditing(true)
    }, [])

    const cancelChild = useCallback(() => {
        setEditing(false)
    }, [])

    const { updateRegistrationStep } = useAuth()
    const { navigateTo } = useRouter()
    const finish = useCallback(() => {
        const processMutation = (success: boolean) => {
            if (success) {
                updateRegistrationStep()
            }
            else {
                navigateTo(routes.error)
            }
        }
        setLoading(true)
        authMutation('finishRegistration', processMutation)
    }, [updateRegistrationStep, navigateTo, authMutation])

    return (
        <FormContainer title={texts.title}>
            {parents.map(({ personid, name }) =>
                <ChildTitle name={name} key={personid} />
            )}
            {children.map(({ personid, name }) =>
                <ChildTitle name={name} key={personid} />
            )}
            {editing ?
                <ChildInfo
                    number={children.length + 1}
                    onFinish={finishChild}
                    onCancel={cancelChild}
                />
                :
                <>
                    <AddChild onClick={addChild} />
                    <FormNavigation
                        onSubmit={finish}
                        submitText={basicTexts.finish}
                        loading={loading}
                    />
                </>
            }
        </FormContainer>
    )
}

const ChildTitle = ({ name }: { name: string }) => {
    return (
        <>
            <Grid item container justify='space-between'>
                <Typography variant='h6'>
                    {name}
                </Typography>
                <Check color='primary' />
            </Grid>
            <Divider />
        </>
    )
}

type ChildInfoType = {
    onFinish: Consumer<string>,
    onCancel: Callback,
    number: number,
}
const ChildInfo = ({ onFinish, onCancel, number }: ChildInfoType) => {
    const [loading, setLoading] = useState(false)
    const texts = useTextLang(childrenInfoTexts)
    const name = `${texts.child} ${number}`
    const { navigateTo } = useRouter()
    const basicTexts = useBasicTexts()
    const { authMutation } = useAuth()

    const finish = useCallback((values: Record<keyof PersonInfoType, string>) => {
        setLoading(true)
        const processMutation = (success: boolean) => {
            if (success) {
                onFinish(values.firstName)
            } else {
                navigateTo(routes.error)
            }
        }
        authMutation('registerChild', processMutation, `firstName:"${values.firstName.trim()}",lastName:"${values.lastName.trim()}",sex:${values.sex},dateOfBirth:"${values.dateOfBirth}",nationality:"${values.nationality}",preferredLanguage:"${values.preferredLanguage}",email:"${values.email}",phoneNumber:"${values.phoneNumber}",phoneCarrier:"${values.phoneCarrier}"`)
    }, [onFinish, navigateTo, authMutation])

    const { values, setValue, errors, setError, handleSubmit } = useForm(fullPersonRules(), finish, personDefaultValues)

    return (
        <>
            <Grid item container direction='row'>
                <Typography variant='h6'>
                    {name}
                </Typography>
            </Grid>
            <Divider />
            <FormatForm>
                <PersonFields values={values} setValue={setValue} errors={errors} setError={setError} />
            </FormatForm>
            <FormNavigation
                onSubmit={handleSubmit}
                submitText={basicTexts.done}
                onCancel={onCancel}
                cancelText={basicTexts.cancel}
                loading={loading}
            />
        </>
    )
}

const AddChild = ({ onClick }: { onClick: Callback }) => {
    const texts = useTextLang(childrenInfoTexts)
    return (
        <>
            <Grid item container direction='row' spacing={1} wrap='nowrap' onClick={onClick}>
                <Grid item xs container alignItems='center'>
                    <AddBox color='disabled' />
                </Grid>
                <Grid item container>
                    <Typography variant='h6' color='textSecondary'>
                        {texts.addChild}
                    </Typography>
                </Grid>
            </Grid>
        </>
    )
}

export default ChildrenInfo