import React, { useEffect, useCallback, useRef } from 'react'
import { TextField, useMediaQuery, Theme } from '@material-ui/core'
import { FormField, defaultText } from '../../types';
import { TextFieldProps } from '@material-ui/core/TextField';
import { useTheme } from '@material-ui/styles';
import { scrollToView } from '../../Global/GlobalItems';

type TextFieldType<FieldName> = FormField<FieldName, string> & {
    minLength?: number,
    maxLength?: number,
} & TextFieldProps
export default function Text<FieldName>({ field, value, setValue, errorMsg, onEnter, minLength = 0, maxLength = 9999, required, helperText, ...rest }: TextFieldType<FieldName>) {
    
    const handleChange: TextFieldProps['onChange'] = useCallback((event) => {
        setValue(field, event.target.value)
    }, [field, setValue])

    const keyPress = useCallback(({ keyCode }: { keyCode: number }) => {
        if (keyCode === 13 && onEnter) {
            onEnter()
        }
    }, [onEnter])

     // Remove value on unmount
     useEffect(() => {
        return () => {
            setValue(field, defaultText)
        }
    }, [field, setValue])

    const theme = useTheme<Theme>()
    const matches = useMediaQuery(theme.breakpoints.down('xs'))
    const myRef = useRef<HTMLDivElement>(null)
    const handleFocus = useCallback(() => {
        if (matches)
            scrollToView(myRef)
    }, [myRef, matches])

    return (
        <TextField
            autoComplete='off'
            autoCorrect='off'
            type='text'
            margin='dense'
            fullWidth={true}
            {...rest}
            ref={myRef}
            onKeyDown={keyPress}
            onFocus={handleFocus}
            value={value || ''}
            onChange={handleChange}
            helperText={errorMsg || helperText}
            error={errorMsg !== undefined}
        />
    )
}