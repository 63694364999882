import React, { useMemo } from 'react'
import moment from 'moment-timezone'
import { Grid, Typography, Divider, CardContent, Theme, useMediaQuery, Button } from '@material-ui/core'
import Image from '../Global/Image'
import { useTextLang } from '../Contexts/LangContext';
import { FullEvent, EventSession } from '../server-types';
import { formatDateRange, LineTypography } from '../Global/GlobalItems';
import { Link as RouterLink } from 'react-router-dom'
import { makeStyles, useTheme } from '@material-ui/styles';
import { routes } from '../Contexts/RouterContext';
import Price from '../Global/PriceFormat';

const eventContentsTexts = {
    moreInfo: {
        en: 'More Info'
    },
    price: {
        en: 'Price'
    },
    pricePerParticipant: {
        en: 'per participant'
    },
    pricePerEvent: {
        en: 'total'
    },
    location: {
        en: 'Location'
    },
    sessions: {
        en: 'Sessions:'
    },
    donationsWelcome: {
        en: 'Donations greatly appreciated'
    },
    suggestedDonation: {
        en: `Suggested donation`
    }
}

const useStyles = makeStyles(theme => ({
    disabledSession: {
        color: theme.palette.grey[400],
    }
}))

type EventContentsProps = {
    eventInfoID: string,
    desktop: boolean,
    eventData: Partial<FullEvent>
}
export default function EventContents({ eventInfoID, desktop, eventData }: EventContentsProps) {
    const texts = useTextLang(eventContentsTexts)

    const sessions = eventData.sessions || []
    const docSrc = eventData.docSrc
    const title = eventData.title || ''
    const subtitle = eventData.subtitle || ''
    const description = eventData.description || ''
    const location = eventData.location || ''
    const imageHD = eventData.imageHD || eventData.imageRect
    const imageRect = eventData.imageRect
    const status = eventData.status
    const price = eventData.price || 0
    const suggestedDonation = eventData.suggestedDonation || 0
    const individualPrice = eventData.individualPrice || false
    const priceDescription = eventData.priceDescription
    const theme = useTheme<Theme>()
    const matches = useMediaQuery(theme.breakpoints.up('md'))
    return (
        <>
            <CardContent>
                <Typography variant='h5' align={desktop ? 'center' : 'left'}>
                    {title}
                </Typography>
                <Typography gutterBottom align={desktop ? 'center' : 'left'}>
                    {subtitle}
                </Typography>
            </CardContent>
            <Grid item container>
                <Image rect src={matches ? imageHD : imageRect} alt={title} />
            </Grid>
            <CardContent>
                <Grid container spacing={3} direction='column'>
                    <Grid item>
                        <Details description={description} location={location} price={price} suggestedDonation={suggestedDonation} individualPrice={individualPrice} status={status} priceDescription={priceDescription} />
                    </Grid>
                    {docSrc &&
                        <Grid item>
                            <Button color='primary' disableRipple component={RouterLink} to={routes.eventInfo(eventInfoID)}>
                                {texts.moreInfo}
                            </Button>
                        </Grid>}
                    <Grid item>
                        <Divider component='div' />
                    </Grid>
                    <Grid item >
                        <Typography gutterBottom>
                            {texts.sessions}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Grid container direction={desktop ? 'row' : 'column'} spacing={3}>
                            <Grid item xs={desktop ? true : false} container direction='column' spacing={3}>
                                {sessions.map(({ sessionid, ...sessionInfo }, index) => (
                                    (2 * index < sessions.length) && <Session {...sessionInfo} key={sessionid} />
                                ))}
                            </Grid>
                            {desktop &&
                                <Grid>
                                    <Divider component='div' orientation='vertical' />
                                </Grid>
                            }
                            <Grid item xs={desktop ? true : false} container direction='column' spacing={3}>
                                {sessions.map(({ sessionid, ...sessionInfo }, index) => (
                                    (2 * index >= sessions.length) && <Session {...sessionInfo} key={sessionid} />
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
        </>
    )
}

type DetailsProps = {
    description: string,
    location?: string,
    price: number,
    suggestedDonation: number,
    individualPrice: boolean,
    priceDescription?: string,
    status?: string,
}
function Details({ description, location, price, suggestedDonation, individualPrice, priceDescription, status }: DetailsProps) {
    const texts = useTextLang(eventContentsTexts)
    return (
        <Grid item container direction='column' spacing={3}>
            <Grid item>
                {status && <Typography variant='body1' color='error'>
                    {status}
                </Typography>}
            </Grid>
            <Grid item>
                <Price price={price} suggestedDonation={suggestedDonation} individualPrice={individualPrice} priceDescription={priceDescription}/>
            </Grid>
            {description && <Grid item>
                <LineTypography variant='body1'>
                    {description}
                </LineTypography>
            </Grid>}
            {location && <Grid item>
                <Typography variant='body1'>
                    {`${texts.location}:`}
                </Typography>
                <LineTypography variant='body1' align='center'>
                    {location}
                </LineTypography>
            </Grid>}
        </Grid>
    )
}

function Session({ title, location, dateStart, dateEnd, description }: Omit<EventSession, 'sessionid'>) {
    const texts = useTextLang(eventContentsTexts)
    const classes = useStyles()
    const dates = useMemo(() => formatDateRange(dateStart, dateEnd), [dateStart, dateEnd])
    const sessionClassName = useMemo(() =>
        (moment(dateEnd).isBefore(moment()) && classes.disabledSession) || undefined,
        [dateEnd, classes])

    return (
        <Grid item container direction='column' spacing={1}>
            {(typeof title === 'string' && title.length > 0) &&
                <Grid item>
                    <Typography variant='body1' className={sessionClassName}>
                        {title}
                    </Typography>
                </Grid>}
            {(typeof description === 'string' && description.length > 0) &&
                <Grid item>
                    <Typography variant='body1' className={sessionClassName}>
                        {description}
                    </Typography>
                </Grid>}
            {(typeof location === 'string' && location.length > 0) &&
                <Grid item>
                    <Typography variant='body1' className={sessionClassName}>
                        {`${texts.location}: ${location}`}
                    </Typography>
                </Grid>}
            <Grid item>
                <Typography variant='body1' className={sessionClassName}>
                    {dates}
                </Typography>
            </Grid>
        </Grid>
    )
}