import React, { useState, useCallback } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Grid, Button, CircularProgress, Link } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import { LogoVertical } from '../Global/Icons'
import { useTextLang } from '../Contexts/LangContext';
import { useRouter, routes } from '../Contexts/RouterContext';
import Password from '../Form/Components/Password'
import { BasicDialog } from '../Global/Dialogs'
import { MatchProps } from '../types'
import { mutation } from '../Global/ServerConnection'
import { escape } from '../Global/GlobalItems'

const loginTexts = {
    emptyPasswordError: {
        en: 'Please enter your new password',
        es: 'Por favor escriba su contraseña'
    },
    shortPasswordError: {
        en: 'Passwords should be at least 8 characters',
        es: 'La contraseña debe ser de al menos 8 caracteres'
    },
    password: {
        en: 'New Password',
        es: 'Contraseña Nueva'
    },
    save: {
        en: 'Save',
        es: 'Guardar'
    },
    sendNewCode: {
        en: 'Send New Link',
        es: 'Enviar Enlace de Nuevo'
    },
    error: {
        en: 'Error',
        es: 'Error'
    },
    tryAgain: {
        en: 'Could not change your password. Try initiating process again.',
        es: 'No fue posible cambiar la contraseña.  Por favor inicie el proceso de nuevo.'
    },
    success: {
        en: 'Success',
        es: 'Se logro'
    },
    loginToContinue: {
        en: 'Now, sign in to continue.',
        es: 'Ahora, inicie la sesión para continuar'
    }
}

const useStyles = makeStyles(theme => ({
    logo: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        height: theme.spacing(20),
        [theme.breakpoints.up('sm')]: {
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(4),
            height: theme.spacing(25),
        },
        [theme.breakpoints.up('xl')]: {
            height: theme.spacing(30),
        }
    },
    layout: {
        width: '100%',
        height: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: theme.breakpoints.values.sm,
        padding: theme.spacing(2),
    },
    background: {
        backgroundColor: theme.palette.common.white,
        width: '100%',
    },
    field: {
        maxWidth: theme.breakpoints.values.sm * 0.8,
    },
    createAccount: {
        marginTop: theme.spacing(1),
    },
    saveButton: {
        maxWidth: theme.breakpoints.values.sm * 0.5,
        marginTop: theme.spacing(1),
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        })
    },
    smallDivider: {
        width: '100%',
        height: theme.spacing(2)
    }
}))

export default function ChangePassword({ match }: MatchProps) {
    const token = match.params.token
    const { navigateTo } = useRouter()

    const [password, setPassword] = useState('')
    const [passwordError, setPasswordError] = useState<string>()
    const [errorDialogOpen, setErrorDialogOpen] = useState(false)
    const [successDialogOpen, setSuccessDialogOpen] = useState(false)
    const texts = useTextLang(loginTexts)

    const valid = useCallback(() => {
        if (password.length === 0) {
            setPasswordError(texts.emptyPasswordError)
            return false
        } else if (password.length < 8) {
            setPasswordError(texts.shortPasswordError)
            return false
        }
        return true
    }, [password, texts])

    const [loading, setLoading] = useState(false)

    const attemptChange = useCallback(() => {
        const processAttempt = (success: boolean) => {
            if (success) {
                setSuccessDialogOpen(true)
            } else {
                setErrorDialogOpen(true)
            }
            setLoading(false)
        }
        if (valid()) {
            setLoading(true)
            mutation('changePassword', processAttempt, `token:"${token}",newPassword:"${escape(password)}"`)
        }
    }, [password, valid, token])

    const closeErrorDialog = useCallback(() => {
        setErrorDialogOpen(false)
    }, [])

    const closeSuccessDialog = useCallback(() => {
        navigateTo(routes.login, true, { next: routes.home })
    }, [navigateTo])

    const setValue = useCallback((field, value) => {
        setPassword(value)
        setPasswordError(undefined)
    }, [])

    const classes = useStyles()
    return (
        <Grid alignItems='center' direction='column' wrap='nowrap' container className={classes.layout}>
            <Grid item component={RouterLink} to={routes.home}>
                <LogoVertical className={classes.logo} />
            </Grid>
            <Password
                field='password'
                className={classes.field}
                variant='outlined'
                label={texts.password}
                value={password}
                setValue={setValue}
                errorMsg={passwordError}
                margin='normal'
                onEnter={attemptChange}
            />
            <Grid item>
                <Button variant='contained' size='large' color='primary' className={classes.saveButton} fullWidth={!loading} onClick={attemptChange}>
                    {texts.save}
                </Button>
            </Grid>
            <Grid item className={classes.smallDivider} />
            {loading &&
                <Grid item>
                    <CircularProgress size={24} className={classes.createAccount} />
                </Grid>}
            {!loading &&
                <>
                    <Link gutterBottom color='primary' component={RouterLink} to={routes.forgotPassword}>
                        {texts.sendNewCode}
                    </Link>
                </>}
            <BasicDialog open={errorDialogOpen} handleClose={closeErrorDialog} title={texts.error} contents={texts.tryAgain} />
            <BasicDialog open={successDialogOpen} handleClose={closeSuccessDialog} title={texts.success} contents={texts.loginToContinue} />
        </Grid>
    );
}