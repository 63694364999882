import React, { useState, useCallback, PropsWithChildren, useEffect, MutableRefObject, useMemo } from 'react'
import { langKey } from '../../Contexts/LangContext'
import { adminMutation, adminQuery } from '../../Global/ServerConnection'
import { BasicDialog } from '../../Global/Dialogs'
import { defaultOption, OptionValue, FormField, Callback, Consumer, MatchProps, TriConsumer } from '../../types'
import { Grid, InputAdornment, Typography, Divider, Button, CircularProgress, Fab, makeStyles } from '@material-ui/core'
import FileInput from '../../Form/Components/FileInput'
import Text from '../../Form/Components/Text'
import Selection from '../../Form/Components/Selection'
import { TagOption, Person } from '../../server-types'
import { TextFieldProps } from '@material-ui/core/TextField'
import SelectionMulti from '../../Form/Components/SelectionMulti'
import { Cancel, Edit, Save } from '@material-ui/icons'
import SingleCheckBox from '../../Form/Components/CheckBox'
import DateSelect from '../../Form/Components/DateSelect'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { EventInfoType, TeacherType } from '../admin-types'
import { formatDateRange, LineTypography, escape } from '../../Global/GlobalItems'
import moment from 'moment-timezone'
import DateTimeSelect from '../../Form/Components/DateTimeSelect'
import NumberField from '../../Form/Components/NumberField'
import LoadingPage from '../../Global/LoadingPage';
import ColorPicker from '../../Form/Components/ColorPicker'

type Restriction = {
    type: OptionValue,
    hasTShirt: boolean,
    dbLow?: MaterialUiPickersDate,
    dbHigh?: MaterialUiPickersDate,
    minimum?: number,
    maximum?: number,
}

type SessionInput = {
    sessionid: string,
    titleEn: string,
    titleEs: string,
    descriptionEn: string,
    descriptionEs: string,
    begin: MaterialUiPickersDate,
    end: MaterialUiPickersDate,
    location: string,
    language: OptionValue,
    reminderTextEn: string,
    reminderTextEs: string,
}

type EventInput = {
    docSrcEn?: string,
    docSrcEs?: string,
    tags: string[],
    titleEn?: string,
    titleEs?: string,
    legalTitle: string,
    subtitleEn?: string,
    subtitleEs?: string,
    descriptionEn?: string,
    descriptionEs?: string,
    location?: string,
    capacity: number,
    teachers: TeacherType[],
    imageHD?: string,
    imageRect?: string,
    imageSqr?: string,
    price: number,
    suggestedDonation: number,
    individualPrice: boolean,
    priceDescriptionEn?: string,
    priceDescriptionEs?: string,
    restrictions: Restriction[],
    sortOrder: number,
    calendarTitle: string,
    mergeInCalendar: boolean,
    calendarBackground: string,
    calendarText: string,
    approvedFamiliesOnly: boolean,
    visible: boolean
}

const restrictionTypes = [
    { value: 'ANY', label: 'Everyone' },
    { value: 'COUPLE', label: 'Couples' },
    { value: 'FATHER', label: 'Fathers' },
    { value: 'MOTHER', label: 'Mothers' },
    { value: 'ADULT', label: 'Adults' },
    { value: 'MAN', label: 'Men' },
    { value: 'WOMAN', label: 'Women' },
]

const teacherRoles = [
    { value: 'INSTRUCTOR', label: 'Instructor' },
    { value: 'VOLUNTEER', label: 'Volunteer' },
    { value: 'ADMINISTRATOR', label: 'Administrator' },
    { value: 'TEACHER', label: 'Teacher' },
]

const languageOptions = [
    { value: 'en', label: 'English' },
    { value: 'es', label: 'Spanish' },
]

const useStyles = makeStyles(theme => ({
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    fabProgress: {
        position: 'fixed',
        bottom: theme.spacing(1.5),
        right: theme.spacing(1.5),
    },
    fabContainer: {
        height: theme.spacing(10)
    },
    calendarPreview: {
        boxSizing: 'content-box',
        position: 'relative',
        display: 'block',
        fontSize: '0.85em',
        lineHeight: 1.4,
        borderRadius: '3px',
        border: '1px solid',
        width: '100%',
        paddingLeft: 2,
    }
}))


export default function CreateEvent({ match }: MatchProps) {
    const eventID = match.params.id
    const [loading, setLoading] = useState(true)
    const [submitting, setSubmitting] = useState(false)
    const [errorSaving, setErrorSaving] = useState(false)
    const [addingRes, setAddingRes] = useState(false)
    const [addingSess, setAddingSess] = useState(false)
    const [editingSess, setEditingSess] = useState<number>()
    const classes = useStyles()
    const [values, setValues] = useState<EventInput>({
        titleEn: '',
        titleEs: '',
        legalTitle: '',
        subtitleEn: '',
        subtitleEs: '',
        descriptionEn: '',
        descriptionEs: '',
        location: '',
        docSrcEn: '',
        docSrcEs: '',
        tags: [],
        capacity: 25,
        teachers: [],
        imageHD: '',
        imageRect: '',
        imageSqr: '',
        price: 0,
        suggestedDonation: 0,
        individualPrice: true,
        priceDescriptionEn: '',
        priceDescriptionEs: '',
        sortOrder: 1000,
        calendarTitle: '',
        mergeInCalendar: false,
        calendarBackground: '',
        calendarText: '',
        approvedFamiliesOnly: true,
        visible: false,
        restrictions: [{ type: { value: 'ANY', label: 'Everyone' }, hasTShirt: false }] as Restriction[],
    })
    const [sessions, setSessions] = useState<SessionInput[]>([])

    const setValue = useCallback((field, value) => {
        setValues((values: EventInput) => ({
            ...values,
            [field]: value
        }))
    }, [])

    useEffect(() => {
        const processEvent = ({ sessions, ...event }: EventInfoType) => {
            setValues({
                titleEn: event.title.en,
                titleEs: event.title.es,
                legalTitle: event.legalTitle,
                subtitleEn: event.subtitle.en,
                subtitleEs: event.subtitle.es,
                descriptionEn: event.description.en,
                descriptionEs: event.description.es,
                location: event.location,
                docSrcEn: event.docSrc.en,
                docSrcEs: event.docSrc.es,
                tags: event.tags,
                capacity: parseInt(event.capacity, 10),
                teachers: event.teachers,
                imageHD: event.imageHD,
                imageRect: event.imageRect,
                imageSqr: event.imageSqr,
                price: parseFloat(event.price),
                suggestedDonation: parseFloat(event.suggestedDonation),
                individualPrice: event.individualPrice,
                priceDescriptionEn: event.priceDescription.en,
                priceDescriptionEs: event.priceDescription.es,
                sortOrder: parseInt(event.sortOrder, 10),
                approvedFamiliesOnly: event.approvedFamiliesOnly,
                calendarTitle: event.calendarTitle,
                mergeInCalendar: event.mergeInCalendar,
                calendarBackground: event.calendarBackground,
                calendarText: event.calendarText,
                visible: event.visible,
                restrictions: event.restrictions.map(({ type, hasTShirt, dbHigh, dbLow, minimum, maximum }) => ({
                    type: restrictionTypes.find(({ value }) => value === type) || defaultOption,
                    hasTShirt: hasTShirt,
                    dbHigh: dbHigh ? moment(dbHigh) : null,
                    dbLow: dbLow ? moment(dbLow) : null,
                    minimum: minimum ? parseInt(minimum, 10) : undefined,
                    maximum: maximum ? parseInt(maximum, 10) : undefined,
                })),
            })
            setSessions(sessions.map(session => ({
                sessionid: session.sessionid,
                titleEn: session.title.en,
                titleEs: session.title.es,
                descriptionEn: session.description.en,
                descriptionEs: session.description.es,
                begin: moment(session.begin),
                end: moment(session.end),
                location: session.location,
                language: languageOptions.find(({ value }) => value === session.language) || defaultOption,
                reminderTextEn: session.reminderText.en,
                reminderTextEs: session.reminderText.es,
            })))
            setLoading(false)
        }
        return adminQuery('getEvent', processEvent, `eventid:"${eventID}"`, 'sessions{sessionid,eventid,title{en,es}description{en,es}begin,end,location,language,reminderText{en,es}}docSrc{en,es}tags,title{en,es}legalTitle,subtitle{en,es}description{en,es}location,capacity,teachers{personid,name,role},imageHD,imageRect,imageSqr,price,suggestedDonation,individualPrice,priceDescription{en,es},restrictions{type,hasTShirt,dbLow,dbHigh,minimum,maximum}sortOrder,calendarTitle,mergeInCalendar,calendarBackground,calendarText,approvedFamiliesOnly,visible')
    }, [eventID])

    const save = useCallback(() => {
        setSubmitting(true)
        const formattedRestrictions = values.restrictions.map(
            ({ type, hasTShirt, dbHigh, dbLow, minimum, maximum }) => {
                return `{type:${type.value},hasTShirt:${hasTShirt},dbHigh:${dbHigh && dbHigh.isValid() ? `"${dbHigh.format()}"` : 'null'},dbLow:${dbLow && dbLow.isValid() ? `"${dbLow.format()}"` : 'null'},minimum:${minimum || 'null'},maximum:${maximum || 'null'}}`
            })
        const formattedTags = values.tags.map(val => `"${val}"`)
        const formattedTeachers = values.teachers.map(({ personid, role }) => `{personid:"${personid}",role:"${role}"}`)

        const processEdit = (success: boolean) => {
            setSubmitting(false)
            if (!success) {
                setErrorSaving(true)
            }
        }
        // TODO: Send teachers
        adminMutation('editEvent', processEdit, `eventid:"${eventID}",titleEn:"${escape(values.titleEn)}",titleEs:"${escape(values.titleEs)}",legalTitle:"${escape(values.legalTitle)}",subtitleEn:"${escape(values.subtitleEn)}",subtitleEs:"${escape(values.subtitleEs)}",descriptionEn:"${escape(values.descriptionEn)}",descriptionEs:"${escape(values.descriptionEs)}",location:"${escape(values.location)}",docSrcEn:"${values.docSrcEn}",docSrcEs:"${values.docSrcEs}",tags:[${formattedTags}],capacity:${values.capacity},teachers:[${formattedTeachers}],imageHD:"${values.imageHD}",imageRect:"${values.imageRect}",imageSqr:"${values.imageSqr}",price:${values.price},suggestedDonation:${values.suggestedDonation},individualPrice:${values.individualPrice},priceDescriptionEn:"${escape(values.priceDescriptionEn)}",priceDescriptionEs:"${escape(values.priceDescriptionEs)}",sortOrder:${values.sortOrder},calendarTitle:"${values.calendarTitle}",mergeInCalendar:${values.mergeInCalendar},calendarBackground:"${values.calendarBackground}",calendarText:"${values.calendarText}",approvedFamiliesOnly:${values.approvedFamiliesOnly},visible:${values.visible},restrictions:[${formattedRestrictions}]`)
    }, [values, eventID])

    const addTeacher = useCallback((personid: string, name: string, role: string) => {
        setValues(values => {
            const teachers = values.teachers.filter(teacher => teacher.personid !== personid)
            teachers.push({ personid, name, role })
            return { ...values, teachers }
        })
    }, [])

    const removeTeacher = useCallback((personid: string) => {
        setValues(values => ({
            ...values,
            teachers: values.teachers.filter(teacher => teacher.personid !== personid)
        }))
    }, [])

    const newRestriction = useCallback(() => { setAddingRes(true) }, [])
    const cancelRestriction = useCallback(() => { setAddingRes(false) }, [])
    const addRestriction = useCallback((restriction: Restriction) => {
        cancelRestriction()
        setValues(values => {
            const restrictions = values.restrictions
            restrictions.push(restriction)
            return { ...values, restrictions }
        })
    }, [cancelRestriction])
    const deleteRestriction = useCallback((index) => {
        setValues(values => {
            const restrictions = values.restrictions
            restrictions.splice(index, 1)
            return { ...values, restrictions }
        })
    }, [])

    const newSession = useCallback(() => { setAddingSess(true) }, [])
    const modifySession = useCallback((index: number) => {
        setEditingSess(index)
        const root = window.document.body.children.item(1)
        if (root) {
            const mainPage = root.children.item(0)
            if (mainPage) {
                // console.log(mainPage)
                window.scrollTo({ top: mainPage.clientHeight, behavior: 'smooth' })
            }
        }
    }, [])
    const cancelSession = useCallback(() => { setAddingSess(false); setEditingSess(undefined) }, [])
    const addSession = useCallback((session: SessionInput) => {
        cancelSession()
        // console.log(session)
        setSessions(sessions => {
            const newSessions = [...sessions, session]
            return newSessions
        })
    }, [cancelSession])
    const editSession = useCallback((session: SessionInput) => {
        const index = editingSess
        if (index !== undefined) {
            setSessions(sessions => {
                const newSessions = [...sessions]
                newSessions[index] = session
                return newSessions
            })
        }
        cancelSession()
    }, [cancelSession, editingSess])
    const deleteSession = useCallback((index) => {
        setSessions(sessions => {
            const newSessions = [...sessions]
            newSessions.splice(index, 1)
            return newSessions
        })
    }, [])
    const closeErrorDialog = useCallback(() => setErrorSaving(false), [])

    return (
        <Grid container direction='column' spacing={2}>
            <LineContainer>
                <Text field='titleEn' value={values.titleEn} label='Title English' setValue={setValue} multiline />
                <Text field='titleEs' value={values.titleEs} label='Title Spanish' setValue={setValue} multiline />
            </LineContainer>
            <LineContainer>
                <Text field='subtitleEn' value={values.subtitleEn} label='Subtitle English' setValue={setValue} multiline />
                <Text field='subtitleEs' value={values.subtitleEs} label='Subtitle Spanish' setValue={setValue} multiline />
            </LineContainer>
            <LineContainer>
                <Text field='legalTitle' value={values.legalTitle} label='Legal Title' setValue={setValue} />
            </LineContainer>
            <LineContainer>
                <Text field='descriptionEn' value={values.descriptionEn} label='Description English' setValue={setValue} multiline />
                <Text field='descriptionEs' value={values.descriptionEs} label='Description Spanish' setValue={setValue} multiline />
            </LineContainer>
            <LineContainer>
                <Text field='location' value={values.location} label='Location' setValue={setValue} multiline />
            </LineContainer>
            <LineContainer>
                <FileInput field='docSrcEn' value={values.docSrcEn} label='Documentation English' setValue={setValue} location='docs' />
                <FileInput field='docSrcEs' value={values.docSrcEs} label='Documentation Spanish' setValue={setValue} location='docs' />
            </LineContainer>
            <LineContainer>
                <TagSelection field='tags' selected={values.tags} label='Tags' setValue={setValue} />
                <NumberField field='capacity' value={values.capacity} label='Capacity' setValue={setValue} />
            </LineContainer>
            <LineContainer>
                <FileInput image field='imageHD' value={values.imageHD} label='Image HD' setValue={setValue} rect location='images' />
            </LineContainer>
            <Grid item container spacing={2}>
                <Grid item xs={8}>
                    <FileInput image field='imageRect' value={values.imageRect} label='Image Rectangle' setValue={setValue} rect location='images' />
                </Grid>
                <Grid item xs={4}>
                    <FileInput image field='imageSqr' value={values.imageSqr} label='Image Square' setValue={setValue} location='images' />
                </Grid>
            </Grid>
            <LineContainer>
                <NumberField field='price' precision={2} value={values.price} label='Price' setValue={setValue}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">{'$'}</InputAdornment>,
                    }} />
                <NumberField field='suggestedDonation' precision={2} value={values.suggestedDonation} label='Suggested Donation' setValue={setValue}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">{'$'}</InputAdornment>,
                    }} />
                <SingleCheckBox field='individualPrice' value={values.individualPrice} label='Individual Price' setValue={setValue} />
            </LineContainer>
            <LineContainer>
                <Text field='priceDescriptionEn' value={values.priceDescriptionEn} label='Price Description English' setValue={setValue} multiline />
                <Text field='priceDescriptionEs' value={values.priceDescriptionEs} label='Price Description Spanish' setValue={setValue} multiline />
            </LineContainer>
            <LineContainer>
                <NumberField field='sortOrder' value={values.sortOrder} label='Sort Order' setValue={setValue} />
                <SingleCheckBox field='approvedFamiliesOnly' value={values.approvedFamiliesOnly} label='Approved Families Only' setValue={setValue} />
                <SingleCheckBox field='visible' value={values.visible} label='Visible' setValue={setValue} />
            </LineContainer>
            <Divider />
            <LineContainer>
                <Typography >
                    {'Calendar Settings:'}
                </Typography>
                <div className={classes.calendarPreview} style={{ color: values.calendarText, backgroundColor: values.calendarBackground, borderColor: values.calendarBackground }}>
                    {values.calendarTitle}
                </div>
            </LineContainer>
            <LineContainer>
                <Text field='calendarTitle' value={values.calendarTitle} label='Calendar Title' setValue={setValue} />
                <SingleCheckBox field='mergeInCalendar' value={values.mergeInCalendar} label='Merge In Calendar' setValue={setValue} />
            </LineContainer>
            <LineContainer>
                <ColorPicker field='calendarBackground' value={values.calendarBackground} label='Background Color' setValue={setValue} />
                <ColorPicker field='calendarText' value={values.calendarText} label='Text Color' setValue={setValue} />
            </LineContainer>
            <Divider />
            <TeacherSelect addTeacher={addTeacher} removeTeacher={removeTeacher} teachers={values.teachers} />
            <Divider />
            <LineContainer>
                <Typography >
                    {'Restrictions:'}
                </Typography>
            </LineContainer>
            {values.restrictions.map((restriction, index) => <Restriction {...restriction} remove={deleteRestriction} index={index} key={index} />)}
            {addingRes ? <AddRestriction onCancel={cancelRestriction} onFinish={addRestriction} /> :
                <LineContainer>
                    <Button onClick={newRestriction} color='primary'>
                        {'Add Restriction'}
                    </Button>
                </LineContainer>}
            <Divider />
            <LineContainer>
                <Typography >
                    {'Sessions:'}
                </Typography>
            </LineContainer>
            <Divider />
            {sessions.map(({ sessionid, ...session }, index, sessions) => <Session {...session} remove={deleteSession} edit={modifySession} sessionid={sessionid} index={index} key={sessionid} removable={sessions.length > 1} />)}
            {addingSess && <AddSession eventID={eventID} onCancel={cancelSession} onAdd={addSession} onEdit={editSession} />}
            {editingSess !== undefined && <AddSession eventID={eventID} onCancel={cancelSession} onAdd={addSession} onEdit={editSession} sessionEdit={sessions[editingSess]} />}
            {!addingSess && editingSess === undefined &&
                <LineContainer>
                    <Button onClick={newSession} color='primary'>
                        {'Add Session'}
                    </Button>
                </LineContainer>}
            <Grid item className={classes.fabContainer}>
                {submitting && <CircularProgress size={64} className={classes.fabProgress} />}
                <Fab className={classes.fab} color={submitting ? 'secondary' : 'primary'} onClick={save}>
                    <Save />
                </Fab>
            </Grid>
            <LoadingPage loading={loading} />
            <BasicDialog open={errorSaving} handleClose={closeErrorDialog} title='Error' contents='Could not save, make sure there are restrictions, check quotation marks, etc.' />
        </Grid>
    )
}

function LineContainer({ children }: PropsWithChildren<{}>) {
    return (
        <Grid item container spacing={2} alignItems='center'>
            {React.Children.map(children, (child, number) => (
                <Grid item xs key={number}>
                    {child}
                </Grid>
            ))}
        </Grid>
    )
}

type TagSelectionProps<FieldName> = Omit<FormField<FieldName, string[]>, 'value'> & {
    menuPortalTarget?: MutableRefObject<undefined>,
    isClearable?: boolean,
    selected: string[]
} & TextFieldProps
function TagSelection<FieldName>({ selected, setValue, ...rest }: TagSelectionProps<FieldName>) {
    const [tags, setTags] = useState<OptionValue[]>([])
    const formattedValue = useMemo(() =>
        selected.map(val => tags.find(({ value }) => value === val))
            .filter(val => val) as OptionValue[], [tags, selected])

    useEffect(() => {
        const processTagList = (tagList: TagOption[]) => {
            setTags(tagList.map(({ tag, tagLabel }) => ({ value: tag, label: tagLabel })))
        }

        const language = localStorage.getItem(langKey)
        return adminQuery('getTagOptions', processTagList, `language:"${language}"`, 'tag,tagLabel')
    }, [])

    const fixedSetValue = useCallback((field, value: readonly OptionValue[]) => {
        setValue(field, value.map(val => val.value))
    }, [setValue])

    return (
        <SelectionMulti {...rest} options={tags} value={formattedValue} setValue={fixedSetValue} />
    )
}

type TeacherSelectionProps = {
    addTeacher: TriConsumer<string, string, string>,
    removeTeacher: Consumer<string>,
    teachers: TeacherType[],
}
function TeacherSelect({ addTeacher, removeTeacher, teachers }: TeacherSelectionProps) {
    const [teacherOptions, setTeacherOptions] = useState<OptionValue[]>([])
    const [values, setValues] = useState({
        teacher: { value: '', label: '' },
        role: { value: 'INSTRUCTOR', label: 'Instructor' },
    })

    useEffect(() => {
        const processTeacherOptions = (teachers: Person[]) => {
            setTeacherOptions(teachers.map(({ personid, name }) => ({ value: personid, label: name })))
        }
        return adminQuery('getTeacherList', processTeacherOptions, undefined, 'name,personid')
    }, [])

    const setValue = useCallback((field, value) => {
        setValues(values => ({ ...values, [field]: value }))
    }, [])

    const finish = useCallback(() => {
        addTeacher(values.teacher.value, values.teacher.label, values.role.value)
    }, [addTeacher, values])

    return (
        <>
            <LineContainer>
                <Typography>{`Teachers:`}</Typography>
            </LineContainer>
            {teachers.map(teacher => (
                <LineContainer key={teacher.personid}>
                    <Teacher remove={removeTeacher} {...teacher} />
                </LineContainer>
            ))}
            <LineContainer>
                <Selection field='teacher' value={values.teacher} label='Teacher' setValue={setValue} options={teacherOptions} />
                <Selection field='role' value={values.role} label='Role' setValue={setValue} options={teacherRoles} />
                <Button onClick={finish} color='primary'>
                    {'Add'}
                </Button>
            </LineContainer>
        </>
    )
}

type TeacherProps = TeacherType & {
    remove: Consumer<string>,
}
function Teacher({ personid, name, role, remove }: TeacherProps) {
    const onRemove = useCallback(() => { remove(personid) }, [remove, personid])

    return (
        <Grid item container spacing={2} alignItems='center' justify='space-between'>
            <Grid item>
                <Typography>
                    {`${name}: ${role}`}
                </Typography>
            </Grid>
            <Grid item>
                <Button onClick={onRemove}>
                    <Cancel color='error' />
                </Button>
            </Grid>
        </Grid>
    )
}

type RestrictionProps = {
    remove: Consumer<number>,
    index: number
} & Restriction
function Restriction({ type, hasTShirt, dbHigh, dbLow, minimum, maximum, remove, index }: RestrictionProps) {
    const low = dbLow ? dbLow.format('YYYY-MM-DD') : '(no limit)'
    const high = dbHigh ? dbHigh.format('YYYY-MM-DD') : '(no limit)'
    const onRemove = useCallback(() => { remove(index) }, [remove, index])
    return (
        <Grid item container spacing={2} alignItems='center' justify='space-between'>
            <Grid item>
                <Typography>
                    {`${type.label} | ${hasTShirt ? 'With T-Shirt' : 'No T-Shirt'} | ages ${low} - ${high}  | ${minimum || '(no limit)'} - ${maximum || '(no limit)'} participants`}
                </Typography>
            </Grid>
            <Grid item>
                <Button onClick={onRemove}>
                    <Cancel color='error' />
                </Button>
            </Grid>
        </Grid>
    )
}

type AddRestrictionProps = {
    onFinish: Consumer<Restriction>,
    onCancel: Callback
}
function AddRestriction({ onFinish, onCancel }: AddRestrictionProps) {
    const [values, setValues] = useState({
        type: { value: 'ANY', label: 'Everyone' },
        hasTShirt: false,
        schoolYear: moment().year(),
        youngest: '',
        dbHigh: null as MaterialUiPickersDate,
        oldest: '',
        dbLow: null as MaterialUiPickersDate,
        minimum: 1,
        maximum: undefined,
    })


    useEffect(() => {
        if (values.youngest && values.schoolYear) {
            const cutoff = moment(`${values.schoolYear}-06-01`)
            const val = parseInt(values.youngest, 10)
            setValues(values => ({ ...values, dbHigh: cutoff.subtract(val, 'y').endOf('y') }))
        }
    }, [values.schoolYear, values.youngest])

    useEffect(() => {
        if (values.oldest && values.schoolYear) {
            const cutoff = moment(`${values.schoolYear}-06-01`)
            const val = parseInt(values.oldest, 10)
            setValues(values => ({ ...values, dbLow: cutoff.subtract(val + 1, 'y').startOf('y') }))
        }
    }, [values.schoolYear, values.oldest])

    const setValue = useCallback((field, value) => {
        setValues(values => ({ ...values, [field]: value }))
    }, [])

    const finish = useCallback(() => {
        const { type, hasTShirt, dbLow, dbHigh, minimum, maximum } = values
        onFinish({ type, hasTShirt, dbLow, dbHigh, minimum, maximum })
    }, [values, onFinish])

    return (
        <>
            <Divider />
            <LineContainer>
                <Selection field='type' value={values.type} label='Type' setValue={setValue} options={restrictionTypes} />
                <SingleCheckBox field='hasTShirt' value={values.hasTShirt} label='With T-Shirt' setValue={setValue} />
            </LineContainer>
            <LineContainer>
                <NumberField field='schoolYear' value={values.schoolYear} label='School Year' setValue={setValue} />
            </LineContainer>
            <LineContainer>
                <Text field='youngest' value={values.youngest} label='Youngest' setValue={setValue} />
                <DateSelect field='dbHigh' value={values.dbHigh} label='High Cutoff' setValue={setValue} setErrorMsg={() => { }} />
            </LineContainer>
            <LineContainer>
                <Text field='oldest' value={values.oldest} label='Oldest' setValue={setValue} />
                <DateSelect field='dbLow' value={values.dbLow} label='Low Cutoff' setValue={setValue} setErrorMsg={() => { }} />
            </LineContainer>
            <LineContainer>
                <NumberField field='minimum' value={values.minimum} label='Minimum # In Group' setValue={setValue} />
                <NumberField field='maximum' value={values.maximum} label='Maxmium # In Group' setValue={setValue} />
            </LineContainer>
            <LineContainer>
                <Button onClick={onCancel} color='primary'>
                    {'Cancel'}
                </Button>
                <Button onClick={finish} color='primary'>
                    {'Finish'}
                </Button>
            </LineContainer>
            <Divider />
        </>
    )
}

type SessionProps = SessionInput & {
    remove: Consumer<number>,
    edit: Consumer<number>,
    index: number,
    removable: boolean
}
function Session({ sessionid, titleEn, titleEs, descriptionEn, descriptionEs, begin, end, location, language, remove, edit, index, removable }: SessionProps) {
    const dates = useMemo(() => formatDateRange(begin ? begin.format() : '', end ? end.format() : ''), [begin, end])
    const onRemove = useCallback(() => {
        const processAttempt = (success: boolean) => {
            if (success)
                remove(index)
        }
        adminMutation('removeSession', processAttempt, `sessionid:"${sessionid}"`)
    }, [remove, index, sessionid])

    const onEdit = useCallback(() => {
        edit(index)
    }, [edit, index])

    return (
        <>
            <Grid item container spacing={2} alignItems='center' justify='space-between'>
                <Grid item>
                    <Button onClick={onRemove} disabled={!removable}>
                        <Cancel color={removable ? 'error' : 'disabled'} />
                    </Button>
                </Grid>
                <Grid item xs>
                    <Grid container direction='column' spacing={1}>
                        {(titleEn || titleEs) &&
                            <LineContainer>
                                <LineTypography variant='body1'>
                                    {`Title En:\n${titleEn}`}
                                </LineTypography>
                                <LineTypography variant='body1'>
                                    {`Title Es:\n${titleEs}`}
                                </LineTypography>
                            </LineContainer>}
                        {(descriptionEn || descriptionEs) &&
                            <LineContainer>
                                <LineTypography variant='body1'>
                                    {`Description En:\n${descriptionEn}`}
                                </LineTypography>
                                <LineTypography variant='body1'>
                                    {`Description Es:\n${descriptionEs}`}
                                </LineTypography>
                            </LineContainer>}
                        {location &&
                            <LineContainer>
                                <LineTypography variant='body1'>
                                    {`Location:\n${location}`}
                                </LineTypography>
                            </LineContainer>}
                        <LineContainer>
                            <Typography variant='body1'>{dates}</Typography>
                        </LineContainer>
                        <LineContainer>
                            <LineTypography variant='body1'>
                                {`Language: ${language.label}`}
                            </LineTypography>
                        </LineContainer>
                    </Grid>
                </Grid>
                <Grid item>
                    <Button onClick={onEdit}>
                        <Edit />
                    </Button>
                </Grid>
            </Grid>
            <Divider />
        </>
    )
}

type AddSessionProps = {
    eventID: string,
    onAdd: Consumer<SessionInput>,
    onEdit: Consumer<SessionInput>,
    onCancel: Callback,
    sessionEdit?: SessionInput
}
function AddSession({ eventID, onAdd, onEdit, onCancel, sessionEdit }: AddSessionProps) {
    const [values, setValues] = useState<SessionInput>(sessionEdit || {
        sessionid: '',
        titleEn: '',
        titleEs: '',
        descriptionEn: '',
        descriptionEs: '',
        begin: null as MaterialUiPickersDate,
        end: null as MaterialUiPickersDate,
        location: '',
        language: languageOptions.find(({ value }) => value === 'en') || defaultOption,
        reminderTextEn: '',
        reminderTextEs: ''
    })

    useEffect(() => {
        const begin = values.begin
        const end = values.end
        if (begin && !end) {
            setValues(values => ({ ...values, end: begin.clone().add(1, 'hour') }))
        } else if (end && !begin) {
            setValues(values => ({ ...values, begin: end.clone().subtract(1, 'hour') }))
        }
    }, [values.begin, values.end])

    const setValue = useCallback((field, value) => {
        setValues(values => ({ ...values, [field]: value }))
    }, [])

    const createSession = useCallback((values: SessionInput) => {
        const processAttempt = (sessionid: string | null) => {
            if (sessionid) {
                onAdd({ ...values, sessionid })
            }
        }
        if (values.begin && values.end) {
            adminMutation('createSession', processAttempt, `eventid:"${eventID}",titleEn:"${escape(values.titleEn)}",titleEs:"${escape(values.titleEs)}",descriptionEn:"${escape(values.descriptionEn)}",descriptionEs:"${escape(values.descriptionEs)}",location:"${escape(values.location)}",begin:"${values.begin.format()}",end:"${values.end.format()}",language:${values.language.value},reminderTextEn:"${escape(values.reminderTextEn)}",reminderTextEs:"${escape(values.reminderTextEs)}"`)
        }
    }, [onAdd, eventID])

    const editSession = useCallback((values: SessionInput) => {
        const processAttempt = (success: boolean) => {
            if (success) {
                onEdit(values)
            }
        }
        if (values.begin && values.end) {
            adminMutation('editSession', processAttempt, `sessionid:"${values.sessionid}",titleEn:"${escape(values.titleEn)}",titleEs:"${escape(values.titleEs)}",descriptionEn:"${escape(values.descriptionEn)}",descriptionEs:"${escape(values.descriptionEs)}",location:"${escape(values.location)}",begin:"${values.begin.format()}",end:"${values.end.format()}",language:${values.language.value},reminderTextEn:"${escape(values.reminderTextEn)}",reminderTextEs:"${escape(values.reminderTextEs)}"`)
        }
    }, [onEdit])

    const finish = useCallback(() => {
        if (values.begin && values.end) {
            if (values.sessionid) {
                editSession(values)
            } else {
                createSession(values)
            }
        }
    }, [values, createSession, editSession])

    return (
        <>
            <LineContainer>
                <Text field='titleEn' value={values.titleEn} label='Title English' setValue={setValue} multiline />
                <Text field='titleEs' value={values.titleEs} label='Title Spanish' setValue={setValue} multiline />
            </LineContainer>
            <LineContainer>
                <Text field='descriptionEn' value={values.descriptionEn} label='Description English' setValue={setValue} multiline />
                <Text field='descriptionEs' value={values.descriptionEs} label='Description Spanish' setValue={setValue} multiline />
            </LineContainer>
            <LineContainer>
                <Text field='location' value={values.location} label='Location' setValue={setValue} multiline />
            </LineContainer>
            <LineContainer>
                <DateTimeSelect field='begin' value={values.begin} label='Begin' setValue={setValue} setErrorMsg={() => { }} />
                <DateTimeSelect field='end' value={values.end} label='End' setValue={setValue} setErrorMsg={() => { }} />
            </LineContainer>
            <LineContainer>
                <Selection field='language' value={values.language} label='Language' setValue={setValue} options={languageOptions} />
            </LineContainer>
            <LineContainer>
                <Text field='reminderTextEn' value={values.reminderTextEn} label='Reminder Text English (Supports HTML)' setValue={setValue} multiline />
                <Text field='reminderTextEs' value={values.reminderTextEs} label='Reminder Text Spanish (Supports HTML)' setValue={setValue} multiline />
            </LineContainer>
            <LineContainer>
                <div dangerouslySetInnerHTML={{ __html: values.reminderTextEn }} />
                <div dangerouslySetInnerHTML={{ __html: values.reminderTextEs }} />
            </LineContainer>
            <LineContainer>
                <Button onClick={onCancel} color='primary'>
                    {'Cancel'}
                </Button>
                <Button onClick={finish} color='primary'>
                    {'Finish'}
                </Button>
            </LineContainer>
            <Divider />
        </>
    )
}