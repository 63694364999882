import React, { useState, useEffect, useCallback } from 'react';
import { Grid, Typography, Divider, List, Button } from '@material-ui/core';
import { useTextLang } from '../../Contexts/LangContext'
import { adminRoutes } from '../Admin'
import { adminQuery } from '../../Global/ServerConnection';
import { makeStyles } from '@material-ui/styles';
import LoadingPage from '../../Global/LoadingPage';
import { Link as RouterLink } from 'react-router-dom';
import SingleCheckBox from '../../Form/Components/CheckBox';

const familiesTexts = {
    title: {
        en: 'Find Family',
        es: 'Encontrar Familia',
    },
    viewFamily: {
        en: 'View',
        es: 'Ver',
    },
    editFamily: {
        en: 'Edit',
        es: 'Editar',
    },
    eventsFamily: {
        en: 'Events',
        es: 'Eventos',
    },
}

const useStyles = makeStyles(theme => ({
    familyRow: {
        padding: theme.spacing(2)
    }
}))

type Family = {
    familyid: string,
    fatherName?: string,
    motherName?: string,
}

export default function FindFamily() {
    const texts = useTextLang(familiesTexts)
    const [families, setFamilies] = useState<Family[]>()
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const processFamilies = (families: Family[]) => {
            setFamilies(families)
            setLoading(false)
        }
        return adminQuery('getFamilies', processFamilies, 'approved:true', 'familyid,fatherName,motherName')
    }, [])

    return (
        <Grid container direction='column' spacing={1}>
            <Grid item>
                <Typography variant='h6' color='textSecondary'>
                    {texts.title}
                </Typography>
            </Grid>
            {families &&
                <List>
                    <Divider />
                    {families.map(({ familyid, ...rest }) =>
                        <Family familyid={familyid} {...rest} key={familyid} />
                    )}
                </List >
            }
            <LoadingPage loading={loading} />
        </Grid>
    )
}

function Family({ familyid, fatherName, motherName }: Family) {
    const texts = useTextLang(familiesTexts)
    const classes = useStyles()

    const name = (fatherName && motherName && `${fatherName}, ${motherName}`) || fatherName || motherName
    return (
        <>
            <Grid container direction='row' alignItems='center' justify='space-between' className={classes.familyRow}>
                <Grid item xs={6}>
                    <Typography>
                        {name}
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <Button color='primary' component={RouterLink} to={adminRoutes.editFamily(familyid)}>
                        {texts.editFamily}
                    </Button>
                </Grid>
                <Grid item xs={2}>
                    <Button color='primary' component={RouterLink} to={adminRoutes.viewFamily(familyid)}>
                        {texts.viewFamily}
                    </Button>
                </Grid>
                <Grid item xs={2}>
                    <Button color='primary' component={RouterLink} to={adminRoutes.eventsFamily(familyid)}>
                        {texts.eventsFamily}
                    </Button>
                </Grid>
            </Grid>
            <Divider />
        </>
    )
}
