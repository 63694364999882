import React, { useEffect, useState, useCallback, useRef, useMemo } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Card, Divider, Theme, Button, Grid, FormControlLabel, Typography, Radio } from '@material-ui/core';

import RegisterContainer from './RegisterContainer'
import { MobileNavigation, DesktopNavigation } from '../Global/BottomNavigation'
import { makeStyles, useTheme } from '@material-ui/styles';
import { useRouter, routes } from '../Contexts/RouterContext';
import { useTextLang, useLang } from '../Contexts/LangContext';
import { MatchProps, InputNavigationProps, Consumer } from '../types';
import { EventSessionSelect } from '../server-types';
import { useBasicTexts, formatDateRange } from '../Global/GlobalItems';
import { useAuth } from '../Contexts/AuthContext';
import LoadingPage from '../Global/LoadingPage';

const addEventTexts = {
    title: {
        en: 'SELECT DATE:',
        es: 'Por favor seleccione la fecha a la que desea registrarse:'
    },
    lessThanOneError: {
        en: `You need to select at least 1 participant`,
        es: 'Por favor seleccione al menos 1 participante'
    },
    lessThanMinError: {
        en: (min: number) => `You need to select at least ${min} participants`,
        es: (min: number) => `Necesita seleccionar al menos ${min} participantes`
    },
    moreThanOneError: {
        en: `You can select at most 1 participant`,
        es: 'Solo puede seleccionar 1 participante'
    },
    moreThanMaxError: {
        en: (max: number) => `You can select at most ${max} participants`,
        es: (max: number) => `Solo puede seleccionar hasta ${max} participantes`
    },
    noTShirtError: {
        en: 'Please select a t-shirt size.',
        es: 'Por favor seleccione el tamaño de la camiseta.'
    },
    error: {
        en: 'Error'
    },
}

const useStyles = makeStyles(theme => ({
    contentCard: {
        margin: theme.spacing(5),
    },
    navigationCard: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
    },
    selectionContainer: {
        padding: theme.spacing(3)
    }
}))

export default function ChooseEventSession({ match }: MatchProps) {
    const { navigateTo, goBack } = useRouter()

    const [sessions, setSessions] = useState<EventSessionSelect[]>([])
    const [selected, setSelected] = useState<string>()
    const texts = useTextLang(addEventTexts)
    const [loading, setLoading] = useState(true)
    const eventInfoID = match.params.id
    const { authQuery } = useAuth()
    const myRef = useRef<any>(null)
    const { language } = useLang()

    useEffect(() => {
        const processSessions = (sessions: EventSessionSelect[]) => {
            setSessions(sessions)
            setLoading(false)
        }
        return authQuery('groupedEventSessions', processSessions, `eventInfoID:"${eventInfoID}",language:"${language}"`, 'eventid,dateStart,dateEnd,status')
    }, [eventInfoID, language, authQuery])

    const toggleEvent = useCallback((eventid: string) => {
        setSelected(eventid)
    }, [])

    const next = useCallback(() => {
        if (selected) {
            navigateTo(routes.addEvent(selected))
        }
    }, [selected, navigateTo])

    const classes = useStyles()
    const theme = useTheme<Theme>()
    const matches = useMediaQuery(theme.breakpoints.up('sm'))

    const eventContents = (
        <Grid container direction='column' spacing={2} className={classes.selectionContainer}>
            <Grid item>
                <Typography variant='body1'>
                    {texts.title}
                </Typography>
            </Grid>
            {sessions.map(session => (
                <Grid item key={session.eventid + session.dateStart}>
                    <SelectEvent toggleEvent={toggleEvent} selected={selected} {...session}/>
                </Grid>
            ))}
        </Grid>
    )
    return (
        <RegisterContainer activeStep={0}>
            {matches
                ? <Card className={classes.contentCard} innerRef={myRef}>
                    {eventContents}
                    <Divider variant='middle' />
                    <DesktopNavigation>
                        <NavigationContents onFinish={next} onCancel={goBack} />
                    </DesktopNavigation>
                </Card>
                : <>
                    {eventContents}
                    <MobileNavigation>
                        <NavigationContents onFinish={next} onCancel={goBack} />
                    </MobileNavigation>
                </>
            }
            <LoadingPage loading={loading} />
        </RegisterContainer >
    )
}


type SelectSessionProps = EventSessionSelect & {
    selected?: string,
    toggleEvent: Consumer<string>
}
function SelectEvent({ eventid, dateStart, dateEnd, status, selected, toggleEvent }: SelectSessionProps) {

    const handleChange = useCallback((event: any, checked: boolean) => {
        if (checked) {
            toggleEvent(eventid)
        }
    }, [eventid, toggleEvent])

    const date = useMemo(() => formatDateRange(dateStart, dateEnd), [dateStart, dateEnd])

    return (
        <FormControlLabel
            control={
                <Radio checked={selected === eventid} onChange={handleChange}
                    value={eventid}
                    color="primary" />}
            label={
                <Grid container direction='row'>
                    <Grid item>
                        {<Typography variant='body1'>
                            {date}
                        </Typography>}
                    </Grid>
                    <Grid item>
                        {status && <Typography variant='body1' color='error'>
                            {status}
                        </Typography>}
                    </Grid>
                </Grid>
            }
        />
    )
}

function NavigationContents({ onFinish, onCancel }: InputNavigationProps) {
    const texts = useBasicTexts()
    return (
        <>
            <Button onClick={onCancel} color='primary'>
                {texts.cancel}
            </Button>
            <Button onClick={onFinish} variant='contained' color='primary'>
                {texts.next}
            </Button>
        </>
    )
}