import React from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';

import { useAuth, permissionsKey } from '../Contexts/AuthContext';
import { useRouter, routes } from '../Contexts/RouterContext';

export default function AdminRoute(props: RouteProps) {
    const { isAuth } = useAuth()
    const { pathname } = useRouter()
    if (isAuth && localStorage.getItem(permissionsKey) === 'ADMIN') {
        return <Route {...props} />
    } else {
        return <Redirect to={{
            pathname: routes.login,
            state: { next: pathname, redirected: true }
        }} />
    }
}