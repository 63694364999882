import React from 'react';
import { makeStyles } from '@material-ui/styles'

import { Card, Typography, CardContent } from '@material-ui/core';
import { useTextLang } from '../../Contexts/LangContext';

const discountCardTexts = {
    discount: {
        en: 'Discount',
        es: 'Descuento'
    },
}

const useStyles = makeStyles(theme => ({
    card: {
        width: '100%',
    },
}))

type DiscountCardProps = {
    discountMoney: number,
    discountPercent: number
}
export default function DiscountCard({ discountMoney, discountPercent }: DiscountCardProps) {
    const texts = useTextLang(discountCardTexts)

    const classes = useStyles()
    if (discountMoney !== 0 || discountPercent !== 1) {
        return (
            <Card className={classes.card}>
                <CardContent>
                    <Typography align='right' gutterBottom>
                        {`${texts.discount}:`}
                    </Typography>
                    {discountMoney !== 0 &&
                        <Typography align='right' variant='body1' color='error'>
                            -${discountMoney.toFixed(2)}
                        </Typography>}
                    {discountPercent !== 1 &&
                        <Typography align='right' variant='body1' color='error'>
                            -{(100 - (discountPercent * 100)).toFixed(2)}%
                                </Typography>}
                </CardContent>
            </Card>
        )
    } else {
        return null
    }
}