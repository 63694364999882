import React, { createContext, useContext, useState, useEffect, useCallback, ReactNode, useMemo } from 'react';
import { BaseLanguage, JSONt, ApprovedLanguage, Consumer, OtherLanguage } from '../types';

//TODO: Add more languages everywhere (all texts in different files, etc.)
type LanguageContextType = {
    language: ApprovedLanguage,
    approvedLanguages: { value: ApprovedLanguage, label: string }[]
    setLanguage: Consumer<ApprovedLanguage>,
}
const LangContext = createContext<LanguageContextType>({
    language: 'en',
    approvedLanguages: [],
    setLanguage: () => { }
});
const approvedLanguages: { value: ApprovedLanguage, label: string }[] = [
    { value: 'en', label: 'English' }, 
    { value: 'es', label: 'Español' }
]

export const langKey = 'language'

export function LangProvider({ children }: { children: ReactNode }) {
    const [language, setLang] = useState<ApprovedLanguage>(localStorage.getItem('language') as ApprovedLanguage || 'en')

    useEffect(() => {
        localStorage.setItem(langKey, language)
    }, [language])

    const setLanguage = useCallback((lang) => {
        const approved = approvedLanguages.find(({ value }) => lang === value)
        if (approved) {
            setLang(lang)
        }
    }, [])

    return (
        <LangContext.Provider
            value={{
                language,
                approvedLanguages,
                setLanguage,
            }}
        >
            {children}
        </LangContext.Provider>
    )
}

export function useLang() {
    return useContext(LangContext)
}

type LanguageText<T = unknown> = {
    [lang in OtherLanguage]?: T
} & { en: T }

type MappedTexts<Texts extends JSONt<LanguageText>> = {
    [P in keyof Texts]: Texts[P][BaseLanguage]
}
export function useTextLang<Texts extends JSONt<LanguageText>>(texts: Texts): MappedTexts<Texts> {
    const { language } = useLang()
    const filtered = useMemo(() =>
        Object.keys(texts).reduce((reduced, key) => {
            //TODO: add other languages
            if (language in texts[key]) {
                return { ...reduced, [key]: texts[key][language] }
            } else {
                return { ...reduced, [key]: texts[key].en }
            }
            //return { ...reduced, [key]: texts[key].en }
        }, {} as MappedTexts<Texts>), [texts, language])
    return filtered
}