import React, { useState, useEffect, useCallback } from 'react'
import moment from 'moment-timezone'
import { useTextLang } from '../../Contexts/LangContext';
import { Grid, Typography, Divider } from '@material-ui/core';
import { AddBox, EditOutlined } from '@material-ui/icons';
import { useRouter, routes } from '../../Contexts/RouterContext';
import useForm, { FormatForm } from '../../Form/FormUtils';
import FamilyInfoFields, { familyInfoRules } from '../../Form/FamilyInfoFields';
import FormNavigation from '../../Form/FormNavigation';
import { useBasicTexts } from '../../Global/GlobalItems';
import { Person } from '../../server-types';
import PersonFields, { personDefaultValues, fullPersonRules } from '../../Form/PersonFields';
import useFieldUtils from '../../Form/FieldUtils';
import { Clickable, InputNavigationProps, defaultOption, FamilyInfoType, PersonInfoType, defaultDate, MatchProps } from '../../types';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { adminMutation, adminQuery } from '../../Global/ServerConnection';
import LoadingPage from '../../Global/LoadingPage';

const familySettingsTexts = {
    newChild: {
        en: 'New Child'
    },
    editFamilyInfo: {
        en: 'Edit Family Info'
    },
    addChild: {
        en: 'Add child'
    },
}

export default function EditFamily({ match }: MatchProps) {
    const familyid = match.params.id
    const [editingPerson, setEditingPerson] = useState<string | undefined>(undefined)
    const [editingFamilyInfo, setEditingFamilyInfo] = useState(false)
    const [addingChild, setAddingChild] = useState(false)
    const [persons, setPersons] = useState<Person[]>([])
    const [familyInfo, setFamilyInfo] = useState<Record<keyof FamilyInfoType, string>>()
    const [loading, setLoading] = useState(true)
    const texts = useTextLang(familySettingsTexts)

    const updateFamilyInfo = useCallback(() => {
        window.scrollTo(0, 0)
        const processInfo = (info: { persons: Person[] } & Record<keyof FamilyInfoType, string>) => {
            if (info) {
                const { persons, ...familyInfo } = info
                setPersons(persons)
                setFamilyInfo(familyInfo)
                setLoading(false)
            }
        }
        adminQuery('getFamilyInfo', processInfo, `familyid:"${familyid}"`, 'persons{personid,name},streetAddress,city,state,country,zipCode,maritalStatus,dateOfMarriage')
    }, [familyid])

    useEffect(() => {
        updateFamilyInfo()
    }, [updateFamilyInfo])

    const editPerson = useCallback((personid: string) => () => {
        setEditingPerson(personid)
        setAddingChild(false)
        setEditingFamilyInfo(false)
    }, [])

    const editFamilyInfo = useCallback(() => {
        setEditingPerson(undefined)
        setAddingChild(false)
        setEditingFamilyInfo(true)
    }, [])

    const addChild = useCallback(() => {
        setEditingPerson(undefined)
        setAddingChild(true)
        setEditingFamilyInfo(false)
    }, [])

    const cancelEdit = useCallback(() => {
        setEditingPerson(undefined)
        setAddingChild(false)
        setEditingFamilyInfo(false)
    }, [])

    const finishEdit = useCallback(() => {
        cancelEdit()
        updateFamilyInfo()
    }, [cancelEdit, updateFamilyInfo])

    return (
        <>
            {editingFamilyInfo && familyInfo
                ? <FamilyInfo
                    familyid={familyid}
                    onFinish={finishEdit}
                    onCancel={cancelEdit}
                    familyInfo={familyInfo}
                />
                : <PersonName
                    name={texts.editFamilyInfo}
                    onClick={editFamilyInfo}
                />}
            {persons.map(({ personid, name }) => {
                if (editingPerson === personid) {
                    return (
                        <PersonInfo
                            onFinish={finishEdit}
                            onCancel={cancelEdit}
                            name={name}
                            personid={personid}
                            key={personid}
                        />
                    )
                } else {
                    return (
                        <PersonName
                            name={name}
                            key={personid}
                            onClick={editPerson(personid)}
                        />
                    )
                }
            })}
            {addingChild ?
                <ChildInfo
                    familyid={familyid}
                    onFinish={finishEdit}
                    onCancel={cancelEdit}
                />
                : <AddChild onClick={addChild} />
            }
            <LoadingPage loading={loading} />
        </>
    )
}

type FamilyInfoProps = InputNavigationProps & {
    familyInfo: Record<keyof FamilyInfoType, string>,
    familyid: string,
}
function FamilyInfo({ onFinish, onCancel, familyInfo, familyid }: FamilyInfoProps) {
    const [loading, setLoading] = useState(false)
    const { navigateTo } = useRouter()
    const basicTexts = useBasicTexts()
    const texts = useTextLang(familySettingsTexts)
    const { maritalStatusOptions, stateOptions, nationalityOptions } = useFieldUtils()

    const finish = useCallback((values: Record<keyof FamilyInfoType, string>) => {
        setLoading(true)
        const processMutation = (success: boolean) => {
            if (success) {
                onFinish()
            } else {
                navigateTo(routes.error)
            }
        }
        adminMutation('updateFamilyInfo', processMutation, `familyid:"${familyid}",streetAddress:"${values.streetAddress.trim()}",city:"${values.city.trim()}",state:"${values.state}",zipCode:"${values.zipCode}",country:"${values.country}",maritalStatus:${values.maritalStatus}${values.maritalStatus === 'MARRIED' ? `,dateOfMarriage:"${values.dateOfMarriage}"` : ''}`)
    }, [navigateTo, onFinish, familyid])

    const { values, setValue, errors, setError, handleSubmit } = useForm(familyInfoRules, finish, {
        maritalStatus: maritalStatusOptions.find(val => val.value === familyInfo.maritalStatus) || defaultOption,
        dateOfMarriage: (familyInfo.dateOfMarriage && moment(familyInfo.dateOfMarriage)) || defaultDate,
        streetAddress: familyInfo.streetAddress,
        city: familyInfo.city,
        state: stateOptions.find(val => val.value === familyInfo.state) || defaultOption,
        country: nationalityOptions.find(val => val.value === familyInfo.country) || defaultOption,
        zipCode: familyInfo.zipCode
    })

    return (
        <>
            <Grid item container direction='row'>
                <Typography variant='h6'>
                    {texts.editFamilyInfo}
                </Typography>
            </Grid>
            <Divider />
            <FormatForm>
                <FamilyInfoFields values={values} setValue={setValue} errors={errors} setError={setError} />
            </FormatForm>
            <FormNavigation
                onSubmit={handleSubmit}
                submitText={basicTexts.done}
                onCancel={onCancel}
                cancelText={basicTexts.cancel}
                loading={loading}
                smallSpacing
            />
            <Divider />
        </>
    )
}

type PersonNameProps = Clickable & {
    name: string,
}
function PersonName({ name, onClick }: PersonNameProps) {
    return (
        <>
            <Grid item container justify='space-between' onClick={onClick}>
                <Typography variant='h6'>
                    {name}
                </Typography>
                <EditOutlined />
            </Grid>
            <Divider />
        </>
    )
}

type PersonInfoProps = InputNavigationProps & Person
function PersonInfo({ personid, name, ...rest }: PersonInfoProps) {
    const [personInfo, setPersonInfo] = useState<Record<keyof PersonInfoType, string>>()

    useEffect(() => {
        const processOriginalValues = (values?: Record<keyof PersonInfoType, string>) => {
            if (values)
                setPersonInfo(values)
        }
        return adminQuery('getPersonInfo', processOriginalValues, `personid:"${personid}"`, 'firstName,lastName,sex,dateOfBirth,nationality,preferredLanguage,email,phoneNumber,phoneCarrier')
    }, [personid])

    if (personInfo) {
        return <DisplayPerson personInfo={personInfo} personid={personid} name={name} {...rest} />
    } else {
        return <PersonName name={name} onClick={() => { }} />
    }
}

type DisplayPersonProps = {
    personInfo: Record<keyof PersonInfoType, string>
} & InputNavigationProps & Person
function DisplayPerson({ onFinish, onCancel, name, personid, personInfo }: DisplayPersonProps) {
    const [loading, setLoading] = useState(false)
    const { navigateTo } = useRouter()
    const basicTexts = useBasicTexts()
    const { carrierOptions, languageOptions, nationalityOptions } = useFieldUtils()

    const finish = useCallback((values: Record<keyof PersonInfoType, string>) => {
        setLoading(true)
        const processMutation = (success: boolean) => {
            if (success) {
                onFinish()
            } else {
                navigateTo(routes.error)
            }
        }
        adminMutation('updatePerson', processMutation, `personid:"${personid}",firstName:"${values.firstName.trim()}",lastName:"${values.lastName.trim()}",sex:${values.sex},dateOfBirth:"${values.dateOfBirth}",nationality:"${values.nationality}",preferredLanguage:"${values.preferredLanguage}",email:"${values.email}",phoneNumber:"${values.phoneNumber}",phoneCarrier:"${values.phoneCarrier}"`)
    }, [navigateTo, onFinish, personid])

    const { values, setValue, errors, setError, handleSubmit } = useForm(fullPersonRules(), finish, {
        firstName: personInfo.firstName,
        lastName: personInfo.lastName,
        sex: personInfo.sex,
        dateOfBirth: moment(personInfo.dateOfBirth) as MaterialUiPickersDate,
        nationality: nationalityOptions.find(val => val.value === personInfo.nationality) || defaultOption,
        preferredLanguage: languageOptions.find(val => val.value === personInfo.preferredLanguage) || defaultOption,
        email: personInfo.email,
        phoneNumber: personInfo.phoneNumber,
        phoneCarrier: carrierOptions.find(val => val.value === personInfo.phoneCarrier) || defaultOption
    })

    return (
        <>
            <Grid item container direction='row'>
                <Typography variant='h6'>
                    {name}
                </Typography>
            </Grid>
            <Divider />
            <FormatForm>
                <PersonFields values={values} setValue={setValue} errors={errors} setError={setError} />
            </FormatForm>
            <FormNavigation
                onSubmit={handleSubmit}
                submitText={basicTexts.done}
                onCancel={onCancel}
                cancelText={basicTexts.cancel}
                loading={loading}
                smallSpacing
            />
            <Divider />
        </>
    )
}

type ChildInfoProps = {
    familyid: string
} & InputNavigationProps
function ChildInfo({ familyid, onFinish, onCancel }: ChildInfoProps) {
    const [loading, setLoading] = useState(false)
    const { navigateTo } = useRouter()
    const texts = useTextLang(familySettingsTexts)
    const basicTexts = useBasicTexts()

    const finish = useCallback((values: Record<keyof PersonInfoType, string>) => {
        setLoading(true)
        const processMutation = (success: boolean) => {
            if (success) {
                onFinish()
            } else {
                navigateTo(routes.error)
            }
        }
        adminMutation('addChild', processMutation, `familyid:"${familyid}",firstName:"${values.firstName.trim()}",lastName:"${values.lastName.trim()}",sex:${values.sex},dateOfBirth:"${values.dateOfBirth}",nationality:"${values.nationality}",preferredLanguage:"${values.preferredLanguage}",email:"${values.email || ''}",phoneNumber:"${values.phoneNumber || ''}",phoneCarrier:"${values.phoneCarrier || ''}"`)
    }, [navigateTo, onFinish, familyid])

    const { values, setValue, errors, setError, handleSubmit } = useForm(fullPersonRules(), finish, personDefaultValues)


    return (
        <>
            <Grid item container direction='row'>
                <Typography variant='h6'>
                    {texts.newChild}
                </Typography>
            </Grid>
            <Divider />
            <FormatForm>
                <PersonFields values={values} setValue={setValue} errors={errors} setError={setError} />
            </FormatForm>
            <FormNavigation
                onSubmit={handleSubmit}
                submitText={basicTexts.done}
                onCancel={onCancel}
                cancelText={basicTexts.cancel}
                loading={loading}
            />
        </>
    )
}

function AddChild({ onClick }: Clickable) {
    const texts = useTextLang(familySettingsTexts)

    return (
        <>
            <Grid item container direction='row' spacing={1} wrap='nowrap' onClick={onClick}>
                <Grid item xs container alignItems='center'>
                    <AddBox color='disabled' />
                </Grid>
                <Grid item container>
                    <Typography variant='h6' color='textSecondary'>
                        {texts.addChild}
                    </Typography>
                </Grid>
            </Grid>
        </>
    )
}