import React from 'react'
import FullPageFormat from '../Global/FullPageFormat';
import FamilySettings from './FamilySettings';
import { makeStyles, Divider, Typography, Grid } from '@material-ui/core';
import { useTextLang } from '../Contexts/LangContext';
import { routeIDs } from '../Contexts/RouterContext'
import { MatchProps } from '../types';
import AccountSettings from './AccountSettings';

const settingsTexts = {
    familySettingsTitle: {
        en: 'Family Info'
    },
    accountSettingsTitle: {
        en: 'Account Info'
    }
}

const useStyles = makeStyles(theme => ({
    smallDivider: {
        width: '100%',
        height: theme.spacing(3)
    },
    largeDivider: {
        width: '100%',
        height: theme.spacing(10)
    }
}))

export default function Settings({ match }: MatchProps) {
    const { id } = match.params
    const classes = useStyles()
    const texts = useTextLang(settingsTexts)

    return (
        <FullPageFormat size='narrow'>
            <Grid container direction='column' spacing={1}>
                <Grid item>
                    <Typography variant='h6' color='textSecondary'>
                        {id === routeIDs.familySettings && texts.familySettingsTitle}
                        {id === routeIDs.accountSettings && texts.accountSettingsTitle}
                    </Typography>
                </Grid>
                <Divider />
                <div className={classes.smallDivider} />
                {id === routeIDs.familySettings && <FamilySettings />}
                {id === 'account-settings' && <AccountSettings />}
                <div className={classes.largeDivider} />
            </Grid>
        </FullPageFormat>
    )
}