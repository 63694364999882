import React, { useEffect, useState, useCallback, useRef } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Card, Divider, Theme, Button } from '@material-ui/core';

import RegisterContainer from './RegisterContainer'
import EventContents from './AddEvent/EventContents'
import { MobileNavigation, DesktopNavigation } from '../Global/BottomNavigation'
import { makeStyles, useTheme } from '@material-ui/styles';
import { useRouter, routes } from '../Contexts/RouterContext';
import { useTextLang, useLang } from '../Contexts/LangContext';
import { MatchProps, JSONt, InputNavigationProps, OptionValue, defaultOption, isOptionValue, ParticipantSelection } from '../types';
import { FullParticipantGroup, MutationAttempt } from '../server-types';
import { useBasicTexts } from '../Global/GlobalItems';
import SelectParticipants from './AddEvent/SelectParticipants';
import useFieldUtils from '../Form/FieldUtils';
import { useAuth } from '../Contexts/AuthContext';
import LoadingPage from '../Global/LoadingPage';
import { BasicDialog } from '../Global/Dialogs';

const addEventTexts = {
    lessThanOneError: {
        en: `You need to select at least 1 participant`,
        es: 'Por favor seleccione al menos 1 participante'
    },
    lessThanMinError: {
        en: (min: number) => `You need to select at least ${min} participants`,
        es: (min: number) => `Necesita seleccionar al menos ${min} participantes`
    },
    moreThanOneError: {
        en: `You can select at most 1 participant`,
        es: 'Solo puede seleccionar 1 participante'
    },
    moreThanMaxError: {
        en: (max: number) => `You can select at most ${max} participants`,
        es: (max: number) => `Solo puede seleccionar hasta ${max} participantes`
    },
    noTShirtError: {
        en: 'Please select a t-shirt size.',
        es: 'Por favor seleccione el tamaño de la camiseta.'
    },
    error: {
        en: 'Error'
    },
}

const useStyles = makeStyles(theme => ({
    contentCard: {
        margin: theme.spacing(5),
    },
    navigationCard: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
    },
}))

export default function AddEvent({ match }: MatchProps) {
    const { navigateTo, goBack } = useRouter()

    const [participantGroups, setParticipantGroups] = useState<FullParticipantGroup[]>([])
    const [selected, setSelected] = useState<JSONt<ParticipantSelection>>({})
    const [errors, setErrors] = useState<(string | undefined)[]>([])
    const [error, setError] = useState<string>()
    const texts = useTextLang(addEventTexts)
    const [loading, setLoading] = useState(true)
    const eventID = match.params.id
    const { authQuery, authMutation } = useAuth()
    const myRef = useRef<any>(null)
    const { language } = useLang()

    const clearError = useCallback(() => {
        setError(undefined)
    }, [])

    useEffect(() => {
        const processParticipants = (participants: FullParticipantGroup[]) => {
            setParticipantGroups(participants)
            setLoading(false)
        }
        return authQuery('getPossibleParticipants', processParticipants, `eventid:"${eventID}"`, 'persons{personid,name,selected,registered,tshirtSize}withTShirt,min,max')
    }, [eventID, authQuery])

    const { tshirtOptions } = useFieldUtils()
    useEffect(() => {
        const numPersons = participantGroups.reduce((num, { persons }) => num + persons.length, 0)
        if (Object.keys(selected).length === 0 && numPersons !== 0) {
            setSelected(participantGroups.reduce((selected, { persons }) => {
                return persons.reduce((p, { personid, selected, tshirtSize }) => {
                    const optionSize = tshirtOptions.find(option => option.value === tshirtSize)
                    return { ...p, [personid]: { checked: selected || false, tshirt: optionSize || defaultOption, error: undefined } }
                }, selected)
            }, {} as JSONt<ParticipantSelection>))
        }
    }, [participantGroups, selected, tshirtOptions])

    const toggleParticipant = useCallback((personid: string, checked: boolean) => {
        setSelected(selected => {
            return { ...selected, [personid]: { ...selected[personid], checked } }
        })
        setErrors([])
    }, [])

    const setTShirt = useCallback((personid: string, tshirt: OptionValue) => {
        setSelected(selected => ({ ...selected, [personid]: { ...selected[personid], tshirt, error: undefined } }))
        setErrors([])
    }, [])

    const processErrors = useCallback(() => {
        const hasTShirtErrors = participantGroups.reduce((hasError, { persons, withTShirt }) => {
            if (withTShirt) {
                return persons.reduce((hasError, { personid }) => {
                    const { checked, tshirt, error } = selected[personid]
                    if (checked && (!isOptionValue(tshirt) || tshirt.value === '')) {
                        setSelected(selected => {
                            return { ...selected, [personid]: { ...selected[personid], error: texts.noTShirtError } }
                        })
                        return true
                    } else {
                        return hasError || error !== undefined
                    }
                }, hasError)
            } else {
                return hasError
            }
        }, false)
        if (hasTShirtErrors) {
            return true
        }

        const errors = participantGroups.map(({ persons, min, max }) => {
            const numSelected = persons.reduce((numSelected, { personid }) => (
                numSelected + (selected[personid] ? 1 : 0)
            ), 0)
            if (numSelected < min) {
                if (min === 1) {
                    return texts.lessThanOneError
                } else {
                    return texts.lessThanMinError(min)
                }
            } else if (numSelected > max) {
                if (max === 1) {
                    return texts.moreThanOneError
                } else {
                    return texts.moreThanMaxError(max)
                }
            } else {
                return undefined
            }
        })
        const hasError = errors.reduce((hasError, error) => (
            hasError || error !== undefined
        ), false)
        if (hasError) {
            setErrors(errors)
        }
        return hasError
    }, [participantGroups, selected, texts])

    const next = useCallback(() => {
        if (!processErrors()) {
            const selectedParticipants = Object.keys(selected).filter((personid) => selected[personid].checked).map(personid => `{personid:"${personid}",tshirtSize:"${selected[personid].tshirt.value}"}`).join(',')
            const processMutation = ({ success, error }: MutationAttempt) => {
                if (success) {
                    navigateTo(routes.pendingEvents)
                } else if (error) {
                    setError(error)
                } else {
                    navigateTo(routes.error)
                }
            }
            authMutation('addEventToCart', processMutation, `language:"${language}",eventid:"${eventID}",persons:[${selectedParticipants}]`, 'success,error')
        }
    }, [processErrors, selected, navigateTo, eventID, authMutation, language])

    const classes = useStyles()
    const theme = useTheme<Theme>()
    const matches = useMediaQuery(theme.breakpoints.up('sm'))

    const eventContents = (
        <EventContents desktop={matches} eventID={eventID}>
            {participantGroups.map(({ persons, withTShirt }, index) => (
                <SelectParticipants persons={persons} error={errors[index]}
                    key={index}
                    selected={selected}
                    withTShirt={withTShirt}
                    setTShirt={setTShirt}
                    toggleParticipant={toggleParticipant}
                    menuPortalTarget={myRef}
                />
            ))}
        </EventContents>
    )
    return (
        <RegisterContainer activeStep={0}>
            {matches
                ? <Card className={classes.contentCard} innerRef={myRef}>
                    {eventContents}
                    <Divider variant='middle' />
                    <DesktopNavigation>
                        <NavigationContents onFinish={next} onCancel={goBack} />
                    </DesktopNavigation>
                </Card>
                : <>
                    {eventContents}
                    <MobileNavigation>
                        <NavigationContents onFinish={next} onCancel={goBack} />
                    </MobileNavigation>
                </>
            }
            <LoadingPage loading={loading} />
            <BasicDialog open={error !== undefined} handleClose={clearError} title={texts.error} contents={error} />
        </RegisterContainer >
    )
}

function NavigationContents({ onFinish, onCancel }: InputNavigationProps) {
    const texts = useBasicTexts()
    return (
        <>
            <Button onClick={onCancel} color='primary'>
                {texts.cancel}
            </Button>
            <Button onClick={onFinish} variant='contained' color='primary'>
                {texts.next}
            </Button>
        </>
    )
}