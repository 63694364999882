import React, { useCallback, useState, PropsWithChildren } from 'react'
import moment from 'moment-timezone'
import { Consumer } from '../types';


const velocityThreshold = 0.5

type SwipeProps = PropsWithChildren<{
    onSwipe: Consumer<'left' | 'right'>
}>
export default function Swipe({ onSwipe, children }: SwipeProps) {
    const [velocity, setVelocity] = useState(0)
    const [timeOfLastDragEvent, setTimeOfLastDragEvent] = useState(0)
    const [prevTouchX, setPrevTouchX] = useState(0)
    const [beingTouched, setBeingTouched] = useState(false)


    const handleStart = useCallback((clientX) => {
        setVelocity(0)
        setTimeOfLastDragEvent(moment.now())
        setPrevTouchX(clientX)
        setBeingTouched(true)
    }, [])

    const handleMove = useCallback((clientX) => {
        if (beingTouched) {
            const currTime = moment.now();
            const elapsed = currTime - timeOfLastDragEvent;
            const velocity = (clientX - prevTouchX) / elapsed;
            setVelocity(velocity)
            setTimeOfLastDragEvent(currTime)
            setPrevTouchX(clientX)
        }
    }, [beingTouched, prevTouchX, timeOfLastDragEvent])

    const handleEnd = useCallback(() => {
        if (velocity > velocityThreshold) {
            onSwipe('right')
        } else if (velocity < -velocityThreshold) {
            onSwipe('left')
        }
        setBeingTouched(false)
    }, [onSwipe, velocity])

    const handleTouchStart = useCallback((touchStartEvent) => {
        touchStartEvent.preventDefault();
        handleStart(touchStartEvent.targetTouches[0].clientX);
    }, [handleStart])

    const handleTouchMove = useCallback((touchMoveEvent) => {
        handleMove(touchMoveEvent.targetTouches[0].clientX);
    }, [handleMove])

    const handleMouseDown = useCallback((mouseDownEvent) => {
        mouseDownEvent.preventDefault();
        handleStart(mouseDownEvent.clientX);
    }, [handleStart])

    const handleMouseMove = useCallback((mouseMoveEvent) => {
        handleMove(mouseMoveEvent.clientX);
    }, [handleMove])

    return (
        <div
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleEnd}
            // The following event handlers are for mouse compatibility:
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleEnd}
            onMouseLeave={handleEnd}
        >
            {children}
        </div>
    )
}