import React, { createContext, useContext, ReactNode, useRef, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
    layout: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        height: '85vh'
    },
    bar: {
        width: '50%',
        height: '100%',
        overflow: 'auto',
        padding: theme.spacing(2)
    }
}))


const SideBarContext = createContext<HTMLDivElement | undefined>(undefined)

const SideBarProvider = ({ children }: { children: ReactNode }) => {
    const ref = useRef<HTMLDivElement>(null)
    const [current, setCurrent] = useState<HTMLDivElement>()
    useEffect(() => {
        const time = 10
        const intervalID = setInterval(() => {
            if (ref && ref.current) {
                setCurrent(ref.current)
                clearInterval(intervalID)
            }
        }, time)

        return () => clearInterval(intervalID)
    }, [ref])

    const classes = useStyles()
    return (
        <SideBarContext.Provider
            value={current}
        >
            <div className={classes.layout}>
                <div className={classes.bar}>
                    {children}
                </div>
                <div className={classes.bar} ref={ref}>
                </div>
            </div>
        </SideBarContext.Provider>
    )
}

export { SideBarProvider }
export const useSideBar = () => useContext(SideBarContext)