import React, { useState, useEffect, useCallback } from 'react';
import { Grid, Typography, Divider, List, Button } from '@material-ui/core';
import { useTextLang } from '../../Contexts/LangContext'
import { Consumer } from '../../types';
import { adminRoutes } from '../Admin'
import { adminQuery, adminMutation } from '../../Global/ServerConnection';
import { makeStyles } from '@material-ui/styles';
import { useRouter } from '../../Contexts/RouterContext';
import LoadingPage from '../../Global/LoadingPage';

const approveFamiliesTexts = {
    title: {
        en: 'Approve Families',
    },
    viewFamily: {
        en: 'View Family'
    },
    approve: {
        en: 'Approve'
    }
}

const useStyles = makeStyles(theme => ({
    familyRow: {
        padding: theme.spacing(2)
    }
}))

type Family = {
    familyid: string,
    fatherName?: string,
    motherName?: string,
}

export default function ApproveFamilies() {
    const texts = useTextLang(approveFamiliesTexts)
    const [families, setFamilies] = useState<Family[]>()
    const [loading, setLoading] = useState(true)

    const updateUnapprovedFamilies = useCallback(() => {
        const processFamilies = (families: Family[]) => {
            setFamilies(families)
            setLoading(false)
        }
        adminQuery('getFamilies', processFamilies, 'approved:false', 'familyid,fatherName,motherName')
    }, [])

    useEffect(() => {
        updateUnapprovedFamilies()
    }, [updateUnapprovedFamilies])

    const approveFamily = useCallback((familyid: string) => {
        adminMutation('approveFamily', updateUnapprovedFamilies, `familyid:"${familyid}"`)
    }, [updateUnapprovedFamilies])

    return (
        <Grid container direction='column' spacing={1}>
            <Grid item>
                <Typography variant='h6' color='textSecondary'>
                    {texts.title}
                </Typography>
            </Grid>
            {families &&
                <List>
                    <Divider />
                    {families.map(({ familyid, ...rest }) =>
                        <Family familyid={familyid} {...rest} approveFamily={approveFamily} key={familyid} />
                    )}
                </List >
            }
            <LoadingPage loading={loading} />
        </Grid>
    )
}

function Family({ familyid, fatherName, motherName, approveFamily }: Family & { approveFamily: Consumer<string> }) {
    const texts = useTextLang(approveFamiliesTexts)
    const { navigateTo } = useRouter()
    const classes = useStyles()

    const viewFamily = useCallback(() => {
        navigateTo(adminRoutes.viewFamily(familyid))
    }, [navigateTo, familyid])

    const approve = useCallback(() => {
        approveFamily(familyid)
    }, [approveFamily, familyid])

    const name = (fatherName && motherName && `${fatherName}, ${motherName}`) || fatherName || motherName
    return (
        <>
            <Grid container direction='row' alignItems='center' justify='space-between' className={classes.familyRow}>
                <Grid item>
                    <Button color='primary' onClick={viewFamily}>
                        {texts.viewFamily}
                    </Button>
                </Grid>
                <Grid item>
                    <Typography>
                        {name}
                    </Typography>
                </Grid>
                <Grid item>
                    <Button color='primary' variant='contained' onClick={approve}>
                        {texts.approve}
                    </Button>
                </Grid>
            </Grid>
            <Divider />
        </>
    )
}
