import React, { useEffect, useCallback, useRef, useState } from 'react'
import { TextField, useMediaQuery, Theme } from '@material-ui/core'
import { FormField } from '../../types';
import { TextFieldProps } from '@material-ui/core/TextField';
import { useTheme } from '@material-ui/styles';
import { scrollToView } from '../../Global/GlobalItems';

type NumberFieldType<FieldName> = FormField<FieldName, number | undefined> & {
    precision?: number,
    min?: number,
    max?: number,
} & TextFieldProps
export default function NumberField<FieldName>({ field, value, setValue, errorMsg, onEnter, required, helperText, precision = 0, min = 0, max = Number.MAX_SAFE_INTEGER, ...rest }: NumberFieldType<FieldName>) {
    const [text, setText] = useState('')

    useEffect(() => {
        if (typeof value === 'number' && !isNaN(value))
            setText(`${value}`)
    }, [value])

    const handleChange: TextFieldProps['onChange'] = useCallback((event) => {
        setText(event.target.value)
    }, [])

    const handleBlur: TextFieldProps['onBlur'] = useCallback((event) => {
        const value = event.target.value
        // console.log('blur', value)
        if (value) {
            // console.log(Number(value).toFixed(precision))
            const number = Number(Number(value).toFixed(precision))
            // console.log(value, number, 'other')
            if (isNaN(number)) {
                setValue(field, 0)
                setText('0')
            } else if (min !== undefined && number < min) {
                setValue(field, min)
                setText(`${min}`)
            } else if (max !== undefined && number > max) {
                setValue(field, max)
                setText(`${max}`)
            } else {
                setValue(field, number)
                setText(`${number}`)
            }
        }
    }, [field, precision, setValue, min, max])

    const keyPress = useCallback(({ keyCode }: { keyCode: number }) => {
        if (keyCode === 13 && onEnter) {
            onEnter()
        }
    }, [onEnter])

    // Remove value on unmount
    useEffect(() => {
        return () => {
            setValue(field, 0)
        }
    }, [field, setValue])

    const theme = useTheme<Theme>()
    const matches = useMediaQuery(theme.breakpoints.down('xs'))
    const myRef = useRef<HTMLDivElement>(null)
    const handleFocus = useCallback(() => {
        if (matches)
            scrollToView(myRef)
    }, [myRef, matches])

    return (
        <TextField
            margin='dense'
            fullWidth={true}
            {...rest}
            ref={myRef}
            onKeyDown={keyPress}
            onFocus={handleFocus}
            value={text}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={errorMsg || helperText}
            error={errorMsg !== undefined}
        />
    )
}