import React from 'react';
import { Grid, Typography, Divider, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { useTextLang } from '../Contexts/LangContext'
import { Link as RouterLink } from 'react-router-dom'
import { adminRoutes } from './Admin'
import { SettingsOutlined, ThumbUp, Search, FindInPage, School, Email, Notifications } from '@material-ui/icons'

const adminMainTexts = {
    title: {
        en: 'Administration',
        es: 'Administracion'
    },
    eventSettings: {
        en: 'Event Settings',
    },
    viewNotifications: {
        en: 'View Notifications'
    },
    sendEmail: {
        en: 'Send Email',
    },
    aproveFamilies: {
        en: 'Approve Families'
    },
    addTeachers: {
        en: 'Add Teachers'
    },
    findFamily: {
        en: 'Find Family'
    },
    reportNavigation: {
        en: 'Reports'
    },
}

export default function AdminMain() {
    const texts = useTextLang(adminMainTexts)

    return (
        <Grid container direction='column' spacing={1}>
            <Grid item>
                <Typography variant='h6' color='textSecondary'>
                    {texts.title}
                </Typography>
            </Grid>
            <List>
                <Divider />
                <ListItem button component={RouterLink} to={adminRoutes.eventNavigation}>
                    <ListItemIcon><SettingsOutlined /></ListItemIcon>
                    <ListItemText primary={texts.eventSettings} />
                </ListItem>
                <Divider />
                <ListItem button component={RouterLink} to={adminRoutes.createEmail}>
                    <ListItemIcon><Email /></ListItemIcon>
                    <ListItemText primary={texts.sendEmail} />
                </ListItem>
                <Divider />
                <ListItem button component={RouterLink} to={adminRoutes.viewNotifications}>
                    <ListItemIcon><Notifications /></ListItemIcon>
                    <ListItemText primary={texts.viewNotifications} />
                </ListItem>
                <Divider />
                <ListItem />
                <Divider />
                <ListItem button component={RouterLink} to={adminRoutes.approveFamilies}>
                    <ListItemIcon><ThumbUp /></ListItemIcon>
                    <ListItemText primary={texts.aproveFamilies} />
                </ListItem>
                <Divider />
                <ListItem button component={RouterLink} to={adminRoutes.addTeachers}>
                    <ListItemIcon><School /></ListItemIcon>
                    <ListItemText primary={texts.addTeachers} />
                </ListItem>
                <Divider />
                <ListItem button component={RouterLink} to={adminRoutes.findFamily}>
                    <ListItemIcon><Search /></ListItemIcon>
                    <ListItemText primary={texts.findFamily} />
                </ListItem>
                <Divider />
                <ListItem />
                <Divider />
                <ListItem button component={RouterLink} to={adminRoutes.reportNavigation}>
                    <ListItemIcon><FindInPage /></ListItemIcon>
                    <ListItemText primary={texts.reportNavigation} />
                </ListItem>
                <Divider />
            </List>
        </Grid>
    )
}