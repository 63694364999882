import React, { useState, useCallback, useEffect } from 'react'
import { adminMutation, adminQuery } from '../../Global/ServerConnection'
import { BasicDialog } from '../../Global/Dialogs'
import { defaultOption, OptionValue, Consumer, MatchProps, BiConsumer } from '../../types'
import { Grid, Typography, Divider, Button, CircularProgress, Fab, makeStyles } from '@material-ui/core'
import Text from '../../Form/Components/Text'
import Selection from '../../Form/Components/Selection'
import { Cancel, Save } from '@material-ui/icons'
import SingleCheckBox from '../../Form/Components/CheckBox'
import { escape } from '../../Global/GlobalItems'
import NumberField from '../../Form/Components/NumberField'
import LoadingPage from '../../Global/LoadingPage';
import { Notification } from '../../Global/Notifications'

const colorOptions = [
    { value: 'red', label: 'Red' },
    { value: 'green', label: 'Green' },
    { value: 'yellow', label: 'Yellow' },
    { value: 'blue', label: 'Blue' },
]

const useStyles = makeStyles(theme => ({
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    fabProgress: {
        position: 'fixed',
        bottom: theme.spacing(1.5),
        right: theme.spacing(1.5),
    },
    fabContainer: {
        height: theme.spacing(10)
    }
}))

type AccountType = {
    accountid: string,
    name: string,
}

type NotificationType = {
    accounts: AccountType[],
    notification: string,
    color: OptionValue,
    priority: number,
    active: boolean,
}

type NotificationInput = {
    accounts: AccountType[],
    notification: string,
    color: string,
    priority: number,
    active: boolean,
}

export default function EditNotification({ match }: MatchProps) {
    const notificationID = match.params.id
    const [loading, setLoading] = useState(true)
    const [submitting, setSubmitting] = useState(false)
    const [errorSaving, setErrorSaving] = useState(false)
    const classes = useStyles()
    const [values, setValues] = useState<NotificationType>({
        accounts: [],
        notification: '',
        color: { value: '', label: '' },
        priority: 0,
        active: false,
    })

    const setValue = useCallback((field, value) => {
        setValues((values: NotificationType) => ({
            ...values,
            [field]: value
        }))
    }, [])

    useEffect(() => {
        const processNotification = (notification?: NotificationInput) => {
            if (notification) {
                setValues({
                    ...notification,
                    color: colorOptions.find(({ value }) => value === notification.color) || defaultOption,
                })
            }
            setLoading(false)
        }
        return adminQuery('getNotification', processNotification, `notificationid:"${notificationID}"`, 'accounts{accountid,name}notification,color,priority,active')
    }, [notificationID])

    const save = useCallback(() => {
        setSubmitting(true)
        const formattedAccounts = values.accounts.length > 0 ? `[${values.accounts.map(({ accountid }) => `"${accountid}"`).join(',')}]` : 'null'

        const processEdit = (success: boolean) => {
            setSubmitting(false)
            if (!success) {
                setErrorSaving(true)
            }
        }
        // TODO: Send teachers
        adminMutation('updateNotification', processEdit, `notificationid:"${notificationID}",accountids:${formattedAccounts},notification:"${escape(values.notification)}",color:"${values.color.value}",priority:${values.priority},active:${values.active}`)
    }, [values, notificationID])

    const addAccount = useCallback((accountid: string, name: string) => {
        setValues(values => {
            const accounts = values.accounts.filter(account => account.accountid !== accountid)
            accounts.push({ accountid, name })
            return { ...values, accounts }
        })
    }, [])

    const removeAccount = useCallback((accountid: string) => {
        setValues(values => ({
            ...values,
            accounts: values.accounts.filter(account => account.accountid !== accountid)
        }))
    }, [])

    const closeErrorDialog = useCallback(() => setErrorSaving(false), [])

    return (
        <Grid container direction='column' spacing={2}>
            <Grid item>
                <Notification notificationid={notificationID} notification={values.notification} color={values.color.value} />
            </Grid>
            <Grid item>
                <Text field='notification' value={values.notification} label='Notification' setValue={setValue} multiline />
            </Grid>
            <Grid item container spacing={2} alignItems='center'>
                <Grid item xs>
                    <Selection field='color' value={values.color} label='Color' setValue={setValue} options={colorOptions} />
                </Grid>
                <Grid item xs>
                    <NumberField field='priority' value={values.priority} label='Priority' setValue={setValue} />
                </Grid>
                <Grid item xs>
                    <SingleCheckBox field='active' value={values.active} label='Active' setValue={setValue} />
                </Grid>
            </Grid>
            <Divider />
            <Grid item>
                <Typography>
                    {'Visible to:'}
                </Typography>
            </Grid>
            {values.accounts.length > 0 && values.accounts.map(account => (
                <Account key={account.accountid} remove={removeAccount} {...account} />
            ))}
            {values.accounts.length === 0 &&
                <Grid item>
                    <Typography>{'Everyone'}</Typography>
                </Grid>
            }
            <AccountSelect addAccount={addAccount} />
            <Grid item className={classes.fabContainer}>
                {submitting && <CircularProgress size={64} className={classes.fabProgress} />}
                <Fab className={classes.fab} color={submitting ? 'secondary' : 'primary'} onClick={save}>
                    <Save />
                </Fab>
            </Grid>
            <LoadingPage loading={loading} />
            <BasicDialog open={errorSaving} handleClose={closeErrorDialog} title='Error' contents='Could not save, check quotation marks, etc.' />
        </Grid>
    )
}

type AccountSelectionProps = {
    addAccount: BiConsumer<string, string>,
}
function AccountSelect({ addAccount }: AccountSelectionProps) {
    const [accountOptions, setAccountOptions] = useState<OptionValue[]>([])
    const [account, setAccount] = useState({ value: '', label: '' })

    useEffect(() => {
        const processAccountOptions = (accounts: AccountType[]) => {
            setAccountOptions(accounts.map(({ accountid, name }) => ({ value: accountid, label: name })))
        }
        return adminQuery('getAccountList', processAccountOptions, undefined, 'accountid,name')
    }, [])

    const setValue = useCallback((field, value) => {
        setAccount(value)
    }, [])

    const finish = useCallback(() => {
        addAccount(account.value, account.label)
        setAccount({ value: '', label: '' })
    }, [addAccount, account])

    return (
        <>
            <Grid item container spacing={2} alignItems='center'>
                <Grid item xs>
                    <Selection field='teacher' value={account} label='Account' setValue={setValue} options={accountOptions} />
                </Grid>
                <Grid item>
                    <Button onClick={finish} color='primary'>
                        {'Add'}
                    </Button>
                </Grid>
            </Grid>
        </>
    )
}

type AccountProps = AccountType & {
    remove: Consumer<string>,
}
function Account({ accountid, name, remove }: AccountProps) {
    const onRemove = useCallback(() => { remove(accountid) }, [remove, accountid])

    return (
        <Grid item container spacing={2} alignItems='center' justify='space-between'>
            <Grid item>
                <Typography>
                    {name}
                </Typography>
            </Grid>
            <Grid item>
                <Button onClick={onRemove}>
                    <Cancel color='error' />
                </Button>
            </Grid>
        </Grid>
    )
}