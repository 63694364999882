import React from 'react'
import { Button, Grid, CircularProgress } from '@material-ui/core';
import { Callback } from '../../types';

type SubmitButtonsProps = {
    onSubmit: Callback,
    submitText: React.ReactNode,
    onCancel?: Callback,
    cancelText?: React.ReactNode,
    disabled?: boolean,
    loading?: boolean,
}
export default function SubmitButtons({ onSubmit, onCancel, submitText, cancelText, disabled = false, loading = false }: SubmitButtonsProps) {
    return (
        <Grid container justify='space-between' alignItems='center'>
            <Grid item xs container justify='flex-start'>
                {onCancel &&
                    <Button color='primary' onClick={onCancel} disabled={disabled || loading}>
                        {cancelText}
                    </Button>}
            </Grid>
            {loading && <CircularProgress size={24} />}
            <Grid item xs container justify='flex-end'>
                <Button variant='contained' color='primary' onClick={onSubmit} disabled={disabled || loading}>
                    {submitText}
                </Button>
            </Grid>
        </Grid>
    )
}