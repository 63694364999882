import React, { useState, useCallback, useEffect, MutableRefObject } from 'react';
import { CardContent, Grid, CardActions, Typography } from '@material-ui/core';

import { useTextLang } from '../../Contexts/LangContext';
import { useBasicTexts, escape } from '../../Global/GlobalItems';
import Selection from '../../Form/Components/Selection';
import { FieldType, defaultOption, defaultText, OptionValue, defaultDate } from '../../types';
import useForm from '../../Form/FormUtils';
import Text from '../../Form/Components/Text';
import Email from '../../Form/Components/Email';
import SubmitButtons from './SubmitButtons'
import { Captcha, EventTitle } from '../../server-types';
import { query } from '../../Global/ServerConnection';
import { useRouter, routes } from '../../Contexts/RouterContext';
import DateSelect from '../../Form/Components/DateSelect';
import { BasicDialog, CaptchaDialog } from '../../Global/Dialogs';

const volunteerTexts = {
    title: {
        en: 'Join our Team of Volunteers!',
    },
    subtitle: {
        en: 'The success of Family Education Institute depends on great volunteers like you.'
    },
    firstName: {
        en: 'First Name'
    },
    lastName: {
        en: 'Last Name'
    },
    dateOfBirth: {
        en: 'Date of Birth'
    },
    email: {
        en: 'Email'
    },
    event: {
        en: 'Event'
    },
    aboutPlaceholder: {
        en: 'What makes you want to volunteer?'
    },
    sendApplication: {
        en: 'Send Application'
    },
    incorrectCaptchaError: {
        en: 'This answer is incorrect'
    },
    emptyCaptchaError: {
        en: 'Please answer the question'
    },
    successTitle: {
        en: 'Success!'
    },
    successContents: {
        en: 'Your volunteer application has been submitted.'
    }
}

const volunteerFormRules = {
    firstName: { type: FieldType.Text, required: true },
    lastName: { type: FieldType.Text, required: true },
    dateOfBirth: { type: FieldType.Date, required: true },
    email: { type: FieldType.Email, required: true },
    event: { type: FieldType.Select },
    about: { type: FieldType.Text, required: true },
}

const volunteerFormDefaultValues = {
    firstName: defaultText,
    lastName: defaultText,
    dateOfBirth: defaultDate,
    email: defaultText,
    event: defaultOption,
    about: defaultText,
}

type VolunteerFormValues = {
    firstName: string,
    lastName: string,
    dateOfBirth: string,
    email: string,
    event: string,
    about: string,
}

type VolunteerProps = {
    menuPortalTarget?: MutableRefObject<undefined>,
}
export default function Volunteer({ menuPortalTarget }: VolunteerProps) {
    const texts = useTextLang(volunteerTexts)
    const basicTexts = useBasicTexts()
    const [loading, setLoading] = useState(false)
    const [eventsLoading, setEventsLoading] = useState(true)
    const [finalValues, setFinalValues] = useState<VolunteerFormValues>()
    const [captcha, setCaptcha] = useState<Captcha>()
    const [captchaError, setCaptchaError] = useState<string>()
    const [captchaLoading, setCaptchaLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const { navigateTo } = useRouter()
    const [eventList, setEventList] = useState<OptionValue[]>([])

    const closeSuccessDialog = useCallback(() => {
        setSuccess(false)
    }, [])

    useEffect(() => {
        const processEventList = (eventList: EventTitle[]) => {
            setEventList(eventList.map(({ eventid, title }) =>
                ({ value: eventid, label: title })))
            setEventsLoading(false)
        }
        return query('eventList', processEventList, undefined, 'eventid,title')
    }, [])

    const getCaptcha = useCallback((name: string) => {
        setLoading(true)
        const processCaptcha = (captcha?: Captcha) => {
            setLoading(false)
            if (captcha) {
                setCaptcha(captcha)
                setCaptchaError(undefined)
            } else {
                navigateTo(routes.error)
            }
        }
        query('getBasicCaptcha', processCaptcha, `name:"${escape(name)}"`, 'captchaID,question')
    }, [navigateTo])

    const finishForm = useCallback((values: VolunteerFormValues) => {
        if (values) {
            setFinalValues(values)
            getCaptcha(values.firstName)
        }
    }, [getCaptcha])

    const { values, setValue, errors, setError, handleSubmit } = useForm(volunteerFormRules, finishForm, volunteerFormDefaultValues)


    const closeCaptcha = useCallback(() => {
        setCaptcha(undefined)
        setCaptchaError(undefined)
    }, [])

    const resetForm = useCallback(() => {
        closeCaptcha()
        Object.entries(volunteerFormDefaultValues).forEach(([key, value]) => {
            setValue(key as any, value)
        })
    }, [closeCaptcha, setValue])

    const getNewCaptcha = useCallback(() => {
        if (finalValues) {
            getCaptcha(finalValues.firstName)
        }
    }, [finalValues, getCaptcha])

    const submitCaptchaGuess = useCallback(({ answer }: { answer: string }) => {
        if (answer.length > 0) {
            setCaptchaLoading(true)
            setCaptchaError(undefined)
            const processVolunteer = (success: boolean) => {
                if (success) {
                    resetForm()
                    setSuccess(true)
                } else {
                    setCaptchaError(texts.incorrectCaptchaError)
                }
                setCaptchaLoading(false)
            }

            if (finalValues && captcha) {
                query('volunteer', processVolunteer, `captchaGuess:{captchaID:"${captcha.captchaID}",answer:"${escape(answer)}"},firstName:"${escape(finalValues.firstName)}",lastName:"${escape(finalValues.lastName)}",dateOfBirth:"${escape(finalValues.dateOfBirth)}",email:"${escape(finalValues.email)}",eventid:"${finalValues.event}",about:"${escape(finalValues.about)}"`)
            }
        } else {
            setCaptchaError(texts.emptyCaptchaError)
        }
    }, [captcha, finalValues, resetForm, texts])

    return (
        <>
            <CardContent>
                <Typography align='center' variant='h6'>
                    {texts.title}
                </Typography>
                <Typography align='center' variant='body2' color='textSecondary'>
                    {texts.subtitle}
                </Typography>
                <Grid container direction='column'>
                    <Text field='firstName' label={texts.firstName} value={values.firstName} setValue={setValue} errorMsg={errors.firstName} autoCapitalize='words' />
                    <Text field='lastName' label={texts.lastName} value={values.lastName} setValue={setValue} errorMsg={errors.lastName} autoCapitalize='words' />
                    <DateSelect field='dateOfBirth' label={texts.dateOfBirth} value={values.dateOfBirth} setValue={setValue} errorMsg={errors.dateOfBirth} setErrorMsg={setError} disableFuture />
                    <Email field='email' label={texts.email} value={values.email} setValue={setValue} errorMsg={errors.email} />
                    <Selection field='event' label={texts.event} value={values.event} setValue={setValue} options={eventList} isLoading={eventsLoading} isClearable menuPortalTarget={menuPortalTarget} />
                    <Text field='about' value={values.about} setValue={setValue} errorMsg={errors.about} multiline variant='outlined' rows='5' placeholder={texts.aboutPlaceholder} />
                </Grid>
            </CardContent>
            <CardActions>
                <SubmitButtons
                    onSubmit={handleSubmit}
                    submitText={texts.sendApplication}
                    onCancel={resetForm}
                    cancelText={basicTexts.cancel}
                    loading={loading}
                />
            </CardActions>
            {captcha &&
                <CaptchaDialog captcha={captcha.question} open onSubmit={submitCaptchaGuess} onCancel={closeCaptcha} onNewCaptcha={getNewCaptcha} loading={captchaLoading} errorMsg={captchaError} />
            }
            <BasicDialog open={success} handleClose={closeSuccessDialog} title={texts.successTitle} contents={texts.successContents} />
        </>
    )
}