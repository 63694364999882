import React, { useState, useEffect, useCallback, useRef } from 'react'
import { TextField, InputAdornment, IconButton, Theme, useMediaQuery } from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import { FormField, defaultText } from '../../types';
import { TextFieldProps } from '@material-ui/core/TextField';
import { scrollToView } from '../../Global/GlobalItems';
import { useTheme } from '@material-ui/styles';

type PasswordType<FieldName> = FormField<FieldName, string> & {
    minLength?: number,
} & TextFieldProps
export default function Password<FieldName>({ field, value, setValue, errorMsg, onEnter, minLength = 8, helperText, ...rest }: PasswordType<FieldName>) {
    const [pswdVisible, setPswdVisible] = useState(false)

    const handleClickShowPassword = useCallback(() => {
        setPswdVisible(!pswdVisible)
    }, [pswdVisible])

    const keyPress = useCallback(({ keyCode }: { keyCode: number }) => {
        if (keyCode === 13 && onEnter) {
            onEnter()
        }
    }, [onEnter])

    const handleChange: TextFieldProps['onChange'] = useCallback((event) => {
        setValue(field, event.target.value)
    }, [field, setValue])

    const theme = useTheme<Theme>()
    const matches = useMediaQuery(theme.breakpoints.down('xs'))
    const myRef = useRef<HTMLDivElement>(null)
    const handleFocus = useCallback(() => {
        if (matches)
            scrollToView(myRef)
    }, [myRef, matches])

    // Remove value on unmount
    useEffect(() => {
        return () => {
            setValue(field, defaultText)
        }
    }, [field, setValue])

    return (
        <TextField
            margin='dense'
            fullWidth
            {...rest}
            ref={myRef}
            onFocus={handleFocus}
            onKeyDown={keyPress}
            value={value || ''}
            onChange={handleChange}
            error={errorMsg !== undefined}
            helperText={errorMsg || helperText}
            type={(pswdVisible ? 'text' : 'password')}
            InputProps={{
                endAdornment: (
                    <InputAdornment position='end'>
                        <IconButton onClick={handleClickShowPassword}>
                            {pswdVisible ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
    )
}