import React, { HTMLAttributes, useEffect, useState, MutableRefObject, useCallback, useRef } from 'react';
import Select, {components} from 'react-select';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { useTheme } from '@material-ui/styles';
import { NoSsr, useMediaQuery } from '@material-ui/core';
import { InputBaseComponentProps } from '@material-ui/core/InputBase';
import { ControlProps } from 'react-select/src/components/Control';
import { PlaceholderProps } from 'react-select/src/components/Placeholder';
import { ValueContainerProps, ContainerProps } from 'react-select/src/components/containers';
import { SingleValueProps } from 'react-select/src/components/SingleValue';
import { OptionProps } from 'react-select/src/components/Option';
import { OptionValue, defaultOption, FormField, isOptionValue } from '../../types';
import { ValueType } from 'react-select/src/types';
import { scrollToView } from '../../Global/GlobalItems';
import { NoticeProps } from 'react-select/src/components/Menu';

const useStyles = makeStyles(theme => ({
    input: {
        display: 'flex',
        padding: 0,
        height: 'auto',
    },
    valueContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        alignItems: 'center',
        overflow: 'hidden',
    },
    noOptionsMessage: {
        padding: theme.spacing(1, 2),
    },
    singleValue: {
        fontSize: 16,
    },
    placeholder: {
        position: 'absolute',
        left: 2,
        bottom: 6,
        fontSize: 16,
    },
    paper: {
        position: 'absolute',
        zIndex: 1,
        marginTop: theme.spacing(1),
        left: 0,
        right: 0,
    },
}));

function NoOptionsMessage({ children, selectProps }: NoticeProps<OptionValue>) {
    const { noOptionsMessage } = selectProps.classes || {}
    return (
        <Typography
            color="textSecondary"
            className={noOptionsMessage}
        >
            {children}
        </Typography>
    );
}

function SelectContainer({ children, selectProps, ...rest }: ContainerProps<OptionValue>) {
    return (
        <div ref={selectProps.containerRef}>
            <components.SelectContainer selectProps={selectProps} {...rest}>
                {children}
            </components.SelectContainer>
        </div>
    )
}

function inputComponent({ inputRef, ...props }: InputBaseComponentProps) {
    const divProps = props as HTMLAttributes<HTMLDivElement>
    return <div {...divProps} ref={inputRef} />;
}

function Control({ children, innerProps, innerRef, selectProps: { classes, TextFieldProps } }: ControlProps<OptionValue>) {
    return (
        <TextField
            inputRef={innerRef}
            margin='dense'
            fullWidth={true}
            {...TextFieldProps}
            InputProps={{
                inputComponent,
                inputProps: {
                    className: classes.input,
                    children,
                    ...innerProps,
                },
            }}
        />
    );
}

function Option({ innerRef, isFocused, isSelected, innerProps, children }: OptionProps<OptionValue>) {
    return (
        <MenuItem
            {...innerProps}
            component='div'
            ref={innerRef}
            selected={isFocused}
            style={{
                fontWeight: isSelected ? 500 : 400,
            }}
        >
            {children}
        </MenuItem>
    );
}

function Placeholder({ selectProps, innerProps = {}, children }: PlaceholderProps<OptionValue>) {
    return (
        <Typography color="textSecondary" className={selectProps.classes.placeholder} {...innerProps}>
            {children}
        </Typography>
    );
}

function SingleValue({ selectProps, children }: SingleValueProps<OptionValue>) {
    return (
        <Typography className={selectProps.classes.singleValue}>
            {children}
        </Typography>
    );
}

function ValueContainer({ selectProps, children }: ValueContainerProps<OptionValue>) {
    return (
        <div className={selectProps.classes.valueContainer}>
            {children}
        </div>
    )
}

const selectComponents = {
    Control,
    NoOptionsMessage,
    Option,
    Placeholder,
    SingleValue,
    ValueContainer,
    SelectContainer,
};

type SelectionType<FieldName> = FormField<FieldName, OptionValue> & {
    menuPortalTarget?: MutableRefObject<undefined>,
    options: OptionValue[],
    isClearable?: boolean,
    isLoading?: boolean,
} & TextFieldProps
export default function Selection<FieldName>({ field, value, setValue, errorMsg, placeholder, helperText, menuPortalTarget, options, isClearable = true, required, isLoading, ...rest }: SelectionType<FieldName>) {
    const classes = useStyles();
    const theme = useTheme<Theme>();
    const [portalTarget, setPortalTarget] = useState<object>({})
    const [focused, setFocused] = useState(false)

    useEffect(() => {
        const time = 10
        const intervalID = setInterval(() => {
            if (menuPortalTarget && menuPortalTarget.current) {
                setPortalTarget({ menuPortalTarget: menuPortalTarget.current })
                clearInterval(intervalID)
            }
        }, time)

        return () => clearInterval(intervalID)
    }, [menuPortalTarget])

    const handleChange = useCallback((option: ValueType<OptionValue>) => {
        if (isOptionValue(option)) {
            setValue(field, option)
        } else {
            setValue(field, defaultOption)
        }
    }, [field, setValue])

    const selectStyles = {
        input: (base: object) => ({
            ...base,
            color: theme.palette.text.primary,
            '& input': {
                font: 'inherit',
            },
        }),
    }

    // Remove value on unmount
    useEffect(() => {
        return () => {
            setValue(field, defaultOption)
        }
    }, [field, setValue])

    const matches = useMediaQuery(theme.breakpoints.down('xs'))
    const myRef = useRef<HTMLDivElement>(null)
    const handleFocus = useCallback(() => {
        setFocused(true)
        if (matches)
            scrollToView(myRef)
    }, [myRef, matches])
    const handleBlur = useCallback(() => {
        setFocused(false)
    }, [])
    const shoulShrink = placeholder || (value && value.value) || focused
    // console.log(matches)
    return (
        <NoSsr>
            <Select
                classes={classes}
                styles={selectStyles}
                TextFieldProps={{
                    ...rest,
                    InputLabelProps: {shrink: shoulShrink ? true : false },
                    helperText: errorMsg || helperText,
                    error: errorMsg !== undefined,
                }}
                menuPlacement={matches ? 'bottom' : 'auto'}
                maxMenuHeight={matches ? 175 : 240}
                isSearchable={!matches}
                inputProps={{readonly: true}}
                containerRef={myRef}
                openMenuOnFocus
                menuShouldScrollIntoView={false}
                placeholder={placeholder || null}
                options={options}
                components={selectComponents}
                isClearable={isClearable}
                value={value || defaultOption}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                isLoading={isLoading}
                {...portalTarget}
            />
        </NoSsr>
    );
}