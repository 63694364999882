import { match } from "react-router"
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

export type Callback = () => void

export type Consumer<Value> = (value: Value) => void

export type BiConsumer<Value1, Value2> = (value1: Value1, value2: Value2) => void

export type TriConsumer<Value1, Value2, Value3> = (value1: Value1, value2: Value2, value3: Value3) => void

export type Producer<Value> = () => Value

export type JSONt<Value = any> = Record<string, Value>

export type OtherLanguage = 'es'
export type BaseLanguage = 'en'
export type ApprovedLanguage = BaseLanguage | OtherLanguage

export type MatchProps = {
    match: match<any>
}

export type InputNavigationProps<> = {
    onFinish: Callback,
    onCancel: Callback
}

export type Clickable<Value = unknown> = {
    onClick: Consumer<Value>
}

export type ImageAlign = 'left' | 'right' | 'center'

export enum FieldType { Text, Password, Email, Date, Phone, ZipCode, Select, SingleCheckbox, Checkbox, RadioButton, Dependent, Multi, File, Number }

export type FormField<FieldName, V> = {
    field: FieldName,
    value?: V,
    setValue: (field: FieldName, value: V) => void,
    errorMsg?: string,
    label?: React.ReactNode,
    helperText?: string,
    required?: boolean,
    onEnter?: Callback
}

export type OptionValue = { label: string, value: string }
export function isOptionValue(arg: any): arg is OptionValue {
    return arg && arg.value !== undefined
}

export type CheckBoxesValue = JSONt<boolean>

export const defaultText = ''
export const defaultOption = { value: '', label: '' }
export const defaultCheckBox = {} as CheckBoxesValue
export const defaultSingleCheckBox = false
export const defaultDate = null as MaterialUiPickersDate
export const defaultNumber = undefined as number | undefined

export type FamilyInfoType = {
    maritalStatus: OptionValue,
    dateOfMarriage: MaterialUiPickersDate,
    streetAddress: string,
    city: string,
    state: OptionValue,
    country: OptionValue,
    zipCode: string,
}

export type PersonInfoType = {
    firstName: string;
    lastName: string;
    sex: string;
    dateOfBirth: MaterialUiPickersDate;
    nationality: OptionValue;
    preferredLanguage: OptionValue;
    email: string;
    phoneNumber: string;
    phoneCarrier: OptionValue;
}

export type AccountInfoType = {
    email: string;
    username: string;
    password: string;
    reference: OptionValue;
    referenceDetails: string;
}

export type ParticipantSelection = { checked: boolean, tshirt: OptionValue, error?: string }
