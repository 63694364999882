import React, { PropsWithChildren } from 'react'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
    bottomFixed: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        borderRadius: 0,
        backgroundColor: theme.palette.common.white,
        borderTop: `1px solid #cccccc`,
    },
    smallSpacer: {
        height: theme.spacing(2),
        width: '100%'
    },
    spacer: {
        height: theme.spacing(10),
        width: '100%'
    },
    fullPadding: {
        padding: theme.spacing(1),
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(2),
        },
        position: 'relative',
    },
    paddingTop: {
        paddingTop: theme.spacing(1),
        [theme.breakpoints.up('md')]: {
            paddingTop: theme.spacing(2),
        },
        position: 'relative',
    }
}))

export function MobileNavigation({ children, smallSpacing }: PropsWithChildren<{smallSpacing?: boolean}>) {
    const classes = useStyles()
    return (
        <>
            <Grid container direction='column' className={classes.bottomFixed}>
                <DesktopNavigation>
                    {children}
                </DesktopNavigation>
            </Grid>
            <div className={smallSpacing ? classes.smallSpacer : classes.spacer} />
        </>
    )
}

type DesktopNavigationProps = PropsWithChildren<{
    paddingTop?: boolean
}>
export function DesktopNavigation({ children, paddingTop }: DesktopNavigationProps) {
    const classes = useStyles()
    return (
        <Grid container justify='space-between' alignItems='center' className={paddingTop ? classes.paddingTop : classes.fullPadding}>
            {children}
        </Grid>
    )
}