import React, { useEffect, useState, useCallback } from 'react';

import AccountInfo from './AccountInfo'
import PersonalInfo from './PersonalInfo'
import SpouseInfo from './SpouseInfo'
import ChildrenInfo from './ChildrenInfo'
import { useAuth } from '../Contexts/AuthContext';
import { useTextLang } from '../Contexts/LangContext';
import { useRouter, routes, routeIDs } from '../Contexts/RouterContext';
import { BasicDialog } from '../Global/Dialogs';
import FullPageFormat from '../Global/FullPageFormat';

const createAccountTexts = {
    finishRegistrationTitle: {
        en: 'Complete Registration'
    },
    finishRegistrationContents: {
        en: 'Please finish registering your family before continuing.'
    }
}

export default function CreateAccount({ match }: { match: any }) {
    const { registrationStep, isAuth, updateRegistrationStep } = useAuth()
    const { routeParams, navigateTo } = useRouter()
    const [dialogOpen, setDialogOpen] = useState<boolean>((routeParams || { redirected: false }).redirected)
    const id = match.params.id
    const texts = useTextLang(createAccountTexts)

    useEffect(() => {
        switch (registrationStep) {
            case 'USER':
                if (id !== routeIDs.registerUser)
                    navigateTo(routes.registerUser, true)
                break
            case 'SPOUSE':
                if (id !== routeIDs.registerSpouse)
                    navigateTo(routes.registerSpouse, true)
                break
            case 'CHILDREN':
                if (id !== routeIDs.registerChildren)
                    navigateTo(routes.registerChildren, true)
                break
            case 'DONE':
            case 'APPROVED':
                navigateTo(routes.home, true)
                break
            default:
                if (!isAuth && id !== routeIDs.createAccount) {
                    navigateTo(routes.login, true)
                } else if (isAuth) {
                    updateRegistrationStep()
                }
                break
        }
    }, [registrationStep, id, navigateTo, isAuth, updateRegistrationStep])

    const closeDialog = useCallback(() => {
        setDialogOpen(false)
    }, [])

    return (
        <FullPageFormat simpleNavBar size='narrow'>
            {id === routeIDs.createAccount && <AccountInfo />}
            {id === routeIDs.registerUser && <PersonalInfo />}
            {id === routeIDs.registerSpouse && <SpouseInfo />}
            {id === routeIDs.registerChildren && <ChildrenInfo />}
            <BasicDialog open={dialogOpen} handleClose={closeDialog} title={texts.finishRegistrationTitle} contents={texts.finishRegistrationContents} />
        </FullPageFormat >
    )
}