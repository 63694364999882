import React, { useState, useCallback, useEffect } from 'react';
import { BiConsumer, JSONt } from '../../../types'
import { Grid, Button, List, Divider, ListItem } from '@material-ui/core'
import { adminQuery } from '../../../Global/ServerConnection'
import { PersonFamily } from '../../admin-types';
import { ChooseByGroupProps, PersonEmail, EventGroups } from '../../admin-types';
import DisplayPerson from './DisplayPerson';

type GroupedEvents = {
    eventid: string,
    title: string,
    teachers: PersonFamily[],
    participants: PersonFamily[]
}
export default function ChooseByEvent({pastEvents, ...props}: ChooseByGroupProps & {pastEvents: boolean}) {
    const [eventGroups, setEventGroups] = useState<GroupedEvents[]>([])

    useEffect(() => {
        const processEventList = (eventEmails: EventGroups) => {
            const reduceTeachers = eventEmails.teachers.reduce((agg, { eventid, title, persons }) => (
                { ...agg, [eventid]: { eventid, title, teachers: persons, participants: [] } }
            ), {} as JSONt<Omit<GroupedEvents, 'participants'>>)

            const reducedParticipants = eventEmails.participants.reduce((agg, { eventid, title, persons }) => {
                if (eventid in reduceTeachers)
                    return [...agg, { ...reduceTeachers[eventid], participants: persons }]
                return [...agg, { eventid, title, teachers: [], participants: persons }]
            }, [] as GroupedEvents[])
            setEventGroups(reducedParticipants)
        }

        return adminQuery('groupByEvent', processEventList, `pastEvents:${pastEvents}`, 'teachers{eventid,title,persons{personid,familyid}}participants{eventid,title,persons{personid,familyid}}')
    }, [pastEvents])

    return (
        <Grid item container direction='column' spacing={2}>
            {eventGroups.map(({ eventid, ...info }) => (
                <Grid item key={eventid}>
                    <DisplayEvents {...info} {...props} />
                </Grid>
            ))}
        </Grid>
    )
}


type DisplayEventProps = ChooseByGroupProps & Omit<GroupedEvents, 'eventid'>
function DisplayEvents({ togglePerson, title, families, participants, teachers, ...props }: DisplayEventProps) {
    const selectAll = useCallback(() => {
        teachers.forEach(({ personid, familyid }) => {
            togglePerson(personid, true)
            togglePerson(families[familyid].fatherid, true)
            togglePerson(families[familyid].motherid, true)
        })
        participants.forEach(({ personid, familyid }) => {
            togglePerson(personid, true)
            togglePerson(families[familyid].fatherid, true)
            togglePerson(families[familyid].motherid, true)
        })
    }, [togglePerson, families, participants, teachers])
    return (
        <List>
            <Divider />
            <ListItem>
                <Button onClick={selectAll} color='primary'>
                    {title}
                </Button>
            </ListItem>
            <Divider />
            {teachers.length > 0 &&
                <DisplayPersonGroup togglePerson={togglePerson} families={families} group={teachers} type='teachers' {...props} />}
            {participants.length > 0 &&
                <DisplayPersonGroup togglePerson={togglePerson} families={families} group={participants} type='participants' {...props} />}
        </List>
    )
}

type DisplayPersonGroupProps = ChooseByGroupProps & {
    type: string,
    group: PersonFamily[],
}
function DisplayPersonGroup({ togglePerson, type, selected, families, group, persons }: DisplayPersonGroupProps) {

    const selectAllPersons = useCallback(() => {
        group.forEach(({ personid }) => togglePerson(personid, true))
    }, [togglePerson, group])
    const selectAllParents = useCallback(() => {
        group.forEach(({ familyid }) => {
            togglePerson(families[familyid].fatherid, true)
            togglePerson(families[familyid].motherid, true)
        })
    }, [togglePerson, families, group])

    return (
        <>
            <ListItem>
                <Grid container direction='row'>
                    <Grid item xs={4}>
                        <Button onClick={selectAllPersons} color='primary'>
                            {type}
                        </Button>
                    </Grid>
                    <Grid item xs={8}>
                        <Button onClick={selectAllParents} color='primary'>
                            {'Parents'}
                        </Button>
                    </Grid>
                </Grid>
            </ListItem>
            <Divider />
            {group.map(({ personid, familyid }) => {
                const fatherid = families[familyid].fatherid
                const motherid = families[familyid].motherid
                return (
                    <DisplayPersonFamily
                        key={personid}
                        person={persons[personid]}
                        father={(fatherid && persons[fatherid]) || undefined}
                        mother={(motherid && persons[motherid]) || undefined}
                        personSelected={selected[personid]}
                        fatherSelected={(fatherid && selected[fatherid]) || false}
                        motherSelected={(motherid && selected[motherid]) || false}
                        togglePerson={togglePerson}
                    />
                )
            })}
        </>
    )
}

type DisplayPersonFamilyProps = {
    person: PersonEmail,
    father?: PersonEmail,
    mother?: PersonEmail,
    personSelected: boolean,
    fatherSelected: boolean,
    motherSelected: boolean,
    togglePerson: BiConsumer<string | undefined, boolean>
}
function DisplayPersonFamily({ person, father, mother, personSelected, fatherSelected, motherSelected, togglePerson }: DisplayPersonFamilyProps) {
    return (
        <>
            <ListItem>
                <Grid container direction='row'>
                    <Grid item xs={4}>
                        <DisplayPerson {...person} togglePerson={togglePerson}
                            checked={personSelected} />
                    </Grid>
                    <Grid item xs={4}>
                        {father && <DisplayPerson {...father} togglePerson={togglePerson}
                            checked={fatherSelected} />}
                    </Grid>
                    <Grid item xs={4}>
                        {mother && <DisplayPerson {...mother} togglePerson={togglePerson}
                            checked={motherSelected} />}
                    </Grid>
                </Grid>
            </ListItem>
            <Divider />
        </>
    )

}