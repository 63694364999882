import React, { useState, useEffect } from 'react';
import { useTextLang } from '../../Contexts/LangContext'
import { MatchProps } from '../../types';
import { adminQuery } from '../../Global/ServerConnection';
import { makeStyles } from '@material-ui/styles';
import LoadingPage from '../../Global/LoadingPage';
import { EventRegisters } from '../admin-types'

const viewParticipantsTexts = {
    title: {
        en: (eventTitle: string) => `Participants for ${eventTitle}`,
    },
    name: {
        en: 'Name'
    },
    tshirt: {
        en: 'T-Shirt'
    },
}

const useStyles = makeStyles(theme => ({
    header: {
        textAlign: 'center',
    },
    table: {
        width: '100%',
        borderCollapse: 'collapse',
    },
    cell: {
        border: '1px solid #dddddd',
        textAlign: 'left',
        padding: theme.spacing(1),
    }
}))


export default function PrintParticipants({ match }: MatchProps) {
    const eventID = match.params.id
    const texts = useTextLang(viewParticipantsTexts)
    const [registrations, setRegistrations] = useState<EventRegisters>()
    const [loading, setLoading] = useState(true)
    const classes = useStyles()

    useEffect(() => {
        const processRegisters = (registrations: EventRegisters) => {
            setRegistrations(registrations)
            setLoading(false)
        }
        return adminQuery('eventRegisters', processRegisters, `eventid:"${eventID}"`, 'participants{familyid,personid,name,tshirtSize},emails,title')
    }, [eventID])

    return (
        <>
            {registrations && <>
                <h2 className={classes.header}>{texts.title(registrations.title)}</h2>
                <table className={classes.table}>
                    <colgroup>
                        <col width='30%' />
                        <col width='10%' />
                        <col width='10%' />
                        <col width='10%' />
                        <col width='10%' />
                        <col width='10%' />
                        <col width='10%' />
                        <col width='10%' />
                    </colgroup>
                    <thead>
                        <tr>
                            <th className={classes.cell}>{texts.name}</th>
                            <th className={classes.cell}>{texts.tshirt}</th>
                            <td className={classes.cell} />
                            <td className={classes.cell} />
                            <td className={classes.cell} />
                            <td className={classes.cell} />
                            <td className={classes.cell} />
                            <td className={classes.cell} />
                        </tr>
                    </thead>
                    <tbody>
                        {registrations.participants.map(({ name, tshirtSize }) => {
                            return (
                                <tr key={name} >
                                    <td className={classes.cell}>{name}</td>
                                    <td className={classes.cell}>{tshirtSize}</td>
                                    <td className={classes.cell} />
                                    <td className={classes.cell} />
                                    <td className={classes.cell} />
                                    <td className={classes.cell} />
                                    <td className={classes.cell} />
                                    <td className={classes.cell} />
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </>}
            <LoadingPage loading={loading} />
        </>
    )
}
