import React, { useState, useEffect, useCallback } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Grid, Button, CircularProgress, Link } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import { LogoVertical } from '../Global/Icons'
import { useAuth } from '../Contexts/AuthContext'
import { useTextLang } from '../Contexts/LangContext';
import { useRouter, routes } from '../Contexts/RouterContext';
import Text from '../Form/Components/Text'
import Password from '../Form/Components/Password'
import { BasicDialog } from '../Global/Dialogs'
import { RegistrationStep } from '../server-types'

const loginTexts = {
    emptyUserError: {
        en: 'Please enter your username or email'
    },
    emptyPasswordError: {
        en: 'Please enter your password'
    },
    incorrectPasswordError: {
        en: 'Incorrect password'
    },
    user: {
        en: 'Username or Email'
    },
    password: {
        en: 'Password'
    },
    login: {
        en: 'Login'
    },
    createAccount: {
        en: 'Create an Account'
    },
    forgotPassword: {
        en: 'Forgot Password?'
    },
    loginFirstTitle: {
        en: 'Log In'
    },
    loginFirstContents: {
        en: 'Please log in before continuing.'
    }
}

const useStyles = makeStyles(theme => ({
    logo: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        height: theme.spacing(20),
        [theme.breakpoints.up('sm')]: {
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(4),
            height: theme.spacing(25),
        },
        [theme.breakpoints.up('xl')]: {
            height: theme.spacing(30),
        }
    },
    layout: {
        width: '100%',
        height: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: theme.breakpoints.values.sm,
        padding: theme.spacing(2),
    },
    background: {
        backgroundColor: theme.palette.common.white,
        width: '100%',
    },
    field: {
        maxWidth: theme.breakpoints.values.sm * 0.8,
    },
    createAccount: {
        marginTop: theme.spacing(1),
    },
    loginButton: {
        maxWidth: theme.breakpoints.values.sm * 0.5,
        marginTop: theme.spacing(1),
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        })
    },
    smallDivider: {
        width: '100%',
        height: theme.spacing(2)
    }
}))

const Login = () => {
    const { login, isAuth } = useAuth()
    const { routeParams, navigateTo, goBack } = useRouter()

    const [password, setPassword] = useState('')
    const [passwordError, setPasswordError] = useState<string>()

    const [user, setUser] = useState('')
    const [userError, setUserError] = useState<string>()

    const [dialogOpen, setDialogOpen] = useState(() => {
        const { redirected } = routeParams || { redirected: false }
        return redirected === true
    })

    const valid = useCallback(() => {
        return !userError && !passwordError && user.length > 0 && password.length > 0
    }, [userError, passwordError, user, password])

    const texts = useTextLang(loginTexts)

    const validate = useCallback(() => {
        if (user.length === 0)
            setUserError(texts.emptyUserError)
        if (password.length === 0)
            setPasswordError(texts.emptyPasswordError)
    }, [user, password, texts])

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (!isAuth) {
            setLoading(false)
        }
    }, [isAuth, navigateTo, goBack, routeParams])

    const attemptLogin = useCallback(() => {
        const badLogin = () => {
            setPasswordError(texts.incorrectPasswordError)
            setLoading(false)
        }

        const goodLogin = (registrationStep: RegistrationStep | null) => {
            switch (registrationStep) {
                case 'USER': navigateTo(routes.registerUser, true)
                    break
                case 'SPOUSE': navigateTo(routes.registerSpouse, true)
                    break
                case 'CHILDREN': navigateTo(routes.registerChildren, true)
                    break
                default:
                    const nextState = routeParams || {}
                    if ('next' in nextState) {
                        navigateTo(nextState.next, true)
                    } else {
                        goBack()
                    }
            }
        }

        if (valid()) {
            setLoading(true)
            login(user, password, goodLogin, badLogin)
        } else {
            validate()
        }
    }, [navigateTo, goBack, routeParams, login, user, password, valid, validate, texts])

    const closeDialog = useCallback(() => {
        setDialogOpen(false)
    }, [])

    const setValue = useCallback((field: string, value: string) => {
        if (field === 'username') {
            setUser(value)
        } else {
            setPassword(value)
        }
        setUserError(undefined)
        setPasswordError(undefined)
    }, [])

    const classes = useStyles()
    return (
        <Grid alignItems='center' direction='column' wrap='nowrap' container className={classes.layout}>
            <Grid item component={RouterLink} to={routes.home}>
                <LogoVertical className={classes.logo} />
            </Grid>
            <Text
                field='username'
                className={classes.field}
                variant='outlined'
                label={texts.user}
                value={user}
                setValue={setValue}
                errorMsg={userError}
                margin='normal'
                autoFocus
                autoCapitalize='off'
                autoComplete='on'
                onEnter={attemptLogin}
            />
            <Password
                field='password'
                className={classes.field}
                variant='outlined'
                label={texts.password}
                value={password}
                setValue={setValue}
                errorMsg={passwordError}
                margin='normal'
                onEnter={attemptLogin}
                autoComplete='on'
            />
            <Grid item>
                <Button variant='contained' size='large' color='primary' className={classes.loginButton} fullWidth={!loading} onClick={attemptLogin}>
                    {texts.login}
                </Button>
            </Grid>
            <Grid item className={classes.smallDivider} />
            {loading &&
                <Grid item>
                    <CircularProgress size={24} className={classes.createAccount} />
                </Grid>}
            {!loading &&
                <>
                    <Link gutterBottom color='primary' component={RouterLink} to={routes.forgotPassword}>
                        {texts.forgotPassword}
                    </Link>
                    <Link color='primary' component={RouterLink} to={routes.createAccount}>
                        {texts.createAccount}
                    </Link>
                </>}
            <BasicDialog open={dialogOpen} handleClose={closeDialog} title={texts.loginFirstTitle} contents={texts.loginFirstContents} />
        </Grid>
    );
}

export default Login