import React, { useCallback, useState } from 'react'
import WriteHTML from './WriteHTML'
import ChoosePersons from './ChoosePersons'
import WritePlainText from './WritePlainText'
import { Attachment } from '../admin-types'
import AddAttachments from './AddAttachments'
import PreviewEmail from './PreviewEmail'

type StepType = 'text' | 'html' | 'attachments' | 'persons' | 'preview'
export default function CreateEmail() {
    const [sender, setSender] = useState('fei@familyeducationinstitute.org')
    const [subject, setSubject] = useState('FEI - ')
    const [plainText, setPlainText] = useState('\n\nSincerely,\nFamily Education Institute')
    const [html, setHTML] = useState('<p>Sincerely,<br>Family Education Institute</p>')
    const [attachments, setAttachments] = useState<Attachment[]>([])
    const [persons, setPersons] = useState<string[]>([])
    const [step, setStep] = useState<StepType>('text')

    const finishText = useCallback((sender: string, subject: string, plainText: string) => {
        // console.log(sender)
        // console.log(subject)
        // console.log(plainText)
        setSender(sender)
        setSubject(subject)
        setPlainText(plainText)
        setStep('html')
    }, [])

    const finishHTML = useCallback((html: string) => {
        // console.log(html)
        setHTML(html)
        setStep('attachments')
    }, [])

    const cancelHTML = useCallback((html: string) => {
        // console.log(html)
        setHTML(html)
        setStep('text')
    }, [])

    const finishAttachments = useCallback((attachments: Attachment[]) => {
        // console.log(attachments)
        setAttachments(attachments)
        setStep('persons')
    }, [])

    const cancelAttachments = useCallback((attachments: Attachment[]) => {
        // console.log(attachments)
        setAttachments(attachments)
        setStep('html')
    }, [])

    const finishPersons = useCallback((persons: string[]) => {
        // console.log(persons)
        setPersons(persons)
        setStep('preview')
    }, [])

    const cancelPersons = useCallback((persons: string[]) => {
        // console.log(persons)
        setPersons(persons)
        setStep('attachments')
    }, [])

    const cancelPreview = useCallback(() => {
        setStep('persons')
    }, [])

    return (
        <>
            {step === 'text' && <WritePlainText initialSender={sender} initialSubject={subject} initialText={plainText} onFinish={finishText} />}
            {step === 'html' && <WriteHTML initialHTML={html} onFinish={finishHTML} onCancel={cancelHTML} />}
            {step === 'attachments' && <AddAttachments initialAttachments={attachments} onFinish={finishAttachments} onCancel={cancelAttachments} />}
            {step === 'persons' && <ChoosePersons initialPersons={persons} onFinish={finishPersons} onCancel={cancelPersons} />}
            {step === 'preview' && <PreviewEmail sender={sender} subject={subject} plainText={plainText} html={html} attachments={attachments} persons={persons} onCancel={cancelPreview} />}
        </>
    )
}