import React, { useState, useEffect, useCallback, useMemo } from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Typography, Grid, Button, Theme, Checkbox, Link, Slider, InputAdornment } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/styles'
import { Link as RouterLink } from 'react-router-dom'

import EventCard from './Payment/EventCard'
import DiscountCard from './Payment/DiscountCard'
import { useTextLang, useLang } from '../Contexts/LangContext';
import { DesktopNavigation, MobileNavigation } from '../Global/BottomNavigation'
import { useRouter, routes } from '../Contexts/RouterContext'
import { JSONt, Callback } from '../types'
import { CartEntry, Cart, CheckoutAttempt, PayPalOrder, MutationAttempt } from '../server-types'
import NoEventsDialog from './NoEventsDialog'
import RegisterContainer from './RegisterContainer'
import { payPalImageSrc, payPalTextSrc } from '../Global/Icons'
import PaymentDialog from './Payment/PaymentDialog'
import { useAuth } from '../Contexts/AuthContext'
import { BasicDialog } from '../Global/Dialogs'
import NumberField from '../Form/Components/NumberField'
import LoadingPage from '../Global/LoadingPage'

const paymentTexts = {
    title: {
        en: 'Event Cart:'
    },
    addEventButton: {
        en: 'Add another event'
    },
    total: {
        en: 'Total'
    },
    confirmOrderButton: {
        en: 'Confirm Order'
    },
    noEventsTitle: {
        en: 'No Pending Events Found'
    },
    error: {
        en: 'Error'
    },
    noEventsContent: {
        en: 'You have no pending events. If you want to register for events, you can add them to your pending events. If you confirmed an order and have not received an email to complete payment, please contact:'
    },
    noEventsContact: {
        en: 'register@FamilyEducationInstitute.org'
    },
    addEventsButton: {
        en: 'Add Events'
    },
    addToMyEvents: {
        en: 'Add To My Events'
    },
    donation: {
        en: 'Donation'
    },
    suggestedDonation: {
        en: 'Suggested donation'
    },
    iAgree: {
        en: 'I agree to the'
    },
    termsAndConditions: {
        en: 'Terms and Conditions'
    },
    waiverPackage: {
        en: 'Waiver and Release documents'
    },
    documentTitle: {
        en: 'Missing signature'
    },
    acceptDocuments: {
        en: 'Please accept our Terms and Conditions, and electronically sign the Waiver and Release of Liability, the Photographic Release and the Medical Treatment Waiver and Release before continuing.'
    }
}

const useStyles = makeStyles(theme => ({
    mainPage: {
        padding: theme.spacing(3, 0)
    },
    header: {
        paddingLeft: theme.spacing(3),
    },
    price: {
        paddingLeft: theme.spacing(1),
    },
    payPalButton: {
        background: '#009cde',
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
        '&:hover': {
            background: '#007de5',
        }
    },
    payPalImage: {
        height: theme.spacing(3),
    },
    donationContainer: {
        padding: theme.spacing(1),
    }
}))

export default function Payment() {
    const [cartEntries, setCartEntries] = useState<CartEntry[]>([])
    const [discountMoney, setDiscountMoney] = useState(0)
    const [discountPercent, setDiscountPercent] = useState(1)
    const [noEvents, setNoEvents] = useState(false)
    const [prices, setPrices] = useState<JSONt<number>>({})
    const [suggestedDonations, setSuggestedDonations] = useState<JSONt<number>>({})
    const [payPalOrder, setPayPalOrder] = useState<PayPalOrder>()
    const [termsChecked, setTermsChecked] = useState(false)
    const [releaseChecked, setReleaseChecked] = useState(false)
    const [releaseDialogOpen, setReleaseDialogOpen] = useState(false)
    const [error, setError] = useState<string>()
    const [cartLoading, setCartLoading] = useState(true)
    const [loading, setLoading] = useState(false)
    const { navigateTo } = useRouter()
    const { authQuery, authMutation } = useAuth()
    const [donation, setDonation] = useState<number>(0);
    const { language } = useLang()

    const handleSliderChange = useCallback((field: any, value?: number | number[]) => {
        // console.log(field, value)
        if (typeof value === 'number') {
            setDonation(value)
        } else {
            setDonation(0)
        }
    }, [])

    const closeReleaseDialog = useCallback(() => {
        setReleaseDialogOpen(false)
    }, [])

    const clearError = useCallback(() => {
        setLoading(false)
        setError(undefined)
    }, [])

    const toggleTerms = useCallback(() => {
        setTermsChecked(checked => !checked)
        setReleaseDialogOpen(false)
    }, [])

    const toggleRelease = useCallback(() => {
        setReleaseChecked(checked => !checked)
        setReleaseDialogOpen(false)
    }, [])

    const updateCart = useCallback(() => {
        const processCart = (cart: Cart) => {
            setReleaseChecked(false)
            setTermsChecked(false)

            const { events, discountMoney, discountPercent } = cart
            setCartEntries(events)
            setNoEvents(events.length === 0)
            if (discountMoney !== null && discountMoney !== undefined)
                setDiscountMoney(discountMoney)
            if (discountPercent !== null && discountPercent !== undefined)
                setDiscountPercent(discountPercent)
            setCartLoading(false)
        }

        return authQuery('getCart', processCart, undefined, 'events{eventid,persons{personid,name,tshirtSize}discountMoney,discountPercent}discountMoney,discountPercent')
    }, [authQuery])

    useEffect(() => {
        return updateCart()
    }, [updateCart])

    const cancelOrder = useCallback(() => {
        const processCancellation = (success: boolean) => {
            if (success) {
                setPayPalOrder(undefined)
                setLoading(false)
            } else {
                navigateTo(routes.error)
            }
        }

        authMutation('cancelPayment', processCancellation)
    }, [navigateTo, authMutation])

    const addToTotal = useCallback((id: string, amount: number, suggestedDonation: number) => {
        setPrices((prices) => {
            if (amount === 0) {
                const newPrices = { ...prices }
                delete newPrices[id]
                return newPrices
            }
            return { ...prices, [id]: amount }
        })
        setSuggestedDonations((suggestedDonations) => {
            if (suggestedDonation === 0) {
                const newSuggestedDonations = { ...suggestedDonations }
                delete newSuggestedDonations[id]
                return newSuggestedDonations
            }
            return { ...suggestedDonations, [id]: suggestedDonation }
        })
    }, [])

    const total = useMemo(() =>
        Math.max(Object.values(prices).reduce((num1, num2) => num1 + num2, 0) * discountPercent - discountMoney, 0),
        [prices, discountMoney, discountPercent])

    const suggestedDonation = useMemo(() =>
        Math.max(Object.values(suggestedDonations).reduce((num1, num2) => num1 + num2, 0), 0),
        [suggestedDonations])

    const removeEvent = useCallback((eventID: string) => {
        const processMutation = ({ success, error }: MutationAttempt) => {
            if (success) {
                updateCart()
            } else if (error) {
                setError(error)
            } else {
                navigateTo(routes.error)
            }
        }
        authMutation('addEventToCart', processMutation, `language:"${language}",eventid:"${eventID}",persons:[]`, 'success,error')
    }, [navigateTo, authMutation, updateCart, language])

    const onPaymentSuccess = useCallback(() => {
        navigateTo(routes.myEvents, true)
    }, [navigateTo])

    const checkout = useCallback(() => {
        if (releaseChecked && termsChecked) {
            setLoading(true)
            const processCheckout = (attempt?: CheckoutAttempt) => {
                if (attempt) {
                    const { invoiceid, orderid, approve, error } = attempt
                    if (error) {
                        setError(error)
                    } else if (invoiceid) {
                        onPaymentSuccess()
                    } else if (orderid && approve) {
                        setPayPalOrder({ orderid, approve })
                        setLoading(false)
                    }
                } else {
                    navigateTo(routes.error)
                }
            }
            authMutation('checkout', processCheckout, `donation:${donation},language:"${language}"`, 'error,orderid,approve{uri,method},invoiceid')
        } else {
            setReleaseDialogOpen(true)
        }
    }, [navigateTo, onPaymentSuccess, authMutation, releaseChecked, termsChecked, donation, language])


    const texts = useTextLang(paymentTexts)
    const classes = useStyles()
    const theme = useTheme<Theme>()
    const matches = useMediaQuery(theme.breakpoints.up('sm'))
    return (
        <RegisterContainer activeStep={1}>
            {cartEntries && cartEntries.length > 0 &&
                <Grid container direction='column' spacing={3} className={classes.mainPage}>
                    <Grid item>
                        <Typography color='textSecondary' variant='h5' className={classes.header}>
                            {texts.title}
                        </Typography>
                    </Grid>
                    {cartEntries.map(({ eventid, ...rest }) => {
                        return (
                            <Grid item container key={eventid}>
                                <EventCard
                                    totalFunc={addToTotal}
                                    eventid={eventid}
                                    key={eventid}
                                    removeEvent={removeEvent}
                                    editable
                                    {...rest} />
                            </Grid>
                        )
                    })}
                    <Grid item>
                        <DiscountCard discountMoney={discountMoney} discountPercent={discountPercent} />
                    </Grid>
                    <Grid item>
                        <Typography gutterBottom>
                            {suggestedDonation > 0 ? `${texts.suggestedDonation}: $${suggestedDonation}` : texts.donation}
                        </Typography>
                        <Grid container spacing={2} alignItems="center" className={classes.donationContainer}>
                            <Grid item xs={10}>
                                <Slider
                                    value={donation}
                                    onChange={handleSliderChange}
                                    step={10}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <NumberField
                                    field='donation'
                                    value={donation}
                                    precision={2}
                                    setValue={handleSliderChange}
                                    min={0}
                                    max={10000 - total}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">{'$'}</InputAdornment>,
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid item container justify={matches ? 'flex-end' : 'center'} alignItems='center'>
                            <Checkbox checked={termsChecked}
                                onClick={toggleTerms}
                                color="primary" />
                            <Typography variant='body2'>
                                {texts.iAgree}
                            </Typography>
                            &nbsp;
                            <Link variant='body2' color='primary' component={RouterLink} to={routes.termsAndConditions}>
                                {texts.termsAndConditions}
                            </Link>
                        </Grid>
                        <Grid item container justify={matches ? 'flex-end' : 'center'} alignItems='center'>
                            <Checkbox checked={releaseChecked}
                                onClick={toggleRelease}
                                color="primary" />
                            <Typography variant='body2'>
                                {texts.iAgree}
                            </Typography>
                            &nbsp;
                            <Link variant='body2' color='primary' component={RouterLink} to={routes.waiverAndRelease}>
                                {texts.waiverPackage}
                            </Link>
                        </Grid>
                    </Grid>
                    {matches
                        ? <DesktopNavigation>
                            <Grid item xs={true} container>
                                <AddEventButton />
                            </Grid>
                            <NavigationContents desktop checkout={checkout} subtotal={total} donation={donation} />
                        </DesktopNavigation>
                        : <>
                            <AddEventButton />
                            <MobileNavigation>
                                <NavigationContents checkout={checkout} subtotal={total} donation={donation} />
                            </MobileNavigation>
                        </>
                    }
                </Grid>
            }
            <NoEventsDialog open={noEvents} />
            <BasicDialog open={releaseDialogOpen} handleClose={closeReleaseDialog} title={texts.documentTitle} contents={texts.acceptDocuments} />
            <BasicDialog open={error !== undefined} handleClose={clearError} title={texts.error} contents={error} />
            <PaymentDialog payPalOrder={payPalOrder} cancelOrder={cancelOrder} onSuccess={onPaymentSuccess} loading={loading} />
            <LoadingPage loading={cartLoading} />
        </RegisterContainer>
    )
}

function AddEventButton() {
    const texts = useTextLang(paymentTexts)
    return (
        <Button color='primary' component={RouterLink} to={routes.events}>
            {texts.addEventButton}
        </Button>
    )
}

type NavigationContentsProps = {
    subtotal: number,
    donation: number,
    desktop?: boolean,
    checkout: Callback
}
function NavigationContents({ subtotal, donation, desktop, checkout }: NavigationContentsProps) {
    const total = Math.floor((subtotal + donation) * 100) / 100
    const classes = useStyles()
    const texts = useTextLang(paymentTexts)
    return (
        <>
            <Grid item xs={true} container alignItems='center' justify={desktop ? 'center' : 'flex-start'}>
                <Typography variant='button'>
                    {texts.total}
                </Typography>
                <Typography variant='h5' className={classes.price}>
                    {`$${total.toFixed(2)}`}
                </Typography>
            </Grid>
            <Grid item xs={true} container justify='flex-end'>
                {total === 0 &&
                    <Button onClick={checkout} color='primary' variant='contained'>
                        {texts.addToMyEvents}
                    </Button>}
                {total !== 0 &&
                    <Button onClick={checkout} variant='contained' className={classes.payPalButton}>
                        <img src={payPalImageSrc} alt='PP' className={classes.payPalImage} />
                        &nbsp;
                        <img src={payPalTextSrc} alt='PayPal' className={classes.payPalImage} />
                    </Button>}
            </Grid>
        </>
    )
}