import React, { PropsWithChildren } from 'react'

import { Grid, Typography, Divider } from '@material-ui/core';

type FormContainerType = PropsWithChildren<{
    title: string
}>
const FormContainer = ({ title, children }: FormContainerType) => {
    return (
        <Grid container direction='column' spacing={1}>
            <Grid item>
                <Typography variant='h6'>
                    {title}
                </Typography>
            </Grid>
            <Divider />
            {children}
        </Grid>
    )
}

export default FormContainer