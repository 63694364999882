import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { Typography, Grid, Switch, FormControlLabel, Divider, List, ListItem, ListItemText, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import EventCard from '../Register/Payment/EventCard'
import { useTextLang } from '../Contexts/LangContext';
import FullPageFormat from '../Global/FullPageFormat';
import NoEventsDialog from '../Register/NoEventsDialog'
import { EventRegister, PersonRegister, EventTitle } from '../server-types'
import { useAuth } from '../Contexts/AuthContext';
import LoadingPage from '../Global/LoadingPage';
import { query } from '../Global/ServerConnection';
import { useRouter, routes } from '../Contexts/RouterContext';
import NoVolunteerDialog from './NoVolunteerDialog';
import { JSONt } from '../types';

const myEventsTexts = {
    titleRegistrations: {
        en: 'My Events:'
    },
    titleVolunteering: {
        en: 'My Volunteering'
    },
    showListView: {
        en: 'Show List View'
    },
    showPastEvents: {
        en: 'Show Past Events'
    },
    viewEvent: {
        en: 'View Event'
    }
}

const useStyles = makeStyles(theme => ({
    mainPage: {
        padding: theme.spacing(3, 0)
    },
    header: {
        paddingLeft: theme.spacing(3),
    },
    price: {
        paddingLeft: theme.spacing(1),
    }
}))

export function ViewMyEvents() {
    return <MyEvents />
}

export function ViewMyVolunteering() {
    return <MyEvents volunteering />
}

function MyEvents({ volunteering = false }: { volunteering?: boolean }) {
    const [registers, setRegisters] = useState<EventRegister[]>([])
    const [loading, setLoading] = useState(true)
    const [pastEvents, setPastEvents] = useState(false)
    const [listView, setListView] = useState(false)
    const { authQuery } = useAuth()

    const handlePastEventsSwitch = useCallback((event: any, value: boolean) => {
        setPastEvents(value)
    }, [])

    const handleListViewSwitch = useCallback((event: any, value: boolean) => {
        setListView(value)
    }, [])

    useEffect(() => {
        setLoading(true)
        const setEvents = (events: EventRegister[]) => {
            setRegisters(events)
            setLoading(false)
        }
        const queryString = volunteering ? 'getMyVolunteering' : 'getMyEvents'
        return authQuery(queryString, setEvents, `pastEvents:${pastEvents}`, 'eventid,personid,firstName,lastName,tshirtSize')
    }, [authQuery, volunteering, pastEvents])

    const texts = useTextLang(myEventsTexts)
    const classes = useStyles()

    return (
        <FullPageFormat>
            {registers && registers.length > 0 &&
                <Grid container direction='column' spacing={3} className={classes.mainPage}>
                    <Grid item container direction='row' justify='space-between'>
                        <Typography color='textSecondary' variant='h5' className={classes.header}>
                            {volunteering ? texts.titleVolunteering : texts.titleRegistrations}
                        </Typography>
                        <FormControlLabel
                            control={<Switch checked={listView} onChange={handleListViewSwitch} color='primary' />}
                            label={texts.showListView}
                        />
                    </Grid>
                    {listView && <ListView registers={registers} />}
                    {!listView && <CardView volunteering={volunteering} registers={registers} />}
                    <Grid item container justify='center'>
                        <FormControlLabel
                            control={<Switch checked={pastEvents} onChange={handlePastEventsSwitch} color='primary' />}
                            label={texts.showPastEvents}
                        />
                    </Grid>
                </Grid>
            }
            {volunteering && <NoVolunteerDialog open={!loading && registers.length === 0} />}
            {!volunteering && <NoEventsDialog open={!loading && registers.length === 0} />}
            <LoadingPage loading={loading} />
        </FullPageFormat>
    )
}

type ViewProps = {
    registers: EventRegister[],
    volunteering?: boolean,
}
function CardView({ registers, volunteering }: ViewProps) {
    const groupedRegisters = useMemo(() =>
        registers.reduce((agg, { eventid, personid, firstName, tshirtSize }) => {
            const personRegister = {
                personid,
                name: firstName,
                tshirtSize,
            }
            return { ...agg, [eventid]: [...(agg[eventid] || []), personRegister] }
        }, {} as JSONt<PersonRegister[]>), [registers])

    return (
        <>
            {Object.entries(groupedRegisters).map(([eventid, persons]) => {
                return (
                    <Grid item container key={eventid}>
                        <EventCard
                            eventid={eventid}
                            persons={persons}
                            volunteering={volunteering}
                            key={eventid}
                        />
                    </Grid>
                )
            })}
        </>
    )
}

function ListView({ registers }: ViewProps) {
    const [eventList, setEventList] = useState<JSONt<string>>({})

    useEffect(() => {
        const processEventList = (events: EventTitle[]) => {
            setEventList(events.reduce((agg, { eventid, title }) => ({
                ...agg,
                [eventid]: title,
            }), {} as JSONt<string>))
        }
        const eventids = registers.reduce((prev, { eventid }) => {
            if (!prev.includes(eventid)) {
                return [...prev, eventid]
            }
            return prev
        }, [] as string[])
            .map(eventid => `"${eventid}"`)
            .join(',')
        return query('eventList', processEventList, `eventids:[${eventids}]`, 'eventid,title')
    }, [registers])

    const groupedRegisters = useMemo(() =>
        registers.reduce((agg, { eventid, personid, firstName, lastName }) => {
            const event = {
                eventid,
                title: eventList[eventid]
            }
            const name = `${firstName} ${lastName}`
            if (personid in agg)
                return { ...agg, [personid]: { name, events: [...agg[personid].events, event] } }
            return { ...agg, [personid]: { name, events: [event] } }
        }, {} as JSONt<PersonListProps>), [registers, eventList])

    return (
        <>

            {Object.entries(groupedRegisters).map(([personid, props]) =>
                <Grid item key={personid}>
                    <PersonList {...props} />
                </Grid>
            )}
        </>
    )
}

type PersonListProps = {
    name: string,
    events: EventTitle[]
}
function PersonList({ name, events }: PersonListProps) {
    const classes = useStyles()
    return (
        <>
            <Typography className={classes.header}>
                {name}:
            </Typography>
            <List>
                {events.map((props) => (
                    <EventList key={props.eventid} {...props} />
                ))}
                <Divider />
            </List>
        </>
    )
}

function EventList({ eventid, title }: EventTitle) {
    const { navigateTo } = useRouter()
    const texts = useTextLang(myEventsTexts)

    const viewEvent = useCallback(() => {
        navigateTo(routes.viewEvent(eventid), false, { type: 'event' })
    }, [navigateTo, eventid])

    return (
        <>
            <Divider />
            <ListItem>
                <ListItemText primary={title} />
                <Button color='primary' onClick={viewEvent}>
                    {texts.viewEvent}
                </Button>
            </ListItem>
        </>
    )
}