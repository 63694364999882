import React from 'react'
import { Typography } from '@material-ui/core'
import { useTextLang } from '../Contexts/LangContext';

const eventContentsTexts = {
    price: {
        en: 'Price'
    },
    pricePerParticipant: {
        en: 'per participant'
    },
    pricePerEvent: {
        en: 'per family'
    },
    donationsWelcome: {
        en: 'Donations greatly appreciated'
    },
    suggestedDonationTitle: {
        en: `Suggested donation`
    },
    suggestedDonation: {
        en: `suggested donation`
    }
}

type PriceProps = {
    price?: number,
    suggestedDonation?: number,
    individualPrice?: boolean,
    priceDescription?: string,
}
export default function Price({ price, suggestedDonation, individualPrice, priceDescription }: PriceProps) {
    const texts = useTextLang(eventContentsTexts)
    let priceText
    if (price === 0 && suggestedDonation === 0) {
        priceText = texts.donationsWelcome
    } else if (price === 0) {
        priceText = `${texts.suggestedDonationTitle}: $${suggestedDonation} ${individualPrice ? texts.pricePerParticipant : texts.pricePerEvent}`
    } else if (suggestedDonation === 0) {
        priceText = `${texts.price}: $${price} ${individualPrice ? texts.pricePerParticipant : texts.pricePerEvent}`
    } else {
        priceText = `${texts.price}: $${price} (+$${suggestedDonation} ${texts.suggestedDonation}) ${individualPrice ? texts.pricePerParticipant : texts.pricePerEvent}`
    }

    return (
        <Typography variant='body1'>
            {priceDescription || priceText}
        </Typography>
    )
}
