import React from 'react';
import { Card, CardContent, CardActions, Grid, Typography, Button, useMediaQuery, Theme } from '@material-ui/core';

import Image from '../Global/Image'
import { makeStyles, useTheme } from '@material-ui/styles';
import { useTextLang } from '../Contexts/LangContext';
import { Callback } from '../types';
import { LineTypography } from '../Global/GlobalItems';

const infoCardsTexts = {
    fepDescription: {
        en: 'A cutting edge program that helps parents educate more effectively. The FEP is based on preventive education, the development of virtues, and the proper use of freedom and responsibility.',
        es: 'Un programa de alto nivel y economico que ayuda a los padres a educar a sus hijos de una manera mas efectiva.  El PEF se basa en educación preventiva, el desarrollo de las virtudes y el uso adecuado de la libertad y la responsabilidad.'
    },
    fepTitle: {
        en: 'Family Education Program',
        es: 'PROGRAMA DE EDUCACION FAMILIAR'
    },
}

const useStyles = makeStyles(theme => ({
    fullWidth: {
        width: '100%'
    },
    unitSpacing: {
        padding: theme.spacing(1),
    },
    lineDivider: {
        width: '100%',
        height: theme.spacing(1),
    },
}))

type InfoCardProps = {
    beforeImage: React.ReactNode,
    afterImage: React.ReactNode,
    action?: React.ReactNode,
    imageSquareSrc: string,
    imageRectSrc: string,
    imageAlt: string,
}
export function InfoCard({ beforeImage, afterImage, action, imageSquareSrc, imageRectSrc, imageAlt }: InfoCardProps) {
    const classes = useStyles()
    const theme = useTheme<Theme>()
    const mobile = useMediaQuery(theme.breakpoints.down('sm'))
    if (mobile) {
        return (
            <Card className={classes.fullWidth}>
                <Grid container direction='column' alignItems='stretch'>
                    <CardContent>
                        {beforeImage}
                    </CardContent>
                    <Image rect src={imageRectSrc} alt={imageAlt} />
                    <CardContent >
                        {afterImage}
                    </CardContent>
                    {action &&
                        <CardActions>
                            {action}
                        </CardActions>}
                </Grid>
            </Card>
        )
    } else {
        return (
            <Card className={classes.fullWidth}>
                <Grid container>
                    <Grid item xs={12} md={6} container direction='column' alignItems='stretch'>
                        <CardContent>
                            {beforeImage}
                            {afterImage}
                        </CardContent>
                        <Grid item xs={true} container alignItems='flex-end'>
                            <CardActions disableSpacing>
                                {action}
                            </CardActions>
                        </Grid>
                    </Grid>
                    <Grid item container md={6} className={classes.unitSpacing}>
                        <Image src={imageSquareSrc} alt={imageAlt} fullHeight />
                    </Grid>
                </Grid>
            </Card>
        )
    }
}

type FEPCardProps = {
    actionFunc: Callback,
    actionText: string
}
export function FEPCard({ actionFunc, actionText }: FEPCardProps) {
    const classes = useStyles()
    const texts = useTextLang(infoCardsTexts)
    const theme = useTheme<Theme>()
    const mobile = useMediaQuery(theme.breakpoints.down('sm'))
    if (mobile) {
        return (
            <Card>
                <Grid container direction='column'>
                    <Grid item xs={true}>
                        <CardContent>
                            <Typography variant='body1' color='textSecondary'>
                                {texts.fepDescription}
                            </Typography>
                        </CardContent>
                    </Grid>
                    <Image src='FEI-A-PEF-R.jpg' alt={texts.fepTitle} rect />
                    <Grid item className={classes.unitSpacing}>
                        <Button color='primary' onClick={actionFunc}>
                            {actionText}
                        </Button>
                    </Grid>
                </Grid>
            </Card>
        )
    } else {
        return (
            <Card>
                <Grid container>
                    <Grid item xs={3} className={classes.unitSpacing}>
                        <Image src='FEI-A-PEF-S.jpg' alt={texts.fepTitle} fullHeight />
                    </Grid>
                    <Grid item xs={true} container alignItems='center'>
                        <CardContent>
                            <Typography variant='body1' color='textSecondary'>
                                {texts.fepDescription}
                            </Typography>
                        </CardContent>
                    </Grid>
                    <Grid item xs={3} container justify='center' alignItems='center'>
                        <Button variant='contained' color='primary' onClick={actionFunc}>
                            {actionText}
                        </Button>
                    </Grid>
                </Grid>
            </Card>
        )
    }
}


type SubcategoryProps = {
    title: string,
    description: string
}
export function Subcategory({ title, description }: SubcategoryProps) {
    return (
        <>
            <Typography variant='h6'>
                {title}
            </Typography>
            <LineTypography variant='body1' color='textSecondary'>
                {description}
            </LineTypography>
        </>
    )
}

export function LineDivider() {
    const classes = useStyles()
    return <div className={classes.lineDivider} />
}