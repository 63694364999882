import React, { useState, useEffect } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Card, Divider, Button, Theme } from '@material-ui/core';
import { useRouter } from '../../Contexts/RouterContext';
import { useBasicTexts } from '../../Global/GlobalItems';
import { makeStyles, useTheme } from '@material-ui/styles';
import FullPageFormat from '../../Global/FullPageFormat';
import { DesktopNavigation, MobileNavigation } from '../../Global/BottomNavigation';
import { useAuth } from '../../Contexts/AuthContext';

const useStyles = makeStyles(theme => ({
    contentCard: {
        margin: theme.spacing(5),
    },
    navigationCard: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
    },
    markdownContainer: {
        padding: theme.spacing(4)
    },
}))

export default function WaiverAndRelease() {
    const { goBack } = useRouter()
    const texts = useBasicTexts()

    const classes = useStyles()
    const theme = useTheme<Theme>()
    const matches = useMediaQuery(theme.breakpoints.up('sm'))
    const { authQuery } = useAuth()

    const [source, setSource] = useState<string>()
    useEffect(() => {
        return authQuery('WandR', setSource)
    }, [authQuery])

    return (
        <FullPageFormat size='wide'>
            {matches
                ? <Card className={classes.contentCard}>
                    {source &&
                        <div dangerouslySetInnerHTML={{ __html: source }} className={classes.markdownContainer} />
                    }
                    <Divider variant='middle' />
                    <DesktopNavigation>
                        <Button onClick={goBack} disableRipple color='primary'>
                            {texts.back}
                        </Button>
                    </DesktopNavigation>
                </Card>
                : <>
                    {source &&
                        <div dangerouslySetInnerHTML={{ __html: source }} className={classes.markdownContainer} />
                    }
                    <MobileNavigation>
                        <Button onClick={goBack} disableRipple color='primary'>
                            {texts.back}
                        </Button>
                    </MobileNavigation>
                </>
            }
        </FullPageFormat >
    )
}