import React, { useState, useCallback, useMemo } from 'react'

import FormContainer from './FormContainer';
import { useTextLang } from '../Contexts/LangContext';
import { useAuth } from '../Contexts/AuthContext';
import { useRouter, routes } from '../Contexts/RouterContext';
import { useBasicTexts } from '../Global/GlobalItems';
import { PersonInfoType, FamilyInfoType } from '../types';
import FamilyInfoFields, { familyInfoRules, familyInfoDefaultValues } from '../Form/FamilyInfoFields';
import useForm, { FormatForm } from '../Form/FormUtils';
import PersonFields, { fullPersonRules, personDefaultValues } from '../Form/PersonFields';
import FormNavigation from '../Form/FormNavigation';

const personalInfoTexts = {
    title: {
        en: 'Personal Info'
    },
    mustBeAdult: {
        en: 'You must be at least 18 years old to register'
    }
}

const PersonalInfo = () => {
    const [loading, setLoading] = useState(false)

    const texts = useTextLang(personalInfoTexts)
    const { updateRegistrationStep, authMutation } = useAuth()
    const { navigateTo } = useRouter()
    const basicTexts = useBasicTexts()

    const finish = useCallback((values: Record<keyof PersonInfoType | keyof FamilyInfoType, string>) => {
        setLoading(true)
        const processMutation = (success: boolean) => {
            if (success) {
                updateRegistrationStep()
            } else {
                navigateTo(routes.error)
            }
        }
        authMutation('registerUser', processMutation, `firstName:"${values.firstName.trim()}",lastName:"${values.lastName.trim()}",sex:${values.sex},dateOfBirth:"${values.dateOfBirth}",nationality:"${values.nationality}",preferredLanguage:"${values.preferredLanguage}",phoneNumber:"${values.phoneNumber}",phoneCarrier:"${values.phoneCarrier}",streetAddress:"${values.streetAddress.trim()}",city:"${values.city.trim()}",state:"${values.state}",zipCode:"${values.zipCode}",country:"${values.country}",maritalStatus:${values.maritalStatus},dateOfMarriage:${values.maritalStatus === 'MARRIED' ? `"${values.dateOfMarriage}"` : null}`)
    }, [updateRegistrationStep, navigateTo, authMutation])

    const personRules = useMemo(() => fullPersonRules(true, true), [])
    const { values, setValue, errors, setError, handleSubmit } = useForm({ ...personRules, dateOfBirth: {...personRules.dateOfBirth, highDateError: texts.mustBeAdult}, ...familyInfoRules }, finish, { ...personDefaultValues, ...familyInfoDefaultValues })

    return (
        <FormContainer title={texts.title}>
            <FormatForm>
                <PersonFields values={values} setValue={setValue} errors={errors} setError={setError} adult noEmail/>
                <FamilyInfoFields values={values} setValue={setValue} errors={errors} setError={setError} />
            </FormatForm>
            <FormNavigation
                onSubmit={handleSubmit}
                submitText={basicTexts.next}
                loading={loading}
            />
        </FormContainer>
    )
}

export default PersonalInfo