import React from 'react'
import { Button, Hidden, Grid, makeStyles, CircularProgress } from '@material-ui/core';
import { MobileNavigation, DesktopNavigation } from '../Global/BottomNavigation'
import { Callback } from '../types';

const useStyles = makeStyles(theme => ({
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
    },
    divider: {
        width: '100%',
        height: theme.spacing(3)
    }
}));

type NavigationProps = {
    onSubmit: Callback,
    submitText: string,
    onCancel?: Callback,
    cancelText?: string,
    disabled?: boolean,
    loading?: boolean,
    smallSpacing?: boolean,
}
export default function FormNavigation({ smallSpacing, ...props }: NavigationProps) {
    const classes = useStyles()
    return (
        <>
            <Hidden smUp>
                <MobileNavigation smallSpacing={smallSpacing}>
                    <NavContents {...props} />
                </MobileNavigation>
            </Hidden>
            <Hidden xsDown>
                <DesktopNavigation paddingTop>
                    <NavContents {...props} />
                    <div className={classes.divider} />
                </DesktopNavigation>
            </Hidden>
        </>
    )
}

function NavContents({ onSubmit, onCancel, submitText, cancelText, disabled = false, loading = false }: NavigationProps) {
    return (
        <>
            <Grid item xs container justify='flex-start'>
                {onCancel &&
                    <Button color='primary' onClick={onCancel} disabled={disabled || loading}>
                        {cancelText}
                    </Button>}
            </Grid>
            {loading && <CircularProgress size={24} />}
            <Grid item xs container justify='flex-end'>
                <Button variant='contained' color='primary' onClick={onSubmit} disabled={disabled || loading}>
                    {submitText}
                </Button>
            </Grid>
        </>
    )
}