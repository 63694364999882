import React, { useState, useCallback } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Grid, Button, CircularProgress, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import { LogoVertical } from '../Global/Icons'
import { useTextLang } from '../Contexts/LangContext';
import { useRouter, routes } from '../Contexts/RouterContext';
import Text from '../Form/Components/Text'
import { query } from '../Global/ServerConnection'
import Email from '../Form/Components/Email'
import { BasicDialog } from '../Global/Dialogs'
import { escape } from '../Global/GlobalItems'

const forgotPasswordTexts = {
    guide: {
        en: 'Enter your username or email and we will send you an email to change your password.'
    },
    noneSelectedError: {
        en: 'Please enter your username or email.'
    },
    usernameError: {
        en: 'Username does not exist'
    },
    emailError: {
        en: 'Email does not exist'
    },
    username: {
        en: 'Username'
    },
    email: {
        en: 'Email'
    },
    changePassword: {
        en: 'Change Password'
    },
    emailSentTitle: {
        en: 'Success'
    },
    emailSentContents: {
        en: 'Please check your email and follow the instructions to change your password.'
    },
    or: {
        en: 'OR'
    }
}

const useStyles = makeStyles(theme => ({
    logo: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        height: theme.spacing(20),
        [theme.breakpoints.up('sm')]: {
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(4),
            height: theme.spacing(25),
        },
        [theme.breakpoints.up('xl')]: {
            height: theme.spacing(30),
        }
    },
    layout: {
        width: '100%',
        height: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: theme.breakpoints.values.sm,
        padding: theme.spacing(2),
    },
    background: {
        backgroundColor: theme.palette.common.white,
        width: '100%',
    },
    field: {
        maxWidth: theme.breakpoints.values.sm * 0.8,
    },
    createAccount: {
        marginTop: theme.spacing(1),
    },
    newPasswordButton: {
        maxWidth: theme.breakpoints.values.sm * 0.5,
        marginTop: theme.spacing(1),
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        })
    },
    dividerContainer: {
        maxWidth: theme.breakpoints.values.sm * 0.75,
        paddingTop: theme.spacing(1)
    },
    line: {
        height: 0,
        borderColor: theme.palette.grey[400],
        borderTopStyle: 'solid',
        borderTopWidth: 1,
    },
    dividerText: {
        color: theme.palette.grey[400],
        padding: theme.spacing(0, 1)
    },
}))

const ForgotPassword = () => {
    const [email, setEmail] = useState('')
    const [username, setUsername] = useState('')
    const [error, setError] = useState<string>()
    const [success, setSuccess] = useState(false)
    const texts = useTextLang(forgotPasswordTexts)
    const { navigateTo } = useRouter()
    const [loading, setLoading] = useState(false)

    const valid = useCallback(() => {
        return !error && (username.length > 0 || email.length > 0)
    }, [error, username, email])

    const onFinish = useCallback(() => {
        navigateTo(routes.home)
    }, [navigateTo])

    const attemptPasswordChange = useCallback(() => {
        const processAttempt = (status?: 'SUCCESS' | 'INCORRECT_USERNAME' | 'INCORRECT_EMAIL') => {
            switch (status) {
                case 'INCORRECT_USERNAME': setError('username')
                    break
                case 'INCORRECT_EMAIL': setError('email')
                    break
                default:
                    setSuccess(true)
            }
            setLoading(false)
        }

        if (valid()) {
            setLoading(true)
            let params
            if (username) {
                params = `username:"${escape(username)}"`
            } else {
                params = `email:"${escape(email)}"`
            }
            query('forgotPassword', processAttempt, params)
        } else {
            setError('none')
        }
    }, [username, email, valid])

    const setValue = useCallback((field: string, value: string) => {
        if (field === 'username') {
            setUsername(value)
        } else {
            setEmail(value)
        }
        setError(undefined)
    }, [])

    const classes = useStyles()
    return (
        <Grid alignItems='center' direction='column' wrap='nowrap' container className={classes.layout}>
            <Grid item component={RouterLink} to={routes.home}>
                <LogoVertical className={classes.logo} />
            </Grid>
            <Typography variant='body1' align='center' gutterBottom className={classes.field}>
                {texts.guide}
            </Typography>
            <Text
                field='username'
                className={classes.field}
                variant='outlined'
                label={texts.username}
                value={username}
                setValue={setValue}
                errorMsg={error === 'username' ? texts.usernameError : undefined}
                margin='normal'
                onEnter={attemptPasswordChange}
                autoFocus
                autoCapitalize='off'
            />
            <FieldDivider />
            <Email
                field='email'
                className={classes.field}
                variant='outlined'
                label={texts.email}
                value={email}
                setValue={setValue}
                errorMsg={error === 'email' ? texts.emailError : undefined}
                margin='normal'
                onEnter={attemptPasswordChange}
            />
            {error === 'none' &&
                <Typography color='error' variant='body1' className={classes.field}>
                    {texts.noneSelectedError}
                </Typography>}
            <Grid item>
                {loading
                    ? <CircularProgress size={24} className={classes.createAccount} />
                    : <Button color='primary' onClick={attemptPasswordChange}>
                        {texts.changePassword}
                    </Button>
                }
            </Grid>
            <BasicDialog open={success} handleClose={onFinish} title={texts.emailSentTitle} contents={texts.emailSentContents} />
        </Grid>
    );
}

function FieldDivider() {
    const classes = useStyles()
    const texts = useTextLang(forgotPasswordTexts)
    return (
        <Grid container direction='row' alignItems='center' className={classes.dividerContainer}>
            <Grid item xs className={classes.line} />
            <Grid item>
                <Typography className={classes.dividerText} variant='caption'>
                    {texts.or}
                </Typography>
            </Grid>
            <Grid item xs className={classes.line} />
        </Grid>
    )
}

export default ForgotPassword