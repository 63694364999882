import React, { useEffect, useState } from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Typography, Grid, Button, Theme } from '@material-ui/core'
import { Check } from '@material-ui/icons'
import { Link as RouterLink } from 'react-router-dom'
import { makeStyles, useTheme } from '@material-ui/styles'

import EventCard from './Payment/EventCard'
import { useTextLang } from '../Contexts/LangContext'
import { DesktopNavigation, MobileNavigation } from '../Global/BottomNavigation'
import { routes } from '../Contexts/RouterContext'
import { CartEntry, Cart } from '../server-types'
import RegisterContainer from './RegisterContainer'
import { MatchProps } from '../types'
import { useAuth } from '../Contexts/AuthContext'

const successTexts = {
    title: {
        en: 'Invoice:'
    },
    automaticReminders1: {
        en: 'automatic reminders will'
    },
    automaticReminders2: {
        en: 'be sent via text or email'
    },
    okButton: {
        en: 'OK!'
    }
}

const useStyles = makeStyles(theme => ({
    mainPage: {
        padding: theme.spacing(3, 0)
    },
    header: {
        paddingLeft: theme.spacing(3),
    },
    icon: {
        paddingRight: theme.spacing(1),
    },
    leftText: {
        paddingLeft: theme.spacing(1),
    },
}))

export default function Invoice({ match }: MatchProps) {
    const classes = useStyles()
    const texts = useTextLang(successTexts)
    const invoiceid = match.params.id
    const theme = useTheme<Theme>()
    const matches = useMediaQuery(theme.breakpoints.up('sm'))
    const [invoiceItems, setInvoiceItems] = useState<CartEntry[]>([])
    const { authQuery } = useAuth()

    useEffect(() => {
        const processInvoice = ({ events }: Cart) => {
            setInvoiceItems(events)
        }

        return authQuery('getInvoiceData', processInvoice, `invoiceid:"${invoiceid}"`, 'events{eventid,persons{personid,name,tshirtSize}}')
    }, [invoiceid, authQuery])

    return (
        <RegisterContainer activeStep={2}>
            <Grid container direction='column' spacing={3} className={classes.mainPage}>
                <Grid item>
                    <Typography color='textSecondary' variant='h5' className={classes.header}>
                        {texts.title}
                    </Typography>
                </Grid>
                {invoiceItems.map(({ eventid, ...rest }) => {
                    return (
                        <Grid item container key={eventid}>
                            <EventCard eventid={eventid} key={eventid} {...rest} />
                        </Grid>
                    )
                })}
                {matches
                    ? <DesktopNavigation>
                        <NavigationContents />
                    </DesktopNavigation>
                    : <MobileNavigation>
                        <NavigationContents />
                    </MobileNavigation>
                }
            </Grid>
        </RegisterContainer>
    )
}

function NavigationContents() {
    const classes = useStyles()
    const texts = useTextLang(successTexts)

    return (
        <>
            <Grid item xs={true} className={classes.leftText}>
                <Grid container direction='column'>
                    <Typography color='textSecondary' variant='button'>
                        {texts.automaticReminders1}
                    </Typography>
                    <Typography color='textSecondary' variant='button'>
                        {texts.automaticReminders2}
                    </Typography>
                </Grid>
            </Grid>
            <Grid item>
                <Button variant='contained' color='primary' component={RouterLink} to={routes.home}>
                    <Check className={classes.icon} />
                    {texts.okButton}
                </Button>
            </Grid>
        </>
    )
}