import React, { ReactNode, PropsWithChildren } from 'react'
import { AppBar, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles'
import classnames from 'classnames'

import Navigation from './NavigationBar'


const useStyles = makeStyles(theme => ({
    selectable: {
        WebkitUserSelect: 'text',
        MozUserSelect: 'text',
        MsUserSelect: 'text',
        userSelect: 'text',
    },
    layout: {
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    narrowLayout: {
        maxWidth: theme.breakpoints.values.sm,
    },
    commonLayout: {
        maxWidth: theme.breakpoints.values.sm,
        [theme.breakpoints.up('md')]: {
            maxWidth: theme.breakpoints.values.md,
        },
    },
    wideLayout: {
        maxWidth: theme.breakpoints.values.md,
    },
    mainContainer: {
        padding: theme.spacing(2),
    },
    background: {
        backgroundColor: theme.palette.common.white,
    },
}))

type FullPageFormatProps = PropsWithChildren<{
    barContents?: ReactNode,
    size?: 'common' | 'narrow' | 'wide' | 'full',
    simpleNavBar?: boolean,
    selectable?: boolean
}>
export default function FullPageFormat({ children, barContents, size = 'common', simpleNavBar, selectable=false }: FullPageFormatProps) {
    const classes = useStyles()

    let layoutType
    switch (size) {
        case 'common': layoutType = classes.commonLayout
            break
        case 'narrow': layoutType = classes.narrowLayout
            break
        case 'wide': layoutType = classes.wideLayout
            break
        case 'full': layoutType = ''
            break
    }

    return (
        <>
            <AppBar position='sticky' color='secondary'>
                <Navigation simple={simpleNavBar} />
                {barContents &&
                    <>
                        <Divider variant='middle' />
                        <div className={`${classes.layout} ${layoutType}`}>
                            {barContents}
                        </div>
                    </>
                }
            </AppBar>
            <div className={classnames(classes.background, {[classes.selectable]: selectable})}>
                <div className={`${classes.layout} ${classes.mainContainer} ${layoutType}`}>
                    {children}
                </div>
            </div>
        </>
    )
}