import React from 'react'
import { Callback } from '../types';
import { useTextLang } from '../Contexts/LangContext';
import { BasicDialog } from './Dialogs';

const eventsMotAllowedTexts = {
    eventNotAllowedTitle: {
        en: 'Account Processing',
        es: 'Procesando su cuenta'
    },
    eventNotAllowedContents: {
        en: 'Your account is still processing. If it has been more than two business days since you finished registering, please let us know.',
        es: 'Su cuenta esta siendo procesada. Si han pasado mas de 2 dias habiles desde que termino su registro, por favor comuniquese con nosotros.'
    },
}

type EventNotAllowedDialogProps = {
    open: boolean,
    handleClose: Callback
}
export default function EventNotAllowedDialog(props: EventNotAllowedDialogProps) {
    const texts = useTextLang(eventsMotAllowedTexts)
    return (
        <BasicDialog {...props} title={texts.eventNotAllowedTitle} contents={texts.eventNotAllowedContents} />
    )
}