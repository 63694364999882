import React, { useState, useEffect, useCallback } from 'react';
import { Grid, Typography, Divider, Button, FormControlLabel, Switch } from '@material-ui/core';
import { useTextLang } from '../../Contexts/LangContext'
import { adminRoutes } from '../Admin'
import { adminQuery, adminMutation } from '../../Global/ServerConnection';
import LoadingPage from '../../Global/LoadingPage';
import { Link as RouterLink } from 'react-router-dom';
import { Notification } from '../../Global/Notifications';
import { useRouter } from '../../Contexts/RouterContext';

const viewNotificationsTexts = {
    title: {
        en: 'View Notifications',
    },
    showInactive: {
        en: 'Show Inactive Notifications'
    },
    addNotification: {
        en: 'Add Notification'
    },
}

type NotificationType = {
    notificationid: string,
    everyone: boolean,
    notification: string,
    color: string,
    priority: number,
    active: boolean,
}

export default function ViewNotifications() {
    const texts = useTextLang(viewNotificationsTexts)
    const [notifications, setNotifications] = useState<NotificationType[]>([])
    const [inactiveNotifications, setInactiveNotifications] = useState<NotificationType[]>([])
    const [showInactive, setShowInactive] = useState(false)
    const [loading, setLoading] = useState(true)
    const { navigateTo } = useRouter()

    useEffect(() => {
        const processNotifications = (notifications: NotificationType[]) => {
            setNotifications(notifications.filter(({ active }) => active))
            setInactiveNotifications(notifications.filter(({ active }) => !active))
            setLoading(false)
        }
        return adminQuery('getAllNotifications', processNotifications, undefined, 'notificationid,everyone,notification,color,priority,active')
    }, [])

    const handleInactiveSwitch = useCallback((event: any, value: boolean) => {
        setShowInactive(value)
    }, [])

    const createNotification = useCallback(() => {
        const processNotification = (notificationid: string) => {
            navigateTo(adminRoutes.editNotification(notificationid))
        }
        adminMutation('createNotification', processNotification)
    }, [navigateTo])

    return (
        <Grid container direction='column' spacing={1}>
            <Grid item container direction='row' justify='space-between'>
                <Typography variant='h6' color='textSecondary'>
                    {texts.title}
                </Typography>
                <FormControlLabel
                    control={<Switch checked={showInactive} onChange={handleInactiveSwitch} color='primary' />}
                    label={texts.showInactive}
                />
            </Grid>
            {notifications.map(notification =>
                <ViewNotification key={notification.notificationid} {...notification} />
            )}
            {showInactive && <>
                <Grid item>
                    <Divider />
                </Grid>
                {inactiveNotifications.map(notification =>
                    <ViewNotification key={notification.notificationid} {...notification} />
                )}
            </>}
            <Grid item>
                <Button onClick={createNotification} color='primary' fullWidth>
                    {texts.addNotification}
                </Button>
            </Grid>
            <LoadingPage loading={loading} />
        </Grid>
    )
}

function ViewNotification({ notificationid, notification, color, everyone, priority }: NotificationType) {
    return (
        <Grid item key={notificationid}>
            <Notification notificationid={notificationid} notification={notification} color={color} />
            <Grid container direction='row' justify='space-between'>
                <Grid item>
                    <Typography>
                        {`Priority: ${priority} | ${everyone ? 'Visible to everyone' : 'Visible to some'}`}
                    </Typography>
                </Grid>
                <Grid item>
                    <Button color='primary' component={RouterLink} to={adminRoutes.editNotification(notificationid)}>{'Edit Notification'}</Button>
                </Grid>
            </Grid>
        </Grid>
    )
}