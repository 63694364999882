import React, { useState, useCallback } from 'react';
import { Grid, Typography, Divider, List, ListItem, ListItemIcon, ListItemText, Switch, FormControlLabel } from '@material-ui/core'
import { useTextLang } from '../../Contexts/LangContext'
import { Link as RouterLink } from 'react-router-dom'
import { adminRoutes } from '../Admin'
import { AddBox, AddCircle } from '@material-ui/icons'
import { useRouter } from '../../Contexts/RouterContext'

const reportNavigationTexts = {
    title: {
        en: 'Reports',
        es: 'Reportes',
    },
    events: {
        en: 'Events',
        es: 'Eventos',
    },
    viewPastEvents: {
        en: 'View Expired Events',
        es: 'Ver Eventos Expirados'
    },
    families: {
        en: 'Families',
        es: 'Familias',
    },
    emailsSent: {
        en: 'emails sent',
        es: 'emails enviados',
    }
}

export default function ReportNavigation() {
    const texts = useTextLang(reportNavigationTexts)
    const { navigateTo } = useRouter()

    /*
    const [pastEvents, setPastEvents] = useState(false)
    const onPastEventsChange = useCallback((field: string, selected: boolean) => {
        setPastEvents(selected)
    }, [])

                    <Grid item>
                    <SingleCheckBox field='pastEvents' label={texts.viewPastEvents} value={pastEvents} setValue={onPastEventsChange} />
                </Grid>

    */

    const [pastEvents, setPastEvents] = useState(false)
    const handleSwitchChange = (event: any, checked: boolean) => {
        setPastEvents(checked)
    }

    const navigateToEvents = useCallback(() => {
        navigateTo(adminRoutes.reportEvents, false, { pastEvents })
    }, [navigateTo, pastEvents])

    return (
        <Grid container direction='column' spacing={1}>
            <Grid item>
                <Typography variant='h6' color='textSecondary'>
                    {texts.title}
                </Typography>
            </Grid>
            <List>
                <Divider />
                <ListItem button onClick={navigateToEvents}>
                    <ListItemIcon><AddBox /></ListItemIcon>
                    <ListItemText primary={texts.events} />
                </ListItem>
                <ListItem>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={pastEvents}
                                onChange={handleSwitchChange}
                                name='pastEvents'
                                color='primary'
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                            />
                        }
                        label='Past Events'
                    />
                </ListItem>
                <Divider />
                <ListItem />
                <Divider />
                <ListItem button component={RouterLink} to={adminRoutes.reportFamilies}>
                    <ListItemIcon><AddCircle /></ListItemIcon>
                    <ListItemText primary={texts.families} />
                </ListItem>
                <Divider />
                <ListItem />
                <Divider />
                <ListItem button component={RouterLink} to={adminRoutes.reportEmailsSent}>
                    <ListItemIcon><AddCircle /></ListItemIcon>
                    <ListItemText primary={texts.emailsSent} />
                </ListItem>
                <Divider />
            </List>
        </Grid>
    )
}