import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Grid, Typography, List, Button, Divider } from '@material-ui/core';
import { useTextLang } from '../../Contexts/LangContext'
import { JSONt } from '../../types';
import { adminRoutes } from '../Admin'
import { adminQuery } from '../../Global/ServerConnection';
import { makeStyles } from '@material-ui/styles';
//import { useRouter } from '../../Contexts/RouterContext';
import { Link as RouterLink } from 'react-router-dom';
// import LoadingPage from '../../Global/LoadingPage';
import { EventRegisters, Participant,  SideBarContents, ViewEventReport } from '../admin-types'
import { AdminTable, AdminTableRow, AdminTableCell, AdminCellButton } from '../Common/ViewInfo';
import { useRouter } from '../../Contexts/RouterContext';
import SideBarPortal from '../Common/SideBarPortal';
import { formatDateRange } from '../../Global/GlobalItems';



const Texts = {
    title: {
        en: (eventTitle: string) => `Participants for ${eventTitle}`,
    },
    viewFamily: {
        en: 'View Family'
    },
    total: {
        en: 'Total'
    },
    emails: {
        en: 'Emails'
    },
    printParticipants: {
        en: 'Print Participants'
    }
}

const useStyles = makeStyles(theme => ({
    selectable: {
        WebkitUserSelect: 'text',
        MozUserSelect: 'text',
        MsUserSelect: 'text',
        userSelect: 'text',
    }
}))


export default function ReportEvents() {
    const { routeParams } = useRouter()
    const { pastEvents } = routeParams
    const [events, setEvents] = useState<ViewEventReport[]>()

    useEffect(() => {
        const processEvents = (events: ViewEventReport[]) => {
            setEvents(events)
        }
        return adminQuery('eventIDList', processEvents, `pastEvents:${pastEvents}`, 'eventid, visible, past, first_session, last_session')
    }, [pastEvents])

    return (
        <Grid container direction='row'>
            {events &&
                <List>
                    <Divider />
                    {events.map(({ eventid, ...rest }) =>
                        <ViewOneEvent key={eventid} eventid={eventid} {...rest} />
                    )}
                </List >
            }
        </Grid>
    )
}


function ViewOneEvent({ eventid, past, visible, first_session, last_session }: ViewEventReport) {
    const texts = useTextLang(Texts)
    const [registrations, setRegistrations] = useState<EventRegisters>()
    //const [loading, setLoading] = useState(true)
    const classes = useStyles()

    useEffect(() => {
        const processRegisters = (registrations: EventRegisters) => {
            setRegistrations(registrations)
            //setLoading(false)
        }
        return adminQuery('eventRegisters', processRegisters, `eventid:"${eventid}"`, 'participants{familyid,personid,name,tshirtSize,invoiceid,paymentid},emails,title')
    }, [eventid])

    return (
        <Grid container direction='column' spacing={1}>
            {registrations &&
                <>
                    <Grid item>
                        <Typography variant='h6' color='primary'>
                            {formatDateRange(first_session,last_session)}
                            <br/>
                            {past ? 'PAST EVENT - ' : ''}
                            {visible ? '' : 'INVISIBLE EVENT - '}
                            {texts.title(registrations.title)}
                        </Typography>
                    </Grid>
                    { (registrations.participants.length>0) ?
                    <>
                        <ParticipantList participants={registrations.participants} />
                        <Typography paragraph>
                            {texts.total}: {registrations.participants.length}
                        </Typography>
                        <Typography>
                            {texts.emails}:
                        </Typography>
                        <Typography className={classes.selectable}>
                            {registrations.emails.join(', ')}
                        </Typography>
                        <Button color='primary' component={RouterLink} to={adminRoutes.printEventParticipants(eventid)}>
                            {texts.printParticipants}
                        </Button>
                    </>
                    : <Typography color='error'>No participants</Typography>
                    }
                </>
            }
            <Typography><br /><br /></Typography>
        </Grid>
    )
}


function ParticipantList({ participants }: { participants: Participant[] }) {
    const [sideBarContents, setSideBarContents] = useState<SideBarContents>()

    const handleClose = useCallback(() => setSideBarContents(undefined), [])

    const openFamily = useCallback((familyid: string) => {
        // console.log('family', familyid)
        setSideBarContents({ type: 'family', id: familyid })
    }, [])

    const openPerson = useCallback((personid: string) => {
        // console.log('person', personid)
        setSideBarContents({ type: 'person', id: personid })
    }, [])

    const openInvoice = useCallback((invoiceid: string) => {
        // console.log('invoice', invoiceid)
        setSideBarContents({ type: 'invoice', id: invoiceid })
    }, [])

    const openPayment = useCallback((paymentid: string) => {
        // console.log('payment', paymentid)
        setSideBarContents({ type: 'payment', id: paymentid })
    }, [])

    const families = useMemo(() => Object.entries(
        participants.reduce((groups, { familyid, ...person }) => {
            //TODO: Remove once all families are in new database
            if (!familyid) {
                return { ...groups, noFamily: [...groups.noFamily, person] }
            }
            ////////////////////////////////////////////////////////////////
            else {
                return { ...groups, [familyid]: [...(groups[familyid] || []), person] }
            }
        }, { noFamily: [] } as JSONt<Omit<Participant, 'familyid'>[]>)
    ), [participants])
    console.log('families', families)

    const colTitles = [
        {title:'Family ID',size:'16%'},
        {title:'Name',size:'32%'},
        {title:'TShirt',size:'8%'},
        {title:'Person ID',size:'12%'},
        {title:'Invoice ID',size:'12%'},
        {title:'Payment ID',size:'20%'}
    ]

    return (
        <>
            <AdminTable header={
                <AdminTableRow>
                    {colTitles.map((thisCol,index) =>
                        <AdminTableCell key={index} width={thisCol.size} text={thisCol.title} />
                    )}
                </AdminTableRow>
            }>
                {families.map(([familyid, persons]) => persons.map(({ name, tshirtSize, personid='', invoiceid='', paymentid='' }, index) => (
                    <AdminTableRow key={personid || name} lastRow={index===(persons.length-1)}>
                        {index === 0 &&
                            <AdminTableCell rowSpan={persons.length}>
                                <AdminCellButton onClick={openFamily} clickParam={familyid} disabled={familyid === 'noFamily'}>
                                    {familyid}
                                </AdminCellButton>
                            </AdminTableCell>
                        }
                        <AdminTableCell text={name} />
                        <AdminTableCell text={tshirtSize} />
                        <AdminTableCell >
                            <AdminCellButton onClick={openPerson} clickParam={personid || ''} disabled={personid === null}>
                                {personid || 'no id'}
                            </AdminCellButton>
                        </AdminTableCell>
                        <AdminTableCell >
                            <AdminCellButton onClick={openInvoice} clickParam={invoiceid || ''} disabled={invoiceid === null}>
                                {invoiceid || 'no id'}
                            </AdminCellButton>
                        </AdminTableCell>
                        <AdminTableCell >
                            <AdminCellButton onClick={openPayment} clickParam={paymentid || ''} disabled={paymentid === null}>
                                {paymentid || 'no id'}
                            </AdminCellButton>
                        </AdminTableCell>
                    </AdminTableRow>
                )))}
            </AdminTable>
            {sideBarContents && <SideBarPortal handleClose={handleClose} {...sideBarContents} />}
        </>
    )
}
