import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Grid, Typography, List, Button, Divider } from '@material-ui/core';
import { useTextLang } from '../../Contexts/LangContext'
import { JSONt } from '../../types';
import { adminRoutes } from '../Admin'
import { adminQuery } from '../../Global/ServerConnection';
import { makeStyles } from '@material-ui/styles';
//import { useRouter } from '../../Contexts/RouterContext';
import { Link as RouterLink } from 'react-router-dom';
// import LoadingPage from '../../Global/LoadingPage';
import { EmailReportsSent } from '../admin-types'
import { AdminTable, AdminTableRow, AdminTableCell, AdminCellButton } from '../Common/ViewInfo';
import { useRouter } from '../../Contexts/RouterContext';
import SideBarPortal from '../Common/SideBarPortal';
import { formatDateRange } from '../../Global/GlobalItems';



const Texts = {
    title: {
        en: (eventTitle: string) => `Participants for ${eventTitle}`,
    },
    viewFamily: {
        en: 'View Family'
    },
    total: {
        en: 'Total'
    },
    emails: {
        en: 'Emails'
    },
    printParticipants: {
        en: 'Print Participants'
    }
}

const useStyles = makeStyles(theme => ({
    selectable: {
        WebkitUserSelect: 'text',
        MozUserSelect: 'text',
        MsUserSelect: 'text',
        userSelect: 'text',
    }
}))


export default function ReportEmailsSent() {
    const { routeParams } = useRouter()
    const { reportLimit } = routeParams
    const [report, setReport] = useState<EmailReportsSent[]>()

    useEffect(() => {
        const processReport = (report: EmailReportsSent[]) => {
            setReport(report)
        }
        return adminQuery('reportEmailsSent', processReport, `limit:"${reportLimit}"`, 'emailid,type,id,sender,recipient,messageid,status,dateCreated')
    }, [reportLimit])

    const colTitles = [
        {title:'email ID',size:'15%'},
        {title:'Type',size:'10%'},
        {title:'ID',size:'15%'},
        {title:'Sender',size:'15%'},
        {title:'Recipient',size:'15%'},
        {title:'Message ID',size:'15%'},
        {title:'Date Created',size:'15%'}
    ]

    return (
        <Grid container direction='row'>
            {report &&
                <AdminTable header={
                    <AdminTableRow>
                        {colTitles.map((thisCol,index) =>
                            <AdminTableCell key={index} width={thisCol.size} text={thisCol.title} />
                        )}
                    </AdminTableRow>
                    }>
                    {report.map(({ ...values }) => (
                        <AdminTableRow key={values.emailid}>
                            <AdminTableCell text={values.emailid} />
                            <AdminTableCell text={values.type} />
                            <AdminTableCell text={values.id} />
                            <AdminTableCell text={values.emailid} />
                            <AdminTableCell text={values.sender} />
                            <AdminTableCell text={values.recipient} />
                            <AdminTableCell text={values.messageid} />
                            <AdminTableCell text={values.dateCreated} />
                        </AdminTableRow>
                    ))}
                </AdminTable>
            }
        </Grid>
    )
}

