import React, { useState, useEffect, useCallback } from 'react';
import { Grid, Typography, Divider, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { useTextLang } from '../../Contexts/LangContext'
import { Link as RouterLink } from 'react-router-dom'
import { adminRoutes } from '../Admin'
import { VisibilityOutlined, EditOutlined, FileCopyOutlined, PeopleOutlined, AddOutlined, CallSplit } from '@material-ui/icons';
import { Consumer, OptionValue, defaultOption, isOptionValue } from '../../types';
import Selection from '../../Form/Components/Selection';
import { adminQuery, adminMutation } from '../../Global/ServerConnection';
import { useRouter } from '../../Contexts/RouterContext';
import { NavigationDialog } from '../../Global/Dialogs';
import SingleCheckBox from '../../Form/Components/CheckBox';

const adminMainTexts = {
    title: {
        en: 'Event Settings',
    },
    selectEvent: {
        en: 'Select an event'
    },
    viewPastEvents: {
        en: 'View Past Events'
    },
    viewEvent: {
        en: 'View Event'
    },
    viewParticipants: {
        en: 'View Participants'
    },
    editEvent: {
        en: 'Edit Event'
    },
    duplicateEvent: {
        en: 'Duplicate Event'
    },
    branchEvent: {
        en: 'Branch Event'
    },
    createEvent: {
        en: 'Create Event'
    },
    dialogTitle: {
        en: 'Warning'
    },
    copyEventContents: {
        en: 'Are you sure you want to duplicate the event? This will copy all the information.'
    },
    branchEventContents: {
        en: 'Are you sure you want to branch the event? This will not copy the event information.'
    },
    createEventContents: {
        en: 'Are you sure you want to create an event?'
    },
}

export default function EventNavigation() {
    const [event, setEvent] = useState(defaultOption)
    const texts = useTextLang(adminMainTexts)
    const [copyEventOpen, setCopyEventOpen] = useState(false)
    const [branchEventOpen, setBranchEventOpen] = useState(false)
    const [createEventOpen, setCreateEventOpen] = useState(false)
    const { navigateTo } = useRouter()

    const copyEvent = useCallback(() => {
        const processEvent = (eventid: string) => {
            navigateTo(adminRoutes.editEvent(eventid))
        }
        if (event.value)
            adminMutation('copyEvent', processEvent, `eventid:"${event.value}"`)
    }, [event, navigateTo])

    const branchEvent = useCallback(() => {
        const processEvent = (eventid: string) => {
            navigateTo(adminRoutes.editEvent(eventid))
        }
        if (event.value)
            adminMutation('branchEvent', processEvent, `eventid:"${event.value}"`)
    }, [event, navigateTo])

    const createEvent = useCallback(() => {
        const processEvent = (eventid: string) => {
            navigateTo(adminRoutes.editEvent(eventid))
        }
        adminMutation('createEvent', processEvent)
    }, [navigateTo])

    const openCopyEvent = useCallback(() => {
        setCopyEventOpen(true)
    }, [])

    const closeCopyEvent = useCallback(() => {
        setCopyEventOpen(false)
    }, [])

    const openBranchEvent = useCallback(() => {
        setBranchEventOpen(true)
    }, [])

    const closeBranchEvent = useCallback(() => {
        setBranchEventOpen(false)
    }, [])

    const openCreateEvent = useCallback(() => {
        setCreateEventOpen(true)
    }, [])

    const closeCreateEvent = useCallback(() => {
        setCreateEventOpen(false)
    }, [])

    return (
        <Grid container direction='column' spacing={1}>
            <Grid item>
                <Typography variant='h6' color='textSecondary'>
                    {texts.title}
                </Typography>
            </Grid>
            <FindEventID event={event} setEvent={setEvent} />
            <List>
                <Divider />
                <ListItem button component={RouterLink} to={adminRoutes.viewEventParticipants(event.value)}>
                    <ListItemIcon><PeopleOutlined /></ListItemIcon>
                    <ListItemText primary={texts.viewParticipants} />
                </ListItem>
                <Divider />
                <ListItem button component={RouterLink} to={adminRoutes.editEvent(event.value)}>
                    <ListItemIcon><EditOutlined /></ListItemIcon>
                    <ListItemText primary={texts.editEvent} />
                </ListItem>
                <Divider />
                <ListItem />
                <Divider />
                <ListItem button component={RouterLink} to={adminRoutes.viewEvent(event.value)}>
                    <ListItemIcon><VisibilityOutlined /></ListItemIcon>
                    <ListItemText primary={texts.viewEvent} />
                </ListItem>
                <Divider />
                <ListItem button onClick={openCopyEvent}>
                    <ListItemIcon><FileCopyOutlined /></ListItemIcon>
                    <ListItemText primary={texts.duplicateEvent} />
                </ListItem>
                <Divider />
                <ListItem button onClick={openBranchEvent}>
                    <ListItemIcon><CallSplit /></ListItemIcon>
                    <ListItemText primary={texts.branchEvent} />
                </ListItem>
                <Divider />
                <ListItem button onClick={openCreateEvent}>
                    <ListItemIcon><AddOutlined /></ListItemIcon>
                    <ListItemText primary={texts.createEvent} />
                </ListItem>
                <Divider />
            </List>
            <NavigationDialog open={copyEventOpen} onCancel={closeCopyEvent} onContinue={copyEvent} title={texts.dialogTitle} contents={texts.copyEventContents} />
            <NavigationDialog open={branchEventOpen} onCancel={closeBranchEvent} onContinue={branchEvent} title={texts.dialogTitle} contents={texts.branchEventContents} />
            <NavigationDialog open={createEventOpen} onCancel={closeCreateEvent} onContinue={createEvent} title={texts.dialogTitle} contents={texts.createEventContents} />
        </Grid>
    )
}

type ShortEvent = {
    eventid: string,
    title: string,
    past: boolean,
    visible: boolean,
}
type FindEventIDProps = {
    event: OptionValue,
    setEvent: Consumer<OptionValue>
}
function FindEventID({ event, setEvent }: FindEventIDProps) {
    const [eventList, setEventList] = useState<OptionValue[]>([])
    const [loading, setLoading] = useState(true)
    const [pastEvents, setPastEvents] = useState(false)
    const texts = useTextLang(adminMainTexts)

    useEffect(() => {
        const processEventList = (eventList: ShortEvent[]) => {
            // console.log(eventList)
            setEventList(eventList.map(({ eventid, title, past, visible }) => {
                let fixedTitle = title
                if (past) {
                    fixedTitle = `OLD - ${title}`
                } else if (!visible) {
                    fixedTitle = `INV - ${title}`
                }
                return { value: eventid, label: fixedTitle }
            }))
            setLoading(false)
        }

        return adminQuery('eventList', processEventList, `pastEvents:${pastEvents}`, 'eventid,title,past, visible')
    }, [pastEvents])

    const onSelectionChange = useCallback((field: string, event: OptionValue | OptionValue[]) => {
        if (isOptionValue(event))
            setEvent(event)
    }, [setEvent])

    const onPastEventsChange = useCallback((field: string, selected: boolean) => {
        setPastEvents(selected)
    }, [])

    return (
        <>
            <Grid item>
                <Selection field='eventID' label={texts.selectEvent} value={event} setValue={onSelectionChange} options={eventList} isLoading={loading} isClearable={false} />
            </Grid>
            <Grid item>
                <SingleCheckBox field='pastEvents' label={texts.viewPastEvents} value={pastEvents} setValue={onPastEventsChange} />
            </Grid>
        </>
    )
}