import React, { useState, useEffect, PropsWithChildren, useCallback, useMemo } from 'react'
import { Grid, Typography, Divider, CardContent, FormControlLabel, Checkbox } from '@material-ui/core'
import Image from '../../Global/Image'
import { useLang, useTextLang } from '../../Contexts/LangContext';
import { Tune } from '@material-ui/icons'
import { EventDetails } from '../../server-types';
import { useRouter, routes } from '../../Contexts/RouterContext';
import EventNotAllowedDialog from '../../Global/EventNotAllowedDialog';
import { useAuth } from '../../Contexts/AuthContext';
import Price from '../../Global/PriceFormat';

const eventContentsTexts = {
    adjustInfo: {
        en: 'Adjust Family Info',
        es: 'Modificar información de la familia'
    },
}

type EventContentsProps = PropsWithChildren<{
    desktop?: boolean,
    eventID: string
}>
export default function EventContents({ desktop, eventID, children }: EventContentsProps) {
    const texts = useTextLang(eventContentsTexts)
    const [event, setEvent] = useState<EventDetails>({})
    // const [approvedFamiliesOnly, setApprovedFamiliesOnly] = useState(false)
    // const [title, setTitle] = useState('')
    // const [subtitle, setSubtitle] = useState('')
    // const [imageSrc, setImageSrc] = useState()
    // const [price, setPrice] = useState(0)
    // const [individualPrice, setIndividualPrice] = useState(false)
    // const [priceDescription, setPriceDescription] = useState<string>()
    // const [status, setStatus] = useState<string>()
    const { navigateTo, goBack } = useRouter()
    const { language } = useLang()

    const { isAuth, registrationStep, authQuery } = useAuth()
    const notApproved = useMemo(() => isAuth && event.approvedFamiliesOnly === true && registrationStep === 'DONE', [isAuth, event, registrationStep])

    useEffect(() => {
        const processEvent = (event: EventDetails) => {
            if (event)
                setEvent(event)
        }
        return authQuery('eventDetails', processEvent, `eventid:"${eventID}",language:"${language}"`, 'title,subtitle,status,imageHD,imageRect,price,suggestedDonation,individualPrice,priceDescription,approvedFamiliesOnly')
    }, [eventID, language, authQuery])

    const goToFamilySettings = useCallback(() => {
        navigateTo(routes.familySettings)
    }, [navigateTo])

    return (
        <>
            <CardContent>
                <Grid container direction='column' alignItems={desktop ? 'center' : 'flex-start'}>
                    <Typography variant='h5'>
                        {event.title}
                    </Typography>
                    <Typography gutterBottom>
                        {event.subtitle}
                    </Typography>
                </Grid>
            </CardContent>
            <Grid item container>
                <Image rect src={event.imageHD || event.imageRect} alt={event.title} />
            </Grid>
            <CardContent>
                <Grid container direction={'column'} spacing={3}>
                    <Grid item>
                        {event.status && <Typography variant='body1' color='error'>
                            {event.status}
                        </Typography>}
                    </Grid>
                    <Grid item>
                        <Price price={event.price} suggestedDonation={event.suggestedDonation} individualPrice={event.individualPrice} priceDescription={event.priceDescription} />
                    </Grid>
                    <Grid item>
                        <Divider variant='fullWidth' component='div' />
                    </Grid>
                    <Grid item xs={desktop ? true : false}>
                        <Grid container direction='column'>
                            {children}
                            <FormControlLabel
                                control={
                                    <Checkbox icon={<Tune />}
                                        onChange={goToFamilySettings} />}
                                label={
                                    <Typography variant='body1' color='textSecondary'>
                                        {texts.adjustInfo}
                                    </Typography>
                                } />
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
            <EventNotAllowedDialog open={notApproved} handleClose={goBack} />
        </>
    )
}