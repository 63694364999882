import React, { useState, useCallback, useEffect } from 'react';
import { OptionValue, TriConsumer } from '../../types'
import { Grid, TextField, Typography, Button } from '@material-ui/core';
import { useTextLang } from '../../Contexts/LangContext'
import { adminQuery } from '../../Global/ServerConnection';
import Selection from '../../Form/Components/Selection';

const plainTextTexts = {
    title: {
        en: 'Write Plain Text Message',
    },
    next: {
        en: 'Next'
    }
}

type WritePlainTextProps = {
    initialSender: string,
    initialText: string,
    initialSubject: string,
    onFinish: TriConsumer<string, string, string>,
}
export default function WritePlainText({ initialSender, initialText, initialSubject, onFinish }: WritePlainTextProps) {
    const [sender, setSender] = useState({value: initialSender, label: initialSender})
    const [senderList, setSenderList] = useState([sender])
    const [senderLoading, setSenderLoading] = useState(true)
    const [subject, setSubject] = useState(initialSubject)
    const [text, setText] = useState(initialText)
    const texts = useTextLang(plainTextTexts)

    useEffect(() => {
        const processSenderList = (senderList: string[]) => {
            // console.log(senderList)
            setSenderList(senderList.map(sender => ({ value: sender, label: sender })))
            setSenderLoading(false)
        }
        return adminQuery('getEmailSenderList', processSenderList)
    }, [])

    const handleSenderChange = useCallback((field: string, value: OptionValue) => {
        setSender(value)
    }, [setSender])

    const handleSubjectChange = useCallback((event: any) => {
        setSubject(event.target.value)
    }, [setSubject])

    const handleTextChange = useCallback((event: any) => {
        setText(event.target.value)
    }, [setText])

    const handleSubmit = useCallback(() => {
        onFinish(sender.value, subject, text)
    }, [onFinish, sender, subject, text])

    return (
        <Grid container direction='column' spacing={3}>
            <Grid item>
                <Typography variant='h6' color='textSecondary'>
                    {texts.title}
                </Typography>
            </Grid>
            <Grid item container direction='row' spacing={3}>
                <Grid item xs>
                    <Selection field='sender' label={'Select sender'} value={sender} setValue={handleSenderChange} options={senderList} isLoading={senderLoading} isClearable={false} />
                </Grid>
                <Grid item>
                    <Button onClick={handleSubmit} color='primary'>
                        {'Next'}
                    </Button>
                </Grid>
            </Grid>
            <Grid item>
                <TextField
                    label='Subject'
                    value={subject}
                    onChange={handleSubjectChange}
                    fullWidth
                />
            </Grid>
            <Grid item>
                <TextField
                    label='Plain Text Message'
                    value={text}
                    onChange={handleTextChange}
                    fullWidth
                    multiline
                    rows={15}
                    variant='outlined'
                />
            </Grid>
        </Grid>
    )
}