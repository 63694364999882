import React, { useState, useEffect } from 'react'
import { useTextLang } from '../../Contexts/LangContext';
import { Grid, Typography, Divider } from '@material-ui/core';
import { EditOutlined } from '@material-ui/icons';
import { Person } from '../../server-types';
import { FamilyInfoType, MatchProps } from '../../types';
import { PersonEventsType } from '../admin-types';
import { adminQuery } from '../../Global/ServerConnection';
import LoadingPage from '../../Global/LoadingPage';


const familySettingsTexts = {
    newChild: {
        en: 'New Child'
    },
    viewFamilyInfo: {
        en: 'View Family Events'
    },
    addChild: {
        en: 'Add child'
    },
    edit: {
        en: 'Edit'
    }
}


export default function EventsFamily({ match }: MatchProps) {
    const familyid = match.params.id
    const pastEvents = match.params.pastEvents
    const [persons, setPersons] = useState<Person[]>([])
    const [familyInfo, setFamilyInfo] = useState<Record<keyof FamilyInfoType, string>>()
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const processInfo = (info: { persons: Person[] } & Record<keyof FamilyInfoType, string>) => {
            if (info) {
                const { persons, ...familyInfo } = info
                setPersons(persons)
                setFamilyInfo(familyInfo)
                setLoading(false)
            }
        }
        return adminQuery('getFamilyInfo', processInfo, `familyid:"${familyid}"`, 'persons{personid,name},streetAddress,city,state,zipCode,country,maritalStatus,dateOfMarriage')
    }, [familyid])


    return (
        <>
            {familyInfo && <FamilyInfo familyInfo={familyInfo} />}
            {persons.map(({ personid, name }) =>
                <PersonEvents
                    name={name}
                    personid={personid}
                    pastEvents={pastEvents}
                    key={personid}
                />
            )}
            <Divider />
            <LoadingPage loading={loading} />
        </>
    )
}


type FamilyInfoProps = {
    familyInfo: Record<keyof FamilyInfoType, string>,
}
function FamilyInfo({ familyInfo }: FamilyInfoProps) {
    const texts = useTextLang(familySettingsTexts)

    return (
        <>
            <Grid item container direction='row'>
                <Typography variant='h6'>
                    {texts.viewFamilyInfo}
                </Typography>
            </Grid>
            <Divider />
        </>
    )
}


type PersonNameProps = {
    name: string,
}
function PersonName({ name }: PersonNameProps) {
    return (
        <>
            <Grid item container justify='space-between'>
                <Typography variant='h6'>
                    {name}
                </Typography>
                <EditOutlined />
            </Grid>
            <Divider />
        </>
    )
}

type personEventsProps = {
    personid: string,
    name: string,
    pastEvents:boolean,
}
function PersonEvents({ personid, name, pastEvents }: personEventsProps) {
    const [personEvents, setPersonEvents] = useState<PersonEventsType>()

    useEffect(() => {
        const processOriginalValues = (values?: PersonEventsType) => {
            if (values)
                setPersonEvents(values)
        }
        return adminQuery('getPersonEvents', processOriginalValues, `personid:"${personid}"`, 'events{eventid,title,past,tshirtSize,invoiceid,paymentid}')
    }, [personid])

    if (personEvents) {
        return <DisplayPerson personEvents={personEvents} name={name} personid={personid} />
    } else {
        return <PersonName name={name} />
    }
}



type PersonEventProps = {
	eventid: string,
    title: string,
    past: boolean,
    tshirtSize: string,
    invoiceid: string,
    paymentid: string,
}
function PersonEvent({ eventid, title, past, tshirtSize, invoiceid, paymentid }: PersonEventProps) {
    
    return (
        <>
			<Grid container direction='row' alignItems='center' justify='space-between'>
				<Grid item xs={1}>{past?'PAST':''}</Grid>
				<Grid item xs={2}>{eventid}</Grid>
				<Grid item xs={3}>{title}</Grid>
				<Grid item xs={2}>{tshirtSize}</Grid>
				<Grid item xs={2}>{invoiceid}</Grid>
				<Grid item xs={2}>{paymentid}</Grid>
			</Grid>
            <Divider />
        </>
    )
}


type DisplayPersonProps = {
	name: string,
	personid: string,
    personEvents: PersonEventsType
}
function DisplayPerson({ name, personid, personEvents }: DisplayPersonProps) {

    return (
        <>
            <Grid container direction='row'>
				<Typography variant='h6'>
					{name} - {personid}
				</Typography>
            </Grid>
            <Divider />
			<Grid container direction='row' alignItems='center' justify='space-between'>
                <Grid item xs={1}>past</Grid>
    			<Grid item xs={2}>eventid</Grid>
				<Grid item xs={3}>title</Grid>
				<Grid item xs={2}>tshirtSize</Grid>
				<Grid item xs={2}>invoiceid</Grid>
				<Grid item xs={2}>paymentid</Grid>
			</Grid>
			{personEvents.events.map(({ eventid, title, past, tshirtSize, invoiceid, paymentid }) =>
                <PersonEvent
					eventid= {eventid}
                    title= {title}
                    past= {past}
					tshirtSize= {tshirtSize}
					invoiceid= {invoiceid}
					paymentid= {paymentid}
					key={eventid}
				/>
			)}
        </>
    )
}
