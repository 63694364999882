import React from 'react'
import { Grid, Dialog, CircularProgress, } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
    dialog: {
        background: 'rgba(0, 0, 0, 0.5)'
    },
    mainContainer: {
        height: '100%',
        width: '100%'
    },
}))

type LoadingPageProps = {
    loading: boolean
}
export default function LoadingPage({ loading }: LoadingPageProps) {
    const classes = useStyles()
    if (loading) {
        return (
            <Dialog
                classes={{ paperFullScreen: classes.dialog }}
                open={loading}
                disableBackdropClick
                disableEscapeKeyDown
                fullScreen
            >
                <Grid container direction='column' className={classes.mainContainer} alignItems='center'>
                    <Grid item container xs={true} justify='center' alignItems='center'>
                        <CircularProgress color='secondary' />
                    </Grid>
                </Grid>
            </Dialog>
        )
    } else {
        return null
    }
}