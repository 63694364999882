import React, { useEffect, useCallback, useRef } from 'react'
import { TextField, Theme, useMediaQuery } from '@material-ui/core'
import { FormField, defaultText } from '../../types';
import { TextFieldProps } from '@material-ui/core/TextField';
import { scrollToView } from '../../Global/GlobalItems';
import { useTheme } from '@material-ui/styles';

type EmailType<FieldName> = FormField<FieldName, string> & TextFieldProps

export default function Email<FieldName>({ field, value, setValue, errorMsg, onEnter, helperText, required, ...rest }: EmailType<FieldName>) {
    const handleChange: TextFieldProps['onChange'] = useCallback((event) => {
        setValue(field, event.target.value)
    }, [field, setValue])

    const theme = useTheme<Theme>()
    const matches = useMediaQuery(theme.breakpoints.down('xs'))
    const myRef = useRef<HTMLDivElement>(null)
    const handleFocus = useCallback(() => {
        if (matches)
            scrollToView(myRef)
    }, [myRef, matches])

    const keyPress = useCallback(({ keyCode }: { keyCode: number }) => {
        if (keyCode === 13 && onEnter) {
            onEnter()
        }
    }, [onEnter])

    // Remove value on unmount
    useEffect(() => {
        return () => {
            setValue(field, defaultText)
        }
    }, [field, setValue])

    return (
        <TextField
            autoComplete='off'
            autoCorrect='off'
            margin='dense'
            fullWidth
            {...rest}
            ref={myRef}
            onFocus={handleFocus}
            value={value || ''}
            onChange={handleChange}
            type='text'
            helperText={errorMsg || helperText}
            error={errorMsg !== undefined}
            onKeyDown={keyPress}
        />
    )
}