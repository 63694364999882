import React, { useEffect, useCallback, useRef, useState, InputHTMLAttributes } from 'react'
import { makeStyles, Grid, Typography } from '@material-ui/core'
import { FormField } from '../../types';
import axios from 'axios';
import { tokenKey } from '../../Contexts/AuthContext';
import { useTextLang } from '../../Contexts/LangContext';
import Image from '../../Global/Image';
import Text from './Text';
import { NavigationDialog, BasicDialog } from '../../Global/Dialogs';

const imageInputTexts = {
    dropOrClick: {
        en: 'Drop a file or click to select one'
    },
    typeOrSelect: {
        en: 'Type a file name or select one from your computer'
    },
    fileExistsTitle: {
        en: 'File Already Exists'
    },
    replaceFileContents: {
        en: 'Continue only if you want to replace the file.'
    },
    fileExistsContents: {
        en: 'Please use a different name for the file or type in the name to reuse the file in the server.'
    }
}

const useStyles = makeStyles(theme => ({
    inputEl: {
        display: 'none'
    },
    dropZone: {
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: theme.palette.grey[400],
        borderRadius: theme.spacing(1),
        minHeight: theme.spacing(7),
    }
}))

type FileInputType<FieldName> = FormField<FieldName, string> & {
    image?: boolean,
    rect?: boolean,
    location?: 'images' | 'docs' | 'attachments',
    canReplaceFile?: boolean
}
export default function FileInput<FieldName>({ field, value, setValue, label, image, rect, location, canReplaceFile = true }: FileInputType<FieldName>) {
    const [file, setFile] = useState<Blob | File>()
    const inputEl = useRef<HTMLInputElement>(null)
    const classes = useStyles()
    const texts = useTextLang(imageInputTexts)
    const [replaceDialogOpen, setReplaceDialogOpen] = useState(false)
    const [fileExistsDialogOpen, setFileExistsDialogOpen] = useState(false)

    // Remove value on unmount
    useEffect(() => {
        return () => {
            setValue(field, '')
        }
    }, [field, setValue])

    const handleSubmit = useCallback((file, replace = false) => {
        // console.log(file)
        if (file) {
            var formData = new FormData();
            formData.append("file", file);
            axios.post('/fileupload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                params: {
                    sessionToken: localStorage.getItem(tokenKey),
                    replaceFile: canReplaceFile && replace,
                    location,
                }
            }).then(response => {
                const { filename, error } = response.data
                if (filename) {
                    setValue(field, filename)
                } else if (error) {
                    if (canReplaceFile) {
                        setReplaceDialogOpen(true)
                    } else {
                        setFileExistsDialogOpen(true)
                    }
                }
            })
                .catch((thrown) => {
                    if (axios.isCancel(thrown)) {
                        // console.log('Request canceled', thrown.message);
                    } else {
                        // console.log('AXIOS ERROR')
                    }
                })
        }
    }, [field, setValue, location, canReplaceFile])

    useEffect(() => {
        handleSubmit(file)
    }, [handleSubmit, file])

    const handleFileSelect = useCallback((event) => {
        event.stopPropagation();
        event.preventDefault();
        setFile(event.dataTransfer.files[0])
    }, [])

    const handleDragOver = useCallback((event) => {
        event.stopPropagation();
        event.preventDefault();
        event.dataTransfer.dropEffect = 'copy';
    }, [])

    const handleChange: InputHTMLAttributes<HTMLInputElement>['onChange'] = (e) => {
        const target = e.target
        if (target.files) {
            setFile(target.files[0])
        }
    }

    const openFilePrompt = useCallback(() => {
        if (inputEl && inputEl.current) {
            inputEl.current.click()
        }
    }, [inputEl])

    const closeReplaceDialog = useCallback(() => {
        setReplaceDialogOpen(false)
    }, [])

    const closeFileExistsDialog = useCallback(() => {
        setFileExistsDialogOpen(false)
    }, [])

    const continueSubmission = useCallback(() => {
        handleSubmit(file, true)
        setReplaceDialogOpen(false)
    }, [handleSubmit, file])

    return (
        <Grid container direction='column' spacing={1}>
            <Text field={field} value={value} label={label} setValue={setValue} />
            <Grid onDragOver={handleDragOver} onDrop={handleFileSelect} onClick={openFilePrompt} container justify='center' alignItems='center' className={classes.dropZone}>
                <input ref={inputEl} type="file" name="filetoupload" onChange={handleChange} className={classes.inputEl} />
                {!value && <Typography> {texts.dropOrClick} </Typography>}
                {value && !image && <Typography> {value} </Typography>}
                {value && image && <Image src={value} align='right' alt={value} rect={rect} />}
            </Grid>
            <Typography variant='caption'> {texts.typeOrSelect} </Typography>
            <BasicDialog open={fileExistsDialogOpen} handleClose={closeFileExistsDialog} title={texts.fileExistsTitle} contents={texts.fileExistsContents} />
            <NavigationDialog open={replaceDialogOpen} onCancel={closeReplaceDialog} onContinue={continueSubmission} title={texts.fileExistsTitle} contents={texts.replaceFileContents} />
        </Grid>
    )
}