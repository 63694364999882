import React, { useCallback, useState } from 'react';
import { Callback } from '../../types'
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography, Divider, Button } from '@material-ui/core';
import { Attachment } from '../admin-types';
import { LineTypography, escape } from '../../Global/GlobalItems';
import { adminMutation } from '../../Global/ServerConnection';
import LoadingPage from '../../Global/LoadingPage';
import { useRouter } from '../../Contexts/RouterContext'
import { adminRoutes } from '../Admin'
import { NavigationDialog } from '../../Global/Dialogs';

const useStyles = makeStyles(theme => ({
    contentContainer: {
        width: '100%',
        padding: theme.spacing(3),
    },
}))

export function wrapHTML(html: string) {
    return `<div style='background-color: #fff; color: #000000; font-size: 1rem; font-family: "Roboto", "Helvetica", "Arial", sans-serif; font-weight: 400; line-height: 1.43; letter-spacing: 0.01071em; width: 100%; max-width: 600px; margin-left: auto; margin-right: auto; padding: 12px; box-sizing: border-box;'><div style='border: 1px solid #cccccc; overflow: hidden; width: 100%; border-radius: 8px;'><div style='width: 50%; margin-left: auto; margin-right: auto; padding: 24px;'><a href="https://familyeducationinstitute.org"><img src='https://fei-data.s3-us-west-2.amazonaws.com/images/EmailHeaderImage.png' alt='Family Education Institute' style='display: inline-block; width: 100%; height: auto;'></a></div><hr style="margin: 0px 12px; color:rgba(0, 0, 0, 0.12);" ><div style='padding: 8px 24px; flex-grow: 1; flex-basis: 0;'>${html}</div></div></div>`
}

type PreviewEmailProps = {
    sender: string,
    subject: string,
    plainText: string,
    html: string,
    attachments: Attachment[],
    persons: string[],
    onCancel: Callback,
}
export default function PreviewEmail({ sender, subject, plainText, html, attachments, persons, onCancel }: PreviewEmailProps) {
    const classes = useStyles()
    const [emailid, setEmailid] = useState<string>()
    const [loading, setLoading] = useState(false)
    const { navigateTo } = useRouter()

    const sendPreview = useCallback(() => {
        setLoading(true)
        const processSend = (emailid?: string) => {
            if (emailid) {
                setEmailid(emailid)
            }
            setLoading(false)
        }
        const formattedAttachments = attachments.map(({ name, nameInEmail }) => `{name:"${escape(name)}",nameInEmail:"${escape(nameInEmail)}"}`).join(',')
        const formattedRecipients = persons.map(personid => `"${personid}"`).join(',')
        return adminMutation('sendPreviewEmail', processSend, `sender:"${escape(sender)}",recipients:[${formattedRecipients}],subject:"${escape(subject)}",plainText:"${escape(plainText)}",html:"${escape(html)}",attachments:[${formattedAttachments}]`)

    }, [sender, persons, subject, plainText, html, attachments])

    const sendEmail = useCallback(() => {
        if (emailid)
            navigateTo(adminRoutes.sendEmail(emailid))
    }, [navigateTo, emailid])

    return (
        <Grid container direction='column' spacing={1}>
            <Grid item container direction='row' justify='flex-end'>
                <Button color='primary' onClick={onCancel}>
                    Back
                </Button>
            </Grid>
            <Grid item>
                <Typography variant='h6' color='textSecondary'>
                    Sender:
                </Typography>
                <Typography>
                    {sender}
                </Typography>
            </Grid>
            <Divider />
            <Grid item>
                <Typography variant='h6' color='textSecondary'>
                    Subject:
                </Typography>
                <Typography>
                    {subject}
                </Typography>
            </Grid>
            <Divider />
            <Grid item>
                <Typography variant='h6' color='textSecondary'>
                    Text:
                </Typography>
                <LineTypography>
                    {plainText}
                </LineTypography>
            </Grid>
            <Divider />
            <Grid item className={classes.contentContainer} dangerouslySetInnerHTML={{ __html: wrapHTML(html) }} />
            <Divider />
            <Grid item>
                <Typography variant='h6' color='textSecondary'>
                    Attachments:
                </Typography>
                {attachments.map(({ name, nameInEmail }) => {
                    return (
                        <Grid container direction='row' key={name}>
                            <Grid item xs>
                                <Typography>
                                    {`File Name: ${name}`}
                                </Typography>
                            </Grid>
                            <Grid item xs>
                                <Typography>
                                    {`File Name in Email: ${nameInEmail}`}
                                </Typography>
                            </Grid>
                        </Grid>
                    )
                })}
            </Grid>
            <Divider />
            <Grid item container direction='row' justify='center'>
                <Button color='primary' onClick={sendPreview}>
                    {'Send Preview'}
                </Button>
            </Grid>
            <LoadingPage loading={loading} />
            <NavigationDialog open={emailid !== undefined} title='Preview Sent' contents='Do you want to continue?' onContinue={sendEmail} onCancel={onCancel} />
        </Grid>
    )
}