import React, { useState, useCallback } from 'react';
import { Callback, Consumer } from '../../types'
import { makeStyles } from '@material-ui/styles';
import { Dialog, Grid, TextField, Typography, Button } from '@material-ui/core';
import { CloseRounded } from '@material-ui/icons';
import { wrapHTML } from './PreviewEmail';

const useStyles = makeStyles(theme => ({
    dialog: {
        background: 'rgba(0, 0, 0, 0.5)'
    },
    mainContainer: {
        height: '100%',
        width: '100%'
    },
    closeIcon: {
        margin: theme.spacing(2),
        color: 'rgb(200,200,200)',
    },
    contentContainer: {
        width: '100%',
        padding: theme.spacing(3),
    },
}))

const buttonHTML = `<div style='width: 100%; display: flex; justify-content: center; padding: 24px 0px;'><a style='color: #fff; background-color: #2E7599; padding: 6px 16px; line-height: 1.75; border-radius: 20px; text-transform: uppercase; text-decoration: none; cursor: pointer;' href='??? INSERT HREF ???' target='_blank' rel='noopener noreferrer'><span style='width: 100%;'>??? INSERT TEXT ???</span></a></div>`

type WriteHTMLProps = {
    initialHTML: string,
    onFinish: Consumer<string>,
    onCancel: Consumer<string>,
}
export default function WriteHTML({ initialHTML, onFinish, onCancel }: WriteHTMLProps) {
    const [html, setHTML] = useState(initialHTML)

    const handleChange = useCallback((event: any) => {
        setHTML(event.target.value)
    }, [setHTML])

    const appendHTML = useCallback((value: string) => {
        setHTML(html => html + value)
    }, [setHTML])

    return (
        <Grid container direction='column' spacing={1}>
            <Grid item>
                <Typography variant='h6' color='textSecondary'>
                    Write Raw HTML
                </Typography>
            </Grid>
            <ToolBar html={html} setHTML={setHTML} appendHTML={appendHTML} onFinish={onFinish} onCancel={onCancel} />
            <Grid item>
                <TextField
                    label='Raw HTML'
                    value={html}
                    onChange={handleChange}
                    fullWidth
                    multiline
                    rows={15}
                    variant='outlined'
                />
            </Grid>
        </Grid>
    )
}

type ToolBarProps = {
    html: string,
    setHTML: Consumer<string>,
    appendHTML: Consumer<string>,
    onFinish: Consumer<string>,
    onCancel: Consumer<string>,
}
function ToolBar({ html, setHTML, appendHTML, onFinish, onCancel }: ToolBarProps) {
    const [previewOpen, setPreviewOpen] = useState(false)

    const handleInsertButton = useCallback(() => {
        appendHTML(buttonHTML)
    }, [appendHTML])

    const handleBack = useCallback(() => {
        onCancel(html)
    }, [onCancel, html])

    const openPreview = useCallback(() => {
        setPreviewOpen(true)
    }, [])

    const closePreview = useCallback(() => {
        setPreviewOpen(false)
    }, [])

    return (
        <>
            <Grid item container direction='row'>
                <Button onClick={handleInsertButton} color='primary'>
                    Insert Button
                </Button>
                <Grid item xs />
                <Button onClick={handleBack} color='primary'>
                    Back
                </Button>
                <Button onClick={openPreview} color='primary'>
                    Preview
                </Button>
            </Grid>
            <PreviewHTML html={html} open={previewOpen} handleClose={closePreview} onFinish={onFinish} />
        </>
    )
}

type RawHTMLProps = {
    html: string,
    open: boolean,
    handleClose: Callback,
    onFinish: Consumer<string>,
}
function PreviewHTML({ html, open, handleClose, onFinish }: RawHTMLProps) {
    const classes = useStyles()

    const finish = useCallback(() => {
        onFinish(html)
    }, [onFinish, html])

    return (
        <Dialog
            classes={{ paperFullScreen: classes.dialog }}
            open={open}
            disableBackdropClick
            disableEscapeKeyDown
            fullScreen
        >
            <Grid container direction='column' className={classes.mainContainer} alignItems='center'>
                <Grid item container justify='flex-end'>
                    <CloseRounded onClick={handleClose} className={classes.closeIcon} />
                </Grid>
                <Grid item className={classes.contentContainer} dangerouslySetInnerHTML={{ __html: wrapHTML(html) }} />
                <Grid item>
                    <Button color='secondary' onClick={finish}>
                        Next
                    </Button>
                </Grid>
            </Grid>
        </Dialog>
    )
}