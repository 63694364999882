import React, { useCallback, useMemo } from 'react';
import { Grid, List, Divider, ListItem, ListItemText, Button } from '@material-ui/core'
import { FamilyGroups } from '../../admin-types';
import { ChooseByGroupProps } from '../../admin-types';
import DisplayPerson from './DisplayPerson';

export default function ChooseByFamily({ families, ...props }: ChooseByGroupProps) {
    return (
        <Grid item container direction='row' spacing={2}>
            {Object.values(families).map(({ familyid, ...familyIds }) => (
                <Grid key={familyid} item xs={4}>
                    <DisplayFamily {...familyIds} {...props} />
                </Grid>
            ))}
        </Grid>
    )
}


type DisplayFamilyProps = Omit<FamilyGroups, 'familyid'> & Omit<ChooseByGroupProps, 'families'>
function DisplayFamily({ togglePerson, selected, persons, fatherid, motherid, childrenids }: DisplayFamilyProps) {
    const father = useMemo(() => fatherid ? persons[fatherid] : undefined, [persons, fatherid])
    const mother = useMemo(() => motherid ? persons[motherid] : undefined, [persons, motherid])
    const children = useMemo(() => childrenids ? childrenids.map(id => persons[id]) : [], [persons, childrenids])

    const familyName = useMemo(() => (father && father.lastName) || (mother && mother.lastName) || 'No Name', [father, mother])

    const selectAll = useCallback(() => {
        togglePerson(fatherid, true)
        togglePerson(motherid, true)
        if (childrenids)
            childrenids.forEach(id => togglePerson(id, true))
    }, [togglePerson, fatherid, motherid, childrenids])

    return (
        <List>
            <Divider />
            <ListItem>
                <Grid container direction='row' justify='space-between'>
                    <ListItemText primary={familyName} />
                    <Button onClick={selectAll} color='primary'>
                        {'Select All'}
                    </Button>
                </Grid>
            </ListItem>
            <Divider />
            {father && <>
                <ListItem>
                    <DisplayPerson {...father} togglePerson={togglePerson}
                        checked={selected[father.personid]} />
                </ListItem>
                <Divider />
            </>}
            {mother && <>
                <ListItem>
                    <DisplayPerson {...mother} togglePerson={togglePerson}
                        checked={selected[mother.personid]} />
                </ListItem>
                <Divider />
            </>}
            {children && children.map(child =>
                <React.Fragment key={child.personid}>
                    <ListItem>
                        <DisplayPerson {...child} togglePerson={togglePerson}
                            checked={selected[child.personid]} />
                    </ListItem>
                    <Divider />
                </React.Fragment>
            )}
        </List>
    )
}