import React, { useState, useEffect } from 'react'
import moment from 'moment-timezone'
import { Grid, Typography, Divider } from '@material-ui/core';
import { FormatForm } from '../../Form/FormUtils';
import useFieldUtils from '../../Form/FieldUtils';
import { MatchProps } from '../../types';
import { adminQuery } from '../../Global/ServerConnection';
import { Admin_PersonFullInfoType } from '../admin-types';

// el pip significa union de izq y der, pero gana derecha.
type Admin_PersonType_ViewOnly = Record<keyof Admin_PersonFullInfoType, string> & { familyids: string[] }

export default function ViewPersonPage({ match }: MatchProps) {
    return <ViewPerson personID={match.params.id} />
}

export function ViewPerson({ personID }: { personID: string }) {
    const [personInfo, setPersonInfo] = useState<Admin_PersonType_ViewOnly>()

    useEffect(() => {
        const processOriginalValues = (values?: Admin_PersonType_ViewOnly) => {
            if (values)
                setPersonInfo(values)
        }
        return adminQuery('getPersonInfo', processOriginalValues, `personid:"${personID}"`, 'personid,firstName,lastName,sex,dateOfBirth,nationality,preferredLanguage,email,phoneNumber,phoneCarrier,familyids,dateCreated,dateLastUpdate,active')
    }, [personID])

    if (personInfo) {
        return <AdminDisplayPerson {...personInfo} />
    } else {
        return <Typography>Unk pID</Typography>
    }
}


function AdminDisplayPerson(values: Admin_PersonType_ViewOnly) {

    const { labels } = useFieldUtils()

    return (
        <>
            <Grid item container direction='row'>
                <Typography variant='h6'>
                    {values.firstName}
                </Typography>
            </Grid>
            <Divider />
            <FormatForm>
                <Typography>
                    PersonID: {values.personid}
                </Typography>
                <Typography>
                    {`${labels.firstName}: ${values.firstName}`}
                </Typography>
                <Typography>
                    {`${labels.lastName}: ${values.lastName}`}
                </Typography>
                <Typography>
                    {`${labels.sex}: ${values.sex}`}
                </Typography>
                <Typography>
                    {`${labels.dateOfBirth}: ${moment(values.dateOfBirth).format('MMM D, YYYY')}`}
                </Typography>
                <Typography>
                    {`${labels.nationality}: ${values.nationality}`}
                </Typography>
                <Typography>
                    {`${labels.preferredLanguage}: ${values.preferredLanguage}`}
                </Typography>
                <Typography>
                    {`${labels.email}: ${values.email}`}
                </Typography>
                <Typography>
                    {`${labels.phoneNumber}: ${values.phoneNumber}`}
                </Typography>
                <Typography>
                    {`${labels.phoneCarrier}: ${values.phoneCarrier}`}
                </Typography>
                <Typography>
                    Family IDs: {values.familyids.map(familyid => { return (familyid) }).join(', ')}
                </Typography>
                <Typography>
                    DateCreated: {values.dateCreated}
                </Typography>
                <Typography>
                    DateLastUpdate: {values.dateLastUpdate}
                </Typography>
                <Typography>
                    Active: {`${values.active}`}
                </Typography>
            </FormatForm>
            <Divider />
        </>
    )
}
