import React from "react"
import { FieldType, PersonInfoType, defaultText, defaultDate, defaultOption } from "../types"
import usePersonUtils from "./FieldUtils"
import Text from "./Components/Text"
import RadioButton from "./Components/RadioButton"
import DateSelect from "./Components/DateSelect"
import Email from "./Components/Email"
import Phone from "./Components/Phone"
import Selection from "./Components/Selection"
import { JSONt } from "../types"
import moment from 'moment-timezone'
import { useTextLang } from "../Contexts/LangContext"

const personFieldTexts = {
    phoneCarrierHelper: {
        en: 'required if you wish to receive text reminders'
    }
}

export function fullPersonRules(isAdult = false, noEmail = false) {
    return {
        firstName: { type: FieldType.Text, required: true },
        lastName: { type: FieldType.Text, required: true },
        sex: { type: FieldType.RadioButton, required: true },
        dateOfBirth: { type: FieldType.Date, required: true, maxDate: isAdult ? moment().subtract(18, 'y') : moment() },
        nationality: { type: FieldType.Select, required: true },
        preferredLanguage: { type: FieldType.Select, required: true },
        email: { type: FieldType.Email, required: isAdult && !noEmail },
        phoneNumber: { type: FieldType.Phone, required: isAdult },
        phoneCarrier: { type: FieldType.Select },
    }
}


export const personDefaultValues = {
    firstName: defaultText,
    lastName: defaultText,
    sex: defaultText,
    dateOfBirth: defaultDate,
    nationality: defaultOption,
    preferredLanguage: defaultOption,
    email: defaultText,
    phoneNumber: defaultText,
    phoneCarrier: defaultOption,
}

type PersonFieldsType = {
    values: PersonInfoType & JSONt,
    setValue: (name: keyof PersonInfoType, value: PersonInfoType[keyof PersonInfoType]) => void,
    errors: Partial<Record<keyof PersonInfoType, string>> & JSONt,
    setError: (name: keyof PersonInfoType, message?: string) => void,
    spouse?: boolean,
    adult?: boolean,
    noEmail?: boolean,
}

export default function PersonFields({ values, setValue, errors, setError, spouse, adult, noEmail }: PersonFieldsType) {
    const { labels, sexOptions, carrierOptions, languageOptions, nationalityOptions, toOptionalLabel } = usePersonUtils()
    const texts = useTextLang(personFieldTexts)

    return (
        <>
            <Text field='firstName' label={labels.firstName} value={values.firstName} setValue={setValue} errorMsg={errors.firstName} autoCapitalize='words'/>
            <Text field='lastName' label={labels.lastName} value={values.lastName} setValue={setValue} errorMsg={errors.lastName} autoCapitalize='words'/>
            {!spouse &&
                <RadioButton field='sex' label={labels.sex} value={values.sex} setValue={setValue} errorMsg={errors.sex} options={sexOptions} row />
            }
            <DateSelect field='dateOfBirth' label={labels.dateOfBirth} value={values.dateOfBirth} setValue={setValue} errorMsg={errors.dateOfBirth} setErrorMsg={setError} disableFuture />
            <Selection field='nationality' label={labels.nationality} value={values.nationality} setValue={setValue} errorMsg={errors.nationality} options={nationalityOptions} />
            <Selection field='preferredLanguage' label={labels.preferredLanguage} value={values.preferredLanguage} setValue={setValue} errorMsg={errors.preferredLanguage} options={languageOptions} />
            {!noEmail && <Email field='email' label={adult ? labels.email : toOptionalLabel(labels.email)} value={values.email} setValue={setValue} errorMsg={errors.email} />}
            <Phone field='phoneNumber' label={adult ? labels.phoneNumber : toOptionalLabel(labels.phoneNumber)} value={values.phoneNumber} setValue={setValue} errorMsg={errors.phoneNumber} />
            {values.phoneNumber &&
                <Selection field='phoneCarrier' label={toOptionalLabel(labels.phoneCarrier)} value={values.phoneCarrier} setValue={setValue} errorMsg={errors.phoneCarrier} options={carrierOptions} helperText={texts.phoneCarrierHelper} />
            }
        </>
    )
}