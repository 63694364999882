import React, { useState, useCallback, useEffect } from 'react';
import { Consumer, JSONt } from '../../types'
import { Grid, Typography, Button, FormControlLabel, Switch } from '@material-ui/core'
import { useTextLang } from '../../Contexts/LangContext'
import { adminQuery } from '../../Global/ServerConnection'
import ChooseByFamily from './ChoosePersons/ChooseByFamily';
import ChooseByEvent from './ChoosePersons/ChooseByEvent';
import { PersonEmail, FamilyGroups, ChooseByGroupProps } from '../admin-types';

const chooseParticipantsTexts = {
    title: {
        en: 'Event Settings',
    },
    groupByEvents: {
        en: 'Group By Events'
    },
    pastEvents: {
        en: 'Past Events'
    },
    next: {
        en: 'Next'
    },
    back: {
        en: 'Back'
    }
}

type ChoosePersonsProps = {
    initialPersons: string[]
    onFinish: Consumer<string[]>,
    onCancel: Consumer<string[]>
}
export default function ChoosePersons({ initialPersons, onFinish, onCancel }: ChoosePersonsProps) {
    const [selected, setSelected] = useState<Record<string, boolean>>()
    const [chooseByEvents, setChooseByEvents] = useState(true)
    const [pastEvents, setPastEvents] = useState(false)
    const texts = useTextLang(chooseParticipantsTexts)
    const [persons, setPersons] = useState<JSONt<PersonEmail>>()
    const [families, setFamilies] = useState<JSONt<FamilyGroups>>()

    useEffect(() => {
        const processPersonList = (personList: PersonEmail[]) => {
            setPersons(personList.reduce((agg, { personid, ...personInfo }) => ({
                ...agg, [personid]: { ...personInfo, personid }
            }), {} as JSONt<PersonEmail>))
        }

        return adminQuery('getPersonEmailList', processPersonList, undefined, 'personid,firstName,lastName,email,status')
    }, [initialPersons])

    useEffect(() => {
        if (persons) {
            setSelected(Object.keys(persons).reduce((agg, personid) => {
                return { ...agg, [personid]: initialPersons.includes(personid) }
            }, {} as Record<string, boolean>))
        }
    }, [initialPersons, persons])

    useEffect(() => {
        const processFamiliesList = (familiesList: FamilyGroups[]) => {
            setFamilies(familiesList.reduce((agg, { familyid, ...familyInfo }) => ({
                ...agg, [familyid]: { ...familyInfo, familyid }
            }), {} as JSONt<FamilyGroups>))
        }

        return adminQuery('groupByFamily', processFamiliesList, undefined, 'familyid,fatherid,motherid,childrenids')
    }, [])

    const getSelected = useCallback(() => {
        if (selected) {
            return Object.entries(selected).reduce((agg, [personid, selected]) => {
                if (selected) {
                    return [...agg, personid]
                }
                return agg
            }, [] as string[])
        } else {
            return []
        }
    }, [selected])

    const handleNext = useCallback(() => {
        onFinish(getSelected())
    }, [onFinish, getSelected])

    const handleBack = useCallback(() => {
        onCancel(getSelected())
    }, [onCancel, getSelected])

    const handleGroupBySwitch = useCallback((event: any, value: boolean) => {
        setChooseByEvents(value)
    }, [])

    const handlePastEventsSwitch = useCallback((event: any, value: boolean) => {
        setPastEvents(value)
    }, [])

    const togglePerson = useCallback((personid: string | undefined, value: boolean) => {
        if (persons && personid && persons[personid].status === 'OK')
            setSelected(selected => ({ ...selected, [personid]: value }))
    }, [persons])

    const props = { chooseByEvents, pastEvents, selected, families, persons, togglePerson }
    return (
        <Grid container direction='column' spacing={1}>
            <Grid item>
                <Typography variant='h6' color='textSecondary'>
                    {texts.title}
                </Typography>
            </Grid>
            <Grid item container direction='row'>
                <FormControlLabel
                    control={<Switch checked={chooseByEvents} onChange={handleGroupBySwitch} color='primary' />}
                    label={texts.groupByEvents}
                />
                {chooseByEvents && <FormControlLabel
                    control={<Switch checked={pastEvents} onChange={handlePastEventsSwitch} color='primary' />}
                    label={texts.pastEvents}
                />}
                <Grid item xs />
                <Button onClick={handleBack} color='primary'>
                    {texts.back}
                </Button>
                <Button onClick={handleNext} color='primary'>
                    {texts.next}
                </Button>
            </Grid>
            <Grid item>
                <RenderGroup {...props} />
            </Grid>
        </Grid>
    )
}

type RenderGroupProps = Partial<ChooseByGroupProps> & {chooseByEvents: boolean, pastEvents: boolean}
function RenderGroup({ chooseByEvents, pastEvents, selected, families, persons, togglePerson }: RenderGroupProps) {
    if (selected === undefined || families === undefined || persons === undefined || togglePerson === undefined) {
        return null
    } else {
        const props = {selected, families, persons, togglePerson}
        return <>
            {chooseByEvents && <ChooseByEvent pastEvents={pastEvents} {...props} />}
            {!chooseByEvents && <ChooseByFamily {...props} />}
        </>
    }
}