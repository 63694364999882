import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Callback, ImageAlign } from '../types';
import { transformImageUri } from './GlobalItems';

const useStyles = makeStyles(theme => ({
    wrapper: {
        position: 'relative',
        overflow: 'hidden',
        width: '100%',
    },
    square: {
        paddingBottom: '100%', // 1:1
        borderRadius: theme.spacing(1 / 2),
    },
    rect: {
        paddingBottom: '50%', // 2:1
    },
    container: {
        position: 'absolute',
        width: '100%',
        height: '100%',
    },
    image: {
        position: 'absolute',
    },
    width: {
        height: 'auto',
        width: '100%',
    },
    height: {
        height: '100%',
        width: 'auto'
    },
    right: {
        right: 0,
    },
    left: {
        left: 0,
    },
    center: {

    }
}))

type ImageProps = {
    rect?: boolean,
    fullHeight?: boolean,
    onClick?: Callback,
    src?: string,
    align?: ImageAlign,
    alt?: string
}
export default function Image({ rect, fullHeight, onClick, src, align, alt }: ImageProps) {
    const classes = useStyles()
    return (
        <div onClick={onClick}
            className={`${classes.wrapper} ${classes[(rect ? 'rect' : 'square')]}`}>
            <div className={classes.container}>
                {src &&
                    <img src={transformImageUri(src)} alt={alt || ''} className={`${classes.image} ${classes[fullHeight ? 'height' : 'width']} ${classes[align || 'center']}`} />}
            </div>
        </div>
    )
}