import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Button, Card, CardContent, Typography, CardActions, Grid, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles'

import Image from '../Global/Image'
import { useLang, useTextLang } from '../Contexts/LangContext';
import { query } from '../Global/ServerConnection';
import { routes, useRouter } from '../Contexts/RouterContext';
import { Event } from '../server-types';
import { shortFormatDateRange, useBasicTexts } from '../Global/GlobalItems';
import { Callback } from '../types';
import { useAuth } from '../Contexts/AuthContext';

const eventCardTexts = {
    sessionCountText: {
        en: (sessionCount: number) => {
            switch (sessionCount) {
                case 0: return 'No more sessions'
                case 1: return 'Date:'
                default: return `${sessionCount} sessions total. Next session:`
            }
        }
    },
    moreInfo: {
        en: 'More Info'
    }
}

const useStyles = makeStyles(theme => ({
    actions: {
        display: 'flex',
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
        color: theme.palette.text.secondary
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    oneLine: {
        height: theme.spacing(3)
    },
    twoLines: {
        height: theme.spacing(6)
    },
    description: {
        [theme.breakpoints.up('sm')]: {
            height: theme.spacing(9),
        },
    },
    invisible: {
        display: 'none'
    },
    visible: {
        display: 'initial'
    }
}))

type EventCardProps = {
    eventInfoID: string,
    selectedFilters: string[],
    openEventNotAllowed: Callback
}
export default function EventCard({ eventInfoID, selectedFilters, openEventNotAllowed }: EventCardProps) {
    const [event, setEvent] = useState<Partial<Event>>({})
    const { language } = useLang()
    const { navigateTo } = useRouter()
    const [visible, setVisible] = useState(false)
    const { isAuth, registrationStep } = useAuth()
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const processEvent = (event: Event) => {
            setEvent(event)
            setLoading(false)
        }

        return query('eventInfo', processEvent, `eventInfoID:"${eventInfoID}",language:"${language}"`, 'eventid,tags,title,subtitle,sessionCount,nextSessionStart,nextSessionEnd,status,imageRect,approvedFamiliesOnly')
    }, [eventInfoID, language])

    useEffect(() => {
        if (event.tags) {
            const eventTags = event.tags
            const visible = selectedFilters.reduce((visible, filter) => visible && eventTags.includes(filter), true)
            setVisible(visible)
        }
    }, [selectedFilters, event.tags])

    const notApproved = useMemo(() => isAuth && event.approvedFamiliesOnly && registrationStep === 'DONE', [isAuth, event.approvedFamiliesOnly, registrationStep])

    const goToEvent = useCallback(() => {
        navigateTo(routes.viewEvent(eventInfoID), false, {type: 'info'})
    }, [navigateTo, eventInfoID])

    const classes = useStyles()

    return (
        <Grid item xs={12} md={6} className={visible ? classes.visible : classes.invisible}>
            <Card>
                {loading && <CircularProgress />}
                {!loading &&
                    <>
                        <Header title={event.title} subtitle={event.subtitle} />
                        <Image rect src={event.imageRect} alt={event.title} onClick={goToEvent} />
                        <NextSession dateStart={event.nextSessionStart} dateEnd={event.nextSessionEnd} sessionCount={event.sessionCount} />
                        <Actions eventid={event.eventid} eventInfoID={eventInfoID} notApproved={notApproved} openEventNotAllowed={openEventNotAllowed} />
                    </>}
            </Card>
        </Grid>
    );
}

type HeaderProps = {
    title?: string,
    subtitle?: string,
}
function Header({ title, subtitle }: HeaderProps) {
    return (
        <CardContent>
            <Typography variant='h5' gutterBottom noWrap>
                {title || ''}
            </Typography>
            <Typography gutterBottom noWrap>
                {subtitle || ''}
            </Typography>
        </CardContent>
    )
}

type NextSessionProps = {
    dateStart?: string,
    dateEnd?: string,
    sessionCount?: number
}
function NextSession({ dateStart, dateEnd, sessionCount }: NextSessionProps) {
    const classes = useStyles()
    const texts = useTextLang(eventCardTexts)
    const dates = useMemo(() => shortFormatDateRange(dateStart, dateEnd), [dateStart, dateEnd])
    return (
        <CardContent>
            <Typography gutterBottom noWrap className={classes.oneLine}>
                {texts.sessionCountText(sessionCount || 0)}
            </Typography>
            {dates &&
                <Typography variant='body1' noWrap className={classes.oneLine}>
                    {dates}
                </Typography>
            }
        </CardContent>
    )
}

type ActionsProps = {
    eventid?: string,
    eventInfoID: string,
    notApproved?: boolean,
    openEventNotAllowed: Callback
}
function Actions({ eventid, eventInfoID, notApproved, openEventNotAllowed }: ActionsProps) {
    const classes = useStyles()
    const texts = useTextLang(eventCardTexts)
    const basicTexts = useBasicTexts()
    const { navigateTo } = useRouter()

    const goToEvent = useCallback(() => {
        navigateTo(routes.viewEvent(eventInfoID), false, {type: 'info'})
    }, [navigateTo, eventInfoID])

    const register = useCallback(() => {
        if (eventid) {
            navigateTo(routes.addEvent(eventid))
        } else {
            navigateTo(routes.chooseSession(eventInfoID))
        }
    }, [navigateTo, eventid, eventInfoID])

    return (
        <CardActions className={classes.actions} disableSpacing>
            <Grid container justify='space-between'>
                {notApproved &&
                    <Button color='primary' disableRipple onClick={openEventNotAllowed}>
                        {basicTexts.register}
                    </Button>
                }
                {!notApproved && 
                    <Button color='primary' disableRipple onClick={register}>
                        {basicTexts.register}
                    </Button>
                }
                <Button color='primary' disableRipple onClick={goToEvent}>
                    {texts.moreInfo}
                </Button>
            </Grid>
        </CardActions>
    )
}