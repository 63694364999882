import React, { useState, useEffect, useCallback } from 'react';
import { Grid, Typography, Divider, List, ListItem, ListItemText, Button } from '@material-ui/core';
import { useTextLang } from '../../Contexts/LangContext'
import { Consumer, OptionValue, defaultOption, isOptionValue } from '../../types';
import Selection from '../../Form/Components/Selection';
import { adminQuery, adminMutation } from '../../Global/ServerConnection';
import { Person } from '../../server-types';

const addTeachersTexts = {
    title: {
        en: 'Add Teachers',
    },
    selectPerson: {
        en: 'Select a person'
    },
    addTeacher: {
        en: 'Add Teacher'
    }
}

export default function AddTeachers() {
    const [teachers, setTeachers] = useState<Person[]>([])
    const texts = useTextLang(addTeachersTexts)

    const updateTeachers = useCallback(() => {
        return adminQuery('getTeacherList', setTeachers, undefined, 'name,personid')
    }, [])

    useEffect(() => {
        updateTeachers()
    }, [updateTeachers])

    const addTeacher = useCallback((personid: string) => {
        const processAdd = (eventid: string) => {
            updateTeachers()
        }
        adminMutation('addTeacher', processAdd, `personid:"${personid}"`)
    }, [updateTeachers])

    return (
        <Grid container direction='column' spacing={1}>
            <Grid item>
                <Typography variant='h6' color='textSecondary'>
                    {texts.title}
                </Typography>
            </Grid>
            <FindPerson addTeacher={addTeacher} />
            <Grid container direction='row' spacing={3}>
                <Grid item xs>
                    <List>
                        <Divider />
                        {teachers.map(({ name }, index) => (index % 2 === 0 && <>
                            <ListItem>
                                <ListItemText primary={name} />
                            </ListItem>
                            <Divider />
                        </>))}
                    </List>
                </Grid>
                <Grid item xs>
                    <List>
                        <Divider />
                        {teachers.map(({ name }, index) => (index % 2 === 1 && <>
                            <ListItem>
                                <ListItemText primary={name} />
                            </ListItem>
                            <Divider />
                        </>))}
                    </List>
                </Grid>
            </Grid>
        </Grid>
    )
}

type FindEventIDProps = {
    addTeacher: Consumer<string>
}
function FindPerson({ addTeacher }: FindEventIDProps) {
    const [person, setPerson] = useState(defaultOption)
    const [personList, setPersonList] = useState<OptionValue[]>([])
    const [loading, setLoading] = useState(true)
    const texts = useTextLang(addTeachersTexts)

    useEffect(() => {
        const processPersonList = (personList: Person[]) => {
            // console.log(eventList)
            setPersonList(personList.map(({ personid, name }) => {
                return { value: personid, label: name }
            }))
            setLoading(false)
        }
        return adminQuery('getPersonList', processPersonList, undefined, 'personid,name')
    }, [])

    const onSelectionChange = useCallback((field: string, person: OptionValue | OptionValue[]) => {
        if (isOptionValue(person))
            setPerson(person)
    }, [])

    const handleAddTeacher = useCallback(() => {
        if (person) {
            addTeacher(person.value)
        }
    }, [person, addTeacher])

    return (
        <>
            <Grid item>
                <Selection field='eventID' label={texts.selectPerson} value={person} setValue={onSelectionChange} options={personList} isLoading={loading} isClearable={false} />
            </Grid>
            <Grid item container justify='flex-end'>
                <Button color='primary' onClick={handleAddTeacher}>
                    {texts.addTeacher}
                </Button>
            </Grid>
        </>
    )
}