import React, { createContext, useContext, useCallback, ReactNode } from "react";
import { withRouter } from 'react-router-dom'
import { History } from 'history'
import { JSONt, Callback } from "../types";

const routes = {
    viewEvent: (id: string) => `/view-event/${id}`,
    eventInfo: (id: string) => `/event-info/${id}`,
    chooseSession: (id: string) => `/choose-session/${id}`,
    addEvent: (id: string) => `/add-event/${id}`,
    pendingEvents: '/pending-events',
    viewInvoice: (id: string) => `/view-invoice/${id}`,
    myEvents: '/my-events',
    myVolunteering: '/my-volunteering',
    familySettings: '/settings/family-settings',
    accountSettings: '/settings/account-settings',
    login: '/login',
    calendar: '/calendar',
    forgotPassword: '/forgot-password',
    createAccount: '/create-account/account-info',
    registerUser: '/create-account/personal-info',
    registerSpouse: '/create-account/spouse-info',
    registerChildren: '/create-account/children-info',
    events: '/home/events',
    fep: '/home/fep',
    about: '/home/about',
    termsAndConditions: '/terms-and-conditions',
    waiverAndRelease: '/waiver-and-release',
    error: '/error',
    home: '/',
    admin: '/admin',
}

const paths = {
    viewEvent: '/view-event/:id',
    eventInfo: '/event-info/:id',
    chooseSession: '/choose-session/:id',
    addEvent: '/add-event/:id',
    pendingEvents: '/pending-events',
    viewInvoice: '/view-invoice/:id',
    myEvents: '/my-events',
    myVolunteering: '/my-volunteering',
    settings: '/settings/:id',
    login: '/login',
    calendar: '/calendar',
    forgotPassword: '/forgot-password',
    createAccount: '/create-account/:id',
    termsAndConditions: '/terms-and-conditions',
    waiverAndRelease: '/waiver-and-release',
    home: '/home/:id',
    error: '/error',
    admin: '/admin',
}

const routeIDs = {
    createAccount: 'account-info',
    registerUser: 'personal-info',
    registerSpouse: 'spouse-info',
    registerChildren: 'children-info',
    events: 'events',
    fep: 'fep',
    about: 'about',
    familySettings: 'family-settings',
    accountSettings: 'account-settings',
}

type RouterContextType = {
    navigateTo: (path: String, replace?: boolean, state?: JSONt | null) => void,
    goBack: Callback,
    goForward: Callback,
    routeParams: any,
    pathname: string
}
const RouterContext = createContext<RouterContextType>({
    navigateTo: () => { },
    goBack: () => { },
    goForward: () => { },
    routeParams: null,
    pathname: ''
})

const RouteProvider = withRouter(({ history, children }: { history: History, children?: ReactNode }) => {
    const navigateTo = useCallback((pathname = '/', replace = false, state = null) => {
        if (replace) {
            history.replace({ pathname, state })
        } else {
            history.push({ pathname, state })
        }
    }, [history])

    const goBack = history.goBack
    const goForward = history.goForward
    const routeParams = history.location.state || {}
    const pathname = history.location.pathname

    return (
        <RouterContext.Provider value={{ navigateTo, goBack, goForward, routeParams, pathname }}>
            {children}
        </RouterContext.Provider>
    )
})

const useRouter = () => useContext(RouterContext)

export { RouteProvider, useRouter, routes, paths, routeIDs }