import React, { useEffect } from 'react';
import { ThemeProvider, makeStyles } from '@material-ui/styles';
import { MuiThemeProvider, createMuiTheme, CssBaseline } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment'
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom'
import { RouteProvider, useRouter, paths, routes } from './Contexts/RouterContext';
import { AuthProvider } from './Contexts/AuthContext';
import { LangProvider } from './Contexts/LangContext';
import ProtectedRoute from './Auth/ProtectedRoute';
import ChooseEventSession from './Register/ChooseEventSession';
import AddEvent from './Register/AddEvent';
import { ViewMyVolunteering, ViewMyEvents } from './Settings/MyEvents';
import Settings from './Settings/Settings';
import ViewEvent from './Events/ViewEvent';
import Login from './Auth/Login';
import CreateAccount from './CreateAccount/CreateAccount';
import Main from './Main/Main';
import ErrorPage from './Global/ErrorPage';
import Payment from './Register/Payment';
import Invoice from './Register/Invoice';
import CapturePayment from './OutsideEndpoints/CapturePayment';
import VerifyEmail from './OutsideEndpoints/VerifyEmail';
import ForgotPassword from './Settings/ForgotPassword';
import TermsAndConditions from './Register/Payment/TermsAndConditions';
import WaiverAndRelease from './Register/Payment/WaiverAndRelease';
import EventInfo from './Events/EventInfo';
import AdminRoute from './Auth/AdminRoute';
import Admin from './Admin/Admin';
import ChangePassword from './OutsideEndpoints/ChangePassword';
import moment from 'moment-timezone'
import ViewCalendar from './Calendar/ViewCalendar';


const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#2E7599',
        },
        secondary: {
            main: '#ffffff',
        },
        text: {
            primary: '#000000',
            secondary: '#9E9E9E',
        }
    },
    overrides: {
        MuiButton: {
            root: {
                borderRadius: 20
            }
        },
        MuiCard: {
            root: {
                border: `1px solid #cccccc`,
                boxShadow: 'none',
                borderRadius: '8px'
            }
        },
        MuiTableCell: {
            root: {
                border: '1px solid black',
                borderBottom: '1px solid black',
            }
        },
        MuiDialog: {
            root: {
                WebkitUserSelect: 'none',
                MozUserSelect: 'text',
                MsUserSelect: 'none',
                userSelect: 'none',
            }
        }
    }
})

const useStyles = makeStyles(theme => ({
    mainPage: {
        minHeight: '100vh',
        width: '100%',
        position: 'absolute',
        backgroundColor: theme.palette.common.white,
        WebkitUserSelect: 'none',
        MozUserSelect: 'text',
        MsUserSelect: 'none',
        userSelect: 'none',
    }
}))

export default function App() {
    moment.tz.setDefault('US/Central')
    return (
        <ThemeProvider theme={theme}>
            <MuiThemeProvider theme={theme}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <BrowserRouter>
                        <RouteProvider>
                            <AuthProvider>
                                <LangProvider>
                                    <CssBaseline />
                                    <Router />
                                </LangProvider>
                            </AuthProvider>
                        </RouteProvider>
                    </BrowserRouter>
                </MuiPickersUtilsProvider>
            </MuiThemeProvider>
        </ThemeProvider>
    )
} //TODO: remove MuiThemeProvider

function ScrollToTop() {
    const { pathname } = useRouter()
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}

function Router() {
    const classes = useStyles()
    return (
        <div className={classes.mainPage}>
            <ScrollToTop />
            <Switch>
                <AdminRoute path={paths.admin} component={Admin} />
                <ProtectedRoute exact path={paths.chooseSession} component={ChooseEventSession} />
                <ProtectedRoute exact path={paths.addEvent} component={AddEvent} />
                <ProtectedRoute exact path={paths.pendingEvents} component={Payment} />
                <ProtectedRoute exact path={paths.viewInvoice} component={Invoice} />
                <ProtectedRoute path={paths.myEvents} component={ViewMyEvents} />
                <ProtectedRoute path={paths.myVolunteering} component={ViewMyVolunteering} />
                <ProtectedRoute path={paths.settings} component={Settings} />
                <Route exact path={'/capture-payment/:id'} component={CapturePayment} />
                <Route exact path={'/change-password/:token'} component={ChangePassword} />
                <Route exact path={'/verify-email/:token'} component={VerifyEmail} />
                <Route exact path={paths.viewEvent} component={ViewEvent} />
                <Route exact path={paths.eventInfo} component={EventInfo} />
                <Route exact path={paths.login} component={Login} />
                <Route exact path={paths.calendar} component={ViewCalendar} />
                <Route exact path={paths.forgotPassword} component={ForgotPassword} />
                <Route exact path={paths.termsAndConditions} component={TermsAndConditions} />
                <Route exact path={paths.waiverAndRelease} component={WaiverAndRelease} />
                <Route exact path={paths.createAccount} component={CreateAccount} />
                <Route exact path={paths.home} component={Main} />
                <Route exact path={paths.error} component={ErrorPage} />
                <Redirect from='*' to={routes.events} />
            </Switch>
        </div>
    )
}