import React, { useState, useEffect, useCallback } from 'react'
import moment from 'moment-timezone'
import { useTextLang } from '../Contexts/LangContext';
import { Grid, Typography, Divider } from '@material-ui/core';
import { AddBox, EditOutlined } from '@material-ui/icons';
import { useRouter, routes } from '../Contexts/RouterContext';
import useForm, { FormatForm } from '../Form/FormUtils';
import FamilyInfoFields, { familyInfoRules } from '../Form/FamilyInfoFields';
import FormNavigation from '../Form/FormNavigation';
import { useBasicTexts } from '../Global/GlobalItems';
import { Person, FamilyPerson } from '../server-types';
import PersonFields, { personDefaultValues, fullPersonRules } from '../Form/PersonFields';
import useFieldUtils from '../Form/FieldUtils';
import { Clickable, InputNavigationProps, defaultOption, FamilyInfoType, PersonInfoType, defaultDate } from '../types';
import { useAuth } from '../Contexts/AuthContext';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import LoadingPage from '../Global/LoadingPage';
import { NavigationDialog } from '../Global/Dialogs';

const familySettingsTexts = {
    newChild: {
        en: 'New Child'
    },
    editFamilyInfo: {
        en: 'Edit Family Info'
    },
    addChild: {
        en: 'Add child'
    },
    unsavedChangesTitle: {
        en: 'Unsaved Changes'
    },
    unsavedChangesContents: {
        en: 'You have unsaved changes. Are you sure you want to continue?'
    }
}

export default function FamilySettings() {
    const [edit, setEdit] = useState<string>()
    const [nextEdit, setNextEdit] = useState<string>()
    const [unsavedChanges, setUnsavedChanges] = useState(false)
    const [persons, setPersons] = useState<FamilyPerson[]>([])
    const [familyInfo, setFamilyInfo] = useState<Record<keyof FamilyInfoType, string>>()
    const texts = useTextLang(familySettingsTexts)
    const { authQuery } = useAuth()
    const [loading, setLoading] = useState(true)

    const updateFamilyInfo = useCallback(() => {
        window.scrollTo(0, 0)
        const processInfo = (info: { persons: FamilyPerson[] } & Record<keyof FamilyInfoType, string>) => {
            if (info) {
                const { persons, ...familyInfo } = info
                setPersons(persons)
                setFamilyInfo(familyInfo)
                setLoading(false)
            }
        }
        authQuery('getFamilyInfo', processInfo, undefined, 'persons{personid,name,role},streetAddress,city,state,zipCode,country,maritalStatus,dateOfMarriage')
    }, [authQuery])

    useEffect(() => {
        updateFamilyInfo()
    }, [updateFamilyInfo])

    const checkEdit = useCallback((type) => {
        if (edit !== undefined && unsavedChanges) {
            setNextEdit(type)
        } else {
            setEdit(type)
        }
    }, [edit, unsavedChanges])

    const editPerson = useCallback((personid: string) => () => {
        checkEdit(personid)
    }, [checkEdit])

    const editFamilyInfo = useCallback(() => {
        checkEdit('family info')
    }, [checkEdit])

    const addChild = useCallback(() => {
        checkEdit('new child')
    }, [checkEdit])

    const cancelEdit = useCallback(() => {
        setEdit(undefined)
        setNextEdit(undefined)
        setUnsavedChanges(false)
    }, [])

    const discardUnsavedEdit = useCallback(() => {
        setEdit(nextEdit)
        setNextEdit(undefined)
        setUnsavedChanges(false)
    }, [nextEdit])

    const continueUnsavedEdit = useCallback(() => {
        setNextEdit(undefined)
    }, [])

    const finishEdit = useCallback(() => {
        cancelEdit()
        updateFamilyInfo()
    }, [cancelEdit, updateFamilyInfo])

    /*
    const hasEdited = useCallback(() => {
        setUnsavedChanges(true)
    }, [])
    */

    return (
        <>
            {edit === 'family info' && familyInfo
                ? <FamilyInfo
                    onFinish={finishEdit}
                    onCancel={cancelEdit}
                    familyInfo={familyInfo}
                />
                : <PersonName
                    name={texts.editFamilyInfo}
                    onClick={editFamilyInfo}
                />}
            {persons.map(({ personid, name }) => {
                if (edit === personid) {
                    return (
                        <PersonInfo
                            onFinish={finishEdit}
                            onCancel={cancelEdit}
                            name={name}
                            personid={personid}
                            key={personid}
                        />
                    )
                } else {
                    return (
                        <PersonName
                            name={name}
                            key={personid}
                            onClick={editPerson(personid)}
                        />
                    )
                }
            })}
            {edit === 'new child' &&
                <ChildInfo
                    onFinish={finishEdit}
                    onCancel={cancelEdit}
                />}
            {edit === undefined &&
                <AddChild onClick={addChild} />}
            <LoadingPage loading={loading} />
            <NavigationDialog open={nextEdit !== undefined} onCancel={continueUnsavedEdit} onContinue={discardUnsavedEdit} title={texts.unsavedChangesTitle} contents={texts.unsavedChangesContents} />
        </>
    )
}

type FamilyInfoProps = InputNavigationProps & {
    familyInfo: Record<keyof FamilyInfoType, string>
}
function FamilyInfo({ onFinish, onCancel, familyInfo }: FamilyInfoProps) {
    const [loading, setLoading] = useState(false)
    const { navigateTo } = useRouter()
    const basicTexts = useBasicTexts()
    const texts = useTextLang(familySettingsTexts)
    const { maritalStatusOptions, stateOptions, nationalityOptions } = useFieldUtils()
    const { authMutation } = useAuth()

    const finish = useCallback((values: Record<keyof FamilyInfoType, string>) => {
        setLoading(true)
        const processMutation = (success: boolean) => {
            if (success) {
                onFinish()
            } else {
                navigateTo(routes.error)
            }
        }
        authMutation('updateFamilyInfo', processMutation, `streetAddress:"${values.streetAddress.trim()}",city:"${values.city.trim()}",state:"${values.state}",zipCode:"${values.zipCode}",country:"${values.country}",maritalStatus:${values.maritalStatus}${values.maritalStatus === 'MARRIED' ? `,dateOfMarriage:"${values.dateOfMarriage}"` : ''}`)
    }, [navigateTo, onFinish, authMutation])

    const { values, setValue, errors, setError, handleSubmit } = useForm(familyInfoRules, finish, {
        maritalStatus: maritalStatusOptions.find(val => val.value === familyInfo.maritalStatus) || defaultOption,
        dateOfMarriage: (familyInfo.dateOfMarriage && moment(familyInfo.dateOfMarriage)) || defaultDate,
        streetAddress: familyInfo.streetAddress,
        city: familyInfo.city,
        state: stateOptions.find(val => val.value === familyInfo.state) || defaultOption,
        zipCode: familyInfo.zipCode,
        country: nationalityOptions.find(val => val.value === familyInfo.country) || defaultOption,
    })

    return (
        <>
            <Grid item container direction='row'>
                <Typography variant='h6'>
                    {texts.editFamilyInfo}
                </Typography>
            </Grid>
            <Divider />
            <FormatForm>
                <FamilyInfoFields values={values} setValue={setValue} errors={errors} setError={setError} />
            </FormatForm>
            <FormNavigation
                onSubmit={handleSubmit}
                submitText={basicTexts.save}
                onCancel={onCancel}
                cancelText={basicTexts.cancel}
                loading={loading}
                smallSpacing
            />
            <Divider />
        </>
    )
}

type PersonNameProps = Clickable & { name: string }
function PersonName({ name, onClick }: PersonNameProps) {
    return (
        <>
            <Grid item container justify='space-between' onClick={onClick}>
                <Typography variant='h6'>
                    {name}
                </Typography>
                <EditOutlined />
            </Grid>
            <Divider />
        </>
    )
}

type PersonInfoProps = InputNavigationProps & Person
function PersonInfo({ personid, name, ...rest }: PersonInfoProps) {
    const [personInfo, setPersonInfo] = useState<Record<keyof PersonInfoType, string>>()
    const { authQuery } = useAuth()
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const processOriginalValues = (values?: Record<keyof PersonInfoType, string>) => {
            if (values)
                setPersonInfo(values)
            setLoading(false)
        }
        return authQuery('getPersonInfo', processOriginalValues, `personid:"${personid}"`, 'firstName,lastName,sex,dateOfBirth,nationality,preferredLanguage,email,phoneNumber,phoneCarrier')
    }, [personid, authQuery])


    if (personInfo) {
        return <DisplayPerson personInfo={personInfo} personid={personid} name={name} {...rest} />
    } else {
        return (
            <>
                <PersonName name={name} onClick={() => { }} />
                <LoadingPage loading={loading} />
            </>
        )
    }
}

type DisplayPersonProps = {
    personInfo: Record<keyof PersonInfoType, string>
} & InputNavigationProps & Person
function DisplayPerson({ onFinish, onCancel, name, personid, personInfo }: DisplayPersonProps) {
    const [loading, setLoading] = useState(false)
    const { navigateTo } = useRouter()
    const basicTexts = useBasicTexts()
    const { carrierOptions, languageOptions, nationalityOptions } = useFieldUtils()
    const { authMutation } = useAuth()

    const finish = useCallback((values: Record<keyof PersonInfoType, string>) => {
        setLoading(true)
        const processMutation = (success: boolean) => {
            if (success) {
                onFinish()
            } else {
                navigateTo(routes.error)
            }
        }
        authMutation('updatePerson', processMutation, `personid:"${personid}",firstName:"${values.firstName.trim()}",lastName:"${values.lastName.trim()}",sex:${values.sex},dateOfBirth:"${values.dateOfBirth}",nationality:"${values.nationality}",preferredLanguage:"${values.preferredLanguage}",email:"${values.email}",phoneNumber:"${values.phoneNumber}",phoneCarrier:"${values.phoneCarrier}"`)
    }, [navigateTo, onFinish, personid, authMutation])

    const { values, setValue, errors, setError, handleSubmit } = useForm(fullPersonRules(), finish, {
        firstName: personInfo.firstName,
        lastName: personInfo.lastName,
        sex: personInfo.sex,
        dateOfBirth: moment(personInfo.dateOfBirth) as MaterialUiPickersDate,
        nationality: nationalityOptions.find(val => val.value === personInfo.nationality) || defaultOption,
        preferredLanguage: languageOptions.find(val => val.value === personInfo.preferredLanguage) || defaultOption,
        email: personInfo.email,
        phoneNumber: personInfo.phoneNumber,
        phoneCarrier: carrierOptions.find(val => val.value === personInfo.phoneCarrier) || defaultOption
    })

    return (
        <>
            <Grid item container direction='row'>
                <Typography variant='h6'>
                    {name}
                </Typography>
            </Grid>
            <Divider />
            <FormatForm>
                <PersonFields values={values} setValue={setValue} errors={errors} setError={setError} />
            </FormatForm>
            <FormNavigation
                onSubmit={handleSubmit}
                submitText={basicTexts.save}
                onCancel={onCancel}
                cancelText={basicTexts.cancel}
                loading={loading}
                smallSpacing
            />
            <Divider />
        </>
    )
}


function ChildInfo({ onFinish, onCancel }: InputNavigationProps) {
    const [loading, setLoading] = useState(false)
    const { navigateTo } = useRouter()
    const texts = useTextLang(familySettingsTexts)
    const basicTexts = useBasicTexts()
    const { authMutation } = useAuth()

    const finish = useCallback((values: Record<keyof PersonInfoType, string>) => {
        setLoading(true)
        const processMutation = (success: boolean) => {
            if (success) {
                onFinish()
            } else {
                navigateTo(routes.error)
            }
        }
        authMutation('registerChild', processMutation, `firstName:"${values.firstName.trim()}",lastName:"${values.lastName.trim()}",sex:${values.sex},dateOfBirth:"${values.dateOfBirth}",nationality:"${values.nationality}",preferredLanguage:"${values.preferredLanguage}",email:"${values.email || ''}",phoneNumber:"${values.phoneNumber || ''}",phoneCarrier:"${values.phoneCarrier || ''}"`)
    }, [navigateTo, onFinish, authMutation])

    const { values, setValue, errors, setError, handleSubmit } = useForm(fullPersonRules(), finish, personDefaultValues)


    return (
        <>
            <Grid item container direction='row'>
                <Typography variant='h6'>
                    {texts.newChild}
                </Typography>
            </Grid>
            <Divider />
            <FormatForm>
                <PersonFields values={values} setValue={setValue} errors={errors} setError={setError} />
            </FormatForm>
            <FormNavigation
                onSubmit={handleSubmit}
                submitText={basicTexts.done}
                onCancel={onCancel}
                cancelText={basicTexts.cancel}
                loading={loading}
            />
        </>
    )
}

function AddChild({ onClick }: Clickable) {
    const texts = useTextLang(familySettingsTexts)

    return (
        <>
            <Grid item container direction='row' spacing={1} wrap='nowrap' onClick={onClick}>
                <Grid item xs container alignItems='center'>
                    <AddBox color='disabled' />
                </Grid>
                <Grid item container>
                    <Typography variant='h6' color='textSecondary'>
                        {texts.addChild}
                    </Typography>
                </Grid>
            </Grid>
        </>
    )
}