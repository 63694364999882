import React, { useState, useEffect } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Card, Divider, Button, Theme } from '@material-ui/core';
import { useRouter } from '../../Contexts/RouterContext';
import { useBasicTexts, transformImageUri, getDoc } from '../../Global/GlobalItems';
import { makeStyles, useTheme } from '@material-ui/styles';
import FullPageFormat from '../../Global/FullPageFormat';
import { DesktopNavigation, MobileNavigation } from '../../Global/BottomNavigation';
import Markdown from 'react-markdown'
import LoadingPage from '../../Global/LoadingPage';


const useStyles = makeStyles(theme => ({
    contentCard: {
        margin: theme.spacing(5),
    },
    navigationCard: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
    },
    markdownContainer: {
        padding: theme.spacing(4)
    },
}))

export default function TermsAndConditions() {
    const { goBack } = useRouter()
    const texts = useBasicTexts()

    const classes = useStyles()
    const theme = useTheme<Theme>()
    const matches = useMediaQuery(theme.breakpoints.up('sm'))
    const [loading, setLoading] = useState(true)

    const [source, setSource] = useState<string>()
    useEffect(() => {
        const processSource = (source?: string) => {
            setSource(source)
            setLoading(false)
        }

        return getDoc('TandC.md', processSource)
    }, [])

    return (
        <FullPageFormat size='wide'>
            {matches
                ? <Card className={classes.contentCard}>
                    <Markdown source={source} escapeHtml={false} transformImageUri={transformImageUri} className={classes.markdownContainer} />
                    <Divider variant='middle' />
                    <DesktopNavigation>
                        <Button onClick={goBack} disableRipple color='primary'>
                            {texts.back}
                        </Button>
                    </DesktopNavigation>
                </Card>
                : <>
                    <Markdown source={source} escapeHtml={false} transformImageUri={transformImageUri} className={classes.markdownContainer} />
                    <MobileNavigation>
                        <Button onClick={goBack} disableRipple color='primary'>
                            {texts.back}
                        </Button>
                    </MobileNavigation>
                </>
            }
            <LoadingPage loading={loading} />
        </FullPageFormat >
    )
}