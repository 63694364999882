import React, { useCallback } from 'react'

import { FormControlLabel, Checkbox } from '@material-ui/core';
import { FormField } from '../../types';

type CheckBoxesType<FieldName> = FormField<FieldName, boolean> & {disabled?: boolean}
export default function SingleCheckBox<FieldName>({ field, value, setValue, label, ...props }: CheckBoxesType<FieldName>) {
    const handleChange = useCallback((event: any, checked: boolean) => {
        // console.log(field, checked)
        setValue(field, checked)
    }, [field, setValue])

    return (
        <FormControlLabel
            control={
                <Checkbox
                    checked={value || false}
                    onChange={handleChange}
                    value={value}
                    color='primary'
                    
                    {...props}
                />
            }
            label={label}
        />
    )
}