import React, { useEffect, useState, useCallback } from 'react'
import { Grid, Typography, Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles'

import { useTextLang } from '../Contexts/LangContext';
import { MatchProps } from '../types';
import { mutation } from '../Global/ServerConnection';
import { DoneRounded, CloseRounded } from '@material-ui/icons';

const mainTexts = {
    successText: {
        en: 'Payment successful!',
        es: 'El pago se proceso con exito'
    },
    failureText: {
        en: 'There has been a problem, the payment was not completed.',
        es: 'Hubo algun problema.  El pago no se realizo.'
    },
    continueButton: {
        en: 'Click to Continue',
        es: 'Presione para continuar'
    },
    closeTab: {
        en: 'You can close this tab and return to view your events.',
        es: 'Puede cerrar esta pestaña y regresar a ver sus eventos.',
    }
}

const useStyles = makeStyles(theme => ({
    mainContainer: {
        height: '100vh',
        backgroundColor: theme.palette.grey[50],
    },
    doneIcon: {
        color: '#32CD32',
    },
    failIcon: {
        color: '#DC143C',
    }
}))

export default function CapturePayment({ match }: MatchProps) {
    const classes = useStyles()
    const texts = useTextLang(mainTexts)
    const paypalID = match.params.id
    const [loading, setLoading] = useState(true)
    const [success, setSuccess] = useState(true)
    const [done, setDone] = useState(false)

    const finish = useCallback(() => {
        window.close()
        setDone(true)
    }, [])

    useEffect(() => {
        const processConfirmation = (success: boolean) => {
            setSuccess(success)
            setLoading(false)
        }
        mutation('confirmPayment', processConfirmation, `paypalID:"${paypalID}"`)
    }, [paypalID])

    return (
        <Grid container direction='column' className={classes.mainContainer} justify='center' alignItems='center'>
            {loading &&
                <Grid item>
                    <CircularProgress />
                </Grid>}
            {!loading &&
                <>
                    <Grid item>
                        {success ? <DoneRounded fontSize='large' className={classes.doneIcon} /> : <CloseRounded fontSize='large' className={classes.failIcon} />}
                    </Grid>
                    <Grid item>
                        <Typography align='center'>
                            {success ? texts.successText : texts.failureText}
                        </Typography>
                    </Grid>
                    <Grid item >
                        {done && <Typography align='center'>
                            {texts.closeTab}
                        </Typography>}
                        {!done && <Button onClick={finish}>
                            {texts.continueButton}
                        </Button>}
                    </Grid>
                </>}
        </Grid>
    )
}