import React, { useEffect, useCallback } from 'react'

import { FormControlLabel, FormControl, FormLabel, FormHelperText, RadioGroup, Radio } from '@material-ui/core';
import { RadioGroupProps } from '@material-ui/core/RadioGroup';
import { FormField, OptionValue, defaultText } from '../../types';
import { FormControlProps } from '@material-ui/core/FormControl';

type RadioButtonType<FieldName> = FormField<FieldName, string> & {
    options: OptionValue[],
    row?: boolean,
} & FormControlProps<'fieldset'>
export default function RadioButton<FieldName>({ field, value, setValue, errorMsg, options, label, helperText, row, ...rest }: RadioButtonType<FieldName>) {
    const handleChange: RadioGroupProps['onChange'] = useCallback((event) => {
        setValue(field, event.target.value)
    }, [field, setValue])

    // Remove value on unmount
    useEffect(() => {
        return () => {
            setValue(field, defaultText)
        }
    }, [field, setValue])

    return (
        <FormControl {...rest} component='fieldset' error={errorMsg !== undefined}>
            <FormLabel component='label' >{label}</FormLabel>
            <RadioGroup
                row={row}
                value={value || ''}
                onChange={handleChange}
            >
                {options.map(({ value, label }) =>
                    <FormControlLabel
                        control={
                            <Radio
                                color='primary'
                                size='small'
                            />
                        }
                        value={value}
                        label={label}
                        key={value}
                    />
                )

                }
            </RadioGroup>
            {(errorMsg || helperText) &&
                <FormHelperText>
                    {(errorMsg || helperText)}
                </FormHelperText>
            }
        </FormControl>
    );
}