import React, { useMemo, useCallback, useState, useEffect } from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import FullPageFormat from '../Global/FullPageFormat'
import moment, { Moment } from 'moment-timezone'
import { Link as RouterLink } from 'react-router-dom'
import './main.scss' // webpack must be configured to do this
import { query } from '../Global/ServerConnection';
import { EventSourceFunc } from '@fullcalendar/core/event-sources/func-event-source';
import { useLang, useTextLang } from '../Contexts/LangContext';
import { routes } from '../Contexts/RouterContext';
import { JSONt, Callback } from '../types';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, useMediaQuery, Theme, Grid, CircularProgress } from '@material-ui/core';
import { FullEvent } from '../server-types';
import { formatDateRange } from '../Global/GlobalItems';
import { useTheme } from '@material-ui/styles';
import { useAuth } from '../Contexts/AuthContext';
import Swipe from '../Global/Swipe';

const viewCalendarTexts = {
    close: {
        en: 'Close',
        es: 'Cerrar'
    },
    moreInfo: {
        en: 'More Info',
        es: 'Mas informacion'
    },
}

type ServerEvent = {
    eventid: string,
    title: string,
    sessionid: string,
    start: string,
    end: string,
    mergeInCalendar: boolean,
    backgroundColor: string,
    textColor: string,
}

type EventType = {
    eventid: string,
    title: string,
    sessionid: string,
    backgroundColor: string,
    textColor: string,
    start: Moment,
    end: Moment,
}

export default function ViewCalendar() {
    const dateStart = useMemo(() => moment().subtract(1, 'month').startOf('month').toISOString(), [])
    const dateEnd = useMemo(() => moment().add(2, 'year').endOf('month').toISOString(), [])
    const { language } = useLang()

    const [eventid, setEventid] = useState<string>()
    const [familyEvents, setFamilyEvents] = useState(false)
    const { isAuth, authQuery } = useAuth()
    const theme = useTheme<Theme>()
    const phone = useMediaQuery(theme.breakpoints.down('xs'))
    const desktop = useMediaQuery(theme.breakpoints.up('xl'))
    const calendarRef = React.createRef<FullCalendar>()

    const handleSwipe = useCallback((direction) => {
        if (calendarRef.current) {
            let calendarApi = calendarRef.current.getApi()
            if (direction === 'left') {
                calendarApi.next()
            } else if (direction === 'right') {
                calendarApi.prev()
            }
        }
    }, [calendarRef])

    const getEvents: EventSourceFunc = useCallback(({ start, end }, successCallback, failureCallback) => {
        const processEvents = (events?: ServerEvent[]) => {
            if (events) {
                const eventData = events.reduce((agg, { eventid, start, end, mergeInCalendar, ...event }) => {
                    const startDate = moment(start)
                    const endDate = moment(end)
                    if (agg[eventid] && agg[eventid].length === 1 && mergeInCalendar) {
                        const newStart = moment.min(agg[eventid][0].start, startDate)
                        const newEnd = moment.max(agg[eventid][0].end, endDate)
                        return { ...agg, [eventid]: [{ ...event, eventid, start: newStart, end: newEnd }] }
                    }
                    return { ...agg, [eventid]: [...(agg[eventid] || []), { ...event, start: startDate, end: endDate, eventid }] }
                }, {} as JSONt<EventType[]>)

                const reducedEvents = Object.values(eventData).reduce((agg, eventArray) => [...agg, ...eventArray], [] as EventType[])

                successCallback(reducedEvents.map(({ eventid, title, sessionid, start, end, backgroundColor, textColor }) => ({
                    id: sessionid,
                    groupId: eventid,
                    start: start.format(),
                    end: end.format(),
                    title: title,
                    color: backgroundColor,
                    textColor: textColor
                })))
            } else {
                failureCallback({ message: 'No events found' })
            }
        }
        if (isAuth && familyEvents) {
            authQuery('familyCalendarEvents', processEvents, `language:"${language}",start:"${start.toISOString()}",end:"${end.toISOString()}"`, 'eventid,title,sessionid,start,end,mergeInCalendar,backgroundColor,textColor')
        } else {
            query('calendarEvents', processEvents, `language:"${language}",start:"${start.toISOString()}",end:"${end.toISOString()}"`, 'eventid,title,sessionid,start,end,mergeInCalendar,backgroundColor,textColor')
        }
    }, [language, authQuery, isAuth, familyEvents])

    const handleEventClick = useCallback(({ event }) => {
        setEventid(event.groupId)
    }, [])

    const closeEventDialog = useCallback(() => setEventid(undefined), [])

    const toggleFamilyEvents = useCallback(() => setFamilyEvents(true), [])
    const toggleAllEvents = useCallback(() => setFamilyEvents(false), [])
    const familyEventsButton = useMemo(() => {
        if (isAuth && familyEvents)
            return ' allEvents'
        if (isAuth)
            return ' myEvents'
        return ''
    }, [isAuth, familyEvents])

    return (
        <FullPageFormat size={desktop ? 'common' : 'narrow'}>
            <Swipe onSwipe={handleSwipe}>
                <FullCalendar
                    customButtons={{
                        myEvents: {
                            text: 'MY EVENTS',
                            click: toggleFamilyEvents
                        },
                        allEvents: {
                            text: 'ALL EVENTS',
                            click: toggleAllEvents
                        }
                    }}
                    displayEventTime={false}
                    buttonText={{
                        today: 'TODAY',
                    }}
                    events={getEvents}
                    timeZone='US/Central'
                    validRange={{
                        start: dateStart,
                        end: dateEnd
                    }}
                    header={{
                        left: familyEventsButton,
                        center: 'title',
                        right: 'prev,next'
                    }}
                    eventLimit={true}
                    defaultView='dayGridMonth'
                    plugins={[dayGridPlugin, momentTimezonePlugin]}
                    eventClick={handleEventClick}
                    aspectRatio={phone ? 0.75 : 1.35}
                    eventLimitText={phone ? '' : 'more'}
                    ref={calendarRef}
                />
            </Swipe>
            <ViewEventDialog eventid={eventid} handleClose={closeEventDialog} />
        </FullPageFormat>
    )
}

type ViewEventDialogProps = {
    eventid?: string,
    handleClose: Callback
}
function ViewEventDialog({ eventid, handleClose }: ViewEventDialogProps) {
    const [eventData, setEventData] = useState<Partial<FullEvent>>({ title: '', subtitle: '', description: '', sessions: [] })
    const [loading, setLoading] = useState(false)
    const { language } = useLang()
    const texts = useTextLang(viewCalendarTexts)

    useEffect(() => {
        setLoading(true)
        const processEventData = (data: Partial<FullEvent>) => {
            if (data)
                setEventData(data)
            setLoading(false)
        }

        if (eventid) {
            return query('fullEventInfo', processEventData, `id:"${eventid}",info:false,language:"${language}"`, 'sessions{sessionid,dateStart,dateEnd}title,subtitle,description')
        }
    }, [eventid, language])

    return (
        <Dialog open={eventid !== undefined} onClose={handleClose} scroll='paper'>
            {loading &&
                <DialogContent>
                    <CircularProgress />
                </DialogContent>
            }
            {!loading && <>
                <DialogTitle>
                    {eventData.title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {eventData.subtitle}
                    </DialogContentText>
                    {eventData.sessions && eventData.sessions.map(({ sessionid, dateStart, dateEnd }) => (
                        <DialogContentText key={sessionid} color='textPrimary'>
                            {formatDateRange(dateStart, dateEnd)}
                        </DialogContentText>
                    ))}
                </DialogContent>
                <DialogActions >
                    <Grid container justify='space-between'>
                        <Button color='primary' disableRipple component={RouterLink} to={routes.viewEvent(eventid || '')}>
                            {texts.moreInfo}
                        </Button>
                        <Button color='primary' disableRipple onClick={handleClose}>
                            {texts.close}
                        </Button>
                    </Grid>
                </DialogActions >
            </>}
        </Dialog>
    )
}