import React, { useState, useEffect, useCallback } from 'react'
import { useTextLang } from '../Contexts/LangContext';
import { Grid, Typography, Divider, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Theme, useMediaQuery, CircularProgress, Button } from '@material-ui/core';
import useForm, { FormatForm } from '../Form/FormUtils';
import FormNavigation from '../Form/FormNavigation';
import { useBasicTexts } from '../Global/GlobalItems';
import { FieldType, defaultText, Callback, Consumer } from '../types';
import { useAuth } from '../Contexts/AuthContext';
import Password from '../Form/Components/Password';
import Text from '../Form/Components/Text';
import Email from '../Form/Components/Email';
import { EditOutlined } from '@material-ui/icons';
import { escape } from '../Global/GlobalItems';
import { SendEmailChangeAttempt } from '../server-types';
import { useTheme } from '@material-ui/styles';

const accountSettingsTexts = {
    accountInfo: {
        en: 'Account Info'
    },
    editAccountInfo: {
        en: 'Edit Account Info'
    },
    edit: {
        en: 'Edit'
    },
    incorrectPasswordError: {
        en: 'Incorrect password'
    },
    existingUsernameError: {
        en: 'This username already exists'
    },
    existingEmailError: {
        en: 'This email is already registered'
    },
    password: {
        en: 'Password'
    },
    oldPassword: {
        en: 'Old Password'
    },
    changePassword: {
        en: 'Change Password'
    },
    username: {
        en: 'Username'
    },
    email: {
        en: 'Email'
    },
    newUsername: {
        en: 'New Username'
    },
    newPassword: {
        en: 'New Password'
    },
    newEmail: {
        en: 'New Email'
    },
    emailVerificationTitle: {
        en: 'Email Verification'
    },
    verifyEmail: {
        en: 'Please enter the code sent to your email.'
    },
    code: {
        en: 'Verification code'
    },
    reSendEmail: {
        en: 'Send another code'
    },
    emptyCodeError: {
        en: 'Please enter the code'
    },
    badCodeError: {
        en: 'This code is incorrect'
    },
    staleCodeError: {
        en: 'This code is old, we have sent a new one'
    },
}

type EditType = 'username' | 'password' | 'email'
export default function AccountSettings() {
    const [type, setType] = useState<EditType>()
    const texts = useTextLang(accountSettingsTexts)

    const edit = useCallback((type: EditType) => {
        setType(type)
    }, [])

    const stopEdit = useCallback(() => {
        setType(undefined)
    }, [])

    if (type) {
        return (
            <>
                <Grid item container direction='row'>
                    <Typography variant='h6'>
                        {texts.editAccountInfo}
                    </Typography>
                </Grid>
                <Divider />
                {type === 'username' && <EditUsername onFinish={stopEdit} />}
                {type === 'password' && <EditPassword onFinish={stopEdit} />}
                {type === 'email' && <EditEmail onFinish={stopEdit} />}
            </>
        )
    } else {
        return <DisplayAccountInfo onEdit={edit} />
    }
}


type AccountEdit = {
    oldPassword: string,
    username?: string,
    newPassword?: string,
    email?: string
}

type AccountEditAttempt = {
    updateStatus: 'SUCCESS' | 'USERNAME_ALREADY_EXISTS' | 'EMAIL_ALREADY_EXISTS' | 'PASSWORD_TOO_WEAK' | 'INCORRECT_PASSWORD' | 'BAD_CODE',
    sessionToken?: string
}
export const editAccountRules = {
    oldPassword: { type: FieldType.Password, required: true },
    username: { type: FieldType.Text },
    newPassword: { type: FieldType.Password },
    email: { type: FieldType.Email },
}

type AccountInfo = {
    username: string,
    email: string
}
function DisplayAccountInfo({ onEdit }: { onEdit: Consumer<EditType> }) {
    const [accountInfo, setAccountInfo] = useState<AccountInfo>({ username: '', email: '' })
    const texts = useTextLang(accountSettingsTexts)
    const { authQuery } = useAuth()

    useEffect(() => {
        window.scrollTo(0, 0)
        const processInfo = (info: AccountInfo) => {
            if (info)
                setAccountInfo(info)
        }
        authQuery('getAccountInfo', processInfo, undefined, 'username,email')
    }, [authQuery])

    const editUsername = useCallback(() => onEdit('username'), [onEdit])
    const editEmail = useCallback(() => onEdit('email'), [onEdit])
    const editPassword = useCallback(() => onEdit('password'), [onEdit])

    return (
        <>
            <Grid item container justify='space-between' onClick={editUsername}>
                <Typography variant='h6'>
                    {`${texts.username}: ${accountInfo.username}`}
                </Typography>
                <EditOutlined />
            </Grid>
            <Divider />
            <Grid item container justify='space-between' onClick={editEmail}>
                <Typography variant='h6'>
                    {`${texts.email}: ${accountInfo.email}`}
                </Typography>
                <EditOutlined />
            </Grid>
            <Divider />
            <Grid item container justify='space-between' onClick={editPassword}>
                <Typography variant='h6'>
                    {texts.changePassword}
                </Typography>
                <EditOutlined />
            </Grid>
            <Divider />
        </>
    )
}

type EmailEdit = { password: string, email: string }
type EditAccountInfoProps = {
    onFinish: Callback,
}
function EditEmail({ onFinish }: EditAccountInfoProps) {
    const [loading, setLoading] = useState(false)
    const [passwordError, setPasswordError] = useState(false)
    const [emailError, setEmailError] = useState(false)
    const [emailChangeToken, setEmailChangeToken] = useState<string>()
    const basicTexts = useBasicTexts()
    const texts = useTextLang(accountSettingsTexts)
    const { authQuery } = useAuth()

    const finish = useCallback((values: EmailEdit) => {
        setLoading(true)
        setPasswordError(false)
        setEmailError(false)

        const processChangeAttempt = (attempt: SendEmailChangeAttempt) => {
            switch (attempt.status) {
                case 'SUCCESS':
                    if (attempt.emailChangeToken)
                        setEmailChangeToken(attempt.emailChangeToken)
                    break
                case 'EMAIL_ALREADY_EXISTS':
                    setEmailError(true)
                    break
                default:
                    setPasswordError(true)
            }
            setLoading(false)
        }

        authQuery('sendEmailChangeVerification', processChangeAttempt, `password:"${escape(values.password)}",email:"${escape(values.email)}"`, 'status,emailChangeToken')
    }, [authQuery])

    const { values, setValue, errors, setError, handleSubmit } = useForm({
        password: { type: FieldType.Password, required: true },
        email: { type: FieldType.Email },
    }, finish, {
        password: defaultText,
        email: defaultText,
    })

    useEffect(() => {
        if (passwordError) {
            setError('password', texts.incorrectPasswordError)
        } else if (emailError) {
            setError('email', texts.existingEmailError)
        }
    }, [texts, setError, passwordError, emailError])

    const cancelVerification = useCallback(() => {
        setEmailChangeToken(undefined)
    }, [])

    return (
        <>
            <FormatForm>
                <Password field='password' label={texts.password} value={values.password} setValue={setValue} errorMsg={errors.password} />
                <Email field='email' label={texts.newEmail} value={values.email} setValue={setValue} errorMsg={errors.email} onEnter={handleSubmit}/>
            </FormatForm>
            <FormNavigation
                onSubmit={handleSubmit}
                submitText={basicTexts.next}
                onCancel={onFinish}
                cancelText={basicTexts.cancel}
                loading={loading}
            />
            {emailChangeToken !== undefined &&
                <VerificationDialog onFinish={onFinish} onCancel={cancelVerification} emailChangeToken={emailChangeToken} />
            }
        </>
    )
}

type VerificationDialogType = {
    emailChangeToken: string,
    onCancel: Callback,
    onFinish: Callback,
}
export function VerificationDialog({ emailChangeToken, onCancel, onFinish }: VerificationDialogType) {
    const texts = useTextLang(accountSettingsTexts)
    const basicTexts = useBasicTexts()
    const [token, setToken] = useState(emailChangeToken)
    const [loading, setLoading] = useState(false)
    const [codeError, setCodeError] = useState<string>()
    const { authQuery, authMutation, overrideLogin } = useAuth()

    const resendVerification = useCallback(() => {
        setLoading(true)
        setCodeError(undefined)
        const processAttempt = (attempt: SendEmailChangeAttempt) => {
            if (attempt.emailChangeToken) {
                setToken(attempt.emailChangeToken)
            } else {
                onCancel()
            }
            setLoading(false)
        }
        authQuery('resendEmailChangeVerification', processAttempt, `emailChangeToken:"${emailChangeToken}"`, 'status,emailChangeToken')
    }, [authQuery, onCancel, emailChangeToken])

    const changeEmail = useCallback(({ code }: { code: string }) => {
        setCodeError(undefined)
        if (code.length > 0) {
            setLoading(true)
            const processMutation = (attempt: AccountEditAttempt) => {
                setLoading(false)
                switch (attempt.updateStatus) {
                    case 'SUCCESS':
                        if (attempt.sessionToken)
                            overrideLogin(attempt.sessionToken)
                        onFinish()
                        return
                    case 'BAD_CODE':
                        setCodeError(texts.badCodeError)
                        break
                }
            }
            authMutation('updateEmail', processMutation, `emailChangeToken:"${token}",code:"${escape(code)}"`, 'updateStatus,sessionToken')
        } else {
            setCodeError(texts.emptyCodeError)
        }
    }, [authMutation, token, overrideLogin, onFinish, texts])

    const { values, setValue, errors, setError, handleSubmit } = useForm(
        { code: { type: FieldType.Text, required: true } },
        changeEmail,
        { code: '' },
        {})

    useEffect(() => {
        setError('code', codeError)
    }, [codeError, setError])

    const getNewCode = useCallback(() => {
        setValue('code', '')
        setError('code', undefined)
        resendVerification()
    }, [setValue, setError, resendVerification])

    const theme = useTheme<Theme>()
    const matches = useMediaQuery(theme.breakpoints.down('xs'))

    return (
        <Dialog
            open={true}
            onClose={onCancel}
            scroll='paper'
            disableBackdropClick
            disableEscapeKeyDown>
            {!matches &&
                <DialogTitle>
                    {texts.emailVerificationTitle}
                </DialogTitle>
            }
            <DialogContent>
                <DialogContentText>
                    {matches ? `${texts.emailVerificationTitle}: ${texts.verifyEmail}` : texts.verifyEmail}
                </DialogContentText>
                <Text field='code' value={values.code} setValue={setValue} onEnter={handleSubmit} errorMsg={errors.code} autoFocus autoCapitalize='off' />
            </DialogContent>
            <DialogActions >
                {loading
                    ? <Grid container justify='center' >
                        <CircularProgress />
                    </Grid>
                    : <>
                        <Button onClick={onCancel} color='default'>
                            {basicTexts.cancel}
                        </Button>
                        <Button onClick={getNewCode} color='primary'>
                            {texts.reSendEmail}
                        </Button>
                        <Button onClick={handleSubmit} color='primary'>
                            {basicTexts.done}
                        </Button>
                    </>}
            </DialogActions>
        </Dialog>
    )
}

type UsernameEdit = { password: string, username: string }
function EditUsername({ onFinish }: EditAccountInfoProps) {
    const [loading, setLoading] = useState(false)
    const [passwordError, setPasswordError] = useState(false)
    const [usernameError, setUsernameError] = useState(false)
    const basicTexts = useBasicTexts()
    const { authMutation, overrideLogin } = useAuth()
    const texts = useTextLang(accountSettingsTexts)

    const finish = useCallback((values: UsernameEdit) => {
        setLoading(true)
        setPasswordError(false)
        setUsernameError(false)

        const processMutation = (attempt: AccountEditAttempt) => {
            setLoading(false)
            switch (attempt.updateStatus) {
                case 'SUCCESS':
                    if (attempt.sessionToken)
                        overrideLogin(attempt.sessionToken)
                    onFinish()
                    return
                case 'USERNAME_ALREADY_EXISTS':
                    setUsernameError(true)
                    break
                default:
                    setPasswordError(true)
            }
        }
        authMutation('updateUsername', processMutation, `password:"${escape(values.password)}",username:"${escape(values.username)}"`, 'updateStatus,sessionToken')
    }, [onFinish, authMutation, overrideLogin])

    const { values, setValue, errors, setError, handleSubmit } = useForm({
        password: { type: FieldType.Password, required: true },
        username: { type: FieldType.Text, required: true, minLength: 3 },
    }, finish, {
        password: defaultText,
        username: defaultText,
    })

    useEffect(() => {
        if (passwordError) {
            setError('password', texts.incorrectPasswordError)
        } else if (usernameError) {
            setError('username', texts.existingUsernameError)
        }
    }, [texts, setError, passwordError, usernameError])

    return (
        <>
            <FormatForm>
                <Password field='password' label={texts.password} value={values.password} setValue={setValue} errorMsg={errors.password} />
                <Text field='username' label={texts.newUsername} value={values.username} setValue={setValue} errorMsg={errors.username} autoCapitalize='off' onEnter={handleSubmit}/>
            </FormatForm>
            <FormNavigation
                onSubmit={handleSubmit}
                submitText={basicTexts.done}
                onCancel={onFinish}
                cancelText={basicTexts.cancel}
                loading={loading}
            />
        </>
    )
}

type PasswordEdit = { password: string, newPassword: string }
function EditPassword({ onFinish }: EditAccountInfoProps) {
    const [loading, setLoading] = useState(false)
    const [passwordError, setPasswordError] = useState(false)
    const basicTexts = useBasicTexts()
    const { authMutation, overrideLogin } = useAuth()
    const texts = useTextLang(accountSettingsTexts)

    const finish = useCallback((values: PasswordEdit) => {
        setLoading(true)
        setPasswordError(false)

        const processMutation = (attempt: AccountEditAttempt) => {
            setLoading(false)
            switch (attempt.updateStatus) {
                case 'SUCCESS':
                    if (attempt.sessionToken)
                        overrideLogin(attempt.sessionToken)
                    onFinish()
                    return
                default:
                    setPasswordError(true)
            }
        }
        authMutation('updatePassword', processMutation, `password:"${escape(values.password)}",newPassword:"${escape(values.newPassword)}"`, 'updateStatus,sessionToken')
    }, [onFinish, authMutation, overrideLogin])

    const { values, setValue, errors, setError, handleSubmit } = useForm({
        password: { type: FieldType.Password, required: true },
        newPassword: { type: FieldType.Password, required: true, minLength: 8 },
    }, finish, {
        password: defaultText,
        newPassword: defaultText,
    })

    useEffect(() => {
        if (passwordError) {
            setError('password', texts.incorrectPasswordError)
        }
    }, [texts, setError, passwordError])

    return (
        <>
            <FormatForm>
                <Password field='password' label={texts.oldPassword} value={values.password} setValue={setValue} errorMsg={errors.password} />
                <Password field='newPassword' label={texts.newPassword} value={values.newPassword} setValue={setValue} errorMsg={errors.newPassword} onEnter={handleSubmit}/>
            </FormatForm>
            <FormNavigation
                onSubmit={handleSubmit}
                submitText={basicTexts.done}
                onCancel={onFinish}
                cancelText={basicTexts.cancel}
                loading={loading}
            />
        </>
    )
}