import React, { useEffect, useState, useCallback } from 'react'
import { Chip, Grid, Typography, CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import EventCard from '../Events/EventCard'
import { useLang } from '../Contexts/LangContext';
import { query } from '../Global/ServerConnection';
import { GroupedTags, Tag } from '../server-types';
import { useRouter } from '../Contexts/RouterContext';
import EventNotAllowedDialog from '../Global/EventNotAllowedDialog';
import { JSONt, BiConsumer, Consumer } from '../types';

const useStyles = makeStyles(theme => ({
    mainContainer: {
        paddingBottom: theme.spacing(4),
        margin: 0,
        width: '100%'
    },
    invisible: {
        display: 'none'
    },
    visible: {
        display: 'block'
    },
    eventsContainer: {
        margin: 0
    },
    chip: {
        margin: theme.spacing(1 / 2),
    },
    rowContainer: {
        overflow: 'auto'
    },
    noEvents: {
        paddingTop: theme.spacing(2),
    },
    progress: {
        margin: theme.spacing(2)
    },
}))

type EventsProps = {
    visible: boolean
}
export default function Events({ visible }: EventsProps) {
    const [tags, setTags] = useState<GroupedTags>({})
    const [selectedFilters, setSelectedFilters] = useState<JSONt<string>>({})
    const [allEventInfoIDs, setAllEventInfoIDs] = useState<string[]>([])
    const { routeParams } = useRouter()
    const [eventNotAllowedOpen, setEventNotAllowedOpen] = useState(false)

    const openEventNotAllowed = useCallback(() => {
        setEventNotAllowedOpen(true)
    }, [])

    const closeEventNotAllowed = useCallback(() => {
        setEventNotAllowedOpen(false)
    }, [])

    useEffect(() => {
        if (routeParams && routeParams.tag) {
            const filter = routeParams.tag
            const category = Object.keys(tags).find(category =>
                tags[category].some(tag => tag.tag === filter)
            )
            if (category) {
                setSelectedFilters({ [category]: filter })
                return
            }
        }
        setSelectedFilters({})
    }, [routeParams, tags])

    const { language } = useLang()

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const processEventInfoIDs = (eventInfoIDs?: string[]) => {
            if (eventInfoIDs)
                setAllEventInfoIDs(eventInfoIDs)
            setLoading(false)
        }
        return query('eventInfoIDList', processEventInfoIDs)
    }, [])

    useEffect(() => {
        const processTags = (filters: { type: string, tag: string, label: string }[]) => {
            const tags = filters.reduce((tags: GroupedTags, { type, tag, label }) => (
                { ...tags, [type]: [...(tags[type] || []), { tag, label }] }
            ), {})
            setTags(tags)
        }
        return query('eventTags', processTags, `language:"${language}"`, 'type,tag,label')
    }, [language])

    const toggleFilter = useCallback((category: string, filter: string) => {
        setSelectedFilters(selected => {
            if (selected[category] === filter) {
                const newFilters = { ...selected }
                delete newFilters[category]
                return { ...newFilters }
            } else {
                return { ...selected, [category]: filter }
            }
        })
    }, [])

    const classes = useStyles()
    return (
        <div className={visible ? classes.visible : classes.invisible}>
            <Grid container spacing={3} className={classes.mainContainer}>
                <Grid item container>

                    {Object.keys(tags).map((type, index) =>
                        <FilterRow key={index} filters={tags[type]} selected={selectedFilters[type]}
                            toggleFilter={toggleFilter} type={type} />
                    )}
                </Grid>
                {loading &&
                    <Grid item>
                        <CircularProgress />
                    </Grid>}
                {allEventInfoIDs.map(eventInfoID => (
                    <EventCard eventInfoID={eventInfoID} key={eventInfoID}
                        selectedFilters={Object.values(selectedFilters)}
                        openEventNotAllowed={openEventNotAllowed} />
                ))}
                <EventNotAllowedDialog open={eventNotAllowedOpen} handleClose={closeEventNotAllowed} />
            </Grid>
        </div>
    )
}

type FilterRowProps = {
    toggleFilter: BiConsumer<string, string>,
    selected: string,
    filters: Tag[],
    type: string
}
const FilterRow = ({ toggleFilter, selected, filters, type }: FilterRowProps) => {
    const classes = useStyles()
    const handleClick = useCallback((tag: string) => {
        toggleFilter(type, tag)
    }, [toggleFilter, type])
    return (
        <Grid item container alignItems='center' wrap='nowrap'>
            <Typography variant='button' noWrap className={classes.chip}>
                {type}:
            </Typography>
            <Grid item xs={true} container wrap='nowrap' className={classes.rowContainer}>
                {filters.map(({ tag, label }) =>
                    <Filter key={tag}
                        toggleFilter={handleClick}
                        filter={tag}
                        label={label}
                        selected={selected === tag} />
                )}
            </Grid>
        </Grid>
    )
}

type FilterProps = {
    toggleFilter: Consumer<string>,
    filter: string,
    selected: boolean,
    label: string,
}
function Filter({ toggleFilter, filter, selected, label }: FilterProps) {
    const classes = useStyles()

    const handleClick = useCallback(() => {
        toggleFilter(filter)
    }, [toggleFilter, filter])

    return (
        <Chip className={classes.chip}
            onClick={handleClick}
            label={label.toUpperCase()} variant='outlined'
            color={selected ? 'primary' : 'default'} />
    )
}