import { useTextLang } from "../Contexts/LangContext";
import { useMemo, useCallback } from "react";

const sexTexts = {
    male: {
        en: 'Male'
    },
    female: {
        en: 'Female'
    },
}

const nationalityTexts = {
    Afghanistan: { en: 'Afghanistan' },
    Albania: { en: 'Albania' },
    Algeria: { en: 'Algeria' },
    Andorra: { en: 'Andorra' },
    Angola: { en: 'Angola' },
    AntiguaandBarbuda: { en: 'Antigua and Barbuda' },
    Argentina: { en: 'Argentina' },
    Armenia: { en: 'Armenia' },
    Australia: { en: 'Australia' },
    Austria: { en: 'Austria' },
    Azerbaijan: { en: 'Azerbaijan' },
    Bahamas: { en: 'Bahamas' },
    Bahrain: { en: 'Bahrain' },
    Bangladesh: { en: 'Bangladesh' },
    Barbados: { en: 'Barbados' },
    Belarus: { en: 'Belarus' },
    Belgium: { en: 'Belgium' },
    Belize: { en: 'Belize' },
    Benin: { en: 'Benin' },
    Bhutan: { en: 'Bhutan' },
    Bolivia: { en: 'Bolivia' },
    BosniaandHerzegovina: { en: 'Bosnia and Herzegovina' },
    Botswana: { en: 'Botswana' },
    Brazil: { en: 'Brazil' },
    Brunei: { en: 'Brunei' },
    Bulgaria: { en: 'Bulgaria' },
    BurkinaFaso: { en: 'Burkina Faso' },
    Burundi: { en: 'Burundi' },
    CaboVerde: { en: 'Cabo Verde' },
    Cambodia: { en: 'Cambodia' },
    Cameroon: { en: 'Cameroon' },
    Canada: { en: 'Canada' },
    CentralAfricanRepublic: { en: 'Central African Republic' },
    Chad: { en: 'Chad' },
    Chile: { en: 'Chile' },
    China: { en: 'China' },
    Colombia: { en: 'Colombia' },
    Comoros: { en: 'Comoros' },
    DemocraticRepublicoftheCongo: { en: 'Democratic Republic of the Congo' },
    RepublicoftheCongo: { en: 'Republic of the Congo' },
    CostaRica: { en: 'Costa Rica' },
    CotedIvoire: { en: "Cote d'Ivoire" },
    Croatia: { en: 'Croatia' },
    Cuba: { en: 'Cuba' },
    Cyprus: { en: 'Cyprus' },
    Czechia: { en: 'Czechia' },
    Denmark: { en: 'Denmark' },
    Djibouti: { en: 'Djibouti' },
    Dominica: { en: 'Dominica' },
    DominicanRepublic: { en: 'Dominican Republic' },
    Ecuador: { en: 'Ecuador' },
    Egypt: { en: 'Egypt' },
    ElSalvador: { en: 'El Salvador' },
    EquatorialGuinea: { en: 'Equatorial Guinea' },
    Eritrea: { en: 'Eritrea' },
    Estonia: { en: 'Estonia' },
    Eswatini: { en: 'Eswatini' },
    Ethiopia: { en: 'Ethiopia' },
    Fiji: { en: 'Fiji' },
    Finland: { en: 'Finland' },
    France: { en: 'France' },
    Gabon: { en: 'Gabon' },
    Gambia: { en: 'Gambia' },
    Georgia: { en: 'Georgia' },
    Germany: { en: 'Germany' },
    Ghana: { en: 'Ghana' },
    Greece: { en: 'Greece' },
    Grenada: { en: 'Grenada' },
    Guatemala: { en: 'Guatemala' },
    Guinea: { en: 'Guinea' },
    GuineaBissau: { en: 'Guinea-Bissau' },
    Guyana: { en: 'Guyana' },
    Haiti: { en: 'Haiti' },
    Honduras: { en: 'Honduras' },
    Hungary: { en: 'Hungary' },
    Iceland: { en: 'Iceland' },
    India: { en: 'India' },
    Indonesia: { en: 'Indonesia' },
    Iran: { en: 'Iran' },
    Iraq: { en: 'Iraq' },
    Ireland: { en: 'Ireland' },
    Israel: { en: 'Israel' },
    Italy: { en: 'Italy' },
    Jamaica: { en: 'Jamaica' },
    Japan: { en: 'Japan' },
    Jordan: { en: 'Jordan' },
    Kazakhstan: { en: 'Kazakhstan' },
    Kenya: { en: 'Kenya' },
    Kiribati: { en: 'Kiribati' },
    Kosovo: { en: 'Kosovo' },
    Kuwait: { en: 'Kuwait' },
    Kyrgyzstan: { en: 'Kyrgyzstan' },
    Laos: { en: 'Laos' },
    Latvia: { en: 'Latvia' },
    Lebanon: { en: 'Lebanon' },
    Lesotho: { en: 'Lesotho' },
    Liberia: { en: 'Liberia' },
    Libya: { en: 'Libya' },
    Liechtenstein: { en: 'Liechtenstein' },
    Lithuania: { en: 'Lithuania' },
    Luxembourg: { en: 'Luxembourg' },
    Madagascar: { en: 'Madagascar' },
    Malawi: { en: 'Malawi' },
    Malaysia: { en: 'Malaysia' },
    Maldives: { en: 'Maldives' },
    Mali: { en: 'Mali' },
    Malta: { en: 'Malta' },
    MarshallIslands: { en: 'Marshall Islands' },
    Mauritania: { en: 'Mauritania' },
    Mauritius: { en: 'Mauritius' },
    Mexico: { en: 'Mexico' },
    Micronesia: { en: 'Micronesia' },
    Moldova: { en: 'Moldova' },
    Monaco: { en: 'Monaco' },
    Mongolia: { en: 'Mongolia' },
    Montenegro: { en: 'Montenegro' },
    Morocco: { en: 'Morocco' },
    Mozambique: { en: 'Mozambique' },
    Myanmar: { en: 'Myanmar' },
    Namibia: { en: 'Namibia' },
    Nauru: { en: 'Nauru' },
    Nepal: { en: 'Nepal' },
    Netherlands: { en: 'Netherlands' },
    NewZealand: { en: 'New Zealand' },
    Nicaragua: { en: 'Nicaragua' },
    Niger: { en: 'Niger' },
    Nigeria: { en: 'Nigeria' },
    NorthKorea: { en: 'North Korea' },
    NorthMacedonia: { en: 'North Macedonia' },
    Norway: { en: 'Norway' },
    Oman: { en: 'Oman' },
    Pakistan: { en: 'Pakistan' },
    Palau: { en: 'Palau' },
    Palestine: { en: 'Palestine' },
    Panama: { en: 'Panama' },
    PapuaNewGuinea: { en: 'Papua New Guinea' },
    Paraguay: { en: 'Paraguay' },
    Peru: { en: 'Peru' },
    Philippines: { en: 'Philippines' },
    Poland: { en: 'Poland' },
    Portugal: { en: 'Portugal' },
    Qatar: { en: 'Qatar' },
    Romania: { en: 'Romania' },
    Russia: { en: 'Russia' },
    Rwanda: { en: 'Rwanda' },
    SaintKittsandNevis: { en: 'Saint Kitts and Nevis' },
    SaintLucia: { en: 'Saint Lucia' },
    SaintVincentandtheGrenadines: { en: 'Saint Vincent and the Grenadines' },
    Samoa: { en: 'Samoa' },
    SanMarino: { en: 'San Marino' },
    SaoTomeandPrincipe: { en: 'Sao Tome and Principe' },
    SaudiArabia: { en: 'Saudi Arabia' },
    Senegal: { en: 'Senegal' },
    Serbia: { en: 'Serbia' },
    Seychelles: { en: 'Seychelles' },
    SierraLeone: { en: 'Sierra Leone' },
    Singapore: { en: 'Singapore' },
    Slovakia: { en: 'Slovakia' },
    Slovenia: { en: 'Slovenia' },
    SolomonIslands: { en: 'Solomon Islands' },
    Somalia: { en: 'Somalia' },
    SouthAfrica: { en: 'South Africa' },
    SouthKorea: { en: 'South Korea' },
    SouthSudan: { en: 'South Sudan' },
    Spain: { en: 'Spain' },
    SriLanka: { en: 'Sri Lanka' },
    Sudan: { en: 'Sudan' },
    Suriname: { en: 'Suriname' },
    Sweden: { en: 'Sweden' },
    Switzerland: { en: 'Switzerland' },
    Syria: { en: 'Syria' },
    Taiwan: { en: 'Taiwan' },
    Tajikistan: { en: 'Tajikistan' },
    Tanzania: { en: 'Tanzania' },
    Thailand: { en: 'Thailand' },
    TimorLeste: { en: 'Timor-Leste' },
    Togo: { en: 'Togo' },
    Tonga: { en: 'Tonga' },
    TrinidadandTobago: { en: 'Trinidad and Tobago' },
    Tunisia: { en: 'Tunisia' },
    Turkey: { en: 'Turkey' },
    Turkmenistan: { en: 'Turkmenistan' },
    Tuvalu: { en: 'Tuvalu' },
    Uganda: { en: 'Uganda' },
    Ukraine: { en: 'Ukraine' },
    UnitedArabEmirates: { en: 'United Arab Emirates' },
    UnitedKingdom: { en: 'United Kingdom' },
    UnitedStatesofAmerica: { en: 'United States of America' },
    Uruguay: { en: 'Uruguay' },
    Uzbekistan: { en: 'Uzbekistan' },
    Vanuatu: { en: 'Vanuatu' },
    VaticanCity: { en: 'Vatican City' },
    Venezuela: { en: 'Venezuela' },
    Vietnam: { en: 'Vietnam' },
    Yemen: { en: 'Yemen' },
    Zambia: { en: 'Zambia' },
    Zimbabwe: { en: 'Zimbabwe' },
}

const languageTexts = {
    english: {
        en: 'English'
    },
    spanish: {
        en: 'Spanish'
    },
}

const phoneCarrierTexts = {
    att: {
        en: 'AT&T'
    },
    boost: {
        en: 'Boost'
    },
    cricket: {
        en: 'Cricket'
    },
    tmobile: {
        en: 'T-Mobile'
    },
    sprint: {
        en: 'Sprint'
    },
    verizon: {
        en: 'Verizon'
    },
    virgin: {
        en: 'Virgin'
    },
    other: {
        en: 'Other...'
    },
}

const maritalStatusTexts = {
    single: {
        en: 'Single'
    },
    married: {
        en: 'Married'
    },
    widowed: {
        en: 'Widowed'
    },
    separated: {
        en: 'Separated'
    },
}

const tshirtSizeTexts = {
    YXS: {
        en: 'Youth XS'
    },
    YS: {
        en: 'Youth S'
    },
    YM: {
        en: 'Youth M'
    },
    YL: {
        en: 'Youth L'
    },
    YXL: {
        en: 'Youth XL'
    },
    AS: {
        en: 'Adult S'
    },
    AM: {
        en: 'Adult M'
    },
    AL: {
        en: 'Adult L'
    },
    AXL: {
        en: 'Adult XL'
    },
    AXXL: {
        en: 'Adult XXL'
    },
}

const personTexts = {
    ...sexTexts, ...nationalityTexts, ...languageTexts, ...maritalStatusTexts, ...phoneCarrierTexts, ...tshirtSizeTexts,
    optional: {
        en: 'optional'
    }
}

const personLabels = {
    username: {
        en: 'Username',
    },
    password: {
        en: 'Password'
    },
    firstName: {
        en: 'First Name'
    },
    lastName: {
        en: 'Last Name'
    },
    sex: {
        en: 'Sex'
    },
    dateOfBirth: {
        en: 'Date of Birth'
    },
    nationality: {
        en: 'Country of Origin'
    },
    preferredLanguage: {
        en: 'Preferred Language'
    },
    email: {
        en: 'Email',
    },
    phoneNumber: {
        en: 'Phone Number'
    },
    phoneCarrier: {
        en: 'Phone Carrier'
    },
    streetAddress: {
        en: 'Street Address'
    },
    city: {
        en: 'City'
    },
    state: {
        en: 'State'
    },
    zipCode: {
        en: 'Zip Code'
    },
    country: {
        en: 'Country'
    },
    maritalStatus: {
        en: 'Marital Status'
    },
    dateOfMarriage: {
        en: 'Date of Marriage'
    },
}

const stateOptions = ['AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'MY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'
].map(val => ({ value: val, label: val }))

export default function useFieldUtils() {
    const labels = useTextLang(personLabels)
    const texts = useTextLang(personTexts)
    const sexOptions = useMemo(() => [
        { value: 'MALE', label: texts.male },
        { value: 'FEMALE', label: texts.female }
    ], [texts])
    //TODO: Add more nationality options.
    const nationalityOptions = useMemo(() => [
        { value: 'Afghanistan', label: texts.Afghanistan },
        { value: 'Albania', label: texts.Albania },
        { value: 'Algeria', label: texts.Algeria },
        { value: 'Andorra', label: texts.Andorra },
        { value: 'Angola', label: texts.Angola },
        { value: 'Antigua and Barbuda', label: texts.AntiguaandBarbuda },
        { value: 'Argentina', label: texts.Argentina },
        { value: 'Armenia', label: texts.Armenia },
        { value: 'Australia', label: texts.Australia },
        { value: 'Austria', label: texts.Austria },
        { value: 'Azerbaijan', label: texts.Azerbaijan },
        { value: 'Bahamas', label: texts.Bahamas },
        { value: 'Bahrain', label: texts.Bahrain },
        { value: 'Bangladesh', label: texts.Bangladesh },
        { value: 'Barbados', label: texts.Barbados },
        { value: 'Belarus', label: texts.Belarus },
        { value: 'Belgium', label: texts.Belgium },
        { value: 'Belize', label: texts.Belize },
        { value: 'Benin', label: texts.Benin },
        { value: 'Bhutan', label: texts.Bhutan },
        { value: 'Bolivia', label: texts.Bolivia },
        { value: 'Bosnia and Herzegovina', label: texts.BosniaandHerzegovina },
        { value: 'Botswana', label: texts.Botswana },
        { value: 'Brazil', label: texts.Brazil },
        { value: 'Brunei', label: texts.Brunei },
        { value: 'Bulgaria', label: texts.Bulgaria },
        { value: 'Burkina Faso', label: texts.BurkinaFaso },
        { value: 'Burundi', label: texts.Burundi },
        { value: 'Cabo Verde', label: texts.CaboVerde },
        { value: 'Cambodia', label: texts.Cambodia },
        { value: 'Cameroon', label: texts.Cameroon },
        { value: 'Canada', label: texts.Canada },
        { value: 'Central African Republic', label: texts.CentralAfricanRepublic },
        { value: 'Chad', label: texts.Chad },
        { value: 'Chile', label: texts.Chile },
        { value: 'China', label: texts.China },
        { value: 'Colombia', label: texts.Colombia },
        { value: 'Comoros', label: texts.Comoros },
        { value: 'Democratic Republic of the Congo', label: texts.DemocraticRepublicoftheCongo },
        { value: 'Republic of the Congo', label: texts.RepublicoftheCongo },
        { value: 'Costa Rica', label: texts.CostaRica },
        { value: "Cote d'Ivoire", label: texts.CotedIvoire },
        { value: 'Croatia', label: texts.Croatia },
        { value: 'Cuba', label: texts.Cuba },
        { value: 'Cyprus', label: texts.Cyprus },
        { value: 'Czechia', label: texts.Czechia },
        { value: 'Denmark', label: texts.Denmark },
        { value: 'Djibouti', label: texts.Djibouti },
        { value: 'Dominica', label: texts.Dominica },
        { value: 'Dominican Republic', label: texts.DominicanRepublic },
        { value: 'Ecuador', label: texts.Ecuador },
        { value: 'Egypt', label: texts.Egypt },
        { value: 'El Salvador', label: texts.ElSalvador },
        { value: 'Equatorial Guinea', label: texts.EquatorialGuinea },
        { value: 'Eritrea', label: texts.Eritrea },
        { value: 'Estonia', label: texts.Estonia },
        { value: 'Eswatini', label: texts.Eswatini },
        { value: 'Ethiopia', label: texts.Ethiopia },
        { value: 'Fiji', label: texts.Fiji },
        { value: 'Finland', label: texts.Finland },
        { value: 'France', label: texts.France },
        { value: 'Gabon', label: texts.Gabon },
        { value: 'Gambia', label: texts.Gambia },
        { value: 'Georgia', label: texts.Georgia },
        { value: 'Germany', label: texts.Germany },
        { value: 'Ghana', label: texts.Ghana },
        { value: 'Greece', label: texts.Greece },
        { value: 'Grenada', label: texts.Grenada },
        { value: 'Guatemala', label: texts.Guatemala },
        { value: 'Guinea', label: texts.Guinea },
        { value: 'Guinea-Bissau', label: texts.GuineaBissau },
        { value: 'Guyana', label: texts.Guyana },
        { value: 'Haiti', label: texts.Haiti },
        { value: 'Honduras', label: texts.Honduras },
        { value: 'Hungary', label: texts.Hungary },
        { value: 'Iceland', label: texts.Iceland },
        { value: 'India', label: texts.India },
        { value: 'Indonesia', label: texts.Indonesia },
        { value: 'Iran', label: texts.Iran },
        { value: 'Iraq', label: texts.Iraq },
        { value: 'Ireland', label: texts.Ireland },
        { value: 'Israel', label: texts.Israel },
        { value: 'Italy', label: texts.Italy },
        { value: 'Jamaica', label: texts.Jamaica },
        { value: 'Japan', label: texts.Japan },
        { value: 'Jordan', label: texts.Jordan },
        { value: 'Kazakhstan', label: texts.Kazakhstan },
        { value: 'Kenya', label: texts.Kenya },
        { value: 'Kiribati', label: texts.Kiribati },
        { value: 'Kosovo', label: texts.Kosovo },
        { value: 'Kuwait', label: texts.Kuwait },
        { value: 'Kyrgyzstan', label: texts.Kyrgyzstan },
        { value: 'Laos', label: texts.Laos },
        { value: 'Latvia', label: texts.Latvia },
        { value: 'Lebanon', label: texts.Lebanon },
        { value: 'Lesotho', label: texts.Lesotho },
        { value: 'Liberia', label: texts.Liberia },
        { value: 'Libya', label: texts.Libya },
        { value: 'Liechtenstein', label: texts.Liechtenstein },
        { value: 'Lithuania', label: texts.Lithuania },
        { value: 'Luxembourg', label: texts.Luxembourg },
        { value: 'Madagascar', label: texts.Madagascar },
        { value: 'Malawi', label: texts.Malawi },
        { value: 'Malaysia', label: texts.Malaysia },
        { value: 'Maldives', label: texts.Maldives },
        { value: 'Mali', label: texts.Mali },
        { value: 'Malta', label: texts.Malta },
        { value: 'Marshall Islands', label: texts.MarshallIslands },
        { value: 'Mauritania', label: texts.Mauritania },
        { value: 'Mauritius', label: texts.Mauritius },
        { value: 'Mexico', label: texts.Mexico },
        { value: 'Micronesia', label: texts.Micronesia },
        { value: 'Moldova', label: texts.Moldova },
        { value: 'Monaco', label: texts.Monaco },
        { value: 'Mongolia', label: texts.Mongolia },
        { value: 'Montenegro', label: texts.Montenegro },
        { value: 'Morocco', label: texts.Morocco },
        { value: 'Mozambique', label: texts.Mozambique },
        { value: 'Myanmar', label: texts.Myanmar },
        { value: 'Namibia', label: texts.Namibia },
        { value: 'Nauru', label: texts.Nauru },
        { value: 'Nepal', label: texts.Nepal },
        { value: 'Netherlands', label: texts.Netherlands },
        { value: 'New Zealand', label: texts.NewZealand },
        { value: 'Nicaragua', label: texts.Nicaragua },
        { value: 'Niger', label: texts.Niger },
        { value: 'Nigeria', label: texts.Nigeria },
        { value: 'North Korea', label: texts.NorthKorea },
        { value: 'North Macedonia', label: texts.NorthMacedonia },
        { value: 'Norway', label: texts.Norway },
        { value: 'Oman', label: texts.Oman },
        { value: 'Pakistan', label: texts.Pakistan },
        { value: 'Palau', label: texts.Palau },
        { value: 'Palestine', label: texts.Palestine },
        { value: 'Panama', label: texts.Panama },
        { value: 'Papua New Guinea', label: texts.PapuaNewGuinea },
        { value: 'Paraguay', label: texts.Paraguay },
        { value: 'Peru', label: texts.Peru },
        { value: 'Philippines', label: texts.Philippines },
        { value: 'Poland', label: texts.Poland },
        { value: 'Portugal', label: texts.Portugal },
        { value: 'Qatar', label: texts.Qatar },
        { value: 'Romania', label: texts.Romania },
        { value: 'Russia', label: texts.Russia },
        { value: 'Rwanda', label: texts.Rwanda },
        { value: 'Saint Kitts and Nevis', label: texts.SaintKittsandNevis },
        { value: 'Saint Lucia', label: texts.SaintLucia },
        { value: 'Saint Vincent and the Grenadines', label: texts.SaintVincentandtheGrenadines },
        { value: 'Samoa', label: texts.Samoa },
        { value: 'San Marino', label: texts.SanMarino },
        { value: 'Sao Tome and Principe', label: texts.SaoTomeandPrincipe },
        { value: 'Saudi Arabia', label: texts.SaudiArabia },
        { value: 'Senegal', label: texts.Senegal },
        { value: 'Serbia', label: texts.Serbia },
        { value: 'Seychelles', label: texts.Seychelles },
        { value: 'Sierra Leone', label: texts.SierraLeone },
        { value: 'Singapore', label: texts.Singapore },
        { value: 'Slovakia', label: texts.Slovakia },
        { value: 'Slovenia', label: texts.Slovenia },
        { value: 'Solomon Islands', label: texts.SolomonIslands },
        { value: 'Somalia', label: texts.Somalia },
        { value: 'South Africa', label: texts.SouthAfrica },
        { value: 'South Korea', label: texts.SouthKorea },
        { value: 'South Sudan', label: texts.SouthSudan },
        { value: 'Spain', label: texts.Spain },
        { value: 'Sri Lanka', label: texts.SriLanka },
        { value: 'Sudan', label: texts.Sudan },
        { value: 'Suriname', label: texts.Suriname },
        { value: 'Sweden', label: texts.Sweden },
        { value: 'Switzerland', label: texts.Switzerland },
        { value: 'Syria', label: texts.Syria },
        { value: 'Taiwan', label: texts.Taiwan },
        { value: 'Tajikistan', label: texts.Tajikistan },
        { value: 'Tanzania', label: texts.Tanzania },
        { value: 'Thailand', label: texts.Thailand },
        { value: 'Timor-Leste', label: texts.TimorLeste },
        { value: 'Togo', label: texts.Togo },
        { value: 'Tonga', label: texts.Tonga },
        { value: 'Trinidad and Tobago', label: texts.TrinidadandTobago },
        { value: 'Tunisia', label: texts.Tunisia },
        { value: 'Turkey', label: texts.Turkey },
        { value: 'Turkmenistan', label: texts.Turkmenistan },
        { value: 'Tuvalu', label: texts.Tuvalu },
        { value: 'Uganda', label: texts.Uganda },
        { value: 'Ukraine', label: texts.Ukraine },
        { value: 'United Arab Emirates', label: texts.UnitedArabEmirates },
        { value: 'United Kingdom', label: texts.UnitedKingdom },
        { value: 'USA', label: texts.UnitedStatesofAmerica },
        { value: 'Uruguay', label: texts.Uruguay },
        { value: 'Uzbekistan', label: texts.Uzbekistan },
        { value: 'Vanuatu', label: texts.Vanuatu },
        { value: 'Vatican City', label: texts.VaticanCity },
        { value: 'Venezuela', label: texts.Venezuela },
        { value: 'Vietnam', label: texts.Vietnam },
        { value: 'Yemen', label: texts.Yemen },
        { value: 'Zambia', label: texts.Zambia },
        { value: 'Zimbabwe', label: texts.Zimbabwe },
    ], [texts])
    const languageOptions = useMemo(() => [
        { value: 'en', label: texts.english },
        { value: 'es', label: texts.spanish }
    ], [texts])
    //TODO: Check carrier options (some might be unnecessary)
    const carrierOptions = useMemo(() => [
        { value: 'att', label: texts.att },
        { value: 'boost', label: texts.boost },
        { value: 'cricket', label: texts.cricket },
        { value: 'sprint', label: texts.sprint },
        { value: 'tmobile', label: texts.tmobile },
        { value: 'verizon', label: texts.verizon },
        { value: 'virgin', label: texts.virgin },
        { value: 'other', label: texts.other }
    ], [texts])
    const maritalStatusOptions = useMemo(() => [
        { value: 'MARRIED', label: texts.married },
        { value: 'SINGLE', label: texts.single },
        { value: 'WIDOWED', label: texts.widowed },
        { value: 'SEPARATED', label: texts.separated },
    ], [texts])
    const tshirtOptions = useMemo(() => [
        { value: 'YXS', label: texts.YXS },
        { value: 'YS', label: texts.YS },
        { value: 'YM', label: texts.YM },
        { value: 'YL', label: texts.YL },
        { value: 'YXL', label: texts.YXL },
        { value: 'AS', label: texts.AS },
        { value: 'AM', label: texts.AM },
        { value: 'AL', label: texts.AL },
        { value: 'AXL', label: texts.AXL },
        { value: 'AXXL', label: texts.AXXL },
    ], [texts])

    const toOptionalLabel = useCallback((label: string) => `${label} (${texts.optional})`, [texts])

    return { labels, sexOptions, nationalityOptions, languageOptions, carrierOptions, maritalStatusOptions, stateOptions, tshirtOptions, toOptionalLabel }
}