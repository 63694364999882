import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Grid, Typography, List, Button } from '@material-ui/core';
import { useTextLang } from '../../Contexts/LangContext'
import { MatchProps, JSONt } from '../../types';
import { adminRoutes } from '../Admin'
import { adminQuery } from '../../Global/ServerConnection';
import { useRouter } from '../../Contexts/RouterContext';
import { Link as RouterLink } from 'react-router-dom';
import LoadingPage from '../../Global/LoadingPage';
import { EventRegisters, Participant } from '../admin-types'

const viewParticipantsTexts = {
    title: {
        en: (eventTitle: string) => `Participants for ${eventTitle}`,
    },
    viewFamily: {
        en: 'View Family'
    },
    total: {
        en: 'Total'
    },
    emails: {
        en: 'Emails'
    },
    printParticipants: {
        en: 'Print Participants'
    }
}

export default function ViewParticipants({ match }: MatchProps) {
    const eventID = match.params.id
    const texts = useTextLang(viewParticipantsTexts)
    const [registrations, setRegistrations] = useState<EventRegisters>()
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const processRegisters = (registrations: EventRegisters) => {
            setRegistrations(registrations)
            setLoading(false)
        }
        return adminQuery('eventRegisters', processRegisters, `eventid:"${eventID}"`, 'participants{familyid,personid,name,tshirtSize,invoiceid,paymentid},emails,title')
    }, [eventID])

    return (
        <Grid container direction='column' spacing={1}>
            {registrations &&
                <>
                    <Grid item>
                        <Typography variant='h6' color='textSecondary'>
                            {texts.title(registrations.title)}
                        </Typography>
                    </Grid>
                    <ParticipantList participants={registrations.participants} />
                    <Typography paragraph>
                        {`${texts.total}: ${registrations.participants.length}`}
                    </Typography>
                    <Typography>
                        {`${texts.emails}:`}
                    </Typography>
                    <Typography>
                        {registrations.emails.join(', ')}
                    </Typography>
                </>
            }
            <Button color='primary' component={RouterLink} to={adminRoutes.printEventParticipants(eventID)}>
                {texts.printParticipants}
            </Button>
            <LoadingPage loading={loading} />
        </Grid>
    )
}

function ParticipantList({ participants }: { participants: Participant[] }) {
    const families = useMemo(() => Object.entries(
        participants.reduce((groups, { familyid, ...person }) => {
            //TODO: Remove once all families are in new database
            if (!familyid) {
                return { ...groups, noFamily: [...groups.noFamily, person] }
            }
            ////////////////////////////////////////////////////////////////
            else {
                return { ...groups, [familyid]: [...(groups[familyid] || []), person] }
            }
        }, { noFamily: [] } as JSONt<Omit<Participant, 'familyid'>[]>)
    ), [participants])
    return (
        <List>
            <hr/>
            {families.map(([familyid, persons]) =>
                <Family familyid={familyid} persons={persons} key={familyid} />
            )}
        </List >
    )
}

type FamilyProps = {
    familyid: string,
    persons: Omit<Participant, 'familyid'>[]
}
function Family({ familyid, persons }: FamilyProps) {
    const texts = useTextLang(viewParticipantsTexts)
    const { navigateTo } = useRouter()

    const viewFamily = useCallback(() => {
        navigateTo(adminRoutes.viewFamily(familyid))
    }, [navigateTo, familyid])

    return (
        <>
            <Grid container direction='row' alignItems='center'>
                <Grid item>
                    {/*TODO: REMOVE familyid condition once all families are in new database*/}
                    <Button color='primary' onClick={viewFamily} disabled={familyid === 'noFamily'}>
                        {texts.viewFamily}
                    </Button>
                </Grid>
                <Grid item xs>
                    <Grid container direction='column' alignItems='center'>
                        {persons.map(({personid, name, ...props}) => {
                            return (
                                //TODO: REMOVE || name once all families are in new database
                                <AdminPersonInfo personid={personid} name={name} key={personid||name} {...props}/>
                            )
                        })}
                    </Grid>
                </Grid>
            </Grid>
            <hr/>
        </>
    )
}

function AdminPersonInfo({ personid, name, tshirtSize, invoiceid, paymentid }: Omit<Participant, 'familyid'>) {
    return (
        <>
            <Grid container direction='row'>
                <Grid item xs={3}>{name}</Grid>
                <Grid item xs={1}>{tshirtSize}</Grid>
            </Grid>
        </>
    )
}
