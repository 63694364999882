import React, { useCallback, useState, useEffect } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Card, Divider, Button, Theme } from '@material-ui/core';

import EventContents from './EventContents'
import { MobileNavigation, DesktopNavigation } from '../Global/BottomNavigation'
import { makeStyles, useTheme } from '@material-ui/styles';
import { useRouter, routes } from '../Contexts/RouterContext';
import { useLang } from '../Contexts/LangContext';
import FullPageFormat from '../Global/FullPageFormat';
import { MatchProps, Callback } from '../types';
import { FullEvent } from '../server-types';
import { query } from '../Global/ServerConnection';
import { useAuth } from '../Contexts/AuthContext';
import EventNotAllowedDialog from '../Global/EventNotAllowedDialog';
import { useBasicTexts } from '../Global/GlobalItems';
import LoadingPage from '../Global/LoadingPage';

const useStyles = makeStyles(theme => ({
    contentCard: {
        margin: theme.spacing(5),
    },
    navigationCard: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
    },
}))

export default function ViewEvent({ match }: MatchProps) {
    const { goBack, navigateTo, routeParams } = useRouter()
    const id = match.params.id
    const [{ approvedFamiliesOnly, eventid, ...eventData }, setEventData] = useState<Partial<FullEvent>>({})
    const [eventInfoID, setEventInfoID] = useState<string>('')
    const [eventNotAllowedOpen, setEventNotAllowedOpen] = useState(false)
    const [loading, setLoading] = useState(true)

    const classes = useStyles()
    const theme = useTheme<Theme>()
    const matches = useMediaQuery(theme.breakpoints.up('sm'))
    const { language } = useLang()
    const { isAuth, authQuery } = useAuth()

    useEffect(() => {
        const processEventData = ({eventInfoID, ...event}: Partial<FullEvent>) => {
            setEventData(event)
            setEventInfoID(eventInfoID || '')
            setLoading(false)
        }

        const info = routeParams.type === 'info'
        if (isAuth) {
            return authQuery('fullEventInfo', processEventData, `id:"${id}",info:${info},language:"${language}"`, 'eventInfoID,eventid,sessions{sessionid,title,description,location,dateStart,dateEnd}docSrc,title,subtitle,description,location,status,imageHD,imageRect,price,suggestedDonation,priceDescription,individualPrice,approvedFamiliesOnly')
        } else {
            return query('fullEventInfo', processEventData, `id:"${id}",info:${info},language:"${language}"`, 'eventInfoID,eventid,sessions{sessionid,title,description,location,dateStart,dateEnd}docSrc,title,subtitle,description,location,status,imageHD,imageRect,price,suggestedDonation,priceDescription,individualPrice,approvedFamiliesOnly')
        }
    }, [isAuth, id, language, routeParams.type, authQuery])

    const register = useCallback(() => {
        if (eventid) {
            navigateTo(routes.addEvent(eventid))
        } else {
            navigateTo(routes.chooseSession(eventInfoID))
        }
    }, [navigateTo, eventid, eventInfoID])

    const openEventNotAllowed = useCallback(() => {
        setEventNotAllowedOpen(true)
    }, [])

    const closeEventNotAllowed = useCallback(() => {
        setEventNotAllowedOpen(false)
    }, [])

    return (
        <FullPageFormat>
            {matches
                ? <Card className={classes.contentCard}>
                    <EventContents eventInfoID={eventInfoID} desktop={matches} eventData={eventData} />
                    <Divider variant='middle' />
                    <DesktopNavigation>
                        <Buttons register={register} openEventNotAllowed={openEventNotAllowed} goBack={goBack} approvedFamiliesOnly={approvedFamiliesOnly} />
                    </DesktopNavigation>
                </Card>
                : <>
                    <EventContents eventInfoID={eventInfoID} desktop={matches} eventData={eventData} />
                    <MobileNavigation>
                        <Buttons register={register} openEventNotAllowed={openEventNotAllowed} goBack={goBack} approvedFamiliesOnly={approvedFamiliesOnly} />
                    </MobileNavigation>
                </>
            }
            <EventNotAllowedDialog open={eventNotAllowedOpen} handleClose={closeEventNotAllowed} />
            <LoadingPage loading={loading} />
        </FullPageFormat >
    )
}

type ButtonsProps = {
    register: Callback,
    openEventNotAllowed: Callback,
    goBack: Callback,
    approvedFamiliesOnly?: boolean,
}
function Buttons({ register, openEventNotAllowed, goBack, approvedFamiliesOnly }: ButtonsProps) {
    const { isAuth, registrationStep } = useAuth()
    const texts = useBasicTexts()

    const handleRegisterClick = useCallback(() => {
        if (isAuth && approvedFamiliesOnly && registrationStep === 'DONE') {
            openEventNotAllowed()
        } else {
            register()
        }
    }, [isAuth, registrationStep, approvedFamiliesOnly, register, openEventNotAllowed])

    return (
        <>
            <Button onClick={goBack} disableRipple color='primary'>
                {texts.back}
            </Button>
            <Button color='primary' disableRipple onClick={handleRegisterClick}>
                {texts.register}
            </Button>
        </>
    )
}