import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'

import Image from '../../Global/Image'
import { Card, Hidden, Grid, Typography, Button, CardActions, CardContent } from '@material-ui/core';
import { useTextLang, useLang } from '../../Contexts/LangContext';
import { routes, useRouter } from '../../Contexts/RouterContext';
import { PersonRegister, CartEntry, EventDetails } from '../../server-types';
import { Callback, Consumer, TriConsumer } from '../../types';
import { useAuth } from '../../Contexts/AuthContext';
import { shortFormatDateRange, LineTypography } from '../../Global/GlobalItems';

const eventCardTexts = {
    participantsTitle: {
        en: 'Participants',
        es: 'Participantes'
    },
    volunteersTitle: {
        en: 'Volunteers',
        es: 'Voluntarios'
    },
    subtotal: {
        en: 'Subtotal',
        es: 'Subtotal'
    },
    editButton: {
        en: 'Edit',
        es: 'Modificar'
    },
    removeButton: {
        en: 'Remove',
        es: 'Eliminar'
    },
    moreInfoButton: {
        en: 'More Info',
        es: 'Mas información'
    },
    nextSession: {
        en: 'Next Sesion:',
    }
}

const useStyles = makeStyles(theme => ({
    card: {
        width: '100%',
    },
    fullHeight: {
        height: '100%',
    },
    desktopContainer: {
        padding: theme.spacing(1),
    },
    daction: {
        justifyContent: 'flex-end',
        padding: 0,
    },
    maction: {
        display: 'flex',
    },
    strikethrough: {
        textDecoration: 'line-through',
    }
}))

type EventCardProps = CartEntry & {
    volunteering?: boolean,
    editable?: boolean,
    removeEvent?: Consumer<string>,
    totalFunc?: TriConsumer<string, number, number>
}
export default function EventCard({ volunteering, editable, eventid, removeEvent, persons, totalFunc, discountMoney, discountPercent }: EventCardProps) {
    const [event, setEvent] = useState<EventDetails>({})
    // const [title, setTitle] = useState('')
    // const [subtitle, setSubtitle] = useState('')
    // const [imageRect, setImageRect] = useState<string>()
    // const [imageSqr, setImageSqr] = useState<string>()
    // const [price, setPrice] = useState(0)
    // const [individualPrice, setIndividualPrice] = useState(false)
    const { language } = useLang()
    const { authQuery } = useAuth()

    const subtotal = useMemo(() => {
        const price = event.price || 0
        return event.individualPrice ? price * persons.length : price
    }, [event, persons.length])

    const total = useMemo(() => {
        let percent = 1
        if (discountPercent !== null && discountPercent !== undefined)
            percent = discountPercent
        let money = 0
        if (discountMoney !== null && discountMoney !== undefined)
            money = discountMoney
        return subtotal * percent - money
    }, [subtotal, discountPercent, discountMoney])

    useEffect(() => {
        const processEvent = (event?: EventDetails) => {
            if (event)
                setEvent(event)
        }

        return authQuery('eventDetails', processEvent, `eventid:"${eventid}",language:"${language}"`, 'imageSqr,imageRect,title,subtitle,nextSessionStart,nextSessionEnd,price,suggestedDonation,individualPrice')
    }, [authQuery, eventid, language])

    useEffect(() => {
        if (editable && totalFunc) {
            const suggestedDonation = event.suggestedDonation || 0
            totalFunc(eventid, total, event.individualPrice ? suggestedDonation * persons.length : suggestedDonation)
        }

        return () => {
            if (editable && totalFunc)
                totalFunc(eventid, 0, 0)
        }
    }, [eventid, editable, total, event, totalFunc, persons.length])

    const remove = useCallback(() => {
        if (removeEvent) {
            removeEvent(eventid)
        }
    }, [removeEvent, eventid])

    const classes = useStyles()

    const nextSession = useMemo(() => shortFormatDateRange(event.nextSessionStart, event.nextSessionEnd), [event])

    const commonProps = {
        title: event.title,
        subtitle: event.subtitle,
        volunteering,
        editable,
        eventid,
        imageAlt: event.title,
        price: subtotal,
        persons,
        removeEvent: remove,
        discountedPrice: total,
        nextSession,
    }

    return (
        <Card className={classes.card}>
            <Hidden smUp>
                <MobileContents imageSrc={event.imageRect} {...commonProps} />
            </Hidden>
            <Hidden xsDown>
                <DesktopContents imageSrc={event.imageSqr} {...commonProps} />
            </Hidden>
        </Card>
    )
}

type ContentsProps = {
    eventid: string,
    persons: PersonRegister[],
    title?: string,
    subtitle?: string,
    imageSrc?: string,
    imageAlt?: string,
    price: number,
    discountedPrice: number,
    nextSession: string,
    editable?: boolean,
    volunteering?: boolean,
    removeEvent: Callback
}

function DesktopContents({ eventid, volunteering, title, subtitle, nextSession, imageSrc, imageAlt, price, discountedPrice, persons, editable, removeEvent }: ContentsProps) {
    const classes = useStyles()
    const texts = useTextLang(eventCardTexts)
    const { navigateTo } = useRouter()

    const goToEvent = useCallback(() => {
        navigateTo(routes.viewEvent(eventid), false, { type: 'event' })
    }, [navigateTo, eventid])

    return (
        <Grid container spacing={2} className={classes.desktopContainer}>
            <Grid item xs={3}>
                <Image fullHeight src={imageSrc} align='right' alt={imageAlt} />
            </Grid>
            <Grid item xs={true} container spacing={2} direction='column'>
                <Grid item>
                    <Typography gutterBottom>
                        {title}
                    </Typography>
                    <Typography variant='body1' color='textSecondary'>
                        {subtitle}
                    </Typography>
                </Grid>
                <Grid item>
                    {nextSession &&
                        <LineTypography variant='body1'>
                            {`${texts.nextSession}\n${nextSession}`}
                        </LineTypography>
                    }
                </Grid>
            </Grid>
            <Grid item>
                <Grid container direction='column' justify='space-between' className={classes.fullHeight}>
                    <Grid item container direction='column'>
                        <Typography gutterBottom>
                            {volunteering ? texts.volunteersTitle : texts.participantsTitle}
                        </Typography>
                        {persons.map(({ name, tshirtSize }, index) => (
                            <Typography variant='button' key={index}>
                                {`${index + 1}. ${name}${tshirtSize ? `: ${tshirtSize}` : ''}`}
                            </Typography>
                        ))}
                    </Grid>
                    {!editable &&
                        <Grid item>
                            <Button color='primary' className={classes.daction} onClick={goToEvent}>
                                {texts.moreInfoButton}
                            </Button>
                        </Grid>
                    }
                </Grid>
            </Grid>
            {editable &&
                <Grid item>
                    <Grid className={classes.fullHeight}
                        container direction='column' justify='space-between'>
                        <Grid item container direction='column' alignItems='flex-end'>
                            <Typography gutterBottom>
                                {texts.subtotal}
                            </Typography>
                            <Grid item>
                                <Typography display='inline' gutterBottom >
                                    ${discountedPrice}
                                </Typography>
                                &nbsp;
                                {price !== discountedPrice &&
                                    <Typography color='error' display='inline' variant='body2' className={classes.strikethrough} gutterBottom>
                                        {price}
                                    </Typography>}
                            </Grid>
                        </Grid>
                        <Grid item container direction='column' alignItems='flex-end'>
                            <Button color='primary' onClick={removeEvent}
                                className={classes.daction}>
                                {texts.removeButton}
                            </Button>
                            <Button color='primary' component={RouterLink} to={routes.addEvent(eventid)}
                                className={classes.daction}>
                                {texts.editButton}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            }
        </Grid>
    )
}

function MobileContents({ eventid, title, nextSession, imageSrc, imageAlt, price, discountedPrice, editable, persons, removeEvent }: ContentsProps) {
    const classes = useStyles()
    const texts = useTextLang(eventCardTexts)
    const { navigateTo } = useRouter()

    const goToEvent = useCallback(() => {
        navigateTo(routes.viewEvent(eventid), false, { type: 'event' })
    }, [navigateTo, eventid])

    return (
        <>
            <CardContent>
                <Typography gutterBottom>
                    {title}
                </Typography>
                <Typography variant='button' display='block' gutterBottom >
                    {persons.map(({ name }) => name).join(' & ')}
                </Typography>
                {nextSession &&
                    <LineTypography variant='body1' gutterBottom>
                        {`${texts.nextSession}\n${nextSession}`}
                    </LineTypography>
                }
                {editable &&
                    <>
                        <Typography display='inline' gutterBottom >
                            {`${texts.subtotal}: $${discountedPrice}`}
                        </Typography>
                        &nbsp;
                        {price !== discountedPrice &&
                            <Typography color='error' display='inline' variant='body2' className={classes.strikethrough} gutterBottom>
                                {price}
                            </Typography>}
                    </>}
            </CardContent>
            <Image src={imageSrc} alt={imageAlt} fullHeight align='right' rect />
            <CardActions className={classes.maction} disableSpacing>
                <Grid container justify='space-between'>
                    {editable
                        ? <>
                            <Button color='primary' component={RouterLink} to={routes.addEvent(eventid)}>
                                {texts.editButton}
                            </Button>
                            <Button color='primary' onClick={removeEvent}>
                                {texts.removeButton}
                            </Button>
                        </>
                        : <Button color='primary' onClick={goToEvent}>
                            {texts.moreInfoButton}
                        </Button>
                    }
                </Grid>
            </CardActions>
        </>
    )
}