import axios from "axios";
import { Consumer, JSONt } from "../types";
import { tokenKey } from "../Contexts/AuthContext";

export function makeAxiosCall<Response>(
    mutation: boolean,
    query: string,
    processResponse: Consumer<Response>,
    params?: string,
    values?: string,
    cancellable?: boolean
) {
    let cancelTokenParams = {}
    let cancelCall = undefined
    if (cancellable) {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        cancelTokenParams = { cancelToken: source.token }
        cancelCall = source.cancel
    }
    const wrappedParams = params === undefined ? '' : `(${params})`
    const wrappedValues = values === undefined ? '' : `{${values}}`
    const graphqlString = `${mutation ? 'mutation' : ''}{${query}${wrappedParams}${wrappedValues}}`
    axios.post(`/graphql?query=${encodeURIComponent(graphqlString)}`, {}, cancelTokenParams)
        .then(response => {
            processResponse(response.data.data[query])
        })
        .catch((thrown) => {
            if (axios.isCancel(thrown)) {
                // console.log('Request canceled', thrown.message);
            } else {
                // console.log('AXIOS ERROR')
            }
        })

    return cancelCall
}

export function query<Response>(
    query: string,
    processFunc: Consumer<Response>,
    params?: string,
    values?: string,
) {
    return makeAxiosCall(false, query, processFunc, params, values, true)
}

export function mutation<Response>(
    mutation: string,
    processFunc: Consumer<Response>,
    params?: string,
    values?: string,
) {
    makeAxiosCall(true, mutation, processFunc, params, values)
}

function makeAdminAxiosCall<Response>(mutation: boolean, query: string, processResponse: Consumer<Response>, params?: string, values?: string, cancellable?: boolean) {
    const wrappedParams = params === undefined ? '' : `(${params})`
    const wrappedValues = values === undefined ? '' : `{${values}}`
    const sessionToken = localStorage.getItem(tokenKey)
    const processCall = (admin?: JSONt<Response>) => {
        if (admin) {
            processResponse(admin[query])
        } else {
            // console.log('ACCESS DENIED')
        }
    }
    if (sessionToken) {
        return makeAxiosCall(mutation, 'admin', processCall, `sessionToken:"${sessionToken}"`, `${query}${wrappedParams}${wrappedValues}`, cancellable)
    } else {
        return undefined
    }
}

export function adminQuery<Response>(query: string, processFunc: Consumer<Response>, params?: string, values?: string) {
    return makeAdminAxiosCall(false, query, processFunc, params, values, true)
}

export function adminMutation<Response>(mutation: string, processFunc: Consumer<Response>, params?: string, values?: string){
    makeAdminAxiosCall(true, mutation, processFunc, params, values)
}