import React, { useState, useCallback } from 'react'
import FormContainer from './FormContainer';
import { useTextLang } from '../Contexts/LangContext';
import { useAuth } from '../Contexts/AuthContext';
import { useRouter, routes } from '../Contexts/RouterContext';
import useForm, { FormatForm } from '../Form/FormUtils';
import PersonFields, { fullPersonRules, personDefaultValues } from '../Form/PersonFields';
import FormNavigation from '../Form/FormNavigation';
import { useBasicTexts } from '../Global/GlobalItems';
import { PersonInfoType } from '../types';

const spouseInfoTexts = {
    title: {
        en: 'Spouse Info'
    }
}

const spouseRules = { ...fullPersonRules(true), sex: { ...fullPersonRules(true).sex, required: false } }
export default function SpouseInfo() {
    const [loading, setLoading] = useState(false)

    const texts = useTextLang(spouseInfoTexts)
    const { updateRegistrationStep, authMutation } = useAuth()
    const { navigateTo } = useRouter()
    const basicTexts = useBasicTexts()

    const finish = useCallback((values: Record<keyof PersonInfoType, string>) => {
        setLoading(true)
        const processMutation = (success: boolean) => {
            if (success) {
                updateRegistrationStep()
            } else {
                navigateTo(routes.error)
            }
        }
        authMutation('registerSpouse', processMutation, `firstName:"${values.firstName.trim()}",lastName:"${values.lastName.trim()}",dateOfBirth:"${values.dateOfBirth}",nationality:"${values.nationality}",preferredLanguage:"${values.preferredLanguage}",email:"${values.email}",phoneNumber:"${values.phoneNumber}",phoneCarrier:"${values.phoneCarrier}"`)
    }, [updateRegistrationStep, navigateTo, authMutation])
    
    const { values, setValue, errors, setError, handleSubmit } = useForm(spouseRules, finish, personDefaultValues)

    return (
        <FormContainer title={texts.title}>
            <FormatForm>
                <PersonFields values={values} setValue={setValue} errors={errors} setError={setError} spouse adult/>
            </FormatForm>
            <FormNavigation
                onSubmit={handleSubmit}
                submitText={basicTexts.next}
                loading={loading}
            />
        </FormContainer>
    )
}