import React, { useCallback } from 'react'
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core'
import { useTextLang } from '../Contexts/LangContext';
import { routes, useRouter } from '../Contexts/RouterContext'
import { useBasicTexts } from '../Global/GlobalItems';

const noVolunteerDialogTexts = {
    noVolunteerTitle: {
        en: 'No Events Found'
    },
    noVolunteerContent: {
        en: `You are not registered to volunteer in any events. If you want to volunteer for events, you can click the 'Volunteer' button below.`
    },
    volunteerButton: {
        en: 'Volunteer'
    }
}

export default function NoVolunteerDialog({ open }: {open: boolean}) {
    const texts = useTextLang(noVolunteerDialogTexts)
    const basicTexts = useBasicTexts()
    const { goBack ,navigateTo } = useRouter()

    const goToVolunteer = useCallback(() => {
        navigateTo(routes.about, false, { tab: 'VOLUNTEER' })
    }, [navigateTo])

    return (
        <Dialog
            open={open}
            onClose={goBack}
            disableBackdropClick
            disableEscapeKeyDown>
            <DialogTitle>
                {texts.noVolunteerTitle}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {texts.noVolunteerContent}
                </DialogContentText>
            </DialogContent>
            <DialogActions >
                <Button onClick={goToVolunteer} color='primary'>
                    {texts.volunteerButton}
                </Button>
                <Button onClick={goBack} color='primary'>
                    {basicTexts.back}
                </Button>
            </DialogActions>
        </Dialog>
    )
}