import React, { createContext, useContext, useState, useEffect, useCallback, ReactNode } from 'react';
import { query, makeAxiosCall } from '../Global/ServerConnection';
import { useRouter, routes } from './RouterContext';
import { Canceler } from 'axios';
import { Callback, Consumer, JSONt } from '../types';
import { LoginAttempt, RegistrationStep } from '../server-types';
import { escape } from '../Global/GlobalItems';

export const tokenKey = 'sessionToken'
export const permissionsKey = 'permissions'
const stepKey = 'registrationStep'

type AuthContextType = {
    removeSessionToken: Callback,
    login: (
        user: string,
        password: string,
        goodLogin?: Consumer<RegistrationStep | null>,
        badLogin?: Callback
    ) => void,
    updateRegistrationStep: Callback,
    overrideLogin: Consumer<string>,
    isAuth: boolean,
    registrationStep: RegistrationStep | null,
    authQuery: <R>(query: string, processFunc: Consumer<R>, params?: string, values?: string, cancellable?: boolean) => Canceler | undefined,
    authMutation: <R>(mutation: string, processFunc: Consumer<R>, params?: string, values?: string) => void,
}
const AuthContext = createContext<AuthContextType>({
    removeSessionToken: () => { },
    login: () => { },
    updateRegistrationStep: () => { },
    overrideLogin: () => { },
    isAuth: false,
    registrationStep: null,
    authQuery: () => undefined,
    authMutation: () => { },
});

const AuthProvider = ({ children }: { children: ReactNode }) => {
    const [sessionToken, setSessionToken] = useState(localStorage.getItem(tokenKey))
    const [registrationStep, setRegistrationStep] = useState<RegistrationStep | null>(localStorage.getItem(stepKey) as RegistrationStep || null)
    const { navigateTo } = useRouter()

    const removeSessionToken = useCallback(() => {
        setSessionToken(null)
        setRegistrationStep(null)
        navigateTo(routes.home)
    }, [navigateTo])

    const makeAuthenticatedAxiosCall = useCallback((mutation: boolean, query: string, processResponse: Consumer<any>, params?: string, values?: string, cancellable?: boolean) => {
        const wrappedParams = params === undefined ? '' : `(${params})`
        const wrappedValues = values === undefined ? '' : `{${values}}`
        const sessionToken = localStorage.getItem(tokenKey)
        const processCall = (authenticate?: JSONt<any>) => {
            if (authenticate) {
                processResponse(authenticate[query])
            } else {
                removeSessionToken()
            }
        }
        if (sessionToken) {
            return makeAxiosCall(mutation, 'authenticate', processCall, `sessionToken:"${sessionToken}"`, `${query}${wrappedParams}${wrappedValues}`, cancellable)
        } else {
            return undefined
        }
    }, [removeSessionToken])

    const authQuery = useCallback((query, processFunc, params = undefined, values = undefined) => {
        return makeAuthenticatedAxiosCall(false, query, processFunc, params, values, true)
    }, [makeAuthenticatedAxiosCall])

    const authMutation = useCallback((mutation, processFunc, params = undefined, values = undefined) => {
        makeAuthenticatedAxiosCall(true, mutation, processFunc, params, values)
    }, [makeAuthenticatedAxiosCall])

    const renewToken = useCallback(() => {
        return authQuery('renewToken', setSessionToken)
    }, [authQuery])

    // Renew sessionToken at the beginning ot the session
    useEffect(() => {
        renewToken()
    }, [renewToken])

    const updateRegistrationStep = useCallback(() => {
        return authQuery('getRegistrationStep', setRegistrationStep)
    }, [authQuery])

    // Update localStorage and registration step when sessionToken changes
    useEffect(() => {
        if (sessionToken) {
            localStorage.setItem(tokenKey, sessionToken)
            /* updateRegistrationStep needs to be after localStorage because 
                it is used for authenticated queries */
            updateRegistrationStep()
        } else {
            localStorage.removeItem(tokenKey)
            setRegistrationStep(null)
        }
    }, [sessionToken, updateRegistrationStep])

    // Update localStorage when registrationStep changes
    useEffect(() => {
        if (registrationStep) {
            localStorage.setItem(stepKey, registrationStep)
        } else {
            localStorage.removeItem(stepKey)
        }
    }, [registrationStep])

    //Renew sessionToken every 10 minutes
    useEffect(() => {
        const time = 10 * 60 * 1000
        if (sessionToken) {
            let cancelRenewal: Canceler | null | undefined = () => null
            const timer = setTimeout(() => {
                cancelRenewal = renewToken()
            }, time)

            return () => {
                clearTimeout(timer)
                if (cancelRenewal)
                    cancelRenewal()
            }
        }
    }, [sessionToken, renewToken])

    const login = useCallback((user, password, goodLogin = () => { }, badLogin = () => { }) => {
        const processLogin = (login: LoginAttempt) => {
            if (login.loginStatus) {
                setSessionToken(login.sessionToken)
                setRegistrationStep(login.registrationStep)
                goodLogin(login.registrationStep)
                if (login.permissions)
                    localStorage.setItem(permissionsKey, login.permissions)
            }
            else {
                badLogin(login.error)
            }
        }
        return query('login', processLogin, `user:"${escape(user)}",password:"${escape(password)}"`, 'loginStatus,sessionToken,registrationStep,permissions')
    }, [])

    return (
        <AuthContext.Provider
            value={{
                removeSessionToken, login, updateRegistrationStep,
                overrideLogin: setSessionToken, isAuth: sessionToken !== null, registrationStep, authQuery, authMutation
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export { AuthProvider }
export const useAuth = () => useContext(AuthContext)