import React, { useState, useMemo, useEffect, useCallback } from 'react'
import { Grid, Link, Typography, Button } from '@material-ui/core'

import { makeStyles } from '@material-ui/styles'
import { NotificationType } from '../server-types';
import { query } from './ServerConnection';
import { Close } from '@material-ui/icons';
import { useAuth } from '../Contexts/AuthContext';
import { Consumer } from '../types';

const useStyles = makeStyles(theme => ({
    red: {
        backgroundColor: '#e53935',
        color: 'white'
    },
    yellow: {
        backgroundColor: '#ffeb3b',
        color: 'black'
    },
    blue: {
        backgroundColor: '#4fc3f7',
        color: 'black'
    },
    green: {
        backgroundColor: '#00c853',
        color: 'black'
    },
}))

export default function Notifications() {
    const [notifications, setNotifications] = useState<NotificationType[]>([])
    const { isAuth, authQuery } = useAuth()

    useEffect(() => {
        const processNotifications = (n: NotificationType[]) => {
            setNotifications(n.filter(({ notificationid }) => sessionStorage.getItem(notificationid) == null))
        }
        if (isAuth) {
            return authQuery('getNotifications', processNotifications, undefined, 'notificationid,notification,color')
        } else {
            return query('getNotifications', processNotifications, undefined, 'notificationid,notification,color')
        }
    }, [isAuth, authQuery])

    const handleDismiss = useCallback((id: string) => {
        setNotifications(notifications => {
            sessionStorage.setItem(id, 'dismissed')
            return notifications.filter(({ notificationid }) => notificationid !== id)
        })
    }, [])

    if (notifications.length === 0) {
        return null
    } else {
        return <Notification {...notifications[0]} onDismiss={handleDismiss} />
    }
}

const regex = /<[^<>|]*\|[^<>|]*>/g
function getLinks(notification: string) {
    // console.log(notification.match(regex))
    // console.log((notification.match(regex) || [])
    //     .map(match => match.slice(1, -1).split('|')))
    // console.log(notification.split(regex))

    const links = (notification.match(regex) || [])
        .map(match => match.slice(1, -1).split('|'))
        .map(([text, href]) => ({ text, href }))
    return notification.split(regex)
        .flatMap((text, index) => ([{ text, href: undefined }, links[index]]))
        .filter(elem => elem !== undefined)
}

type NotificationProps = NotificationType & {
    onDismiss?: Consumer<string>,
}
export function Notification({ notification, color, notificationid, onDismiss }: NotificationProps) {
    const classes = useStyles()
    const parsedNotifications = useMemo(() => getLinks(notification), [notification])
    const colorClass = useMemo(() => {
        switch (color) {
            case 'red': return classes.red
            case 'yellow': return classes.yellow
            case 'green': return classes.green
            default: return classes.blue
        }
    }, [color, classes])

    const dismissNotification = useCallback(() => {
        if (typeof onDismiss === 'function')
            onDismiss(notificationid)
    }, [onDismiss, notificationid])

    // console.log(parsedNotifications)
    return (
        <Grid className={colorClass} container justify='flex-end' alignItems='center'>
            <Grid item xs={true} />
            <Grid item>
                {parsedNotifications.map(({ text, href }, index) => {
                    if (href) {
                        return <Link key={index} href={href}>{text}</Link>
                    } else {
                        return <Typography key={index} display='inline' color='inherit'>{text}</Typography>
                    }
                })}
            </Grid>
            <Grid item xs={true} container justify='flex-end' alignItems='center'>
                <Grid item>
                    <Button color='inherit' onClick={dismissNotification}>
                        <Close />
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    )
}