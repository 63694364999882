import React, { useState, useCallback, MutableRefObject } from 'react';
import { CardContent, Grid, CardActions, Typography, InputAdornment } from '@material-ui/core';

import { useTextLang } from '../../Contexts/LangContext';
import { useBasicTexts } from '../../Global/GlobalItems';
import { FieldType, defaultText, defaultSingleCheckBox } from '../../types';
import useForm from '../../Form/FormUtils';
import Text from '../../Form/Components/Text';
import Email from '../../Form/Components/Email';
import NumberField from '../../Form/Components/NumberField';
import SubmitButtons from './SubmitButtons'
import { PayPalOrder } from '../../server-types';
import { mutation } from '../../Global/ServerConnection';
import { useRouter, routes } from '../../Contexts/RouterContext';
import { BasicDialog } from '../../Global/Dialogs';
import PaymentDialog from '../../Register/Payment/PaymentDialog';
import { payPalImageSrc } from '../../Global/Icons';
import { makeStyles } from '@material-ui/styles';

const donateTexts = {
    title: {
        en: 'Help Us Build a Better Tomorrow!',
    },
    subtitle: {
        en: 'The viability of Family Education Institute depends on the generosity of donors like you.'
    },
    firstName: {
        en: 'First Name'
    },
    lastName: {
        en: 'Last Name'
    },
    email: {
        en: 'Email'
    },
    amount: {
        en: 'Donation Amount'
    },
    note: {
        en: 'Optional Note'
    },
    anonymous: {
        en: 'Make Donation Anonymous'
    },
    donate: {
        en: 'Donate'
    },
    successTitle: {
        en: 'Thank You!'
    },
    successContents: {
        en: 'Donations like yours help us support families.'
    }
}

const useStyles = makeStyles(theme => ({
    payPalImage: {
        height: theme.spacing(3),
    },
}))

const donateFormRules = {
    firstName: { type: FieldType.Text, required: true },
    lastName: { type: FieldType.Text, required: true },
    email: { type: FieldType.Email, required: true },
    amount: { type: FieldType.Number, required: true, min: 0.01, max: 10000 },
    note: { type: FieldType.Text },
    anonymous: { type: FieldType.SingleCheckbox },
}

const donateFormDefaultValues = {
    firstName: defaultText,
    lastName: defaultText,
    email: defaultText,
    amount: 0 as number | undefined,
    note: defaultText,
    anonymous: defaultSingleCheckBox,
}

type DonateFormValues = {
    firstName: string,
    lastName: string,
    email: string,
    amount: string,
    note: string,
    anonymous: string,
}

type DonateProps = {
    menuPortalTarget?: MutableRefObject<undefined>,
}
export default function Donate({ menuPortalTarget }: DonateProps) {
    const texts = useTextLang(donateTexts)
    const basicTexts = useBasicTexts()
    const [loading, setLoading] = useState(false)
    const { navigateTo } = useRouter()
    const [payPalOrder, setPayPalOrder] = useState<PayPalOrder>()
    const [success, setSuccess] = useState(false)
    const classes = useStyles()

    const closeSuccessDialog = useCallback(() => {
        setSuccess(false)
    }, [])

    const donate = useCallback((values: DonateFormValues) => {
        setLoading(true)
        const processOrder = (order?: PayPalOrder) => {
            if (order) {
                setPayPalOrder(order)
                setLoading(false)
            } else {
                navigateTo(routes.error)
            }
        }
        if (values) {
            mutation('donate', processOrder, `firstName:"${values.firstName}",lastName:"${values.lastName}",email:"${values.email}",amount:${values.amount},note:"${values.note.trim()}",anonymous:${values.anonymous === 'selected'}`, 'orderid,approve{uri,method}')
        }
    }, [navigateTo])

    const { values, setValue, errors, handleSubmit } = useForm(donateFormRules, donate, donateFormDefaultValues)

    const resetForm = useCallback(() => {
        Object.entries(donateFormDefaultValues).forEach(([key, value]) => {
            setValue(key as any, value)
        })
    }, [setValue])

    const onDonationSuccess = useCallback(() => {
        setSuccess(true)
        setPayPalOrder(undefined)
        resetForm()
    }, [resetForm])

    const cancelOrder = useCallback(() => {
        const processCancellation = (success: boolean) => {
            if (success) {
                setPayPalOrder(undefined)
                setLoading(false)
            } else {
                navigateTo(routes.error)
            }
        }
        if (payPalOrder) {
            mutation('cancelDonation', processCancellation, `orderid:"${payPalOrder.orderid}"`)
        }
    }, [navigateTo, payPalOrder])

    return (
        <>
            <CardContent>
                <Typography align='center' variant='h6'>
                    {texts.title}
                </Typography>
                <Typography align='center' variant='body2' color='textSecondary'>
                    {texts.subtitle}
                </Typography>
                <Grid container direction='column'>
                    <Text field='firstName' label={texts.firstName} value={values.firstName} setValue={setValue} errorMsg={errors.firstName} autoCapitalize='words' />
                    <Text field='lastName' label={texts.lastName} value={values.lastName} setValue={setValue} errorMsg={errors.lastName} autoCapitalize='words' />
                    <Email field='email' label={texts.email} value={values.email} setValue={setValue} errorMsg={errors.email} />
                    <NumberField field='amount' precision={2} value={values.amount} label={texts.amount} setValue={setValue} errorMsg={errors.amount} min={0} max={10000} InputProps={{
                        startAdornment: <InputAdornment position="start">{'$'}</InputAdornment>,
                    }} />
                    <Text field='note' value={values.note} setValue={setValue} errorMsg={errors.note} multiline variant='outlined' rows='5' placeholder={texts.note} />
                    {/* <Grid item container justify='center'>
                        <SingleCheckBox field='anonymous' value={values.anonymous} label={texts.anonymous} setValue={setValue} />
                    </Grid> */}
                </Grid>
            </CardContent>
            <CardActions>
                <SubmitButtons
                    onSubmit={handleSubmit}
                    submitText={
                        <>
                            {texts.donate}
                            &nbsp;
                            &nbsp;
                            <img src={payPalImageSrc} alt='PP' className={classes.payPalImage} />
                        </>
                    }
                    onCancel={resetForm}
                    cancelText={basicTexts.cancel}
                />
            </CardActions>
            <PaymentDialog payPalOrder={payPalOrder} cancelOrder={cancelOrder} onSuccess={onDonationSuccess} loading={loading} checkout={false} />
            <BasicDialog open={success} handleClose={closeSuccessDialog} title={texts.successTitle} contents={texts.successContents} />
        </>
    )
}
