import React, { PropsWithChildren, useCallback } from 'react'
import { Stepper, Step, StepLabel } from '@material-ui/core';
import { Check, InsertInvitationOutlined, CreditCardOutlined, ThumbUpOutlined } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles'

import FullPageFormat from '../Global/FullPageFormat';

// const registerContainerTexts = {
//     events: {
//         en: 'EVENTS'
//     },
//     payment: {
//         en: 'PAYMENT'
//     },
//     success: {
//         en: 'SUCCESS'
//     }
// }

const useStyles = makeStyles(theme => ({
    grey: {
        color: theme.palette.text.secondary
    },
    black: {
        color: theme.palette.text.primary
    }
}))

type Step = 0 | 1 | 2

type RegisterContainerProps = PropsWithChildren<{
    activeStep: Step,
}>
export default function RegisterContainer({ activeStep, children }: RegisterContainerProps) {
    const classes = useStyles()

    const stepIcon = useCallback((step: Step, className: string) => {
        switch (step) {
            case 0: return <InsertInvitationOutlined className={className} />
            case 1: return <CreditCardOutlined className={className} />
            case 2: return <ThumbUpOutlined className={className} />
        }
    }, [])

    const getIcon = useCallback((step: Step) => {
        const className = activeStep >= step ? classes.black : classes.grey

        return (activeStep > step
            ? <Check className={className} />
            : stepIcon(step, className))
    }, [activeStep, classes, stepIcon])

    return (
        <FullPageFormat barContents={
            <Stepper activeStep={activeStep} alternativeLabel={false}>
                <Step>
                    <StepLabel icon={getIcon(0)}>
                        {/* {texts.events} */}
                    </StepLabel>
                </Step>
                <Step>
                    <StepLabel icon={getIcon(1)}>
                        {/* {texts.payment} */}
                    </StepLabel>
                </Step>
                <Step>
                    <StepLabel icon={getIcon(2)}>
                        {/* {texts.success} */}
                    </StepLabel>
                </Step>
            </Stepper>
        }>
            {children}
        </FullPageFormat>
    )
}