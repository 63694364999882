import React from 'react'
import { Link as RouterLink } from 'react-router-dom';
import { Grid, Typography, Link } from '@material-ui/core';
import { useTextLang } from '../Contexts/LangContext';
import FullPageFormat from './FullPageFormat';
import { routes } from '../Contexts/RouterContext';

const errorPageTexts = {
    errorMessage: {
        en: 'You have encountered an error, please try again later.',
        es: 'Hubo algun error, por favor intente mas tarde.'
    },
    goBack: {
        en: 'Go back',
        es: 'Regresar'
    }
}

export default function ErrorPage() {
    const texts = useTextLang(errorPageTexts)
    return (
        <FullPageFormat size='narrow'>
            <Grid container alignItems='center' justify='center' spacing={5}>
                <Grid item >
                    <Typography variant='h5' align='center'>
                        {texts.errorMessage}
                    </Typography>
                </Grid>
                <Grid item container alignItems='center' justify='center'>
                    <Link color='primary' component={RouterLink} to={routes.home}>
                        {texts.goBack}
                    </Link>
                </Grid>
            </Grid>
        </FullPageFormat>
    )
}