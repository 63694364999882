import React, { useCallback, ReactNode } from 'react'
import { Typography, Button, TableRow, Table, TableCell, TableBody, TableFooter, TableHead } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { TableCellProps } from '@material-ui/core/TableCell';
import { TableRowProps } from '@material-ui/core/TableRow';
import { TableProps } from '@material-ui/core/Table';
import { ButtonProps } from '@material-ui/core/Button';
import { Consumer } from '../../types';

const useStyles = makeStyles(theme => ({
    table: {
        borderCollapse: 'collapse',
        border: '3px solid black',
        width: '100%',
        margin: theme.spacing(2, 0),
    },
    head: {
        border: '2px solid black',
    },
    cell: {
        border: '1px solid red',
    },
    bottomRow: {
        borderBottom: '2px solid black',
    },
}))




type AdminTableProps = TableProps & {
    header?: ReactNode,
    footer?: ReactNode,
}
export function AdminTable({ children, header, footer, ...props }: AdminTableProps) {
    const classes = useStyles()
    return (
        <Table className={classes.table} size='small' {...props}>
            {header && <TableHead className={classes.head}>{header}</TableHead>}
            <TableBody>
                {children}
            </TableBody>
            {footer && <TableFooter>{footer}</TableFooter>}
        </Table>
    )
}


type AdminTableRowProps = TableRowProps & {
    lastRow?: boolean
}
export function AdminTableRow({ children, lastRow=false, ...props }: AdminTableRowProps) {
    const classes = useStyles()
    return (
        <TableRow className={lastRow? classes.bottomRow:''} {...props}>
            {children}
        </TableRow>
    )
}


type AdminTableCellProps = TableCellProps & {
    width?: string | number,
    text?: string
}
export function AdminTableCell({ children, width, text, ...props }: AdminTableCellProps) {
    return (
        <TableCell style={{ width:width ||'auto'}}  {...props}>
            {text && <Typography>{text}</Typography>}
            {children}
        </TableCell>
    )
}


type AdminCellButtonProps<T> = {
    onClick: Consumer<T>,
    clickParam: T
} & Omit<ButtonProps, 'onClick'>
export function AdminCellButton<T>({ onClick, clickParam, children, ...props }: AdminCellButtonProps<T>) {
    const handleClick = useCallback(() => {
        onClick(clickParam)
    }, [onClick, clickParam])
    return (
        <Button color='primary' onClick={handleClick} {...props}>
            {children}
        </Button>
    )
}





type AdminViewTableProps = {
    rows: (string | number)[][],
    colsizes: (string | number)[],
    links: string[],
    options?: {
        withTable?: boolean,
    }
}

export function AdminViewTable({ rows, colsizes, links, options: { withTable = true } = {} }: AdminViewTableProps) {
    const getColSize = useCallback(index => { return index < colsizes.length ? colsizes[index] : "auto" }, [colsizes])
    const classes = useStyles()

    //let tableClass = ''
    //if (withTable) {
    //    tableClass = classes.table
    //}

    return (
        <Table className={classes.table}>
            <TableBody>
                {rows.map((row, index) => (
                    <TableRow key={index}>
                        {row.map((value, index) => (
                            <TableCell style={{ padding: 3, borderStyle: 'solid', borderWidth: 1, width: getColSize(index) }} key={index}>
                                <Typography>{value}</Typography>
                                { // <AdminRenderLink link={links[index]} linkTxt={value} />
                                }
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    )
}



/*
type AdminRenderLinkProps = {
    link: string,
    linkTxt: string | number,
}

function AdminRenderLink({ link = '', linkTxt }: AdminRenderLinkProps) {
    const container = useSideBar()
    const navigateToLink = useCallback(() => {
        return (
            <Portal container={container}>
                <Typography>
                    {link}
                </Typography>
            </Portal>
        )}, [container,link])

    if ((link !== '') && (linkTxt!=='')) {
        return <Button onClick={navigateToLink}>{linkTxt}</Button>
    } else {
        return <Typography>{linkTxt || 'NO'}</Typography>
    }
}



function AdminRenderLink({ link = '', linkTxt }: AdminRenderLinkProps) {
    if (link !== '') {
        return <Button color='primary' component={RouterLink} to={link} target='_blank'>
            {linkTxt || 'NO ID'}</Button>
    } else {
        return <Typography>{linkTxt}</Typography>
    }
}


<table>
{ rows.map((row) => (
    <tr>
        {row.map((value, index) => (
            <td width={getColSize(index)}>
                    <AdminRenderLink link={links[index]} linkTxt={value} />
            </td>
        ))}
    </tr>
))}
</table>

            <Grid container direction='column'>
                { rows.map((row) => (
                    <Grid item container >
                        {row.map((value, index) =>
                            <Grid item xs={getColSize(index)} style={{padding:3, borderStyle:"solid", borderWidth:1}}>
                                <Typography>
                                    <AdminRenderLink link={links[index]} linkTxt={value} />
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                ))}
            </Grid>
        </>

*/
