import React from 'react'
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'

import { useTextLang } from '../Contexts/LangContext';
import { routes, useRouter } from '../Contexts/RouterContext'
import { useBasicTexts } from '../Global/GlobalItems';

const noEventsDialogTexts = {
    noEventsTitle: {
        en: 'No Events Found'
    },
    noEventsContent: {
        en: 'You have no events. If you want to register for events, you can add them to your pending events. If you confirmed an order and have not received an email to complete payment, please contact:'
    },
    noEventsContact: {
        en: 'register@FamilyEducationInstitute.org'
    },
    addEventsButton: {
        en: 'Add Events'
    }
}

export default function NoEventsDialog({ open }: {open: boolean}) {
    const texts = useTextLang(noEventsDialogTexts)
    const basicTexts = useBasicTexts()
    const { goBack } = useRouter()
    return (
        <Dialog
            open={open}
            onClose={goBack}
            disableBackdropClick
            disableEscapeKeyDown>
            <DialogTitle>
                {texts.noEventsTitle}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {texts.noEventsContent}
                </DialogContentText>
                <DialogContentText>
                    {texts.noEventsContact}
                </DialogContentText>
            </DialogContent>
            <DialogActions >
                <Button color='primary' component={RouterLink} to={routes.events}>
                    {texts.addEventsButton}
                </Button>
                <Button onClick={goBack} color='primary'>
                    {basicTexts.back}
                </Button>
            </DialogActions>
        </Dialog>
    )
}