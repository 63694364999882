import React, { useMemo } from 'react';
import { Portal, Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useSideBar } from './SideBarContext';
import { Callback } from '../../types';
import { Close } from '@material-ui/icons';
import { ViewPerson } from '../FamilySettings/ViewPerson';
import { ViewFamily } from '../FamilySettings/ViewFamily';
import { ViewInvoice } from '../Accounting/ViewInvoice';
import { ViewPayment } from '../Accounting/ViewPayment';
import { SideBarContents } from '../admin-types';

const useStyles = makeStyles(theme => ({
    buttonContainer: {
        padding: theme.spacing(2),
    },
    closeButton: {
        color: 'red'
    }
}))

type SideBarPortalProps = SideBarContents & {
    handleClose: Callback
}
export default function SideBarPortal({ type, id, handleClose }: SideBarPortalProps) {
    const container = useSideBar()
    const classes = useStyles()

    const contents = useMemo(() => {
        switch (type) {
            case 'person': return <ViewPerson personID={id} />
            case 'family': return <ViewFamily familyID={id} />
            case 'invoice': return <ViewInvoice invoiceID={id} />
            case 'payment': return <ViewPayment paymentID={id} />
        }
    }, [type, id])

    if (container === null) {
        return null
    }
    return (
        <Portal container={container}>
            <hr />
            <Grid container className={classes.buttonContainer} justify='flex-end'>
                <Grid item>
                    <Button className={classes.closeButton} onClick={handleClose}>
                        <Close />
                    </Button>
                </Grid>
            </Grid>
            {contents}
            <hr />
        </Portal>
    )
}


