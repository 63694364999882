import React, { useState, useEffect } from 'react'
import { Typography } from '@material-ui/core';
import { MatchProps } from '../../types';
import { adminQuery } from '../../Global/ServerConnection';
import { Admin_InvoiceType } from '../admin-types';
import { AdminViewTable } from '../Common/ViewInfo';


// el pip significa union de izq y der, pero gana derecha.
type Admin_InvoiceType_ViewOnly=Record<keyof Admin_InvoiceType, string> & {discounts: string[]}

export default function ViewInvoicePage({ match }: MatchProps) {
    return <ViewInvoice invoiceID={match.params.id}/>    
}

export function ViewInvoice({invoiceID}: {invoiceID: string}) {
    const [invoiceInfo, setInvoiceInfo] = useState<Admin_InvoiceType_ViewOnly>()

    useEffect(() => {
        const processOriginalValues = (values?: Admin_InvoiceType_ViewOnly) => {'auto'
            if (values)
                setInvoiceInfo(values)
        }
        return adminQuery('getInvoiceInfo', processOriginalValues, `invoiceid:"${invoiceID}"`, 'invoiceid,ip_address,user_agent,orderid,paymentid,familyid,discounts,payment,comments,dateCreated,dateLastUpdate,donation')
    }, [invoiceID])

	if (invoiceInfo) {
		return <AdminDisplayInvoice {...invoiceInfo} />
    } else {
        return  <Typography>Unk invoiceID</Typography>
    }
}



function AdminDisplayInvoice(values: Admin_InvoiceType_ViewOnly) {
    const rows=[
        ['FamilyID',values.familyid],
        ['InvoiceID',values.invoiceid],
        ['OrderID',values.orderid],
        ['Payment',values.payment],
        ['PaymentID',values.paymentid],
        ['Donation',values.donation],
        ['Discounts',values.discounts.map(v => {return (v)}).join(', ')],
        ['Comments',values.comments],
        ['IP',values.ip_address],
        ['UserAgent',values.user_agent],
        ['DateCreated',values.dateCreated],
        ['DateLastUpdate',values.dateLastUpdate],
    ]
    
    return ( <AdminViewTable rows={rows} colsizes={['20%','auto']} links={['','']}></AdminViewTable> )
}

