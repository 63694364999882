import React, { useState, useRef, useEffect, MutableRefObject } from 'react';
import { Card, Tabs, Tab, Divider } from '@material-ui/core';

import { makeStyles } from '@material-ui/styles';
import { useTextLang } from '../../Contexts/LangContext';
import { scrollToView } from '../../Global/GlobalItems';
import Contact from './Contact'
import Volunteer from './Volunteer'
import Donate from './Donate'

const contactVolunteerDonatePropsTexts = {
    contact: {
        en: 'Contact'
    },
    volunteer: {
        en: 'Volunteer'
    },
    donate: {
        en: 'Donate'
    },
}

const useStyles = makeStyles(theme => ({
    fullWidth: {
        width: '100%',
    },
    unitSpacing: {
        padding: theme.spacing(1),
    },
    tab: {
        flexBasis: 100,
    },
}))

type TabType = 'CONTACT' | 'VOLUNTEER' | 'DONATE'

type ContactVolunteerDonateProps = {
    tab?: TabType,
    titleRef?: MutableRefObject<any>,
}
export default function ContactVolunteerDonate({ tab, titleRef }: ContactVolunteerDonateProps) {
    const classes = useStyles()
    const texts = useTextLang(contactVolunteerDonatePropsTexts)
    const [value, setValue] = useState(tab || 'CONTACT')

    const handleChange = (event: React.ChangeEvent<{}>, newValue: TabType) => {
        setValue(newValue);
    }

    // Scroll Into View when we navigated to contact, volunteer or donate
    const cardRef = useRef<any>(null)
    useEffect(() => {
        if (tab) {
            setValue(tab)
            scrollToView(titleRef)
        }
    }, [titleRef, tab])

    return (
        <Card className={classes.fullWidth} innerRef={cardRef}>
            <Tabs value={value} variant='fullWidth' indicatorColor='primary' onChange={handleChange}>
                <Tab label={texts.contact} value={'CONTACT'} className={classes.tab} />
                <Tab label={texts.volunteer} value={'VOLUNTEER'} className={classes.tab} />
                <Tab label={texts.donate} value={'DONATE'} className={classes.tab} />
            </Tabs>
            <Divider />
            {value === 'CONTACT' && <Contact menuPortalTarget={cardRef}/>}
            {value === 'VOLUNTEER' && <Volunteer menuPortalTarget={cardRef}/>}
            {value === 'DONATE' && <Donate menuPortalTarget={cardRef}/>}
        </Card>
    )
}