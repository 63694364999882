import React, { useState, useEffect } from 'react';
import { Grid, Typography, Divider, makeStyles } from '@material-ui/core';

import { MatchProps } from '../../types';
import { adminQuery } from '../../Global/ServerConnection';
import moment from 'moment-timezone'
import { EventInfoType, SessionInfoType } from '../admin-types';
import { FormatForm } from '../../Form/FormUtils';
import LoadingPage from '../../Global/LoadingPage';
import { formatDateRange } from '../../Global/GlobalItems';

const useStyles = makeStyles( theme => ({
    calendarPreview: {
        boxSizing: 'content-box',
        position: 'relative',
        display: 'block',
        fontSize: '0.85em',
        lineHeight: 1.4,
        borderRadius: '3px',
        border: '1px solid',
        width: '100%',
        paddingLeft: 2,
    }
}))

export default function ViewEvent({ match }: MatchProps) {
    const eventID = match.params.id
    const [event, setEvent] = useState<EventInfoType>()
    const [loading, setLoading] = useState(true)
    const classes = useStyles()

    useEffect(() => {
        const processEvent = (event: EventInfoType) => {
            setEvent(event)
            setLoading(false)
        }
        return adminQuery('getEvent', processEvent, `eventid:"${eventID}"`, 'sessions{sessionid,eventid,title{en,es}description{en,es}begin,end,location,language,reminderText{en,es}}docSrc{en,es}tags,title{en,es}legalTitle,subtitle{en,es}description{en,es}location,capacity,teachers{personid,name,role},imageHD,imageRect,imageSqr,price,suggestedDonation,individualPrice,priceDescription{en,es},restrictions{type,hasTShirt,dbLow,dbHigh,minimum,maximum}sortOrder,calendarTitle,mergeInCalendar,calendarBackground,calendarText,approvedFamiliesOnly,visible')
    }, [eventID])

    if (event)
        return (
            <FormatForm>
                <Typography>
                    {`Legal Title: ${event.legalTitle}`}
                </Typography>
                <Typography>
                    {`Title English: ${event.title.en}, Title Spanish: ${event.title.es}`}
                </Typography>
                <Typography>
                    {`Subtitle English: ${event.subtitle.en}, Subtitle Spanish: ${event.subtitle.es}`}
                </Typography>
                <Typography>
                    {`Description English: ${event.description.en}, Description Spanish: ${event.description.es}`}
                </Typography>
                <Typography>
                    {`Location: ${event.location}`}
                </Typography>
                <Typography>
                    {`Tags: [${event.tags.join(', ')}]`}
                </Typography>
                <Typography>
                    {`Capacity: ${event.capacity}`}
                </Typography>
                <Typography>
                    {`Price: $${event.price} (+ $${event.suggestedDonation} suggested donation) ${event.individualPrice ? 'per person' : 'per family'}`}
                </Typography>
                <Typography>
                    {`Price Description English: ${event.priceDescription.en}, Price Description Spanish: ${event.priceDescription.es}`}
                </Typography>
                <Typography>
                    {`Sort order: ${event.sortOrder}`}
                </Typography>
                <Typography>
                    {event.approvedFamiliesOnly ? 'Only for approved families' : 'For all families'}
                </Typography>
                <Typography>
                    {event.visible ? 'Event visible' : 'Event invisible'}
                </Typography>
                <Typography >
                    {`Calendar Preview: ${event.mergeInCalendar ? 'Will merge sessions in calendar' : 'Will not merge sessions in calendar'}`}
                </Typography>
                <div className={classes.calendarPreview} style={{ color: event.calendarText, backgroundColor: event.calendarBackground, borderColor: event.calendarBackground }}>
                    {event.calendarTitle}
                </div>
                <Typography>
                    {`Teachers: ${event.teachers.length === 0 ? 'none' : ''}`}
                </Typography>
                {event.teachers.map(({ personid, name, role }) => (
                    <Typography key={personid}>
                        {`${name}: ${role}`}
                    </Typography>
                ))}
                <Typography>
                    {'Restrictions:'}
                </Typography>
                {event.restrictions.map(({ type, hasTShirt, dbHigh, dbLow, minimum, maximum }, index) => {
                    const youngest = dbHigh ? moment().endOf('y').diff(moment(dbHigh), 'y') : 0
                    const oldest = dbLow ? moment().subtract(1, 'y').startOf('y').diff(moment(dbLow), 'y') : 150
                    return (
                        <Typography key={index}>
                            {`${type} | ${hasTShirt ? 'With T-Shirt' : 'No T-Shirt'} | ages ${youngest} - ${oldest}  | ${minimum || 0} - ${maximum || 100} participants`}
                        </Typography>
                    )
                })}
                <Typography>
                    {'Sessions:'}
                </Typography>
                <Divider />
                {event.sessions.map(({ sessionid, ...session }) => <Session sessionid={sessionid} {...session} key={sessionid} />)}
                <LoadingPage loading={loading} />
            </FormatForm>
        )
    else return null
}

function Session({ title, description, begin, end, location, language, reminderText }: SessionInfoType) {
    return (
        <Grid item container direction='column' spacing={1}>
            {(title.en || title.es) && <Typography>
                {`Title English: ${title.en}, Title Spanish: ${title.es}`}
            </Typography>}
            {(description.en || description.es) && <Typography>
                {`Description English: ${description.en}, Description Spanish: ${description.es}`}
            </Typography>}
            <Typography>
                {`Date: ${formatDateRange(begin, end)}`}
            </Typography>
            {location && <Typography>
                {`Location: ${location}`}
            </Typography>}
            <Typography>
                {`Language: ${language}`}
            </Typography>
            <Typography>
                {`Reminder Text En (As HTML):`}
            </Typography>
            <div dangerouslySetInnerHTML={{__html: reminderText.en}}/>
            <Typography>
                {`Reminder Text Es (As HTML):`}
            </Typography>
            <div dangerouslySetInnerHTML={{__html: reminderText.es}}/>
            <Divider />
        </Grid>
    )
}