import React, { useState, useEffect, useCallback } from 'react'
import { useTextLang } from '../Contexts/LangContext';
import { accountInfoRules, accountInfoDefaultValues } from '../Form/AccountInfoFields';
import FormContainer from './FormContainer';
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, CircularProgress, Grid, useTheme, Theme, useMediaQuery } from '@material-ui/core';
import { empty, useBasicTexts, escape } from '../Global/GlobalItems';
import { useAuth } from '../Contexts/AuthContext';
import { query, mutation } from '../Global/ServerConnection';
import { useRouter } from '../Contexts/RouterContext';
import useForm, { FormatForm } from '../Form/FormUtils';
import { Consumer, Callback, AccountInfoType, FieldType } from '../types';
import AccountInfoFields from '../Form/AccountInfoFields';
import FormNavigation from '../Form/FormNavigation';
import Text from '../Form/Components/Text';
import { Captcha, CaptchaStatus, RegistrationAttempt, SendVerificationAttempt } from '../server-types';
import { BasicDialog, CaptchaDialog } from '../Global/Dialogs';

const accountInfoTexts = {
    existingUsernameError: {
        en: 'This username already exists'
    },
    existingEmailError: {
        en: 'This email is already registered'
    },
    incorrectCaptchaError: {
        en: 'This answer is incorrect'
    },
    weakPasswordError: {
        en: 'Password is too weak'
    },
    emptyCaptchaError: {
        en: 'Please answer the question'
    },
    title: {
        en: 'Account Info'
    },
    emailVerificationTitle: {
        en: 'Email Verification'
    },
    verifyEmail: {
        en: 'Please enter the code sent to your email.'
    },
    code: {
        en: 'Verification code'
    },
    reSendEmail: {
        en: 'Send another code'
    },
    emptyCodeError: {
        en: 'Please enter the code'
    },
    badCodeError: {
        en: 'This code is incorrect'
    },
    staleCodeError: {
        en: 'This code is old, we have sent a new one'
    },
    termsAndConditions: {
        en: 'Terms and Conditions'
    },
    acceptTermsAndConditions: {
        en: 'Please accept our Terms and Conditions before continuing.'
    }
}

export default function AccountInfo() {
    const texts = useTextLang(accountInfoTexts)

    const [accountValues, setAccountValues] = useState<Record<keyof AccountInfoType, string>>()
    const [accountErrors, setAccountErrors] = useState<Partial<Record<keyof AccountInfoType, string>>>({})
    const [captcha, setCaptcha] = useState<Captcha>()
    const [captchaError, setCaptchaError] = useState<string>()
    const [registrationToken, setRegistrationToken] = useState<string>()
    const [captchaOpen, setCaptchaOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [captchaLoading, setCaptchaLoading] = useState(false)
    const { goBack } = useRouter()

    useEffect(() => {
        setCaptchaOpen(!empty(captcha))
        setCaptchaError(undefined)
    }, [captcha])

    const getCaptcha = useCallback((values: Record<keyof AccountInfoType, string> | undefined = accountValues) => {
        setLoading(true)
        const processCaptcha = ({ status, ...captcha }: Captcha & CaptchaStatus) => {
            setLoading(false)
            switch (status) {
                case 'USERNAME_ALREADY_EXISTS':
                    setAccountErrors({ username: texts.existingUsernameError })
                    break
                case 'EMAIL_ALREADY_EXISTS':
                    setAccountErrors({ email: texts.existingEmailError })
                    break
                default:
                    setCaptcha(captcha)
                    setCaptchaError(undefined)
            }
        }
        if (values) {
            query('getRegistrationCaptcha', processCaptcha, `username:"${escape(values.username)}",email:"${escape(values.email)}"`, 'status,captchaID,question')
        }
    }, [texts, accountValues])

    const createAccount = useCallback((values: Record<keyof AccountInfoType, string>) => {
        setAccountValues(values)
        getCaptcha(values)
    }, [getCaptcha])

    const handleClose = useCallback(() => {
        setCaptcha(undefined)
        setCaptchaError(undefined)
    }, [])

    const cancelVerification = useCallback(() => {
        setRegistrationToken(undefined)
    }, [])

    const submitCaptcha = useCallback(({ answer }: { answer: string }) => {
        if (answer.length > 0) {
            setCaptchaLoading(true)
            setCaptchaError(undefined)
            const processRegistration = (attempt: SendVerificationAttempt) => {
                switch (attempt.status) {
                    case 'SUCCESS':
                        handleClose()
                        if (attempt.registrationToken)
                            setRegistrationToken(attempt.registrationToken)
                        break
                    case 'BAD_CAPTCHA':
                        setCaptchaError(texts.incorrectCaptchaError)
                        break
                    case 'PASSWORD_TOO_WEAK':
                        setAccountErrors({ password: texts.weakPasswordError })
                        handleClose()
                        break
                    case 'USERNAME_ALREADY_EXISTS':
                        setAccountErrors({ username: texts.existingUsernameError })
                        handleClose()
                        break
                    case 'EMAIL_ALREADY_EXISTS':
                        setAccountErrors({ email: texts.existingEmailError })
                        handleClose()
                        break
                }
                setCaptchaLoading(false)
            }

            if (accountValues && captcha) {
                query('sendVerification', processRegistration, `captchaGuess:{captchaID:"${captcha.captchaID}",answer:"${escape(answer)}"},username:"${escape(accountValues.username)}",password:"${escape(accountValues.password)}",email:"${escape(accountValues.email)}",reference:"${accountValues.reference}",referenceDetails:"${escape(accountValues.referenceDetails)}"`, 'status,registrationToken')
            }
        } else {
            setCaptchaError(texts.emptyCaptchaError)
        }
    }, [handleClose, accountValues, captcha, texts])

    return (
        <>
            <CreateAccountForm onSubmit={createAccount} onCancel={goBack} loading={loading} defaultErrors={accountErrors} />
            {captcha &&
                <CaptchaDialog captcha={captcha.question} open={captchaOpen} onSubmit={submitCaptcha} onCancel={handleClose} onNewCaptcha={getCaptcha} loading={captchaLoading} errorMsg={captchaError} />
            }
            {registrationToken !== undefined &&
                <VerificationDialog onCancel={cancelVerification} registrationToken={registrationToken} />
            }
        </>
    )
}

type CreateAccountFormType = {
    onSubmit: Consumer<Record<keyof AccountInfoType, string>>,
    onCancel: Callback,
    loading: boolean,
    defaultErrors: Partial<Record<keyof AccountInfoType, string>>
}
function CreateAccountForm({ onSubmit, onCancel, loading, defaultErrors }: CreateAccountFormType) {
    const texts = useTextLang(accountInfoTexts)
    const basicTexts = useBasicTexts()
    const [termsDialogOpen, setTermsDialogOpen] = useState(false)

    const beforeSubmit = useCallback(({ termsAndConditions, ...values }: Record<keyof AccountInfoType | 'termsAndConditions', string>) => {
        // console.log(termsAndConditions)
        // console.log(values)
        if (termsAndConditions) {
            onSubmit(values)
        } else {
            setTermsDialogOpen(true)
        }
    }, [onSubmit])

    const closeDialog = useCallback(() => {
        setTermsDialogOpen(false)
    }, [])

    const { values, setValue, errors, setError, handleSubmit } = useForm(accountInfoRules, beforeSubmit, accountInfoDefaultValues, { ...defaultErrors, termsAndConditions: undefined })

    useEffect(() => {
        Object.entries(defaultErrors).forEach(([field, error]) => {
            setError(field as keyof AccountInfoType, error)
        })
    }, [defaultErrors, setError])

    return (
        <FormContainer title={texts.title}>
            <FormatForm>
                <AccountInfoFields values={values} setValue={setValue} errors={errors} />
            </FormatForm>
            <FormNavigation
                onSubmit={handleSubmit}
                submitText={basicTexts.next}
                onCancel={onCancel}
                cancelText={basicTexts.cancel}
                loading={loading}
            />
            <BasicDialog open={termsDialogOpen} handleClose={closeDialog} title={texts.termsAndConditions} contents={texts.acceptTermsAndConditions} />
        </FormContainer>
    )
}

type VerificationDialogType = {
    registrationToken: string,
    onCancel: Callback,
}
export function VerificationDialog({ registrationToken, onCancel }: VerificationDialogType) {
    const texts = useTextLang(accountInfoTexts)
    const basicTexts = useBasicTexts()
    const [token, setToken] = useState(registrationToken)
    const [loading, setLoading] = useState(false)
    const [codeError, setCodeError] = useState<string>()
    const { overrideLogin } = useAuth()

    const resendVerification = useCallback(() => {
        setLoading(true)
        setCodeError(undefined)
        const processAttempt = (attempt: SendVerificationAttempt) => {
            if (attempt.registrationToken) {
                setToken(attempt.registrationToken)
            } else {
                onCancel()
            }
            setLoading(false)
        }
        query('resendVerification', processAttempt, `registrationToken:"${registrationToken}"`, 'status,registrationToken')
    }, [onCancel, registrationToken])

    const registerAccount = useCallback(({ code }: { code: string }) => {
        setCodeError(undefined)
        if (code.length > 0) {
            setLoading(true)
            const processRegistration = (attempt: RegistrationAttempt) => {
                switch (attempt.status) {
                    case 'SUCCESS':
                        if (attempt.sessionToken)
                            overrideLogin(attempt.sessionToken)
                        break
                    case 'BAD_CODE':
                        setCodeError(texts.badCodeError)
                        break
                }
                setLoading(false)
            }
            mutation('registerAccount', processRegistration, `registrationToken:"${token}",code:"${escape(code)}"`, 'status,sessionToken')
        } else {
            setCodeError(texts.emptyCodeError)
        }
    }, [overrideLogin, texts, token])

    const { values, setValue, errors, setError, handleSubmit } = useForm(
        { code: { type: FieldType.Text, required: true } },
        registerAccount,
        { code: '' },
        {})

    useEffect(() => {
        setError('code', codeError)
    }, [codeError, setError])

    const getNewCode = useCallback(() => {
        setValue('code', '')
        setError('code', undefined)
        resendVerification()
    }, [setValue, setError, resendVerification])

    const theme = useTheme<Theme>()
    const matches = useMediaQuery(theme.breakpoints.down('xs'))

    return (
        <Dialog
            open={true}
            onClose={onCancel}
            scroll='paper'
            disableBackdropClick
            disableEscapeKeyDown>
            {!matches &&
                <DialogTitle>
                    {texts.emailVerificationTitle}
                </DialogTitle>
            }
            <DialogContent>
                <DialogContentText>
                    {matches ? `${texts.emailVerificationTitle}: ${texts.verifyEmail}` : texts.verifyEmail}
                </DialogContentText>
                <Text field='code' value={values.code} setValue={setValue} onEnter={handleSubmit} errorMsg={errors.code} autoFocus autoCapitalize='off' />
            </DialogContent>
            <DialogActions >
                {loading
                    ? <Grid container justify='center' >
                        <CircularProgress />
                    </Grid>
                    : <>
                        <Button onClick={onCancel} color='default'>
                            {basicTexts.cancel}
                        </Button>
                        <Button onClick={getNewCode} color='primary'>
                            {texts.reSendEmail}
                        </Button>
                        <Button onClick={handleSubmit} color='primary'>
                            {basicTexts.next}
                        </Button>
                    </>}
            </DialogActions>
        </Dialog>
    )
}