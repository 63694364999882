import React, { useCallback } from 'react'

import { Typography, Grid, Button, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles'
import { Link as RouterLink } from 'react-router-dom'
import { LogoShield } from '../Global/Icons'
import { useTextLang } from '../Contexts/LangContext';
import { routes, useRouter } from '../Contexts/RouterContext';

const footerTexts = {
    eventsButton: {
        en: 'Events',
        es: 'Eventos'
    },
    fepButton: {
        en: 'F.E.P.',
        es: 'P.E.F.'
    },
    aboutButton: {
        en: 'About',
        es: 'Quienes Somos'
    },
    contactButton: {
        en: 'Contact',
        es: 'Contacto'
    },
    volunteerButton: {
        en: 'Volunteer',
        es: 'Voluntarios'
    },
    donateButton: {
        en: 'Donate',
        es: 'Donativos'
    },
    feiCopyright: {
        en: '©2019, 2020 FAMILY EDUCATION INSTITUTE',
        es: '©2019, 2020 FAMILY EDUCATION INSTITUTE'
    }
}

const useStyles = makeStyles(theme => ({
    placeholder: {
        position: 'relative',
        height: theme.spacing(6 + 12),
        [theme.breakpoints.up('md')]: {
            height: theme.spacing(10 + 16),
        },
    },
    footer: {
        position: 'absolute',
        width: '100%',
        bottom: 0
    },
    contents: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        [theme.breakpoints.up('md')]: {
            paddingTop: theme.spacing(5),
            paddingBottom: theme.spacing(5),
        },
    },
    shield: {
        height: theme.spacing(12),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        [theme.breakpoints.up('md')]: {
            height: theme.spacing(16),
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
        },
    },
    copyrightBar: {
        position: 'absolute',
        bottom: 0,
        width: '100%',
    },
    grey: {
        color: theme.palette.text.secondary
    }
}))

export default function Footer() {
    const classes = useStyles()
    const texts = useTextLang(footerTexts)
    const { navigateTo } = useRouter()

    const goToContact = useCallback(() => {
        navigateTo(routes.about, false, { tab: 'CONTACT' })
    }, [navigateTo])

    const goToVolunteer = useCallback(() => {
        navigateTo(routes.about, false, { tab: 'VOLUNTEER' })
    }, [navigateTo])

    const goToDonate = useCallback(() => {
        navigateTo(routes.about, false, { tab: 'DONATE' })
    }, [navigateTo])

    return (
        <>
            <div className={classes.placeholder} />
            <Paper className={classes.footer} elevation={4}>
                <Grid container className={classes.contents}>
                    <Grid item xs={true} container justify='center' direction='column' alignItems='flex-end'>
                        <Button size='small' className={classes.grey} component={RouterLink} to={routes.events}>
                            {texts.eventsButton}
                        </Button>
                        <Button size='small' className={classes.grey} component={RouterLink} to={routes.fep}>
                            {texts.fepButton}
                        </Button>
                        <Button size='small' className={classes.grey} component={RouterLink} to={routes.about}>
                            {texts.aboutButton}
                        </Button>
                    </Grid>
                    <Grid item component={RouterLink} to={routes.home}>
                        <LogoShield className={classes.shield} />
                    </Grid>
                    <Grid item xs={true} container justify='center' direction='column' alignItems='flex-start'>
                        <Button size='small' className={classes.grey} onClick={goToContact}>
                            {texts.contactButton}
                        </Button>
                        <Button size='small' className={classes.grey} onClick={goToVolunteer}>
                            {texts.volunteerButton}
                        </Button>
                        <Button size='small' className={classes.grey} onClick={goToDonate}>
                            {texts.donateButton}
                        </Button>
                    </Grid>
                </Grid>
                <Grid container justify='center' className={classes.copyrightBar}>
                    <Typography variant='caption' color='textSecondary'>
                        {texts.feiCopyright}
                    </Typography>
                </Grid>
            </Paper>
        </>
    );
}