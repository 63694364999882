import React, { useState, useEffect } from 'react'
import moment from 'moment-timezone'
import { useTextLang } from '../../Contexts/LangContext';
import { Grid, Typography, Divider, Button } from '@material-ui/core';
import { EditOutlined } from '@material-ui/icons';
import { FormatForm } from '../../Form/FormUtils';
import { Person } from '../../server-types';
import useFieldUtils from '../../Form/FieldUtils';
import { Clickable, defaultOption, MatchProps } from '../../types';
import { adminQuery } from '../../Global/ServerConnection';
import { Link as RouterLink } from 'react-router-dom';
import { adminRoutes } from '../Admin';
import LoadingPage from '../../Global/LoadingPage';
import { Admin_FamilyFullInfoType, Admin_PersonFullInfoType } from '../admin-types';

const familySettingsTexts = {
    newChild: {
        en: 'New Child'
    },
    viewFamilyInfo: {
        en: 'View Family Info'
    },
    addChild: {
        en: 'Add child'
    },
    edit: {
        en: 'Edit'
    }
}

export default function ViewFamilyPage({ match }: MatchProps) {
    return <ViewFamily familyID={match.params.id} />
}

export function ViewFamily({ familyID }: { familyID: string }) {
    const [persons, setPersons] = useState<Person[]>([])
    const [familyInfo, setFamilyInfo] = useState<Record<keyof Admin_FamilyFullInfoType, string>>()
    const [loading, setLoading] = useState(true)
    const texts = useTextLang(familySettingsTexts)

    useEffect(() => {
        const processInfo = (info: { persons: Person[] } & Record<keyof Admin_FamilyFullInfoType, string>) => {
            if (info) {
                const { persons, ...familyInfo } = info
                setPersons(persons)
                setFamilyInfo(familyInfo)
                setLoading(false)
            }
        }
        return adminQuery('getFamilyInfo', processInfo, `familyid:"${familyID}"`, 'familyid,persons{personid,name},streetAddress,city,state,zipCode,country,maritalStatus,dateOfMarriage,registrationStep,reference,dateCreated,dateLastUpdate,active')
    }, [familyID])


    return (
        <>
            {familyInfo && <FamilyInfo familyInfo={familyInfo} />}
            {persons.map(({ personid, name }) =>
                <PersonInfo
                    name={name}
                    personid={personid}
                    key={personid}
                />
            )}
            <Button color='primary' component={RouterLink} to={adminRoutes.editFamily(familyID)}>
                {texts.edit}
            </Button>
            <Divider />
            <LoadingPage loading={loading} />
        </>
    )
}

type FamilyInfoProps = {
    familyInfo: Record<keyof Admin_FamilyFullInfoType, string>
}
function FamilyInfo({ familyInfo }: FamilyInfoProps) {
    const texts = useTextLang(familySettingsTexts)
    const { labels, maritalStatusOptions, stateOptions, nationalityOptions } = useFieldUtils()

    const values = {
        ...familyInfo,
        maritalStatus: maritalStatusOptions.find(val => val.value === familyInfo.maritalStatus) || defaultOption,
        state: stateOptions.find(val => val.value === familyInfo.state) || defaultOption,
        country: nationalityOptions.find(val => val.value === familyInfo.country) || defaultOption,
    }

    return (
        <>
            <Grid item container direction='row'>
                <Typography variant='h6'>{texts.viewFamilyInfo}</Typography>
            </Grid>
            <Divider />
            <FormatForm>
                <Typography>
                    FamilyID: {values.familyid}
                </Typography>
                <Typography>
                    {`${labels.maritalStatus}: ${values.maritalStatus.label}`}
                </Typography>
                {values.dateOfMarriage &&
                    <Typography>
                        {`${labels.dateOfMarriage}: ${moment(values.dateOfMarriage).format('MMM D, YYYY')}`}
                    </Typography>
                }
                <Typography>
                    {`${labels.streetAddress}: ${values.streetAddress}`}
                </Typography>
                <Typography>
                    {`${labels.city}: ${values.city}`}
                </Typography>
                <Typography>
                    {`${labels.state}: ${values.state.value}`}
                </Typography>
                <Typography>
                    {`${labels.zipCode}: ${values.zipCode}`}
                </Typography>
                <Typography>
                    {`${labels.country}: ${values.country.value}`}
                </Typography>
                <Typography>
                    RegistrationStep: {values.registrationStep}
                </Typography>
                <Typography>
                    Reference: {values.reference}
                </Typography>
                <Typography>
                    DateCreated: {values.dateCreated}
                </Typography>
                <Typography>
                    DateLastUpdate: {values.dateLastUpdate}
                </Typography>
                <Typography>
                    {`Active: ${values.active}`}
                </Typography>
            </FormatForm>
            <Divider />
        </>
    )
}


type PersonNameProps = Clickable & {
    name: string,
}
function PersonName({ name, onClick }: PersonNameProps) {
    return (
        <>
            <Grid item container justify='space-between' onClick={onClick}>
                <Typography variant='h6'>
                    {name}
                </Typography>
                <EditOutlined />
            </Grid>
            <Divider />
        </>
    )
}

function PersonInfo({ personid, name }: Person) {
    const [personInfo, setPersonInfo] = useState<Record<keyof Admin_PersonFullInfoType, string>>()

    useEffect(() => {
        const processOriginalValues = (values?: Record<keyof Admin_PersonFullInfoType, string>) => {
            if (values)
                setPersonInfo(values)
        }
        return adminQuery('getPersonInfo', processOriginalValues, `personid:"${personid}"`, 'personid,firstName,lastName,sex,dateOfBirth,nationality,preferredLanguage,email,phoneNumber,phoneCarrier,dateCreated,dateLastUpdate,active')
    }, [personid])

    if (personInfo) {
        return <DisplayPerson personInfo={personInfo} name={name} />
    } else {
        return <PersonName name={name} onClick={() => { }} />
    }
}

type DisplayPersonProps = {
    name: string,
    personInfo: Record<keyof Admin_PersonFullInfoType, string>
}
function DisplayPerson({ name, personInfo }: DisplayPersonProps) {

    const { labels, carrierOptions, languageOptions, nationalityOptions } = useFieldUtils()

    const values = {
        personid: personInfo.personid,
        firstName: personInfo.firstName,
        lastName: personInfo.lastName,
        sex: personInfo.sex,
        dateOfBirth: personInfo.dateOfBirth,
        nationality: nationalityOptions.find(val => val.value === personInfo.nationality) || defaultOption,
        preferredLanguage: languageOptions.find(val => val.value === personInfo.preferredLanguage) || defaultOption,
        email: personInfo.email,
        phoneNumber: personInfo.phoneNumber,
        phoneCarrier: carrierOptions.find(val => val.value === personInfo.phoneCarrier) || defaultOption,
        dateCreated: personInfo.dateCreated,
        dateLastUpdate: personInfo.dateLastUpdate,
        active: personInfo.active
    }

    return (
        <>
            <Grid item container direction='row'>
                <Typography variant='h6'>
                    {name}
                </Typography>
            </Grid>
            <Divider />
            <FormatForm>
                <Typography>
                    PersonID: {values.personid}
                </Typography>
                <Typography>
                    {`${labels.firstName}: ${values.firstName}`}
                </Typography>
                <Typography>
                    {`${labels.lastName}: ${values.lastName}`}
                </Typography>
                <Typography>
                    {`${labels.sex}: ${values.sex}`}
                </Typography>
                <Typography>
                    {`${labels.dateOfBirth}: ${moment(values.dateOfBirth).format('MMM D, YYYY')}`}
                </Typography>
                <Typography>
                    {`${labels.nationality}: ${values.nationality.label}`}
                </Typography>
                <Typography>
                    {`${labels.preferredLanguage}: ${values.preferredLanguage.label}`}
                </Typography>
                <Typography>
                    {`${labels.email}: ${values.email}`}
                </Typography>
                <Typography>
                    {`${labels.phoneNumber}: ${values.phoneNumber}`}
                </Typography>
                <Typography>
                    {`${labels.phoneCarrier}: ${values.phoneCarrier.label}`}
                </Typography>
                <Typography>
                    DateCreated: {values.dateCreated}
                </Typography>
                <Typography>
                    DateLastUpdate: {values.dateLastUpdate}
                </Typography>
                <Typography>
                    {`Active: ${values.active}`}
                </Typography>
            </FormatForm>
            <Divider />
        </>
    )
}


