import React, { useCallback } from 'react';
import { Typography, Grid, Theme, useMediaQuery, Button } from '@material-ui/core';

import { makeStyles, useTheme } from '@material-ui/styles';
import { useTextLang } from '../Contexts/LangContext';
import { LineTypography } from '../Global/GlobalItems';
import { FEPCard, InfoCard } from './InfoCards';
import { useRouter, routes } from '../Contexts/RouterContext';
import { tags } from './EventTags';

const aboutTexts = {
    fepTitle: {
        en: 'FAMILY EDUCATION PROGRAM',
        es: 'PROGRAMA DE EDUCACION FAMILIAR'
    },
    programStructure: {
        en: 'PROGRAM STRUCTURE',
        es: 'ESTRUCTURA DEL PROGRAMA'
    },
    ourPrograms: {
        en: 'OUR PROGRAMS',
        es: 'NUESTROS PROGRAMAS'
    },
    history: {
        en: 'HISTORY',
        es: 'HISTORIA'
    },
    goToFepButton: {
        en: `View Sessions`,
        es: 'Ver Sesiones'
    },
    programStructureTitle: {
        en: 'Based on Three Pillars:',
    },
    programStructureDescription: {
        en: `1) Study (Books and Discussion)
        2) Practice (Action Plans)
        3) Advisory (Personal Mentoring for Each Couple)
                  
        In this program, 90% of the work is done by parents at home. The books are studied and discussed by the parents, and they carry out the Action Plans in daily family life.
         
        If you are interested, we invite you to participate in an introductory session for our Family Education Program.`,
    },
    schoolForParentsTitle: {
        en: 'School for Parents',
    },
    schoolForParentsDescription: {
        en: `Married couples get together once a month in a trimester to study and work on one of the books about parenting and family education.

        For over 25 years, many parents have benefited from reading the books, attending the general sessions, and applying the guidance they have received throughout the program.`,
    },
    fepMasterTitle: {
        en: 'FEP Master',
    },
    fepMasterDescription: {
        en: `A Family Education Master's Degree for married couples which requires 10 trimesters (30 months) where a total of 20 books are studied and their material is put into practice. Each couple will attend two-hour group discussions each month, have an hour-long personal mentoring session each trimester, prepare a final thesis, and serve in one of the opportunities assigned by the program.`,
    },
    fepOnlineTitle: {
        en: 'FEP Master Online',
    },
    fepOnlineDescription: {
        en: `A Family Education Master's Degree for parents which requires 30 consecutive months of study. Parents will participate in two-hour online discussions and a 20 minute personal mentoring session via video-conference each month, prepare a final thesis, and serve in one of the opportunities assigned by the program.`,
    },
    contactUs: {
        en: 'Contact us for more information'
    },
    historyTitle: {
        en: 'IEEE-USA',
        es: 'IEEE-USA'
    },
    historyDescription: {
        en: `We are part of the European Institute of Educational Studies (IEEE in Spanish), a non-profit international organization founded in Madrid in 1991 by Fernando Corominas and Alfonso Aguiló. 
        
        Fernando Corominas, a naval engineer, found his vocation in the field of education where he, along other collaborators, conducted numerous studies that have become the foundation of the 'Hacer Familia' book series (published by Ediciones Palabra) which now consists of over 70 books.`,
        es: `Representamos al Instituto Europeo de Estudios de la Educación (IEEE), una organización sin fines de lucro fundada en Madrid, España en 1991 por Fernando Corominas y Alfonso Aguiló.
        
        Fernando Corominas, ingeniero naval, encontro su vocación en el campo de la educación en donde ha realizado numerosos estudios, junto con otros colaboradores que comparten su visión y pasión po la educación, y que se encuentran plasmados en la colección de libros "Hacer Familia" publicados por Ediciones Palabra. Esta colección cuenta con mas de 70 libros.`
    },
    goToIEEE: {
        en: 'GO TO > IEEE.COM.ES',
        es: 'IR A > IEEE.COM.ES'
    },
}

const useStyles = makeStyles(theme => ({
    mainContainer: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(4),
        //margin: 0,
        //width: '100%'
    },
    textContainer: {
        padding: theme.spacing(2),
    },
    fullWidth: {
        width: '100%'
    },
    unitSpacing: {
        padding: theme.spacing(1),
    },
}))

export default function FEP() {
    const classes = useStyles()
    const texts = useTextLang(aboutTexts)
    const { navigateTo } = useRouter()
    const goToFEP = useCallback(() => {
        navigateTo(routes.events, false, { tag: tags.fep })
    }, [navigateTo])
    return (
        <Grid container direction='column' spacing={3} className={classes.mainContainer}>
            <Grid item>
                <Typography variant='h5' color='textSecondary' align='center'>
                    {texts.fepTitle}
                </Typography>
            </Grid>
            <Grid item>
                <FEPCard actionFunc={goToFEP} actionText={texts.goToFepButton} />
            </Grid>
            <Grid item>
                <Typography variant='h5' color='textSecondary' align='center'>
                    {texts.programStructure}
                </Typography>
            </Grid>
            <Grid item container>
                <CustomCard title={texts.programStructureTitle}
                    description={texts.programStructureDescription}
                    imageSquareSrc='FEI-PEF-1ProgramStructure-S.jpg'
                    imageRectSrc='FEI-PEF-1ProgramStructure-R.jpg'
                    imageAlt={texts.programStructure}
                />
            </Grid>
            <Grid item>
                <Typography variant='h5' color='textSecondary' align='center'>
                    {texts.ourPrograms}
                </Typography>
            </Grid>
            <Grid item container>
                <CustomCard title={texts.schoolForParentsTitle}
                    description={texts.schoolForParentsDescription}
                    imageSquareSrc='FEI-PEF-2SchoolForParents-S.jpg'
                    imageRectSrc='FEI-PEF-2SchoolForParents-R.jpg'
                    imageAlt={texts.schoolForParentsTitle}
                />
            </Grid>
            <Grid item container>
                <CustomCard title={texts.fepMasterTitle}
                    description={texts.fepMasterDescription}
                    imageSquareSrc='FEI-PEF-3PEF-S.jpg'
                    imageRectSrc='FEI-PEF-3PEF-R.jpg'
                    imageAlt={texts.fepMasterTitle}
                />
            </Grid>
            <Grid item container>
                <CustomCard title={texts.fepOnlineTitle}
                    description={texts.fepOnlineDescription}
                    imageSquareSrc='FEI-PEF-4PEFOnline-S.jpg'
                    imageRectSrc='FEI-PEF-4PEFOnline-R.jpg'
                    imageAlt={texts.fepOnlineTitle}
                />
            </Grid>
            <Grid item>
                <Typography variant='h5' color='textSecondary' align='center'>
                    {texts.history}
                </Typography>
            </Grid>
            <Grid item container>
                <CustomCard title={texts.historyTitle}
                    description={texts.historyDescription}
                    imageSquareSrc='FEI-PEF-5IEEE-S.jpg'
                    imageRectSrc='FEI-PEF-5IEEE-R.jpg'
                    imageAlt={texts.history}
                    action={
                        <Button component={'a'} color='primary' href={'http://www.ieee.com.es/'}
                            target='_blank' rel='noopener noreferrer' className={classes.unitSpacing}>
                            {texts.goToIEEE}
                        </Button>
                    } />
            </Grid>
        </Grid>
    );
}

type CustomCardProps = {
    title: string,
    description: string,
    imageSquareSrc: string,
    imageRectSrc: string,
    imageAlt: string,
    action?: React.ReactNode,
}
function CustomCard({ title, description, imageSquareSrc, imageRectSrc, imageAlt, action }: CustomCardProps) {
    const theme = useTheme<Theme>()
    const desktop = useMediaQuery(theme.breakpoints.up('sm'))

    return (
        <InfoCard imageSquareSrc={imageSquareSrc} imageRectSrc={imageRectSrc} imageAlt={imageAlt}
            beforeImage={
                <Typography variant='h5' paragraph={desktop}>
                    {title}
                </Typography>}
            afterImage={
                <LineTypography color='textSecondary' paragraph={desktop}>
                    {description}
                </LineTypography>
            }
            action={action}
        />
    )
}