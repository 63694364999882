import React, { useEffect, useState } from 'react'
import { Grid, Typography, Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles'

import { useTextLang } from '../Contexts/LangContext';
import { MatchProps } from '../types';
import { mutation } from '../Global/ServerConnection';
import { DoneRounded, CloseRounded } from '@material-ui/icons';
import { Link as RouterLink } from 'react-router-dom';
import { routes } from '../Contexts/RouterContext';


const mainTexts = {
    successText: {
        en: 'Thank you! Your email has been verified.',
        es: 'Gracias! So correo ha sido verificado.'
    },
    continueButton: {
        en: 'Go to Home',
        es: 'Ir a la pagina principal'
    },
    failureText: {
        en: 'There has been a problem, your email was not verified.',
        es: 'Hubo algun problema. Su correo no ha sido verificado.'
    },
}

const useStyles = makeStyles(theme => ({
    mainContainer: {
        height: '100vh',
        backgroundColor: theme.palette.grey[50],
    },
    doneIcon: {
        color: '#32CD32',
    },
    failIcon: {
        color: '#DC143C',
    }
}))

export default function VerifyEmail({ match }: MatchProps) {
    const classes = useStyles()
    const texts = useTextLang(mainTexts)
    const token = match.params.token
    const [loading, setLoading] = useState(true)
    const [success, setSuccess] = useState(true)

    useEffect(() => {
        const processConfirmation = (success: boolean) => {
            setSuccess(success)
            setLoading(false)
        }
        mutation('verifyEmail', processConfirmation, `token:"${token}"`)
    }, [token])

    return (
        <Grid container direction='column' className={classes.mainContainer} justify='center' alignItems='center'>
            {loading &&
                <Grid item>
                    <CircularProgress />
                </Grid>}
            {!loading &&
                <>
                    <Grid item>
                        {success ? <DoneRounded fontSize='large' className={classes.doneIcon} /> : <CloseRounded fontSize='large' className={classes.failIcon} />}
                    </Grid>
                    <Grid item>
                        <Typography align='center'>
                            {success ? texts.successText : texts.failureText}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Button component={RouterLink} to={routes.home}>
                            {texts.continueButton}
                        </Button>
                    </Grid>
                </>}
        </Grid>
    )
}