import React, { useState, useCallback } from 'react'
import { SketchPicker, ColorResult } from 'react-color'
import { makeStyles, Typography } from '@material-ui/core'
import { FormField } from '../../types'

const useStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(1, 0),
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
    },
    color: {
        width: theme.spacing(3),
        height: theme.spacing(3),
        borderColor: theme.palette.grey[400],
        borderRadius: theme.spacing(1 / 4),
        marginRight: theme.spacing(2),
    },
    popover: {
        position: 'absolute',
        zIndex: 2,
    },
    cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
    },
}))

type ColorPickerType<FieldName> = FormField<FieldName, string>
export default function ColorPicker<FieldName>({ field, value, setValue, label }: ColorPickerType<FieldName>) {
    const [open, setOpen] = useState(false)
    const classes = useStyles()

    const handleClick = () => {
        setOpen(open => !open)
    }

    const handleClose = () => {
        setOpen(false)
    };

    const handleChange = useCallback((color: ColorResult) => {
        setValue(field, color.hex)
    }, [field, setValue])

    return (
        <div>
            <div className={classes.container} onClick={handleClick}>
                <div className={classes.color} style={{
                    backgroundColor: value,
                    border: value === '#ffffff' ? '2px solid' : undefined
                }} />
                <Typography display='inline'>
                    {label}
                </Typography>
            </div>
            {open ? <div className={classes.popover}>
                <div className={classes.cover} onClick={handleClose} />
                <SketchPicker
                    color={value}
                    onChange={handleChange}
                    disableAlpha
                    presetColors={[
                        '#D0021B', '#F5A623', '#F8E71C', '#8B572A',
                        '#7ED321', '#417505', '#BD10E0', '#9013FE',
                        '#4A90E2', '#2E7599', '#50E3C2', '#B8E986',
                        '#000000', '#4A4A4A', '#9B9B9B', '#FFFFFF',
                    ]} />
            </div> : null}
        </div>
    )
}