import React, { useCallback, MutableRefObject } from 'react'
import { Grid, Typography, Checkbox, FormControlLabel } from '@material-ui/core'

import { useTextLang } from '../../Contexts/LangContext';
import { JSONt, BiConsumer, ParticipantSelection, OptionValue, defaultOption } from '../../types';
import { PersonRegister } from '../../server-types';
import Selection from '../../Form/Components/Selection';
import useFieldUtils from '../../Form/FieldUtils';

const selectParticipantsTexts = {
    title: {
        en: 'SELECT PARTICIPANTS:',
        es: 'SELECCIONE LOS PARTICIPANTES'
    },
    noParticipantsError: {
        en: 'You have no family members that can participate in this event.',
        es: 'No tiene miembros de la familia que puedan participar en este evento.'
    },
    tshirtSize: {
        en: 'T-Shirt Size',
        es: 'Talla de la camiseta'
    }
}

type SelectParticipantsProps = {
    persons: PersonRegister[],
    error?: string,
    selected: JSONt<ParticipantSelection>,
    withTShirt: boolean,
    setTShirt: BiConsumer<string, OptionValue>,
    toggleParticipant: BiConsumer<string, boolean>
    menuPortalTarget?: MutableRefObject<undefined>
}
export default function SelectParticipants({ persons, error, selected, withTShirt, setTShirt, toggleParticipant, menuPortalTarget }: SelectParticipantsProps) {
    const texts = useTextLang(selectParticipantsTexts)

    return (
        <Grid item container direction='column'>
            {persons.length === 0
                ? <Typography variant='body1' color='error'>
                    {texts.noParticipantsError}
                </Typography>
                : <Typography variant='body1' color={error !== undefined ? 'error' : 'initial'}>
                    {texts.title}
                </Typography>
            }
            {persons.map(({ personid, name, registered }) => <Participant personid={personid} name={name} selection={selected[personid]} registered={registered} onCheckChange={toggleParticipant} onTShirtChange={setTShirt} withTShirt={withTShirt} key={personid} menuPortalTarget={menuPortalTarget}/>
            )}
            {
                error !== undefined &&
                <Typography variant='body1' color='error'>
                    {error}
                </Typography>
            }
        </Grid >
    )
}

type ParticipantProps = {
    selection: ParticipantSelection,
    onCheckChange: BiConsumer<string, boolean>,
    onTShirtChange: BiConsumer<string, OptionValue>,
    withTShirt: boolean,
    menuPortalTarget?: MutableRefObject<undefined>,
} & PersonRegister
function Participant({ personid, name, registered, selection = { checked: false, tshirt: defaultOption }, onCheckChange, onTShirtChange, withTShirt, menuPortalTarget }: ParticipantProps) {
    const texts = useTextLang(selectParticipantsTexts)

    const handleCheckChange = useCallback((event: any, checked: boolean) => {
        onCheckChange(personid, checked)
    }, [onCheckChange, personid])

    const handleTShirtChange = useCallback((field: any, size: OptionValue) => {
        onTShirtChange(personid, size)
    }, [onTShirtChange, personid])

    const { tshirtOptions } = useFieldUtils()

    let checkboxProps
    if (registered) {
        checkboxProps = { checked: true, disabled: true }
    } else {
        checkboxProps = { checked: selection.checked, onChange: handleCheckChange }
    }
    return (
        <>
            <FormControlLabel
                label={name}
                control={
                    <Checkbox {...checkboxProps}
                        value={personid}
                        color="primary" />}
            />
            {!registered && withTShirt && selection.checked &&
                <Selection field={personid} value={selection.tshirt} setValue={handleTShirtChange} errorMsg={selection.error} options={tshirtOptions} label={texts.tshirtSize} menuPortalTarget={menuPortalTarget}/>
            }
        </>
    )
}