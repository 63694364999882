import React, { useState, useEffect, useCallback } from 'react'
import { Typography, Grid, Button, Dialog, CircularProgress, } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import { useTextLang } from '../../Contexts/LangContext';
import { Callback } from '../../types'
import { OrderStatus, PayPalOrder } from '../../server-types'
import { payPalImageSrc, payPalTextSrc } from '../../Global/Icons'
import { CloseRounded } from '@material-ui/icons';
import { useAuth } from '../../Contexts/AuthContext';
import { query } from '../../Global/ServerConnection';

const paymentDialogTexts = {
    helpText: {
        en: 'Don’t see the secure PayPal browser? We’ll help you re-launch the window to complete your purchase',
        es: 'No ve la pagina segura de Paypal ? Le ayudaremos a reiniciar la ventana para que pueda completar el pago'
    },
    continueButton: {
        en: 'Click to Continue',
        es: 'Presione para continuar'
    },
}

const useStyles = makeStyles(theme => ({
    dialog: {
        background: 'rgba(0, 0, 0, 0.5)'
    },
    mainContainer: {
        height: '100%',
        width: '100%'
    },
    closeIcon: {
        margin: theme.spacing(2),
        color: 'rgb(200,200,200)',
    },
    contentContainer: {
        flexGrow: 1,
        maxWidth: theme.breakpoints.values.sm,
        padding: theme.spacing(3)
    },
    payPalImage: {
        height: theme.spacing(5),
    },
    continueButton: {
        textDecoration: 'underline'
    }
}))

type PaymentDialogProps = {
    payPalOrder?: PayPalOrder,
    cancelOrder: Callback,
    onSuccess: Callback,
    loading: boolean,
    checkout?: boolean
}
export default function PaymentDialog({ payPalOrder, cancelOrder, onSuccess, loading, checkout = true }: PaymentDialogProps) {
    const [paymentWindow, setPaymentWindow] = useState<any>()
    const classes = useStyles()
    const texts = useTextLang(paymentDialogTexts)
    const { authQuery } = useAuth()
    // console.log(payPalOrder)
    const closeWindow = useCallback(() => {
        if (paymentWindow) {
            paymentWindow.close()
        }
    }, [paymentWindow])

    const handleClose = useCallback(() => {
        // console.log('closing')
        closeWindow()
        // console.log('cancelling')
        cancelOrder()
    }, [cancelOrder, closeWindow])

    const openPaymentWindow = useCallback(() => {
        if (payPalOrder) {
            if (!paymentWindow || paymentWindow.closed) {
                setPaymentWindow(window.open(payPalOrder.approve.uri, undefined, 'centerscreen,dependent,height=600,width=300'))
            } else {
                paymentWindow.focus()
            }
        }
    }, [payPalOrder, paymentWindow])

    // Add listener to close PayPal window if this page is unloaded.
    useEffect(() => {
        window.addEventListener('beforeunload', handleClose)
        return () => {
            window.removeEventListener('beforeunload', handleClose)
        }
    }, [handleClose])

    const checkOrderStatus = useCallback(() => {
        if (payPalOrder) {
            const processOrderStatus = (status: OrderStatus) => {
                switch (status) {
                    case 'FINISHED':
                        closeWindow()
                        onSuccess()
                        break
                    default:
                        handleClose()
                }
            }
            if (checkout) {
                authQuery('checkOrderStatus', processOrderStatus, `orderid:"${payPalOrder.orderid}"`)
            } else {
                query('checkOrderStatus', processOrderStatus, `orderid:"${payPalOrder.orderid}"`)
            }
        }
    }, [checkout, payPalOrder, handleClose, onSuccess, authQuery, closeWindow])

    // Check order status when PayPal window is closed.
    useEffect(() => {
        if (paymentWindow) {
            sessionStorage.removeItem('payment window closed')
            const interval = setInterval(() => {
                if (paymentWindow.closed) {
                    if (sessionStorage.getItem('payment window closed') === 'true') {
                        sessionStorage.removeItem('payment window closed')
                        // console.log('window is closed')
                        checkOrderStatus()
                        clearInterval(interval)
                    } else {
                        // console.log('window marked closed')
                        sessionStorage.setItem('payment window closed', 'true')
                    }
                } else {
                    sessionStorage.removeItem('payment window closed')
                }
            }, 500)
            return () => {
                clearInterval(interval)
            }
        }
    }, [paymentWindow, checkOrderStatus])

    // Open new PayPal window when we get the order.
    useEffect(() => {
        openPaymentWindow()
    }, [openPaymentWindow])

    return (
        <Dialog
            classes={{ paperFullScreen: classes.dialog }}
            open={loading || payPalOrder !== undefined}
            disableBackdropClick
            disableEscapeKeyDown
            fullScreen
        >
            <Grid container direction='column' className={classes.mainContainer} alignItems='center'>
                {loading &&
                    <Grid item container xs={true} justify='center' alignItems='center'>
                        <CircularProgress color='secondary' />
                    </Grid>}
                {!loading &&
                    <>
                        <Grid item container justify='flex-end'>
                            <CloseRounded onClick={handleClose} className={classes.closeIcon} />
                        </Grid>
                        <Grid item className={classes.contentContainer}>
                            <Grid container justify='center' alignItems='center' direction='column' spacing={2} className={classes.mainContainer}>
                                <Grid item>
                                    <img src={payPalImageSrc} alt='PP' className={classes.payPalImage} />
                                    <img src={payPalTextSrc} alt='PayPal' className={classes.payPalImage} />
                                </Grid>
                                <Grid item>
                                    <Typography color='secondary' align='center'>
                                        {texts.helpText}
                                    </Typography>
                                </Grid>
                                <Grid item >
                                    <Button color='secondary' onClick={openPaymentWindow}>
                                        {texts.continueButton}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </>}
            </Grid>
        </Dialog>
    )
}