import React, { useState, useEffect } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Card, Divider, Button, Theme } from '@material-ui/core';
import Markdown from 'react-markdown'

import { MobileNavigation, DesktopNavigation } from '../Global/BottomNavigation'
import { makeStyles, useTheme } from '@material-ui/styles';
import { useRouter } from '../Contexts/RouterContext';
import { useLang } from '../Contexts/LangContext';
import FullPageFormat from '../Global/FullPageFormat';
import { MatchProps } from '../types';
import { query } from '../Global/ServerConnection';
import { useBasicTexts, transformImageUri, getDoc } from '../Global/GlobalItems';
import LoadingPage from '../Global/LoadingPage';

const useStyles = makeStyles(theme => ({
    contentCard: {
        margin: theme.spacing(5),
    },
    navigationCard: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
    },
    markdownContainer: {
        padding: theme.spacing(4)
    },
}))

export default function EventInfo({ match }: MatchProps) {
    const { goBack } = useRouter()
    const texts = useBasicTexts()
    const eventInfoID = match.params.id
    const [mdSource, setMdSource] = useState<string>()
    const [loading, setLoading] = useState(true)

    const classes = useStyles()
    const theme = useTheme<Theme>()
    const matches = useMediaQuery(theme.breakpoints.up('sm'))
    const { language } = useLang()

    useEffect(() => {
        const processSource = (source?: string) => {
            setMdSource(source)
            setLoading(false)
        }

        const processSourceName = (source?: string) => {
            if (source)
                getDoc(source, processSource)
        }
        return query('eventDocs', processSourceName, `eventInfoID:"${eventInfoID}",language:"${language}"`)
    }, [eventInfoID, language])

    return (
        <FullPageFormat size='wide'>
            {matches
                ? <Card className={classes.contentCard}>
                    <Markdown source={mdSource} escapeHtml={false} transformImageUri={transformImageUri} className={classes.markdownContainer} />
                    <Divider variant='middle' />
                    <DesktopNavigation>
                        <Button onClick={goBack} disableRipple color='primary'>
                            {texts.back}
                        </Button>
                    </DesktopNavigation>
                </Card>
                : <>
                    <Markdown source={mdSource} escapeHtml={false} transformImageUri={transformImageUri} className={classes.markdownContainer} />
                    <MobileNavigation>
                        <Button onClick={goBack} disableRipple color='primary'>
                            {texts.back}
                        </Button>
                    </MobileNavigation>
                </>
            }
            <LoadingPage loading={loading} />
        </FullPageFormat >
    )
}