import React, { useState, useCallback } from 'react';
import { Consumer } from '../../types'
import { Grid, Typography, Button } from '@material-ui/core';
import { Cancel } from '@material-ui/icons';
import FileInput from '../../Form/Components/FileInput'
import Text from '../../Form/Components/Text';
import { Attachment } from '../admin-types';

type AddAttachmentsProps = {
    initialAttachments: Attachment[]
    onFinish: Consumer<Attachment[]>,
    onCancel: Consumer<Attachment[]>,
}
export default function AddAttachments({ initialAttachments, onFinish, onCancel }: AddAttachmentsProps) {
    const [curr, setCurr] = useState<Attachment>({ name: '', nameInEmail: '' })
    const [attachments, setAttachments] = useState(initialAttachments)

    const handleFinish = useCallback(() => {
        onFinish(attachments)
    }, [onFinish, attachments])

    const handleBack = useCallback(() => {
        onCancel(attachments)
    }, [onCancel, attachments])

    const handleChange = useCallback((field, value) => {
        setCurr(curr => ({ ...curr, [field]: value }))
    }, [])

    const addCurrent = useCallback(() => {
        if (curr.name && curr.nameInEmail) {
            setAttachments(attachments => {
                if (attachments.find(({ name }) => name === curr.name) === undefined) {
                    setCurr({ name: '', nameInEmail: '' })
                    return [...attachments, curr]
                }
                return attachments
            })
        }
    }, [curr])

    const removeAttachment = useCallback((filename: string) => {
        setAttachments(attachments => attachments.filter(({ name }) => name !== filename))
    }, [])

    return (
        <Grid container direction='column' spacing={3}>
            <Grid item>
                <Typography variant='h6' color='textSecondary'>
                    {'Add Attachments:'}
                </Typography>
            </Grid>
            <Grid item container direction='row' justify='flex-end'>
                <Grid item>
                    <Button onClick={handleBack} color='primary'>
                        {'Back'}
                    </Button>
                </Grid>
                <Grid item>
                    <Button onClick={handleFinish} color='primary'>
                        {'Next'}
                    </Button>
                </Grid>
            </Grid>
            <Grid item container direction='row' spacing={2}>
                <Grid item xs>
                    <FileInput field='name' value={curr.name} label='Upload Attachment' setValue={handleChange} location='attachments' canReplaceFile={false}/>
                </Grid>
                <Grid item xs container direction='column' spacing={2}>
                    <Grid item>
                        <Text field='nameInEmail' value={curr.nameInEmail} label='Name in email' setValue={handleChange} />
                    </Grid>
                    <Grid item>
                        <Button onClick={addCurrent} color='primary'>
                            {'Add Attachment'}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            {attachments.map(({ name, nameInEmail }) => (
                <DisplayAttachment key={name} name={name} nameInEmail={nameInEmail} removeAttachment={removeAttachment} />
            ))}
        </Grid>
    )
}

type DisplayAttachmentProps = Attachment & {
    removeAttachment: Consumer<string>
}
function DisplayAttachment({ name, nameInEmail, removeAttachment }: DisplayAttachmentProps) {
    const onRemove = useCallback(() => {
        removeAttachment(name)
    }, [removeAttachment, name])
    return (
        <Grid item container direction='row' spacing={2}>
            <Grid item xs={3}>
                <Typography>
                    {`File Name: ${name}`}
                </Typography>
            </Grid>
            <Grid item xs>
                <Typography>
                    {`File Name in Email: ${nameInEmail}`}
                </Typography>
            </Grid>
            <Grid item>
                <Button onClick={onRemove}>
                    <Cancel color='error' />
                </Button>
            </Grid>
        </Grid>
    )
}
