import React from 'react'
import { Tabs, Tab } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'

import Events from './Events'
import FEP from './FEP'
import About from './About'
import { useTextLang } from '../Contexts/LangContext';
import FullPageFormat from '../Global/FullPageFormat';
import { routes, routeIDs } from '../Contexts/RouterContext';
import Footer from './Footer'
import { MatchProps } from '../types';

const mainTexts = {
    eventsTab: {
        en: 'EVENTS',
        es: 'EVENTOS'
    },
    fepTab: {
        en: 'F.E.P.',
        es: 'P.E.F.'
    },
    aboutTab: {
        en: 'ABOUT',
        es: 'QUIENES SOMOS'
    }
}

const useStyles = makeStyles(theme => ({
    tab: {
        flexBasis: 100,
    },
}))

export default function Main({ match }: MatchProps) {
    const classes = useStyles()
    const texts = useTextLang(mainTexts)
    const value = match.params.id;
    return (
        <>
            <FullPageFormat barContents={
                <Tabs value={value} variant='fullWidth' indicatorColor='primary'>
                    <Tab component={RouterLink} to={routes.events} value={routeIDs.events} label={texts.eventsTab} className={classes.tab} />
                    <Tab component={RouterLink} to={routes.fep} value={routeIDs.fep} label={texts.fepTab} className={classes.tab} />
                    <Tab component={RouterLink} to={routes.about} value={routeIDs.about} label={texts.aboutTab} className={classes.tab} />
                </Tabs>
            }>
                <Events visible={value === routeIDs.events} />
                {value === routeIDs.fep && <FEP />}
                {value === routeIDs.about && <About />}
            </FullPageFormat>
            <Footer />
        </>
    )
}