import React, { useState, useCallback, useMemo, MutableRefObject } from 'react';
import { CardContent, Grid, CardActions, Typography } from '@material-ui/core';

import { useTextLang } from '../../Contexts/LangContext';
import { useBasicTexts, escape } from '../../Global/GlobalItems';
import Selection from '../../Form/Components/Selection';
import { FieldType, defaultOption, defaultText } from '../../types';
import useForm from '../../Form/FormUtils';
import Text from '../../Form/Components/Text';
import Email from '../../Form/Components/Email';
import SubmitButtons from './SubmitButtons'
import { Captcha } from '../../server-types';
import { query } from '../../Global/ServerConnection';
import { useRouter, routes } from '../../Contexts/RouterContext';
import { BasicDialog, CaptchaDialog } from '../../Global/Dialogs';

const contactTexts = {
    title: {
        en: 'Need more information?',
    },
    subtitle: {
        en: 'Please contact us if you have any questions or comments.'
    },
    subject: {
        en: 'Subject'
    },
    bodyPlaceholder: {
        en: 'How can we help?'
    },
    firstName: {
        en: 'First Name'
    },
    lastName: {
        en: 'Last Name'
    },
    email: {
        en: 'Email'
    },
    moreFepInfo: {
        en: 'Additional FEP Information'
    },
    sessionZeroRequest: {
        en: `FEP Session '0' Request`
    },
    eventRegistration: {
        en: 'Event Registration'
    },
    other: {
        en: 'Other'
    },
    incorrectCaptchaError: {
        en: 'This answer is incorrect'
    },
    emptyCaptchaError: {
        en: 'Please answer the question'
    },
    successTitle: {
        en: 'Success!'
    },
    successContents: {
        en: 'Your contact request has been sent.'
    }
}

const contactFormRules = {
    subject: { type: FieldType.Select, required: true },
    subjectDetails: { type: FieldType.Text, dependent: { subject: ['Other'] } },
    body: { type: FieldType.Text, required: true },
    firstName: { type: FieldType.Text, required: true },
    lastName: { type: FieldType.Text, required: true },
    email: { type: FieldType.Email, required: true },
}

const contactFormDefaultValues = {
    subject: defaultOption,
    subjectDetails: defaultText,
    body: defaultText,
    firstName: defaultText,
    lastName: defaultText,
    email: defaultText,
}

type ContactFormValues = {
    subject: string,
    subjectDetails: string,
    body: string,
    firstName: string,
    lastName: string,
    email: string,
}

type ContactProps = {
    menuPortalTarget?: MutableRefObject<undefined>,
}
export default function Contact({ menuPortalTarget }: ContactProps) {
    const texts = useTextLang(contactTexts)
    const basicTexts = useBasicTexts()
    const [loading, setLoading] = useState(false)
    const [finalValues, setFinalValues] = useState<ContactFormValues>()
    const [captcha, setCaptcha] = useState<Captcha>()
    const [captchaError, setCaptchaError] = useState<string>()
    const [captchaLoading, setCaptchaLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const { navigateTo } = useRouter()

    const closeSuccessDialog = useCallback(() => {
        setSuccess(false)
    }, [])

    const subjectOptions = useMemo(() => [
        { value: 'Additional FEP Information', label: texts.moreFepInfo },
        { value: `FEP Session '0' Request`, label: texts.sessionZeroRequest },
        { value: 'Event Registration', label: texts.eventRegistration },
        { value: 'Other', label: texts.other }
    ], [texts])

    const getCaptcha = useCallback((name: string) => {
        setLoading(true)
        const processCaptcha = (captcha?: Captcha) => {
            setLoading(false)
            if (captcha) {
                setCaptcha(captcha)
                setCaptchaError(undefined)
            } else {
                navigateTo(routes.error)
            }
        }
        query('getBasicCaptcha', processCaptcha, `name:"${escape(name)}"`, 'captchaID,question')
    }, [navigateTo])

    const finishForm = useCallback((values: ContactFormValues) => {
        if (values) {
            setFinalValues(values)
            getCaptcha(values.firstName)
        }
    }, [getCaptcha])

    const { values, setValue, errors, handleSubmit } = useForm(contactFormRules, finishForm, contactFormDefaultValues)

    const closeCaptcha = useCallback(() => {
        setCaptcha(undefined)
        setCaptchaError(undefined)
    }, [])

    const resetForm = useCallback(() => {
        closeCaptcha()
        Object.entries(contactFormDefaultValues).forEach(([key, value]) => {
            setValue(key as any, value)
        })
    }, [setValue, closeCaptcha])

    const getNewCaptcha = useCallback(() => {
        if (finalValues) {
            getCaptcha(finalValues.firstName)
        }
    }, [finalValues, getCaptcha])

    const submitCaptchaGuess = useCallback(({ answer }: { answer: string }) => {
        if (answer.length > 0) {
            setCaptchaLoading(true)
            setCaptchaError(undefined)
            const processContact = (success: boolean) => {
                if (success) {
                    resetForm()
                    setSuccess(true)
                } else {
                    console.log('got error')
                    setCaptchaError(texts.incorrectCaptchaError)
                }
                setCaptchaLoading(false)
            }

            if (finalValues && captcha) {
                query('contact', processContact, `captchaGuess:{captchaID:"${captcha.captchaID}",answer:"${escape(answer)}"},subject:"${escape(finalValues.subjectDetails || finalValues.subject)}",body:"${escape(finalValues.body)}",firstName:"${escape(finalValues.firstName)}",lastName:"${escape(finalValues.lastName)}",email:"${escape(finalValues.email)}"`)
            }
        } else {
            setCaptchaError(texts.emptyCaptchaError)
        }
    }, [captcha, finalValues, resetForm, texts])


    return (
        <>
            <CardContent>
                <Typography align='center' variant='h6'>
                    {texts.title}
                </Typography>
                <Typography align='center' variant='body2' color='textSecondary'>
                    {texts.subtitle}
                </Typography>
                <Grid container direction='column'>
                    <Selection field='subject' label={texts.subject} value={values.subject} setValue={setValue} errorMsg={errors.subject} options={subjectOptions} menuPortalTarget={menuPortalTarget} />
                    {values.subject.value === 'Other' &&
                        <Text field='subjectDetails' value={values.subjectDetails} setValue={setValue} errorMsg={errors.subjectDetails} />
                    }
                    <Text field='body' value={values.body} setValue={setValue} errorMsg={errors.body} multiline variant='outlined' rows='5' placeholder={texts.bodyPlaceholder} />
                    <Text field='firstName' label={texts.firstName} value={values.firstName} setValue={setValue} errorMsg={errors.firstName} autoCapitalize='words' />
                    <Text field='lastName' label={texts.lastName} value={values.lastName} setValue={setValue} errorMsg={errors.lastName} autoCapitalize='words' />
                    <Email field='email' label={texts.email} value={values.email} setValue={setValue} errorMsg={errors.email} />
                </Grid>
            </CardContent>
            <CardActions>
                <SubmitButtons
                    onSubmit={handleSubmit}
                    submitText={basicTexts.submit}
                    onCancel={resetForm}
                    cancelText={basicTexts.cancel}
                    loading={loading}
                />
            </CardActions>
            {captcha &&
                <CaptchaDialog captcha={captcha.question} open onSubmit={submitCaptchaGuess} onCancel={closeCaptcha} onNewCaptcha={getNewCaptcha} loading={captchaLoading} errorMsg={captchaError} />
            }
            <BasicDialog open={success} handleClose={closeSuccessDialog} title={texts.successTitle} contents={texts.successContents} />
        </>
    )
}
