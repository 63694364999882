import React from "react"
import { JSONt, defaultDate, defaultOption, defaultText } from "../types"
import { FieldType, FamilyInfoType } from "../types"
import usePersonUtils from "./FieldUtils"
import Text from "./Components/Text"
import DateSelect from "./Components/DateSelect"
import Selection from "./Components/Selection"
import ZipCode from "./Components/ZipCode"

export const familyInfoRules = {
    maritalStatus: { type: FieldType.Select, required: true },
    dateOfMarriage: { type: FieldType.Date, dependent: { maritalStatus: ['MARRIED'] } },
    streetAddress: { type: FieldType.Text, required: true },
    city: { type: FieldType.Text, required: true },
    state: { type: FieldType.Select, dependent: { country: ['USA'] } },
    country: { type: FieldType.Select, required: true },
    zipCode: { type: FieldType.ZipCode, required: true }
}

export const familyInfoDefaultValues = {
    maritalStatus: defaultOption,
    dateOfMarriage: defaultDate,
    streetAddress: defaultText,
    city: defaultText,
    state: defaultOption,
    country: { value: 'USA', label: 'United States of America' },
    zipCode: defaultText
}

type FamilyInfoFieldsType = {
    values: FamilyInfoType & JSONt,
    setValue: (name: keyof FamilyInfoType, value: FamilyInfoType[keyof FamilyInfoType]) => void,
    errors: Partial<Record<keyof FamilyInfoType, string>> & JSONt,
    setError: (name: keyof FamilyInfoType, message?: string) => void
}
export default function FamilyInfoFields({ values, setValue, errors, setError }: FamilyInfoFieldsType) {
    const { labels, maritalStatusOptions, stateOptions, nationalityOptions } = usePersonUtils()
    return (
        <>
            <Selection field='maritalStatus' label={labels.maritalStatus} value={values.maritalStatus} setValue={setValue} errorMsg={errors.maritalStatus} options={maritalStatusOptions} />
            {values.maritalStatus.value === 'MARRIED' &&
                <DateSelect field='dateOfMarriage' label={labels.dateOfMarriage} value={values.dateOfMarriage} setValue={setValue} errorMsg={errors.dateOfMarriage} setErrorMsg={setError} disableFuture />
            }
            <Text field='streetAddress' label={labels.streetAddress} value={values.streetAddress} setValue={setValue} errorMsg={errors.streetAddress} autoCapitalize='words' />
            <Text field='city' label={labels.city} value={values.city} setValue={setValue} errorMsg={errors.city} autoCapitalize='words' />
            {values.country.value === 'USA' &&
                <Selection field='state' label={labels.state} value={values.state} setValue={setValue} errorMsg={errors.state} options={stateOptions} />
            }
            <ZipCode field='zipCode' label={labels.zipCode} value={values.zipCode} setValue={setValue} errorMsg={errors.zipCode} />
            <Selection field='country' label={labels.country} value={values.country} setValue={setValue} errorMsg={errors.country} options={nationalityOptions} />
        </>
    )
}