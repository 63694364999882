import React, { useState, useEffect } from 'react'
import { Typography } from '@material-ui/core';
import { MatchProps } from '../../types';
import { adminQuery } from '../../Global/ServerConnection';
import { Admin_PaymentType } from '../admin-types';
import { AdminViewTable } from '../Common/ViewInfo';


// el pip significa union de izq y der, pero gana derecha.
type Admin_PaymentType_ViewOnly=Record<keyof Admin_PaymentType, string>

export default function ViewPaymentPage({ match }: MatchProps) {
    return <ViewPayment paymentID={match.params.id}/>    
}

export function ViewPayment({ paymentID }: {paymentID: string}) {
    const [theInfo, setTheInfo] = useState<Admin_PaymentType>()

    useEffect(() => {
        const processOriginalValues = (values?: Admin_PaymentType) => {
            if (values)
                setTheInfo(values)
        }
        return adminQuery('getPaymentInfo', processOriginalValues, `paymentid:"${paymentID}"`, 'paypalid,orderid,paymentid,familyid,status,get_href{uri,method},approve_href{uri,method},update_href{uri,method},capture_href{uri,method},final_order_href{uri,method},donation')
    }, [paymentID])

	if (theInfo) {
		return <AdminDisplayPayment {...theInfo} />
    } else {
        return  <Typography>Unk paymentID</Typography>
    }
}



function AdminDisplayPayment(values: Admin_PaymentType) {
    const rows=[
		['paypalid',values.paypalid],
		['orderid',values.orderid],
		['paymentid',values.paymentid],
		['familyid',values.familyid],
		['status',values.status],
		['donation',values.donation],
		['get_href',values.get_href.method+' '+values.get_href.uri],
		['approve_href',values.approve_href.method+' '+values.approve_href.uri],
		['update_href',values.update_href.method+' '+values.update_href.uri],
		['capture_href',values.capture_href.method+' '+values.capture_href.uri],
		['final_order_href',values.final_order_href.method+' '+values.final_order_href.uri],
    ]
    
    return ( <AdminViewTable rows={rows} colsizes={['20%','auto']} links={['','']}></AdminViewTable> )
}

