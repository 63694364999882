import { useTextLang } from "../Contexts/LangContext";
import React, { useMemo } from "react"
import { FieldType, AccountInfoType, defaultOption, defaultText } from "../types"
import usePersonUtils from "./FieldUtils"
import Text from "./Components/Text"
import Selection from "./Components/Selection"
import Email from "./Components/Email";
import Password from "./Components/Password";
import { Typography, Link, Grid } from "@material-ui/core";
import { Link as RouterLink } from 'react-router-dom'
import SingleCheckBox from "./Components/CheckBox";
import { routes } from "../Contexts/RouterContext";

const accountInfoLabels = {
    reference: {
        en: 'How did you hear about us?'
    },
    EWTN: {
        en: 'EWTN'
    },
    Brochure: {
        en: 'Brochure'
    },
    IEEE: {
        en: 'IEEE'
    },
    Friend: {
        en: 'Friend'
    },
    Other: {
        en: 'Other...'
    },
    referenceDetailsFriend: {
        en: "Friend's full name"
    },
    referenceDetailsOther: {
        en: 'Please specify'
    },
    iAgree: {
        en: 'I agree to the'
    },
    termsAndConditions: {
        en: 'Terms and Conditions'
    },
}

export const accountInfoDefaultValues = {
    email: defaultText,
    username: defaultText,
    password: defaultText,
    reference: defaultOption,
    referenceDetails: defaultText,
    termsAndConditions: false,
}

export const accountInfoRules = {
    email: { type: FieldType.Email, required: true },
    username: { type: FieldType.Text, required: true, minLength: 3 },
    password: { type: FieldType.Password, required: true, minLength: 8 },
    reference: { type: FieldType.Select, required: true },
    referenceDetails: { type: FieldType.Text, dependent: { reference: ['Friend', 'Other'] } },
    termsAndConditions: { type: FieldType.SingleCheckbox },
}

type FamilyInfoFieldsType = {
    values: AccountInfoType & { termsAndConditions: boolean },
    setValue: (name: keyof AccountInfoType | 'termsAndConditions', value: AccountInfoType[keyof AccountInfoType] | boolean) => void,
    errors: Partial<Record<keyof AccountInfoType, string>>,
}

export default function AccountInfoFields({ values, setValue, errors }: FamilyInfoFieldsType) {
    const { labels } = usePersonUtils()
    const texts = useTextLang(accountInfoLabels)

    const referenceOptions = useMemo(() => [
        { value: 'EWTN', label: texts.EWTN },
        { value: 'Brochure', label: texts.Brochure },
        { value: 'IEEE', label: texts.IEEE },
        { value: 'Friend', label: texts.Friend },
        { value: 'Other', label: texts.Other }
    ], [texts])

    return (
        <>
            <Email field='email' label={labels.email} value={values.email} setValue={setValue} errorMsg={errors.email} autoComplete='on'/>
            <Text field='username' label={labels.username} value={values.username} setValue={setValue} errorMsg={errors.username} autoCapitalize='off' autoComplete='on'/>
            <Password field='password' label={labels.password} value={values.password} setValue={setValue} errorMsg={errors.password} autoComplete='on'/>
            <Selection field='reference' label={texts.reference} value={values.reference} setValue={setValue} errorMsg={errors.reference} options={referenceOptions} />
            {values.reference.value === 'Friend' &&
                <Text field='referenceDetails' label={texts.referenceDetailsFriend} value={values.referenceDetails} setValue={setValue} errorMsg={errors.referenceDetails} />
            }
            {values.reference.value === 'Other' &&
                <Text field='referenceDetails' label={texts.referenceDetailsOther} value={values.referenceDetails} setValue={setValue} errorMsg={errors.referenceDetails} />
            }
            <SingleCheckBox
                field='termsAndConditions'
                value={values.termsAndConditions}
                setValue={setValue}
                label={
                    <Grid container>
                        <Typography variant='body2'>
                            {texts.iAgree}
                        </Typography>
                        &nbsp;
                        <Link variant='body2' color='primary' component={RouterLink} to={routes.termsAndConditions}>
                            {texts.termsAndConditions}
                        </Link>
                    </Grid>
                }
            />
        </>
    )
}