import React, { ReactNode, useEffect, useCallback } from 'react'

import { KeyboardDatePicker, DatePickerProps, DatePicker } from '@material-ui/pickers';
import { useTextLang } from '../../Contexts/LangContext';
import { FormField, defaultDate } from '../../types';
import { useTheme } from '@material-ui/styles';
import { Theme, useMediaQuery } from '@material-ui/core';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

export const dateSelectText = {
    invalidDateMessage: {
        en: 'Invalid Date'
    },
    minDateMessage: {
        en: 'Date is below the minimum'
    },
    maxDateMessage: {
        en: 'Date is above the maximum'
    }
}

type DateSelectType<FieldName> = FormField<FieldName, MaterialUiPickersDate> & {
    setErrorMsg: (field: FieldName, errorMsg?: string) => void,
} & Omit<DatePickerProps, 'onChange' | 'value'>
export default function DateSelect<FieldName>({ field, value, setValue, setErrorMsg, errorMsg, helperText, ...rest }: DateSelectType<FieldName>) {
    const texts = useTextLang(dateSelectText)
    const handleChange = useCallback((date: MaterialUiPickersDate) => {
        // console.log(date && date.format())
        setValue(field, date)
    }, [field, setValue])

    const handleError = useCallback((newError?: ReactNode) => {
        if (newError !== errorMsg && errorMsg === undefined && typeof newError === 'string' && newError !== '') {
            setErrorMsg(field, newError)
        }
    }, [field, errorMsg, setErrorMsg])

    // Remove value on unmount
    useEffect(() => {
        return () => {
            setValue(field, defaultDate)
        }
    }, [field, setValue])

    const theme = useTheme<Theme>()
    const matches = useMediaQuery(theme.breakpoints.down('xs'))

    if (matches) {
        return (
            <DatePicker
                autoComplete='off'
                autoCorrect='off'
                variant='dialog'
                margin='dense'
                fullWidth
                openTo='year'
                invalidDateMessage={texts.invalidDateMessage}
                minDateMessage={texts.minDateMessage}
                maxDateMessage={texts.maxDateMessage}
                format='MM/DD/YYYY'
                {...rest}
                value={value || null}
                error={errorMsg !== undefined}
                helperText={errorMsg || helperText}
                onAccept={handleChange}
                onChange={handleChange}
                onError={handleError}
            />
        )
    } else {
        return (
            <KeyboardDatePicker
                variant='dialog'
                margin='dense'
                fullWidth
                openTo='year'
                allowKeyboardControl
                invalidDateMessage={texts.invalidDateMessage}
                minDateMessage={texts.minDateMessage}
                maxDateMessage={texts.maxDateMessage}
                format='MM/DD/YYYY'
                {...rest}
                value={value || null}
                error={errorMsg !== undefined}
                helperText={errorMsg || helperText}
                onAccept={handleChange}
                onChange={handleChange}
                onError={handleError}
            />
        )
    }
}