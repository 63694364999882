import React, { useState, useEffect, useCallback, useMemo } from 'react'
//import React, { MouseEvent } from 'react'

import { Grid, Button, Hidden, ListItemText, SwipeableDrawer, List, ListItem, ListItemIcon, Divider, IconButton } from '@material-ui/core'
//import { Menu, MenuItem } from '@material-ui/core'

import { Menu as MenuIcon, ShoppingCartOutlined, EventAvailable, PersonOutline, ExitToApp, HomeOutlined, FaceOutlined, AccountBoxOutlined, LockOutlined, NaturePeopleOutlined, Event } from '@material-ui/icons';
//import { Translate, Check } from '@material-ui/icons';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { Link as RouterLink } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'

import { LogoMobile } from './Icons';
import { useTextLang } from '../Contexts/LangContext';
//import { useLang } from '../Contexts/LangContext';

import { useAuth, permissionsKey } from '../Contexts/AuthContext'
import { routes, useRouter } from '../Contexts/RouterContext';
// import { ApprovedLanguage } from '../types';
// import { TypographyProps } from '@material-ui/core/Typography';
import { BasicDialog } from './Dialogs';
import Notifications from './Notifications';

const navigatonBarTexts = {
    languageButton: {
        en: 'Language',
        es: 'Idioma'
    },
    loginButton: {
        en: 'Login',
        es: 'Login'
    },
    logoutButton: {
        en: 'Logout',
        es: 'Logout'
    },
    calendar: {
        en: 'Calendar',
        es: 'Calendario'
    },
    success: {
        en: 'Success!',
        es: 'Operacion terminada con exito!'
    },
    successfulLogout: {
        en: 'You have successfully logged out',
        es: 'Se ha cerrado la sesión'
    },
    ok: {
        en: 'OK',
        es: 'OK'
    },
    menuTitle: {
        en: 'Menu',
        es: 'Menú'
    },
    home: {
        en: 'Home',
        es: 'Página principal'
    },
    goToCart: {
        en: 'Event Cart',
        es: 'Carrito de eventos'
    },
    goToMyEvents: {
        en: 'My Events',
        es: 'Mis eventos'
    },
    goToMyVolunteering: {
        en: 'My Volunteering',
    },
    goToFamilySettings: {
        en: 'Edit Family Info',
        es: 'Modificar información de familia'
    },
    goToAccountSettings: {
        en: 'Edit Account Info',
        es: 'Modificar información de cuenta'
    },
    finishRegistration: {
        en: 'Finish Registration',
        es: 'Terminar registro'
    },
    admin: {
        en: 'Admin',
        es: 'Admin'
    }
}

const useStyles = makeStyles(theme => ({
    button: {
        color: theme.palette.text.primary
    },
    buttonIcon: {
        [theme.breakpoints.up('sm')]: {
            marginRight: theme.spacing(1),
        },
    },
    logoContainer: {
        height: theme.spacing(4),
        padding: theme.spacing(1),
        [theme.breakpoints.up('md')]: {
            height: theme.spacing(3),
            padding: 0,
        },
    },
    logo: {
        height: '100%',
    },
    container: {
        padding: theme.spacing(1),
        [theme.breakpoints.up('md')]: {
            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(3),
        },
    },
    list: {
        width: 'auto',
        minWidth: theme.spacing(40),
    },
}))

export default function Navigation({ simple }: { simple?: boolean }) {
    const [logoutDialogOpen, setLogoutDialogOpen] = useState(false)
    const texts = useTextLang(navigatonBarTexts)
    const { isAuth, removeSessionToken } = useAuth()
    const [wasAuth, setWasAuth] = useState(isAuth)

    useEffect(() => {
        if (wasAuth && !isAuth) {
            setWasAuth(false)
            setLogoutDialogOpen(true)
        }
    }, [isAuth, wasAuth])

    const closeLogoutDialog = useCallback(() => {
        setLogoutDialogOpen(false)
    }, [])

    const classes = useStyles()
    return (
        <>
            <Notifications />
            <Grid container alignItems='center' wrap='nowrap' className={classes.container}>
                <Grid item xs={true}>
                    {!simple && <SettingsDrawer />}
                </Grid>
                <Grid item component={RouterLink} to={routes.home} className={classes.logoContainer} >
                    <LogoMobile className={classes.logo} />
                </Grid>
                <Grid item xs={true} container justify='flex-end' alignItems='center'>
                    {!simple && <Grid item>
                        {isAuth
                            ? <Button className={classes.button} onClick={removeSessionToken}>
                                <ExitToApp className={classes.buttonIcon} />
                                <Hidden xsDown>
                                    {texts.logoutButton}
                                </Hidden>
                            </Button>
                            : <Button className={classes.button} component={RouterLink} to={routes.login}>
                                <PersonOutline className={classes.buttonIcon} />
                                <Hidden xsDown>
                                    {texts.loginButton}
                                </Hidden>
                            </Button>
                        }
                    </Grid>}
                </Grid>
            </Grid>
            <BasicDialog open={logoutDialogOpen} handleClose={closeLogoutDialog} title={texts.success} contents={texts.successfulLogout} />
        </>
    )
}


function SettingsDrawer() {
    const [open, setOpen] = useState(false)
    const texts = useTextLang(navigatonBarTexts)
    const classes = useStyles()
    const { isAuth, removeSessionToken } = useAuth()
    const { pathname } = useRouter()

    const openDrawer = useCallback(() => {
        setOpen(true)
    }, [])

    const closeDrawer = useCallback(() => {
        setOpen(false)
    }, [])

    useEffect(() => {
        closeDrawer()
    }, [pathname, closeDrawer])

    const handleLogout = useCallback(() => {
        setOpen(false)
        removeSessionToken()
    }, [removeSessionToken])

    return (
        <>
            <Button className={classes.button} onClick={openDrawer}>
                <MenuIcon className={classes.buttonIcon} />
                <Hidden xsDown>
                    {texts.menuTitle}
                </Hidden>
            </Button>
            <SwipeableDrawer
                open={open}
                onClose={closeDrawer}
                onOpen={openDrawer}
                onKeyDown={closeDrawer}
                classes={
                    { paper: classes.list }
                }
            >
                <Grid container alignItems='flex-end' justify='flex-end' onClick={closeDrawer}>
                    <IconButton>
                        <ChevronLeftIcon />
                    </IconButton>
                </Grid>
                <Divider />
                <List>
                    <ListItem button component={RouterLink} to={routes.home}>
                        <ListItemIcon><HomeOutlined /></ListItemIcon>
                        <ListItemText primary={texts.home} />
                    </ListItem>
                    <Divider variant='middle' />
                    {isAuth &&
                        <>
                            <AuthLinks />
                            <Divider variant='middle' />
                        </>}
                    {/* 
                    TODO: Add texts in Spanish
                    <LanguageSelect /> 
                    */}
                    <ListItem button component={RouterLink} to={routes.calendar}>
                        <ListItemIcon><Event /></ListItemIcon>
                        <ListItemText primary={texts.calendar} />
                    </ListItem>
                    <Divider variant='middle' />
                    {isAuth
                        ? <ListItem button onClick={handleLogout}>
                            <ListItemIcon><ExitToApp /></ListItemIcon>
                            <ListItemText primary={texts.logoutButton} />
                        </ListItem>
                        : <ListItem button component={RouterLink} to={routes.login}>
                            <ListItemIcon><PersonOutline /></ListItemIcon>
                            <ListItemText primary={texts.loginButton} />
                        </ListItem>
                    }
                    <Divider variant='middle' />
                    {isAuth && localStorage.getItem(permissionsKey) === 'ADMIN' &&
                        <>
                            <ListItem button component={RouterLink} to={routes.admin}>
                                <ListItemIcon><LockOutlined /></ListItemIcon>
                                <ListItemText primary={texts.admin} />
                            </ListItem>
                            <Divider variant='middle' />
                        </>}
                </List>
            </SwipeableDrawer>

        </>
    )
}

function AuthLinks() {
    const texts = useTextLang(navigatonBarTexts)
    const { registrationStep } = useAuth()

    const finishedRegistration = useMemo(() => registrationStep === 'DONE' || registrationStep === 'APPROVED', [registrationStep])

    if (finishedRegistration) {
        return (
            <>
                <ListItem button component={RouterLink} to={routes.pendingEvents}>
                    <ListItemIcon><ShoppingCartOutlined /></ListItemIcon>
                    <ListItemText primary={texts.goToCart} />
                </ListItem>
                <ListItem button component={RouterLink} to={routes.myEvents}>
                    <ListItemIcon><EventAvailable /></ListItemIcon>
                    <ListItemText primary={texts.goToMyEvents} />
                </ListItem>
                <ListItem button component={RouterLink} to={routes.myVolunteering}>
                    <ListItemIcon><NaturePeopleOutlined /></ListItemIcon>
                    <ListItemText primary={texts.goToMyVolunteering} />
                </ListItem>
                <ListItem button component={RouterLink} to={routes.familySettings}>
                    <ListItemIcon><FaceOutlined /></ListItemIcon>
                    <ListItemText primary={texts.goToFamilySettings} />
                </ListItem>
                <ListItem button component={RouterLink} to={routes.accountSettings}>
                    <ListItemIcon><AccountBoxOutlined /></ListItemIcon>
                    <ListItemText primary={texts.goToAccountSettings} />
                </ListItem>
            </>
        )
    } else {
        return (
            <>
                <ListItem button component={RouterLink} to={routes.registerUser}>
                    <ListItemIcon><AccountBoxOutlined /></ListItemIcon>
                    <ListItemText primary={texts.finishRegistration} />
                </ListItem>
            </>
        )
    }
}

/*
function LanguageSelect() {
    const [menuAnchorEl, setMenuAnchorEl] = useState<Element | undefined>(undefined)
    const texts = useTextLang(navigatonBarTexts)

    const languageButtonClick = useCallback((event: MouseEvent) => {
        setMenuAnchorEl(event.currentTarget)
    }, [])

    const closeMenu = useCallback(() => {
        setMenuAnchorEl(undefined)
    }, [])

    const { language, approvedLanguages, setLanguage } = useLang()

    const menuItemClick = useCallback((language: ApprovedLanguage) => {
        setLanguage(language)
        closeMenu()
    }, [closeMenu, setLanguage])

    return (
        <>
            <ListItem button onClick={languageButtonClick}>
                <ListItemIcon>
                    <Translate />
                </ListItemIcon>
                <ListItemText primary={texts.languageButton} />
            </ListItem>
            <Menu anchorEl={menuAnchorEl} open={Boolean(menuAnchorEl)} onClose={closeMenu}>
                {approvedLanguages.map(({ value, label }) => {
                    const selected = language === value
                    const typographyProps: TypographyProps = selected ? { color: 'primary' } : { color: 'textSecondary' }
                    return (
                        <MenuItem onClick={() => menuItemClick(value)} key={value}>
                            <ListItemText primaryTypographyProps={typographyProps}
                                primary={label.toUpperCase()} key={`${value} text`} />
                            <Check color={selected ? 'primary' : 'secondary'} key={`${value} check`} />
                        </MenuItem>
                    )
                })}
            </Menu>
        </>
    )
}
*/