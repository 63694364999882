import React, { RefObject } from 'react'
import { Typography } from "@material-ui/core";
import { TypographyProps } from '@material-ui/core/Typography';
import { useTextLang } from '../Contexts/LangContext';
import moment from 'moment-timezone'
import axios from 'axios';
import { Consumer } from '../types';

export function LineTypography({ children, ...rest }: TypographyProps) {
    if (typeof children === 'string') {
        return (
            <Typography {...rest}>
                {children.split('\n').map((line, key) =>
                    <React.Fragment key={key}>
                        {line}
                        <br />
                    </React.Fragment>
                )}
            </Typography>
        )
    } else {
        return (
            <Typography {...rest}>
                {children}
            </Typography>
        )
    }
}

export function empty(data: any) {
    if (typeof (data) == 'number' || typeof (data) == 'boolean') {
        return false;
    }
    if (typeof (data) == 'undefined' || data === null) {
        return true;
    }
    if (typeof (data.length) != 'undefined') {
        return data.length === 0;
    }
    let count = 0;
    for (let i in data) {
        if (data.hasOwnProperty(i)) {
            count++;
        }
    }
    return count === 0;
}

const basicTexts = {
    next: {
        en: 'Next',
        es: 'Siguiente'
    },
    back: {
        en: 'Back',
        es: 'Regresar'
    },
    register: {
        en: 'Register',
        es: 'Registro'
    },
    submit: {
        en: 'Submit',
        es: 'Enviar'
    },
    cancel: {
        en: 'Cancel',
        es: 'Cancelar'
    },
    continue: {
        en: 'Continue',
        es: 'Continuar'
    },
    finish: {
        en: 'Finish',
        es: 'Terminar'
    },
    done: {
        en: 'Done',
        es: 'Listo'
    },
    ok: {
        en: 'OK',
        es: 'OK'
    },
    home: {
        en: 'Go to Home',
        es: 'Ir a pagina principal'
    },
    save: {
        en: 'Save',
        es: 'Guardar'
    },
    yes: {
        en: 'Yes',
        es: 'Si'
    },
    no: {
        en: 'No',
        es: 'No'
    }
}
export function useBasicTexts() {
    const texts = useTextLang(basicTexts)
    return texts
}

export function scrollToTop() {
    window.scrollTo(0, 0);
}

export function formatDateRange(dateStart?: string | null, dateEnd?: string | null) {
    const dateFormat = 'ddd, MMM Do, YYYY'
    const timeFormat = 'h:mma'

    let formattedDates
    const dateI = moment(dateStart || undefined)
    const dateF = moment(dateEnd || undefined)
    const dateIDate = dateI.format(dateFormat)
    const dateFDate = dateF.format(dateFormat)
    const dateITime = dateI.format(timeFormat)
    const dateFTime = dateF.format(timeFormat)
    if (dateIDate === dateFDate) {
        formattedDates = `${dateIDate} ${dateITime} - ${dateFTime}`
    } else {
        formattedDates = `${dateIDate} ${dateITime} - ${dateFDate} ${dateFTime}`
    }
    return formattedDates
}

export function shortFormatDateRange(dateStart?: string, dateEnd?: string) {
    const dateFormat = 'ddd, MMM D'
    const timeFormat = 'h:mma'

    let formattedDates
    const dateI = moment(dateStart)
    const dateF = moment(dateEnd)
    const dateIDate = dateI.format(dateFormat)
    const dateFDate = dateF.format(dateFormat)
    const dateITime = dateI.format(timeFormat)
    const dateFTime = dateF.format(timeFormat)
    if (dateIDate === dateFDate) {
        formattedDates = `${dateIDate}, ${dateITime} - ${dateFTime}`
    } else {
        formattedDates = `${dateIDate}, ${dateITime} - ${dateFDate}, ${dateFTime}`
    }
    return formattedDates
}

export function escape(str?: string) {
    return str && str.trim().replace(/\\/g, '\\\\').replace(/"/g, '\\"').replace(/\n/g, '\\n').replace(/\r/g, '')
}

export function scrollToView(ref?: RefObject<HTMLDivElement>) {
    if (ref && ref.current) {
        window.scrollTo({ top: ref.current.offsetTop - 150, behavior: 'smooth' })
    }
}

const staticServerURL = 'https://fei-data.s3-us-west-2.amazonaws.com'

export function transformImageUri(src: string) {
    return `${staticServerURL}/images/${src}`
}

export function getDoc(src: string, processResponse: Consumer<string>) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const cancelTokenParams = { cancelToken: source.token }
    const cancelCall = source.cancel
    axios.get<string>(`${staticServerURL}/docs/${src}`, cancelTokenParams)
        .then(response => {
            processResponse(response.data)
        })
        .catch((thrown) => {
            if (axios.isCancel(thrown)) {
                // console.log('Request canceled', thrown.message);
            } else {
                // console.log('AXIOS ERROR')
            }
        })
    return cancelCall
}