import React, { useCallback, useRef } from 'react';
import { Typography, Button, Grid, Theme, useMediaQuery } from '@material-ui/core';

import { makeStyles, useTheme } from '@material-ui/styles';
import { useTextLang } from '../Contexts/LangContext';
import { LineTypography } from '../Global/GlobalItems';
import { InfoCard, FEPCard, LineDivider, Subcategory } from './InfoCards';
import { Consumer } from '../types';
import { useRouter, routes } from '../Contexts/RouterContext';
import { tags } from './EventTags';
import ContactVolunteerDonate from './AboutComponents/ContactVolunteerDonate'

const aboutTexts = {
    aboutTitle: {
        en: 'WHO WE ARE',
        es: 'QUIENES SOMOS'
    },
    fepTitle: {
        en: 'FAMILY EDUCATION PROGRAM',
        es: 'PROGRAMA DE EDUCACION FAMILIAR'
    },
    activitiesTitle: {
        en: 'SUPPORTING ACTIVITIES',
        es: 'ACTIVIDADES DE SOPORTE'
    },
    missionTitle: {
        en: 'MISSION',
        es: 'MISION'
    },
    missionDescription: {
        en: `Founded in 2010, Family Education Institute aims to support parents in their role as primary educators of their children through the Family Education Program and countless supporting activities.`,
        es: 'Fundado en 2010, Family Education Institute ayuda a los padres en su rol como primeros educadores de sus hijos a traves del Programa de Educación Familiar y muchas otras actividades complementarias.'
    },
    visionTitle: {
        en: 'VISION',
        es: 'VISION'
    },
    visionDescription: {
        en: `Contribute to the development of free and responsible future leaders who are virtuous and able to achieve true happiness for themselves and for others.`,
        es: 'Contribuir al desarrollo de futuros lideres libres, responsables, virtuosos y capaces de conseguir verdadera felicidad para ellos y los demas.'
    },
    goToFepButton: {
        en: 'Learn More',
        es: 'Más Información'
    },
    goToCategoryButton: {
        en: (title: string) => `view upcoming ${title}`,
        es: (title: string) => `ver siguientes ${title}`
    },
    coursesTitle: {
        en: 'Courses',
        es: 'Cursos'
    },
    coursesDescription: {
        en: 'A series of classes offered throughout the school year focused on developing knowledge, character and good judgment.',
        es: 'Una serie de clases ofrecidas durante el año escolar que se enfocan en desarrollar conocimiento, caracter y buen criterio.'
    },
    doctrineCoursesTitle: {
        en: 'Doctrine',
        es: 'Doctrina'
    },
    doctrineCoursesDescription: {
        en: 'Opportunities for spiritual development for all members of the family interested in deepening their Faith. There are groups for boys, girls, young adults, fathers, and mothers.',
        es: 'Oportunidades de desarrollo espiritual para todos los miembros de la familia que esten interesados en profundizar su Fé. Hay grupos para niños, niñas, adultos jovenes, padres y madres.'
    },
    anthropologyCoursesTitle: {
        en: 'Anthropology',
        es: 'Antropología'
    },
    anthropologyCoursesDescription: {
        en: `Based on Saint John Paul II's Theology of the Body, these courses will deepen the understanding of the human person and God's love revealed through His creation and our daily life. There are groups for boys, girls, and young adults.`,
        es: 'Basados en la Teología del Cuerpo de San Juan Pablo II, estos cursos permiten profundizar en el entedimiento de la persona humana y el amor de Dios revelado a traves de su creación y nuestra vida diaria. Hay grupos para niños, niñas y adultos jovenes.'
    },
    seminarsTitle: {
        en: `Seminars`,
        es: 'Seminarios'
    },
    seminarsDescription: {
        en: 'In-depth lectures during a single weekend offered by excellent speakers',
        es: 'Conferencias de un solo fin de semana impartidas por excelentes profesores'
    },
    feiSeminarsTitle: {
        en: 'FEI Seminars',
        es: 'Seminarios FEI'
    },
    feiSeminarsDescription: {
        en: 'Explore diverse topics like Anthropology, Bioethics, Theology, Family, Science, Philosophy, Ethics, and History',
        es: 'Explore diferentes temas como Antropología, Bioética, Teología, Familia, Ciencia, Filosofia, Etica e Historía'
    },
    nairobiTitle: {
        en: 'Nairobi',
        es: 'Nairobi'
    },
    nairobiDescription: {
        en: 'An opportunity for couples to form themselves in issues related to marriage and married life in order to flourish in their married vocation, overcome difficulties, and enrich their relationship.',
        es: 'Brinda a los matrimonios la oportunidad de formarse en temas conyugales para vivir plenamente la vocación matrimonial, superar dificultades y enriquecer la relación.'
    },
    workshopsTitle: {
        en: 'Workshops',
        es: 'Talleres'
    },
    ptcTitle: {
        en: 'Protect Your Heart',
        es: 'Protege Tu Corazón'
    },
    ptcDescription: {
        en: 'A series of sessions and activities designed for specific age groups of boys, girls, and young adults in order to help them develop clear ideas on the value of fertility, life, and love.',
        es: 'una serie de sesiones y actividades diseñadas para grupos de niños y niñas en una edad especifica para ayudarlos a desarrollar ideas claras sobre el valor de la fertilidad, la vida y el amor'
    },
    workshopsForLeaders: {
        en: 'Workshop for Leaders',
        es: 'Taller para Líderes'
    },
    volunteerTrainingTitle: {
        en: 'Instructor & Volunteer Training',
        es: 'Capacitación para Instructores y Voluntarios'
    },
    volunteerTrainingDescription: {
        en: `Mandatory for students who are currently participating in FEI's courses and seminars and wish to apply as volunteers or instructors in FEI's summer camps.`,
        es: 'Requerido para estudiantes que actualmente están participando en los seminarios y cursos de FEI y que desean aplicar como instructores o voluntarios en los Campamentos de Verano de FEI'
    },
    clubLeadersTitle: {
        en: 'Workshop for  Club Leaders',
        es: 'Taller para Líderes de Club'
    },
    clubLeadersDescription: {
        en: 'Family Education Institute teachers, instructors, and volunteers develop their abilities as leaders in order to use them in the service of others.',
        es: 'Los maestros, instructores y voluntarios de Family Education Institute desarrollan habilidades de liderazgo para ponerlas al servicio de los demás.'
    },
    criticalThinkingTitle: {
        en: 'Critical Thinking',
        es: 'Pensamiento Crítico'
    },
    criticalThinkingDescription: {
        en: 'Monthly discussions in which participants develop critical thinking and constructive dialogue skills by analyzing movies, books, or current events.'
    },
    movieClubTitle: {
        en: 'Movie Clubs',
        es: 'Clubes de Peliculas'
    },
    movieClubDescription: {
        en: 'We offer movie clubs for boys, girls, young adults, and parents.',
        es: 'Orecemos Clubes de Peliculas para niños, niñas, adultos jovenes y padres.'
    },
    bookClubTitle: {
        en: 'Book Clubs',
        es: 'Clubes de Lectura'
    },
    bookClubDescription: {
        en: 'We offer book clubs for young boys and girls.',
        es: 'Ofrecemos Clubes de Lectura para niños y niñas.'
    },
    contemporaryTopicsTitle: {
        en: 'Contemporary Topics',
        es: 'Temas Contemporaneos'
    },
    contemporaryTopicsDescription: {
        en: 'An opportunity for young adults to learn how to discuss and approach tough topics.',
        es: 'Una oportunidad para que los adultos jovenes puedan acercarse y discutir temas difíciles.'
    },
    campsTitle: {
        en: 'Summer Camps',
        es: 'Campamentos de Verano'
    },
    summerDayCampsTitle: {
        en: 'Summer Day Camps',
        es: 'Campamentos de Verano de Dia'
    },
    summerDayCampsDescription: {
        en: 'Week-long summer day camps where participants discover new hobbies and skills, play outdoors and enjoy nature, and promote learning and living the virtues. We offer summer camps for boys, girls, young adults, and the whole family.',
        es: 'Campamentos de verano de 2 o 3 horas por dia durante una semana en donde los participantes descubren nuevos hobbies y habilidades, juegan afuera, disfrutan de la naturaleza y se promueve el aprendizaje y la vida de virtudes.  Ofrecemos campamentos de verano para niños, niñas, adultos jovenes y para toda la familia.'
    },
    miniCampsTitle: {
        en: 'Mini Camps',
        es: 'Mini Campamentos'
    },
    miniCampsDescription: {
        en: 'All-night summer camp for boys and girls in Elementary involving games, contests, activities, and camping at FEI.',
        es: 'Campamento de toda la noche para niños y niñas en Elementary con juegos, concursos, actividades y acampada en FEI'
    },
    clubsTitle: {
        en: 'Clubs',
        es: 'Clubes'
    },
    clubsDescription: {
        en: `Monthly meetings where members participate in recreational activities, play sports, and receive formation as well as get to know themselves better, develop their character, and grow in leadership.
        
        The main objective is to help parents in their role as educators by supporting their children's spiritual and human formation through the growth of virtues and skills that correspond to their Sensitive Period.`,
        es: `Reuniones mensuales en donde mlos miembros participan en actividades recreativas, juegan deportes y reciben formación y al mismo tiempo se conocen mejor, desarrollan su caracter y crecen en liderazgo.
        
        El principal objetivo es ayudar a los padres en su papel de primeros educadores de sus hijos, tanto en su formación humana como espiritual a través del desarrollo de virtudes y hablidades que correspondan a sus Periodos Sensitivos`
    },
    clubsGirlsTitle: {
        en: 'Clubs for Girls',
        es: 'Clubes para Niñas'
    },
    clubsGirlsDescription: {
        en: `Sunflower, Kolibri and Ignis`,
        es: 'Sunflower, Kolibri e Ignis'
    },
    clubsBoysTitle: {
        en: 'Clubs for Boys',
        es: 'Clubes para Niños'
    },
    clubsBoysDescription: {
        en: `Explore, Tekno, and HSM`,
        es: 'Explore, Tekno y HSM'
    },
    specialEventsTitle: {
        en: 'Special Events',
        es: 'Eventos Especiales'
    },
    specialEventsDescription: {
        en: 'Opportunities to get together with other families who are part of Family Education Institute and desire to form virtuous, pious, and cheerful families.',
        es: 'Oportunidades para reunirse con otras familias que son parte de Family Edeucation Institute y que comparten el deseo de formar familias virtuosas, piadosas y alegres.'
    },
    specialEventsExTitle: {
        en: 'Some events we host:',
        es: 'Algunos de los eventos que organizamos:'
    },
    specialEventsExContents: {
        en: `Back to School Party
        Christmas Posada
        Visiting the Elderly
        Easter Celebration
        Texas Fair
        Pilgrimage`,
        es: `Fiesta de Regreso a Clases
        Posada Navideña
        Visita al Asilo
        Celebración de Pascua
        Feria Texana
        Romería
       `
    },
    support: {
        en: 'SUPPORT',
        es: 'SOPORTE'
    },
}

const useStyles = makeStyles(theme => ({
    mainContainer: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(4),
        //margin: 0,
        //width: '100%'
    },
}))

export default function About() {
    const classes = useStyles()
    const texts = useTextLang(aboutTexts)
    const { navigateTo, routeParams } = useRouter()

    const goToFEP = useCallback(() => {
        navigateTo(routes.fep)
    }, [navigateTo])

    const goToCategory = useCallback((category: string) => {
        navigateTo(routes.events, false, { tag: category })
    }, [navigateTo])

    // Ref to scroll to contact-volunteer-donate
    const titleRef = useRef<any>(null)


    return (
        <Grid container direction='column' spacing={3} className={classes.mainContainer}>
            <Grid item>
                <Typography variant='h5' color='textSecondary' align='center'>
                    {texts.aboutTitle}
                </Typography>
            </Grid>
            <Grid item>
                <TitleCard />
            </Grid>
            <Grid item>
                <Typography variant='h5' color='textSecondary' align='center'>
                    {texts.fepTitle}
                </Typography>
            </Grid>
            <Grid item>
                <FEPCard actionFunc={goToFEP} actionText={texts.goToFepButton} />
            </Grid>
            <Grid item>
                <Typography variant='h5' color='textSecondary' align='center'>
                    {texts.activitiesTitle}
                </Typography>
            </Grid>
            <Grid item container>
                <CategoryCard filter={tags.courses}
                    title={texts.coursesTitle}
                    description={texts.coursesDescription}
                    imageSquareSrc='FEI-A-Courses-S.jpg'
                    imageRectSrc='FEI-A-Courses-R.jpg' goToCategory={goToCategory}
                    afterImage={<>
                        <Subcategory title={texts.doctrineCoursesTitle} description={texts.doctrineCoursesDescription} />
                        <Subcategory title={texts.anthropologyCoursesTitle} description={texts.anthropologyCoursesDescription} />
                    </>} />
            </Grid>
            <Grid item container>
                <CategoryCard filter={tags.seminars}
                    title={texts.seminarsTitle}
                    description={texts.seminarsDescription}
                    imageSquareSrc='FEI-A-Seminars-S.jpg'
                    imageRectSrc='FEI-A-Seminars-R.jpg' goToCategory={goToCategory}
                    afterImage={<>
                        <Subcategory title={texts.feiSeminarsTitle} description={texts.feiSeminarsDescription} />
                        <Subcategory title={texts.nairobiTitle} description={texts.nairobiDescription} />
                    </>} />
            </Grid>
            <Grid item container>
                <CategoryCard filter={tags.workshops}
                    title={texts.workshopsTitle}
                    imageSquareSrc='FEI-A-Workshops-S.jpg'
                    imageRectSrc='FEI-A-Workshops-R.jpg' goToCategory={goToCategory}
                    beforeImage={<Subcategory title={texts.ptcTitle} description={texts.ptcDescription} />}
                    afterImage={<>
                        <Subcategory title={texts.volunteerTrainingTitle} description={texts.volunteerTrainingDescription} />
                        <Subcategory title={texts.clubLeadersTitle} description={texts.clubLeadersDescription} />
                    </>} />
            </Grid>
            <Grid item container>
                <CategoryCard filter={tags.criticalThinking}
                    title={texts.criticalThinkingTitle}
                    description={texts.criticalThinkingDescription}
                    imageSquareSrc='FEI-A-CriticalThinking-S.jpg'
                    imageRectSrc='FEI-A-CriticalThinking-R.jpg' goToCategory={goToCategory}
                    afterImage={<>
                        <Subcategory title={texts.movieClubTitle} description={texts.movieClubDescription} />
                        <Subcategory title={texts.bookClubTitle} description={texts.bookClubDescription} />
                        <Subcategory title={texts.contemporaryTopicsTitle} description={texts.contemporaryTopicsDescription} />
                    </>} />
            </Grid>
            <Grid item container>
                <CategoryCard filter={tags.summerCamps} title={texts.campsTitle}
                    imageSquareSrc='FEI-A-SummerCamp-S.jpg'
                    imageRectSrc='FEI-A-SummerCamp-R.jpg' goToCategory={goToCategory}
                    beforeImage={<Subcategory title={texts.summerDayCampsTitle} description={texts.summerDayCampsDescription} />}
                    afterImage={<Subcategory title={texts.miniCampsTitle} description={texts.miniCampsDescription} />} />
            </Grid>
            <Grid item container>
                <CategoryCard filter={tags.clubs}
                    title={texts.clubsTitle}
                    description={texts.clubsDescription}
                    imageSquareSrc='FEI-A-Clubs-S.jpg'
                    imageRectSrc='FEI-A-Clubs-R.jpg' goToCategory={goToCategory}
                    afterImage={<>
                        <Subcategory title={texts.clubsGirlsTitle} description={texts.clubsGirlsDescription} />
                        <Subcategory title={texts.clubsBoysTitle} description={texts.clubsBoysDescription} />
                    </>} />
            </Grid>
            <Grid item container>
                <CategoryCard filter={tags.specialEvents}
                    title={texts.specialEventsTitle}
                    description={texts.specialEventsDescription}
                    imageSquareSrc='FEI-A-SpecialEvents-S.jpg'
                    imageRectSrc='FEI-A-SpecialEvents-R.jpg' goToCategory={goToCategory}
                    afterImage={<Subcategory title={texts.specialEventsExTitle} description={texts.specialEventsExContents} />
                    } />
            </Grid>
            <Grid item>
                <Typography variant='h5' color='textSecondary' align='center' innerRef={titleRef}>
                    {texts.support}
                </Typography>
            </Grid>
            <Grid item container>
                <ContactVolunteerDonate tab={routeParams.tab} titleRef={titleRef} />
            </Grid>
        </Grid >
    );
}

const TitleCard = () => {
    const texts = useTextLang(aboutTexts)
    const theme = useTheme<Theme>()
    const desktop = useMediaQuery(theme.breakpoints.up('sm'))
    return (
        <InfoCard imageSquareSrc='FEI-A-MissionVision-S.jpg' imageRectSrc='FEI-A-MissionVision-R.jpg'
            imageAlt={texts.aboutTitle}
            beforeImage={
                <>
                    <Typography variant='h5' paragraph>
                        {texts.missionTitle}
                    </Typography>
                    <Typography variant='body1' color='textSecondary' paragraph={desktop}>
                        {texts.missionDescription}
                    </Typography>
                </>}
            afterImage={
                <>
                    <Typography variant='h5' paragraph>
                        {texts.visionTitle}
                    </Typography>
                    <Typography variant='body1' color='textSecondary' paragraph={desktop}>
                        {texts.visionDescription}
                    </Typography>
                </>}
        />
    )
}

type CategoryCardProps = {
    filter: string,
    title: string,
    imageSquareSrc: string,
    imageRectSrc: string,
    beforeImage?: React.ReactNode
    afterImage?: React.ReactNode
    description?: string,
    //examples: string,
    goToCategory: Consumer<string>,
    //imageAlign?: ImageAlign
}
function CategoryCard({ filter, title, description, imageSquareSrc, imageRectSrc, beforeImage, afterImage, goToCategory }: CategoryCardProps) {
    const ucTitle = title.toUpperCase()
    const texts = useTextLang(aboutTexts)

    const handleCategoryClick = useCallback(() => {
        goToCategory(filter)
    }, [goToCategory, filter])

    return (
        <InfoCard imageSquareSrc={imageSquareSrc} imageRectSrc={imageRectSrc} imageAlt={title}
            beforeImage={
                <>
                    <Typography variant='h5'>
                        {ucTitle}
                    </Typography>
                    <LineTypography variant='body1' color='textSecondary'>
                        {description}
                    </LineTypography>
                    {beforeImage && <>
                        <LineDivider />
                        {beforeImage}
                    </>}
                </>}
            afterImage={afterImage}
            action={
                <Button color='primary' disableRipple onClick={handleCategoryClick}>
                    {texts.goToCategoryButton(title)}
                </Button>
            }
        />
    )
}

/*
<Grid item container>
<CategoryCard filter={tags.workshop}
title={texts.workshopsTitle}
imageSquareSrc='A_Workshops_S.jpg'
imageRectSrc='A_Workshops_R.jpg' goToCategory={goToCategory}
beforeImage={<Subcategory title={texts.ptcTitle} description={texts.ptcDescription} />}
afterImage={<>
<Typography variant='h6'>
{texts.workshopsForLeaders}
</Typography>
<Typography variant='subtitle1'>
{texts.volunteerTrainingTitle}
</Typography>
<LineTypography variant='body1' color='textSecondary'>
{texts.volunteerTrainingDescription}
</LineTypography>
<Typography variant='subtitle1'>
{texts.clubLeadersTitle}
</Typography>
<LineTypography variant='body1' color='textSecondary'>
{texts.clubLeadersDescription}
</LineTypography>
</>} />
</Grid>
*/