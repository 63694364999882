import React, { useEffect, useCallback } from 'react'
import { useTextLang } from '../Contexts/LangContext';
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, CircularProgress, Grid, useTheme, Theme, useMediaQuery } from '@material-ui/core';
import { useBasicTexts } from './GlobalItems';
import useForm from '../Form/FormUtils';
import { Consumer, Callback, FieldType } from '../types';
import Text from '../Form/Components/Text';

const dialogTexts = {
    newQuestion: {
        en: 'New Question',
        es: 'Otra Pregunta'
    },
    questionPrompt: {
        en: `Please prove you're not a robot`,
        es: 'Por favor confirme que no es un robot'
    },
}

type BasicDialogProps = {
    open?: boolean,
    handleClose?: Callback,
    title?: string,
    contents?: string
}
export function BasicDialog({ open, handleClose, title, contents }: BasicDialogProps) {
    const basicTexts = useBasicTexts()
    return (
        <Dialog
            open={open === true}
            onClose={handleClose}
        >
            {title &&
                <DialogTitle>
                    {title}
                </DialogTitle>
            }
            {contents &&
                <DialogContent>
                    <DialogContentText>
                        {contents}
                    </DialogContentText>
                </DialogContent>
            }
            <DialogActions>
                <Button onClick={handleClose} color='primary'>
                    {basicTexts.ok}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

type NavigationDialogProps = {
    open: boolean,
    onCancel?: Callback,
    onContinue?: Callback,
    title?: string,
    contents?: string
}
export function NavigationDialog({ open, onCancel, onContinue, title, contents }: NavigationDialogProps) {
    const basicTexts = useBasicTexts()
    return (
        <Dialog
            open={open}
            onClose={onCancel}
        >
            {title &&
                <DialogTitle>
                    {title}
                </DialogTitle>
            }
            {contents &&
                <DialogContent>
                    <DialogContentText>
                        {contents}
                    </DialogContentText>
                </DialogContent>
            }
            <DialogActions>
                <Button onClick={onContinue} color='primary'>
                    {basicTexts.continue}
                </Button>
                <Button onClick={onCancel} color='primary'>
                    {basicTexts.cancel}
                </Button>
            </DialogActions>
        </Dialog>
    )
}



type CaptchaDialogType = {
    captcha: string,
    open: boolean,
    onSubmit: Consumer<Record<'answer', string>>,
    onCancel: Callback,
    onNewCaptcha: Callback,
    loading: boolean,
    errorMsg?: string
}
export function CaptchaDialog({ captcha, open, onSubmit, onCancel, onNewCaptcha, loading, errorMsg }: CaptchaDialogType) {
    const texts = useTextLang(dialogTexts)
    const basicTexts = useBasicTexts()

    const { values, setValue, errors, setError, handleSubmit } = useForm(
        { answer: { type: FieldType.Text, required: true } },
        onSubmit,
        { answer: '' },
        { answer: errorMsg })
    console.log(values, errors, errorMsg)
    useEffect(() => {
        console.log('setting error', errorMsg)
        setError('answer', errorMsg)
    }, [errorMsg, setError])

    const getNewCaptcha = useCallback(() => {
        setValue('answer', '')
        setError('answer', undefined)
        onNewCaptcha()
    }, [setValue, setError, onNewCaptcha])

    const theme = useTheme<Theme>()
    const matches = useMediaQuery(theme.breakpoints.down('xs'))

    return (
        <Dialog
            open={open}
            onClose={onCancel}
            scroll='paper'
            disableBackdropClick
            disableEscapeKeyDown>
            {!matches &&
                <DialogTitle>
                    {`${texts.questionPrompt}:`}
                </DialogTitle>
            }
            <DialogContent>
                <DialogContentText>
                    {matches ? `${texts.questionPrompt}: ${captcha}` : captcha}
                </DialogContentText>
                <Text field='answer' value={values.answer} setValue={setValue} onEnter={handleSubmit} errorMsg={errors.answer} autoFocus />
            </DialogContent>
            <DialogActions >
                {loading
                    ? <Grid container justify='center' >
                        <CircularProgress />
                    </Grid>
                    : <>
                        <Button onClick={onCancel} color='default'>
                            {basicTexts.cancel}
                        </Button>
                        <Button onClick={getNewCaptcha} color='primary'>
                            {texts.newQuestion}
                        </Button>
                        <Button onClick={handleSubmit} color='primary'>
                            {basicTexts.next}
                        </Button>
                    </>}
            </DialogActions>
        </Dialog>
    )
}