import React from 'react'
import moment from 'moment-timezone'
import { FieldType, OptionValue, isOptionValue, CheckBoxesValue } from "../types"
import { JSONt, Consumer, Callback } from "../types"
import { useTextLang } from "../Contexts/LangContext"
import { useState, useCallback } from "react"
import { Grid } from "@material-ui/core"
import { escape } from '../Global/GlobalItems'

const formUtilsTexts = {
    requiredError: {
        en: 'This is required.'
    },
    noOptionError: {
        en: 'Please select an option.'
    },
    lessThanOneOptionError: {
        en: 'You need to select at least 1 option.'
    },
    lessThanMinOptionsError: {
        en: (minChoices: number) => `You need to select at least ${minChoices} options.`
    },
    moreThanMaxOptionsError: {
        en: (maxChoices: number) => `You need to select at most ${maxChoices} options.`
    },
    optionError: {
        en: 'The option you selected does not exist.'
    },
    invalidDateError: {
        en: 'Please enter a valid date.'
    },
    dateBeforeMinimalError: {
        en: 'This date is before the minimum date.'
    },
    dateAfterMaximalError: {
        en: 'This date is after the maximum date.'
    },
    invalidEmailError: {
        en: 'Please enter a valid email.'
    },
    noPasswordError: {
        en: 'Please enter a password.'
    },
    shortPasswordError: {
        en: (minLength: number) => `Passwords should be at least ${minLength} characters.`
    },
    invalidPhoneError: {
        en: 'Please enter a valid phone number.'
    },
    noTextError: {
        en: 'This information is required.'
    },
    shortTextError: {
        en: (minLength: number) => `This should be at least ${minLength} characters.`
    },
    longTextError: {
        en: (maxLength: number) => `This should be at most ${maxLength} characters.`
    },
    invalidZipCodeError: {
        en: 'Please enter a valid zip code.'
    },
    lowNumberError: {
        en: (min: number) => `This should be at least ${min}`
    },
    highNumberError: {
        en: (max: number) => `This should be at most ${max}`
    },
}

type Rule = {
    type: FieldType,
    required?: boolean,
    dependent?: Record<string, any[]>
} & JSONt<any>

export default function useForm<
    FormValues extends Record<string, any> = Record<string, unknown>,
    FieldName extends keyof FormValues = keyof FormValues,
    FieldValue = FormValues[FieldName],
    >
    (rules: Record<FieldName, Rule>, onSubmit: Consumer<Record<FieldName, string>>, defaultValues: FormValues, defaultErrors: Partial<Record<FieldName, string>> = {}): {
        handleSubmit: Callback;
        setError: (name: FieldName, message?: string) => void;
        setValue: (name: FieldName, value: FieldValue) => void;
        values: FormValues;
        errors: Partial<Record<FieldName, string>>;
    } {
    const texts = useTextLang(formUtilsTexts)
    const [values, setValues] = useState(defaultValues)
    const [errors, setErrors] = useState<Partial<Record<FieldName, string>>>(defaultErrors)

    const validateField = useCallback((field: FieldName): string | undefined => {
        const value = values[field]
        // console.log('validating', field, value)
        const { type, required = false, dependent = {}, ...extras } = rules[field]
        const isRequired = required ||
            Object.entries(dependent).reduce(
                (req, [reqField, reqValues]) => {
                    const currVal = values[reqField]
                    let value = currVal
                    if (isOptionValue(currVal)) {
                        value = currVal.value
                    }
                    return req || reqValues.includes(value) || (value && reqValues.length === 0)
                }, false as boolean)
        // console.log(field, 'is required: ', required, isRequired, dependent)
        if (value === null || value === undefined) {
            if (isRequired) {
                return texts.requiredError
            } else {
                return undefined
            }
        }
        switch (type) {
            case FieldType.Checkbox:
                const numChoices = Object.values(value as CheckBoxesValue).filter(value => value).length
                const { minChoices = 0, maxChoices = 9999 } = extras
                if (numChoices < minChoices) {
                    if (minChoices === 1)
                        return texts.lessThanOneOptionError
                    return texts.lessThanMinOptionsError(minChoices)
                } else if (numChoices > maxChoices) {
                    return texts.moreThanMaxOptionsError(maxChoices)
                } else if (isRequired) {
                    return texts.noOptionError
                }
                break
            case FieldType.SingleCheckbox:
                if (isRequired && !value) {
                    return texts.noOptionError
                }
                break
            case FieldType.Date:
                if (moment.isMoment(value)) {
                    const { minDate = moment('1900-01-01'), maxDate = moment('2100-01-01'), lowDateError, highDateError } = extras
                    console.log(highDateError)
                    if (!value.isValid())
                        return texts.invalidDateError
                    else if (value.isBefore(minDate)) {
                        return lowDateError || texts.dateBeforeMinimalError
                    } else if (value.isAfter(maxDate)) {
                        return highDateError || texts.dateAfterMaximalError
                    }
                } else {
                    return texts.invalidDateError
                }
                break
            case FieldType.Email:
                if (value && value !== '') {
                    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    if (!re.test(value as string))
                        return texts.invalidEmailError
                } else if (isRequired) {
                    return texts.invalidEmailError
                }
                break
            case FieldType.Password:
                if (value && value !== '') {
                    const { minLength = 8 } = extras
                    if (value.length < minLength)
                        return texts.shortPasswordError(minLength)
                } else if (isRequired) {
                    return texts.noPasswordError
                }
                break
            case FieldType.Phone:
                if (value && value !== '') {
                    const re = /^\([1-9]\d\d\) \d\d\d-\d\d\d\d$/
                    if (!re.test(value as string))
                        return texts.invalidPhoneError
                } else if (isRequired) {
                    return texts.invalidPhoneError
                }
                break
            case FieldType.RadioButton:
                if ((!value || value === '') && isRequired) {
                    return texts.noOptionError
                }
                break
            case FieldType.Select:
                if ((!isOptionValue(value) || value.value === '') && isRequired) {
                    return texts.noOptionError
                }
                break
            case FieldType.Number:
                const { min = 0, max = 9999 } = extras
                if (value < min) {
                    return texts.lowNumberError(min)
                } else if (value > max) {
                    return texts.highNumberError(max)
                }
                break
            case FieldType.Text:
                if (value && value !== '') {
                    const { minLength = 0, maxLength = 9999 } = extras
                    if (value.length < minLength) {
                        return texts.shortTextError(minLength)
                    } else if (value.length > maxLength) {
                        return texts.longTextError(maxLength)
                    }
                } else if (isRequired) {
                    return texts.noTextError
                }
                break
            case FieldType.ZipCode:
                if (value && value !== '') {
                    const re = /^\d\d\d\d\d$/
                    if (!re.test(value as string))
                        return texts.invalidZipCodeError
                } else if (isRequired) {
                    return texts.invalidZipCodeError
                }
                break
        }
        // console.log('no error in ', field)
        return undefined
    }, [rules, texts, values])

    const validate = useCallback(() => {
        // console.log('validating fields')
        const errors = Object.keys(values).reduce((errors, field) => ({
            ...errors, [field]: validateField(field as FieldName)
        }), {})
        // console.log('errors: ', errors)
        setErrors(errors)
        if (Object.values(errors).find(value => value !== undefined) !== undefined) {
            return false
        } else {
            return true
        }
    }, [validateField, values])

    const getValue = useCallback((field: FieldName): string | undefined => {
        const value = values[field]
        const { type } = rules[field]
        switch (type) {
            case FieldType.Checkbox:
                if (value) {
                    const mappedVal =
                        Object.entries(value as JSONt<boolean>)
                            .map(
                                ([field, checked]) => checked && field,
                                [] as (string | false)[])
                            .filter(val => val)
                            .join(',')
                    return `[${mappedVal}]`
                }
                break
            case FieldType.SingleCheckbox:
                if (value)
                    return 'selected'
                break
            case FieldType.Date:
                if (moment.isMoment(value)) {
                    return value.format()
                }
                break
            case FieldType.Select:
                if (value) {
                    return (value as OptionValue).value
                }
                break
            case FieldType.Number:
                if (value !== undefined) {
                    return `${value}`
                }
                break
            case FieldType.Email:
            case FieldType.Password:
            case FieldType.Phone:
            case FieldType.RadioButton:
            case FieldType.Text:
            case FieldType.ZipCode:
                return value
        }
        return undefined
    }, [rules, values])

    const getValues = useCallback(() => {
        return Object.keys(values).reduce((values, field) => {
            const value = getValue(field as FieldName) || ''
            return { ...values, [field]: escape(value) }
        }, {}) as Record<FieldName, string>
    }, [getValue, values])

    const handleSubmit = useCallback(() => {
        if (validate()) {
            onSubmit(getValues())
        }
    }, [getValues, onSubmit, validate])

    const setError = useCallback((field: FieldName, error?: string) => {
        // console.log('setting error in', field, 'to', error)
        setErrors(errors => ({ ...errors, [field]: error }))
    }, [])

    const setValue = useCallback((field: FieldName, value: FieldValue) => {
        setValues(values => ({ ...values, [field]: value }))
        setErrors(errors => ({ ...errors, [field]: undefined }))
    }, [])

    return { setError, setValue, handleSubmit, values, errors }
}

export function FormatForm({ children }: React.PropsWithChildren<{}>) {
    return (
        <Grid container direction='column' spacing={1}>
            {
                React.Children.map(children, child => {
                    if (child) {
                        return (
                            <Grid item style={{ width: '100%' }}>
                                {child}
                            </Grid>
                        )
                    } else {
                        return null
                    }
                })
            }
        </Grid>
    )
}