import React, { useCallback, useState, useEffect } from 'react';
import { MatchProps } from '../../types'
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography, Divider, Button, List, ListItem, ListItemText } from '@material-ui/core';
import { PersonEmail } from '../admin-types';
import { adminQuery, adminMutation } from '../../Global/ServerConnection';
import LoadingPage from '../../Global/LoadingPage';
import { useRouter } from '../../Contexts/RouterContext'
import { adminRoutes } from '../Admin'
import { NavigationDialog, BasicDialog } from '../../Global/Dialogs';

const useStyles = makeStyles(theme => ({
    recipientsContainer: {
        padding: theme.spacing(3, 0),
    },
}))

export default function SendEmail({ match }: MatchProps) {
    const emailid = match.params.id
    const classes = useStyles()
    const [loading, setLoading] = useState(false)
    const [recipients, setRecipients] = useState<PersonEmail[]>([])
    const [sendEmailOpen, setSendEmailOpen] = useState(false)
    const [successOpen, setSuccessOpen] = useState(false)
    const { navigateTo } = useRouter()

    useEffect(() => {
        return adminQuery('getEmailRecipients', setRecipients, `emailid:"${emailid}"`, 'personid,firstName,lastName,email,status')
    }, [emailid])

    const sendPreview = useCallback(() => {
        setLoading(true)
        const processSend = (success?: boolean) => {
            setLoading(false)
        }
        return adminMutation('resendPreviewEmail', processSend, `emailid:"${emailid}"`)
    }, [emailid])

    const sendEmail = useCallback(() => {
        setSendEmailOpen(false)
        setLoading(true)
        const processSend = (success?: boolean) => {
            if (success) {
                setSuccessOpen(true)
            }
            setLoading(false)
        }
        return adminMutation('sendEmail', processSend, `emailid:"${emailid}"`)
    }, [emailid])

    const openSendEmailDialog = useCallback(() => {
        setSendEmailOpen(true)
    }, [])

    const closeSendEmailDialog = useCallback(() => {
        setSendEmailOpen(false)
    }, [])

    const finish = useCallback(() => {
        navigateTo(adminRoutes.main)
    }, [navigateTo])

    return (
        <>
            <Grid item>
                <Typography variant='h6' color='textSecondary'>
                    {'Verify recipients'}
                </Typography>
            </Grid>
            <Grid item container direction='row' spacing={2} className={classes.recipientsContainer}>
                <Grid item xs>
                    <DisplayRecipients recipients={recipients} listNumber={0} quantity={2} />
                </Grid>
                <Grid item xs>
                    <DisplayRecipients recipients={recipients} listNumber={1} quantity={2} />
                </Grid>
            </Grid>
            <Grid item container direction='row' justify='space-around'>
                <Button color='primary' onClick={sendPreview}>
                    {'Send Preview'}
                </Button>
                <Button color='primary' onClick={openSendEmailDialog}>
                    {'Send Email'}
                </Button>
            </Grid>
            <LoadingPage loading={loading} />
            <NavigationDialog open={sendEmailOpen} title='Send Email' contents='Are you sure you want to send the email' onContinue={sendEmail} onCancel={closeSendEmailDialog} />
            <BasicDialog open={successOpen} title='Success!' contents='Email was sent successfully' handleClose={finish} />
        </>
    )
}

type DisplayRecipientsProps = { recipients: PersonEmail[], listNumber: number, quantity: number }
function DisplayRecipients({ recipients, listNumber, quantity }: DisplayRecipientsProps) {
    return (
        <List>
            <Divider />
            {recipients.map(({ personid, firstName, lastName, email }, index) =>
                (index % quantity === listNumber) && (
                    <React.Fragment key={personid}>
                        <ListItem>
                            <ListItemText primary={`${firstName} ${lastName} - ${email}`} />
                        </ListItem>
                        <Divider />
                    </React.Fragment>
                )
            )}
        </List>
    )
}