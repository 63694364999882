import React from 'react';
import FullPageFormat from '../Global/FullPageFormat';
import { Route, Redirect, Switch } from 'react-router';
import AdminMain from './AdminMain'
import EventNavigation from './EventSettings/EventNavigation'
import ViewEvent from './EventSettings/ViewEvent';
import ViewParticipants from './EventSettings/ViewParticipants';
import PrintParticipants from './EventSettings/PrintParticipants';
import EditFamily from './FamilySettings/EditFamily';
import ViewFamilyPage from './FamilySettings/ViewFamily';
import EventsFamily from './FamilySettings/EventsFamily';
import EditEvent from './EventSettings/EditEvent';
import ApproveFamilies from './FamilySettings/ApproveFamilies';
import FindFamily from './FamilySettings/FindFamily';
import ReportNavigation from './Reports/ReportNavigation';
import ReportEvents from './Reports/ReportEvents';
import ReportFamilies from './Reports/ReportEvents';
import ReportEmailsSent from './Reports/ReportEmails';
import ViewPersonPage from './FamilySettings/ViewPerson';
import ViewInvoicePage from './Accounting/ViewInvoice';
import ViewPaymentPage from './Accounting/ViewPayment';
import { SideBarProvider } from './Common/SideBarContext'
import AddTeachers from './EventSettings/AddTeachers';
import CreateEmail from './Email/CreateEmail';
import SendEmail from './Email/SendEmail';
import ViewNotifications from './Notifications/ViewNotifications';
import EditNotification from './Notifications/EditNotification';


const adminRoutes = {
    home: '/admin',
    main: '/admin/main',
    eventNavigation: '/admin/event-navigation',
    viewEvent: (id: string) => `/admin/view-event/${id}`,
    viewEventParticipants: (id: string) => `/admin/view-event-participants/${id}`,
    printEventParticipants: (id: string) => `/admin/print-event-participants/${id}`,
    editEvent: (id: string) => `/admin/edit-event/${id}`,
    copyEvent: (id: string) => `/admin/copy-event/${id}`,
    viewFamily: (id: string) => `/admin/view-family/${id}`,
    eventsFamily: (id: string) => `/admin/events-family/${id}`,
    editFamily: (id: string) => `/admin/edit-family/${id}`,
    viewPerson: (id: string) => `/admin/view-person/${id}`,
    viewInvoice: (id: string) => `/admin/view-invoice/${id}`,
    viewPayment: (id: string) => `/admin/view-payment/${id}`,
    approveFamilies: '/admin/approve-families',
    addTeachers: '/admin/add-teachers',
    findFamily: '/admin/find-family',
    sendEmail: (id: string) => `/admin/send-email/${id}`,
    createEmail: `/admin/create-email`,
    viewNotifications: '/admin/view-notifications',
    editNotification: (id: string) => `/admin/edit-notification/${id}`,
    reportNavigation: '/admin/reports-navigation',
    reportEvents: `/admin/reports/events`,
    reportFamilies: '/admin/reports/families',
    reportEmailsSent: '/admin/reports/emails-sent',
}

const adminPaths = {
    home: '/admin',
    main: '/admin/main',
    eventNavigation: '/admin/event-navigation',
    viewEvent: '/admin/view-event/:id',
    editEvent: '/admin/edit-event/:id',
    copyEvent: '/admin/copy-event/:id',
    viewEventParticipants: '/admin/view-event-participants/:id',
    printEventParticipants: `/admin/print-event-participants/:id`,
    viewFamily: '/admin/view-family/:id',
    eventsFamily: '/admin/events-family/:id',
    editFamily: '/admin/edit-family/:id',
    viewPerson: '/admin/view-person/:id',
    viewInvoice: '/admin/view-invoice/:id',
    viewPayment: '/admin/view-payment/:id',
    approveFamilies: '/admin/approve-families',
    addTeachers: '/admin/add-teachers',
    findFamily: '/admin/find-family',
    sendEmail: '/admin/send-email/:id',
    createEmail: '/admin/create-email',
    viewNotifications: '/admin/view-notifications',
    editNotification: '/admin/edit-notification/:id',
    reportNavigation: '/admin/reports-navigation',
    reportEvents: '/admin/reports/events',
    reportFamilies: '/admin/reports/families',
    reportEmailsSent: '/admin/reports/emails-sent',
}

export default function Admin() {
    return (
        <div>
            <Switch>
                <Route exact path={adminPaths.printEventParticipants} component={PrintParticipants} />
                <Route path='/admin/reports/*' component={ReportRoutes} />
                <Route path='*' component={FormattedRoutes} />
            </Switch>
        </div>
    )
}

function FormattedRoutes() {
    return (
        <FullPageFormat selectable>
            <Switch>
                <Route exact path={adminPaths.eventNavigation} component={EventNavigation} />
                <Route exact path={adminPaths.viewEvent} component={ViewEvent} />
                <Route exact path={adminPaths.viewEventParticipants} component={ViewParticipants} />
                <Route exact path={adminPaths.viewFamily} component={ViewFamilyPage} />
                <Route exact path={adminPaths.eventsFamily} component={EventsFamily} />
                <Route exact path={adminPaths.editFamily} component={EditFamily} />
                <Route exact path={adminPaths.viewPerson} component={ViewPersonPage} />
                <Route exact path={adminPaths.viewInvoice} component={ViewInvoicePage} />
                <Route exact path={adminPaths.viewPayment} component={ViewPaymentPage} />
                <Route exact path={adminPaths.editEvent} component={EditEvent} />
                <Route exact path={adminPaths.main} component={AdminMain} />
                <Route exact path={adminPaths.createEmail} component={CreateEmail} />
                <Route exact path={adminPaths.sendEmail} component={SendEmail} />
                <Route exact path={adminPaths.approveFamilies} component={ApproveFamilies} />
                <Route exact path={adminPaths.addTeachers} component={AddTeachers} />
                <Route exact path={adminPaths.findFamily} component={FindFamily} />
                <Route exact path={adminPaths.reportNavigation} component={ReportNavigation} />
                <Route exact path={adminPaths.viewNotifications} component={ViewNotifications} />
                <Route exact path={adminPaths.editNotification} component={EditNotification} />
                <Redirect from='*' to={adminRoutes.main} />
            </Switch>
        </FullPageFormat>
    )
}

function ReportRoutes() {
    return (
        <FullPageFormat size='full' selectable>
            <SideBarProvider>
                <Switch>
                    <Route exact path={adminPaths.reportEvents} component={ReportEvents} />
                    <Route exact path={adminPaths.reportFamilies} component={ReportFamilies} />
                    <Route exact path={adminPaths.reportEmailsSent} component={ReportEmailsSent} />
                    <Redirect from='*' to={adminRoutes.main} />
                </Switch>
            </SideBarProvider>
        </FullPageFormat>
    )
}

export { adminRoutes }